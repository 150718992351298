import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

@Component({
    selector: 'val-drop-down',
    templateUrl: './val-drop-down.component.html',
    styleUrls: ['./val-drop-down.component.scss'],
    animations: [
        trigger(
            'valDropDownVoidAnimation',
            [
                state('in', style({ height: '*' })),
                state('void', style({ height: '0' })),
                transition('void=>*', animate('200ms ease-out')),
            ]
        ),
        trigger(
            'valDropDownAnimation',
            [
                state('visible', style({ height: '*' })),
                state('hidden', style({ height: '0' })),
                transition('visible=>hidden', animate('200ms ease-in')),
            ]
        )
    ]
})
export class ValDropDownComponent implements AfterViewInit {
    @ViewChild('vc', { read: ViewContainerRef, static: true }) vc!: ViewContainerRef;
    @HostBinding("class") private get cssClass() {
        return "position-absolute";
    }
    @HostBinding("style.top.px") private get top() {
        return this._top;
    }
    @HostBinding("style.left.px") private get left() {
        return this._left;
    }
    @HostBinding("style.z-index") private get cssZindex() {
        return "1056";
    }
    private _top = 0;
    private _left = 0;
    public btn!: HTMLElement;
    public animationState: "visible" | "hidden" = "visible";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onAnimationDone = new EventEmitter<any>();
    public close = new EventEmitter();
    public class!: string;
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _elref: ElementRef
    ) { }
    private _setPos = () => {
        const btnPos: DOMRect = this.btn.getBoundingClientRect();
        const ddWidth: number = this._elref.nativeElement.scrollWidth;
        const ddHeight: number = this._elref.nativeElement.scrollHeight;
        const docPos: DOMRect = this._document.body.getBoundingClientRect();

        this._top = btnPos.top + btnPos.height - docPos.top;
        if (docPos.width < btnPos.left + ddWidth) {
            if (btnPos.right - ddWidth < 0) {
                this._left = 0;
            } else {
                this._left = btnPos.right - ddWidth;
            }
        } else {
            if (btnPos.right - ddWidth < 0) {
                this._left = 0;
            } else {
                this._left = (btnPos.left + btnPos.width) - ddWidth;
            }
        }
        if (docPos.height < this._top + ddHeight) {
            this._top = (btnPos.top - ddHeight) - docPos.top;
        }
    }
    ngAfterViewInit(): void {
        this._setPos();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public animationDone = (event: any) => {
        if (event.fromState === "visible") {
            this.close.emit();
        }
    }
    @HostListener('document:click', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onGlobalClick(e: any) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const elementRefInPath = e.composedPath().find((e: any) =>
            e === this._elref.nativeElement || e === this.btn);
        if (!elementRefInPath) {
            this.animationState = "hidden";
        }
    }
    @HostListener('window:resize')
    public onGlobalResize() {
        this._setPos();
    }
    @HostListener('document:mouseup')
    public onGlobalMouseUp() {
        this._setPos();
    }
    @HostListener('document:keyup')
    public onGlobalKeyUp() {
        this._setPos();
    }
    @HostListener('window:scroll')
    public onGlobalScroll() {
        this._setPos();
    }
}

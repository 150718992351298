export class PointsDataModel {
    constructor(
        public points: number | null = null,
        public promoPoints: number | null = null,
        public pointsAvailable: number | null = null,
        public pointsUsed: number | null = null,
        public pointsReserved: number | null = null,
        public valueForPoints: number | null = null,
    ) { }
}

<div class="row">
  <div class="col-12 col-sm-6 pe-sm-5 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6>
          Personal details
        </h6>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">From</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerAddress">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">To</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model && model.to && model.to.length>0?model.to[0]:''">
          </div>
        </div>
        <ng-container *ngFor="let to of model?.to; first as isFirst">
          <div *ngIf="!isFirst" class="row">
            <label class="col-auto col-form-label label-communication text-nowrap me-3 invisible">To</label>
            <div class="col">
              <input type="text" disabled class="form-control-plaintext" [ngModel]="to">
            </div>
          </div>
        </ng-container>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Cc</label>
          <div class="col-auto">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model && model.cc && model.cc.length>0?model.cc[0]:''">
          </div>
        </div>
        <ng-container *ngFor="let cc of model?.cc; first as isFirst">
          <div *ngIf="!isFirst" class="row">
            <label class="col-auto col-form-label label-communication text-nowrap me-3 invisible">Cc</label>
            <div class="col-auto">
              <input type="text" disabled class="form-control-plaintext" [ngModel]="cc">
            </div>
          </div>
        </ng-container>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Subject</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.subject">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">First name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerFirstName">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerLastName">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6>
          Details
        </h6>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Work time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="(model?.workTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Queue time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="(model?.queueTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last agent</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.lastAgent">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-4">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6>
          Contact
        </h6>
        <div class="row">
          <div class="col-6 col-sm-4">
            <div class="row">
              <div class="col-12 mt-3">
                <label class="form-label">Contact Id</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="contactId">
              </div>
              <div class="col-12 mt-3">
                <label class="form-label">Channel</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.channel">
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-4">
            <div class="row">
              <div class="col-12 mt-3">
                <label class="form-label">Start date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.startDate | date:'short'">
              </div>
              <div class="col-12 mt-3">
                <label class="form-label">End date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.endDate | date:'short'">
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-4">
            <div class="row">
              <div class="col-12 mt-3">
                <label class="form-label">State</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.state">
              </div>
              <div class="col-12 mt-3">
                <label class="form-label">Direction</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.direction">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-4">
		<div class="card card-shadow border-0 mb-4">
			<div class="card-body">
				<h6>
					Dispositions
				</h6>
				<div *ngIf="communication?.contacts?.length === 0; else dispositionTable">
					<span class="sendto-cc-text-style"> <i>There is no disposition notes!</i></span>
				</div>
				<ng-template #dispositionTable>
					<p-table [value]="communication?.contacts!" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll">
						<ng-template pTemplate="header">
						  <tr>
							<th style="width: 2rem" pResizableColumn></th>
							<th style="width: 2rem" pResizableColumn>
							  Name
							</th>
							<th style="width: 2rem" pResizableColumn>
							  Agent
							</th>
							<th pResizableColumn>
							  Note
							</th>
						  </tr>
						</ng-template>
						<ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
						  <tr>
							<td>{{rowIndex + 1}}</td>
							<td>{{contact.disposition.dispositionName}}</td>
							<td>{{contact.agent}}</td>
							<td>{{contact.disposition.notes}}</td>
						  </tr>
						</ng-template>
					  </p-table>
				</ng-template>
			</div>
		</div>
  </div>
  <div class="col-12 ps-5 mt-4 pb-5">
    <div class="row">
      <div class="col-auto me-5">
        <h6 class="mb-0 mt-1 text-nowrap">
          Email body
        </h6>
      </div>
      <ng-container *ngIf="model?.attachments">
        <ng-container *ngFor="let attachment of model?.attachments">
          <div class="col-auto pe-2 pb-3">
            <button type="button" class="btn btn-sm btn-secondary">
              {{attachment}}
              <i (click)="processAttachment(attachment,'download',$event)" class="vi vi-file-download-dark ms-3"></i>
              <i (click)="processAttachment(attachment,'open',$event)" class="vi vi-open-in-new ms-3"></i>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="col-12">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <iframe class="w-100" style="height: 20rem;" [srcdoc]="(model?.emailBody??'') | htmlSanitizer"></iframe>
      </div>
    </div>
  </div>
</div>
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Renderer2 } from '@angular/core';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { CommunicationEmailAttachmentRequestModel } from '../models/mdp/communication-email-attachment-request.model';
import { CommunicationEmailAttachmentResponseModel } from '../models/mdp/communication-email-attachment-response.model';
import { CommunicationEmailDetailsModel } from '../models/mdp/communication-email-details.model';
import { ValUtilsService } from '../services/val-utils.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { CommunicationModel } from '../models/mdp/communication.model';


@Component({
	selector: 'vrc-communication-email-details',
	templateUrl: './vrc-communication-email-details.component.html',
})
export class VrcCommunicationEmailDetailsComponent {
	private _model: CommunicationEmailDetailsModel | null = null;
	@Input() set model(m: CommunicationEmailDetailsModel | null) {
		this._model = m;
	}
	get model() {
		return this._model;
	}
	@Input() contactId!: number;
	@Input() status!: string;
	@Input() communication: CommunicationModel | null = null;

	constructor(
		public st: VrcStorageService,
		private _srvc: VrcDataService,
		private _ms: MessageService,
		private _u: ValUtilsService,
		private _rend: Renderer2,
	) { }

	public processAttachment = (att: string, operation: 'open' | 'download', e: Event) => {
		e.preventDefault();
		if (this.model && this.model.emailGuid &&
			this.status && this.contactId && att) {
			const contentType = this._getAttachmentContentType(att);
			const attachmentRequest =
				new CommunicationEmailAttachmentRequestModel(
					att, this.model.emailGuid, this.status, this.contactId
				);

			this._srvc.getEmailAttachment(attachmentRequest)
				.pipe(
					take(1),
					tap((result) => {
						if (operation === "open") {
							this._openAttachment(result, contentType);
						}
						else if (operation === "download") {
							this._downloadAttachment(result, contentType, att);
						}
					}),
					catchError((err: HttpErrorResponse) => {
						this._ms.add({
							severity: 'error',
							summary: 'Error',
							detail: err?.error?.message,
						});
						return of(null);
					})
				)
				.subscribe();
		}
		else {
			this._ms.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Error: Cannot process attachment',
			});
		}
	}

	private _getAttachmentContentType = (attachmentName: string): string => {
		let contentType = 'application/octet-stream';

		attachmentName = attachmentName.trim().toLowerCase();

		if (attachmentName) {
			const extension = this._u.getExtension(attachmentName);

			// eslint-disable-next-line no-prototype-builtins
			if (extension && this.st.supportedToOpenContentTypes.hasOwnProperty(extension)) {
				contentType = this.st.supportedToOpenContentTypes[extension];
			}
		}

		return contentType;
	}

	private _openAttachment = (response: CommunicationEmailAttachmentResponseModel, contentType: string) => {
		if (response && response.base64Data && contentType) {
			try {
				const blob = this._u.convertBase64ToBlob(response.base64Data, contentType);
				const url = URL.createObjectURL(blob);

				window.open(url, '_blank', 'noopener');

				setTimeout(() => {
					URL.revokeObjectURL(url);
				}, 1000);
			} catch {
				this._ms.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error: Cannot open the file.',
				});
			}
		}
		else {
			this._ms.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Error: Cannot open the file. Attachment and/or its content type is not defined.',
			});
		}
	}

	private _downloadAttachment = (response: CommunicationEmailAttachmentResponseModel, contentType: string, name: string) => {
		if (response && response.base64Data && contentType) {
			try {
				const blob = this._u.convertBase64ToBlob(response.base64Data, contentType);
				const url = URL.createObjectURL(blob);

				const link = this._rend.createElement('a');
				this._rend.setAttribute(link, 'download', name);
				this._rend.setAttribute(link, 'href', url);
				this._rend.setAttribute(link, 'target', '_blank');
				this._rend.appendChild(document.body, link);
				link.click();
				this._rend.removeChild(document.body, link)

				setTimeout(() => {
					URL.revokeObjectURL(url);
				}, 1000);
			} catch {
				this._ms.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error: Cannot download the file.',
				});
			}
		}
		else {
			this._ms.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Error: Cannot download the file. Attachment and/or its content type is not defined.',
			});
		}
	}
}
import { Pipe, PipeTransform } from '@angular/core';
import { LoyalityDetailsModel } from '../models/mdp/loyality-details.model';

@Pipe({ name: 'selectLoyaltyProgram' })
export class SelectLoyaltyProgramPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(loyalties: Array<LoyalityDetailsModel>, program: string, not = false, trigger = 0): LoyalityDetailsModel | null {
        if (loyalties && loyalties.length > 0) {
            return loyalties.filter(x => not ? x.program !== program : x.program === program).reduceRight((_a:LoyalityDetailsModel | null, b) => b, null);
        }
        return null;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { InterestModel } from '../models/mdp/interest.model';


@Pipe({ name: 'selectedInterests' })
export class SelectedInterestsPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(interests: Array<InterestModel>, category: string, trigger=0): Array<InterestModel> {
        return interests.filter(x => x.isSelected && x.category === category);
    }
}

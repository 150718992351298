import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, delay, finalize, take, tap } from 'rxjs/operators';

import { ClaimPointsModel } from '../models/claim-points.model';
import { IdNameSelectedModel } from '../models/id-name-selected.model';
import { AccountModel } from '../models/mdp/account.model';
import { HierarchyForEmployeeModel } from '../models/mdp/hierarchy-for-employee.model';
import { LoyalityDetailsModel } from '../models/mdp/loyality-details.model';
import { ReservationDetailsModel } from '../models/mdp/reservation-details.model';
import { ParamsModel } from '../models/params.model';
import { VrcConfigService } from '../services/vrc-config.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { TransferPointsModel } from './../models/transfer-points.model';
import { VrcInformationComponent } from './vrc-information.component';
import { ValComponentBaseComponent } from '../common/modules/val-component-base/val-component-base.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vrc-loyality-details',
  templateUrl: './vrc-loyality-details.component.html'
})
export class VrcLoyalityDetailsComponent implements OnInit {
@ViewChildren('v') private _validationFields!: QueryList<ValComponentBaseComponent>;
  public menu: Array<IdNameSelectedModel> = [];
  public model: LoyalityDetailsModel | null = new LoyalityDetailsModel;
  public selectedMenuId: number | string = 1;
  public reservationsLoading = false;
  public hierarchyLoading = false;
  public reservationsTotalRecords = 0;
  public reservations!: Array<ReservationDetailsModel>;
  public modalVisible = false;
  public claimPointModalVisible = false;
  public ambassadorLinkModalVisible = false;
  public transferPointsModel: TransferPointsModel = new TransferPointsModel();
  public hierarchyTotalRecords = 0;
  public hierarchys!: HierarchyForEmployeeModel[];
  public parentLoyaliteAccount!: AccountModel;
  public newLoyatyEmail: string | null = "";
  public errorMessageEmail = "Email is not in good format!";
  public loyaltyForgottenPasswordModalVisible = false;
  public loyaltyNewMailModalVisible = false;
  public layaltyNewEmailConfirmation = false;
  public reservation!: ReservationDetailsModel;
  public claimPointsModel!: ClaimPointsModel;
  public awaitingResponse = false;
  
  constructor(
    private _srvc: VrcDataService,
    private _ms: MessageService,
    private _ar: ActivatedRoute,
    public st: VrcStorageService,
    private _config: VrcConfigService
  ) { }
  public ngOnInit(): void {
    this._ar.params.pipe(
      delay(0),
      tap(x => {
        this.st.selectedLoyaltyNumber = x['id'];
        this.model = this.st.model?.loyaltiesList?.filter(x => x.cardInformation?.cardNumber === this.st.selectedLoyaltyNumber).reduceRight((_a: LoyalityDetailsModel | null, b) => b, null)??null;
      })).subscribe();

    // setTimeout(() => {
    //   this._st.selectedLoyaltyNumber = this._ar.snapshot.params.id;
    //   this.model = this._st.model.loyaltiesList
    //     .filter(x => x.cardInformation.cardNumber === this._st.selectedLoyaltyNumber).reduceRight((_a, b) => b, null);
    // });


    this.menu = [
      new IdNameSelectedModel(1, "Information", true),
      new IdNameSelectedModel(2, "Reservation&Stays"),
    ];
  }
  public menuClick = (m: IdNameSelectedModel, e: MouseEvent) => {
    e.preventDefault();
    this.menu.forEach(x => x.isSelected = false);
    m.isSelected = true;
    this.selectedMenuId = m.id??0;
  }

  public openAmbassadorLink(countryCode: string, loyalityCardNumber: string | null) {
	window.open(this.getAmbassadorLink(countryCode, loyalityCardNumber), "_blank");
  }

  public getAmbassadorLink(countryCode: string, loyalityCardNumber: string | null) {
	if (loyalityCardNumber == null) return;
	const encodedLoyalityCardNumber = btoa(loyalityCardNumber);

	let url = "";
	switch(countryCode) {
		case "HR": url = environment.web_url + "/hr/ambasador-forma-za-novog-korisnika?data=" + encodedLoyalityCardNumber; break;
		case "DE": url = environment.web_url + "/de/ambasador-forma?data=" + encodedLoyalityCardNumber; break;
		case "EN": url = environment.web_url + "/en/ambasador-forma?data=" + encodedLoyalityCardNumber; break;
		case "IT": url = environment.web_url + "/it/ambasador-forma?data=" + encodedLoyalityCardNumber; break;
		case "SI": url = environment.web_url + "/si/ambasador-forma?data=" + encodedLoyalityCardNumber; break;
	}

	return url;
  }

  public reservationsTableLazyLoad = (p: ParamsModel): void => {
    p = p ? p : new ParamsModel();
    p.extraData = this.st.model?.id;
    p.filters['loyalty.cardNumber'] =
      [{
        value: this.model?.cardInformation?.cardNumber,
        matchMode: "equals",
        operator: "and"
      }];
    this.getReservations(p);
  };
  public getReservations = (p: ParamsModel): void => {
    this.reservationsLoading = true;
    this._srvc.getReservationPage(p).pipe(
      take(1),
      tap(x => {
        this.reservationsTotalRecords = x.totalRecords;
        this.reservationsLoading = false;
        this.reservations = x.data;
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  };


  public transferPoints = () => {
    if (!this.transferPointsModel || !this.transferPointsModel.toLoyaltyCardNumber) {
      return;
    }
	if (isNaN(Number(this.transferPointsModel.pointsTransfer ?? 'x')) && isNaN(Number(this.transferPointsModel.amountTransfer ?? 'x'))) {
		this._ms.add({
		severity: 'error',
		summary: 'Error',
		detail: "Invalid value",
		});
		this.transferPointsModel.pointsTransfer = null;
		this.transferPointsModel.amountTransfer = null;
		return;
	}
	this.awaitingResponse = true;
    this._srvc
      .transferPoints(this.transferPointsModel)
      .pipe(
        take(1),
        tap(() => {
          this.modalVisible = false;
          this._ms.add({ severity: 'success', detail: 'Points transferred' });
        }),
        catchError((err: HttpErrorResponse) => {
          this.modalVisible = false;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        }),
		finalize(() => {
			this.awaitingResponse = false;
		})
      ).subscribe();
  }

  public openTransferModal = () => {
    this.transferPointsModel = new TransferPointsModel(this.model?.cardInformation?.cardNumber, null, null);
    this.modalVisible = true;
  }
  public openClaimPointsModal = (reservation: ReservationDetailsModel) => {
    this.claimPointsModel = new ClaimPointsModel(
      this.model?.cardInformation?.cardNumber,
      reservation.property?.code,
      reservation.unit?.code,
      reservation.checkIn,
      reservation.checkOut,
      reservation?.mappingFields?.phobs?.code ? reservation?.mappingFields?.phobs?.code : reservation?.mappingFields?.opera?.CONFIRMATION_NO,
      reservation?.costs?.total?.toString()
    );
    this.claimPointModalVisible = true;
  }

  public claimPoints = () => {
    this._srvc.claimPoints(this.claimPointsModel)
      .pipe(
        take(1),
        tap(() => {
          this._ms.add({ severity: 'success', detail: 'Points claimed' });
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  public getAccountByLoyalityparant(parLoyalityId: string) {
    this._srvc.getAccountByLoyalityparant(parLoyalityId).pipe(
      take(1),
      tap(x => {
        this.parentLoyaliteAccount = x;

      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }

  public openValamartisProfil() {
    window.open(this._config.valamarProfileNewTabUrl + '/main/account/' + this.parentLoyaliteAccount.id);
  }

  public getvalamArtisHierarchy = (cardNumber: string): void => {
    this.hierarchyLoading = true;
    this._srvc.retrieveHierarchyForEmployee(cardNumber).pipe(
      take(1),
      tap(x => {
        this.hierarchys = x.hierarchyForEmployee;
        this.hierarchyLoading = false;
        this.hierarchyTotalRecords = this.hierarchys.length;
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  };
  public resendLoyaltyConfirmation = (loyaltyProfileId: string, email: string): void => {
    if (!loyaltyProfileId || !email) {
      this._ms.add({ severity: 'error', summary: 'Error', detail: 'Error: ' + "Loyalty id or email is not defined" });
      return;
    }
    this._ms.add({ severity: 'info', summary: 'Sending', detail: 'Sending loyalty confirmation email' });
    this.hierarchyLoading = true;
    this._srvc.resendLoyaltyConfirmation(loyaltyProfileId, email).pipe(
      take(1),
      tap(() => this._ms.add({ severity: 'success', summary: 'Success', detail: 'Loyalty confirmation email sent' })),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  };
  public loyaltyForgottenPassword = (loyaltyProfileId: string, email: string): void => {
    this.loyaltyForgottenPasswordModalVisible = false;
    if (!loyaltyProfileId || !email) {
      this._ms.add({ severity: 'error', summary: 'Error', detail: 'Error: ' + "Loyalty id or email is not defined" });
      return;
    }
    this._ms.add({ severity: 'info', summary: 'Sending', detail: 'Sending loyalty forgotten password email' });
    this.hierarchyLoading = true;
    this._srvc.loyaltyForgottenPassword(loyaltyProfileId, email).pipe(
      take(1),
      tap(() => this._ms.add({ severity: 'success', summary: 'Success', detail: 'Loyalty forgotten password email sent' })),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  };

  public layaltyNewEmailModalConfirm() {
	// const reg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
	// if (!this.newLoyatyEmail || !reg.test(this.newLoyatyEmail)) {
	// 	this.errorMessageEmail = "Email is not in good format!";
	// 	this.layaltyNewEmailConfirmation = false;
	// }

	this.layaltyNewEmailConfirmation = true;

    if (this._validationFields) {
      this._validationFields.forEach(x => {
        if (!x.validate()) {
			this.layaltyNewEmailConfirmation = false;
        }
      });
    }

	if (!this.newLoyatyEmail) {
		this.layaltyNewEmailConfirmation = false;
		this._validationFields.forEach(x => {
			x.resetValidation();
		})
	}
  }

  public layaltyNewEmailModalCancel() {
	this.newLoyatyEmail = "";
	this.loyaltyNewMailModalVisible = false;
	this.layaltyNewEmailConfirmation = false;
  }

  public loyaltyNewEmail = (loyaltyCardNumber: string, currentEmail: string, newEmail: string): void => {
    this.loyaltyNewMailModalVisible = false;
    if (!loyaltyCardNumber || !currentEmail || !newEmail) {
      this._ms.add({ severity: 'error', summary: 'Error', detail: 'Error: ' + "Loyalty card number or email is not defined" });
      return;
    }
    this.hierarchyLoading = true;
    this._srvc.loyaltyNewEmail(loyaltyCardNumber, currentEmail, newEmail).pipe(
      take(1),
      tap(() => this._ms.add({ severity: 'success', summary: 'Success', detail: 'Confirmation request sent to new email', life: 10000  })),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Failed to update email address', detail: err?.error?.message, life: 10000  });
        return of(null);
      })
    ).subscribe();

	this.layaltyNewEmailModalCancel();
  };
}



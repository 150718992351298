export class CommunicationRequestModel {
  constructor(
    public accountId: string | null = null,
    public email: string | null = null,
    public phone: string | null = null,
    public localTimeOffset: string | null = null,
    public filterThreads?: FilterThreads | null
  ) { }
}

export interface FilterThreads {
  startDate?: string | null;
  endDate?: string | null;
  communicationType?: string | null;
}

import {
  DatePipe,
  FormatWidth,
  FormStyle,
  getLocaleDateFormat,
  getLocaleDateTimeFormat,
  getLocaleDayNames,
  getLocaleMonthNames,
  getLocaleTimeFormat,
  TranslationWidth,
} from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { ValComponent } from '../../interfaces/val-component.interface';
import { ValComponentBaseComponent } from '../val-component-base/val-component-base.component';

@Component({
  selector: 'val-date-input',
  templateUrl: './val-date-input.component.html'
})
export class ValDateInputComponent extends ValComponentBaseComponent implements OnInit, ValComponent {
  private _loaded = false;
  private _format: 'short' | 'medium' | 'long' | 'full' | 'shortDate' | 'mediumDate' | 'longDate' | 'fullDate' | 'shortTime' | 'mediumTime' | 'longTime' | 'fullTime' = 'shortDate';
  private _dp!: DatePipe;
  private _m: string | null = null;
  private _min: Date | null = null;
  private _max: Date | null = null;
  private _locale: string;
  private _customDateFormat!: string;
  public placeholder = '';
  public rootElement!: HTMLElement;
  public ddVisible = false;
  public set model(x: string) {
    this._m = x;
    if (this.isValidated) {
      this.validate();
    }
    if (this._m.length < this.placeholder.length) {
      this._v = null;
      if (this.valueChangeEvent === 'change') {
        this.apply();
      }
      return;
    }
    if (this._loaded) {
      let tmp: Date | null = null;
      if (x && !tmp) {
        tmp = this._parseDate(x as string) as Date;
      }
      if (tmp instanceof Date) {
        if (this._max && this._max.getTime() < tmp.getTime()) {
          tmp = null;
          this._m = null;
        }
        if (this._min && this._min.getTime() > (tmp?.getTime() ?? 0)) {
          tmp = null;
          this._m = null;
        }
        if (this.disabledDays && this.disabledDays.length > 0) {
          const currentSum = (tmp?.getFullYear() ?? 0) * 10000 + ((tmp?.getMonth() ?? 0) + 1) * 100 + (tmp?.getDate() ?? 0);
          if (this.disabledDays.filter(dd => {
            const sum = dd.getFullYear() * 10000 + (dd.getMonth() + 1) * 100 + dd.getDate();
            return sum === currentSum;
          }).length > 0) {
            tmp = null;
          } else {
            if (this.returnUtcValue) {
              tmp = zonedTimeToUtc(tmp ?? 0, 'gmt');
            }
            if (this.returnType === 'string') {
              this._v = this._getDateAsIsoString(tmp);
            } else {
              this._v = tmp;
            }
            if (this.valueChangeEvent === 'change') {
              this.apply();
            }
          }
        } else {
          if (this.returnUtcValue) {
            tmp = zonedTimeToUtc(tmp??0, 'gmt');
          }
          if (this.returnType === 'string') {
            this._v = this._getDateAsIsoString(tmp);
          } else {
            this._v = tmp;
          }
          if (this.valueChangeEvent === 'change') {
            this.apply();
          }
        }
      } else {
        tmp = null;
      }
      if (tmp === null) {
        this._m = null;
        this._v = null;
        this.apply();
      }
    }
  }
  public get model() { return this._m??''; }
  public sizeCss = '';
  public closeOnBackdropClick = true;
  @Input() set format(x: 'short' | 'medium' | 'long' | 'full' | 'shortDate' | 'mediumDate' | 'longDate' | 'fullDate' | 'shortTime' | 'mediumTime' | 'longTime' | 'fullTime') {
    this._format = x;
    this.placeholder = this._getPlaceholder(x);
  }
  @Input() set value(x: Date | string) {
    this._v = x;
    if (this.isValidated) {
      this.validate();
    }
    if (this._loaded && !this._applied) {
      let tmp = this._getDate(x);
      if (x && !tmp) {
        tmp = this._parseDate(x as string) as Date;
      }
      if (tmp instanceof Date) {
        this._m = this._dp.transform(this._v, this._customDateFormat ? this._customDateFormat : this._format);
        this._v = tmp;
      } else {
        this._m = x as string;
      }
      if (this.changeOnSelect) {
        this.apply();
      }
    }
    this._applied = false;
  }
  get value() { return this._v; }

  @Input() disabledDays!: Array<Date>;
  @Input() showButton = true;
  @Input() closeOnSelect = false;
  @Input() startYear!: number;
  @Input() endYear!: number;
  @Input() returnUtcValue = false;
  @Input() returnType: 'string' | 'date' = 'date';
  @Input() set min(x: string | Date | null) {
    this._min = this._getDate(x);
  }
  get min() { return this._min; }
  @Input() set max(x: string | Date | null) {
    this._max = this._getDate(x);
  }
  get max() { return this._max; }

  @Input() set customDateFormat(x: string) {
    this._customDateFormat = x;
    this.placeholder = this._getPlaceholder(null);
  }
  @Input() changeOnSelect = false;
  @Output() public valueChange = new EventEmitter<Date | string>();

  constructor(@Inject(LOCALE_ID) locale: string, private _elRef: ElementRef) {
    super();
    this._locale = locale;
  }

  override apply(): void {
    this._applied = true;
    this.valueChange.emit(this._v);
  }
  // validate(): boolean {
  //   return this._u.getDate(this._v) ? true : false;
  // }

  ngOnInit(): void {
    this.placeholder = this._getPlaceholder(this._format);
    this._dp = new DatePipe(this._locale);
    let tmp = this._getDate(this._v);
    tmp = tmp ? tmp : this._parseDate(this._v as string) as Date;
    if (tmp instanceof Date) {
      this._m = this._dp.transform(tmp, this._customDateFormat ? this._customDateFormat : this._format);
      this._v = tmp;
    } else {
      this._m = this._v;
    }
    this.rootElement = this._elRef.nativeElement;
    this._loaded = true;
  }

  public setDate = (e: string | Date | null) => {
    if (this._max && this._max.getTime() < (e as Date).getTime()) {
      e = this._max;
      this._m = this._dp.transform(e, this._customDateFormat ? this._customDateFormat : this.format);
    }
    if (this._min && this._min.getTime() > (e as Date).getTime()) {
      e = this._min;
      this._m = this._dp.transform(e, this._customDateFormat ? this._customDateFormat : this.format);
    }
    this._v = e;
    this._m = this._dp.transform(this._v, this._customDateFormat ? this._customDateFormat : this._format);
    if (this.valueChangeEvent === 'change') {
      this.apply();
    }
    if (!this._isFocused) { this._inputEl.nativeElement.focus(); }
    if (this.closeOnSelect) {
      this.ddVisible = false;
    }
  }

  public inputClick = (e: Event) => {
    if (this.showButton) {
      e.stopPropagation();
      this.focus(true);
      // this._isFocused = true;
      // if (this._validator) {
      //   this._validator.hasFocus = true;
      // }
      // e.stopPropagation();
    } else {
      if (this.ddVisible) {
        e.stopPropagation();
      }
    }
  }

  focusOut = () => {
    if ((this._v instanceof Date) && this._m === this._dp.transform(this._v, this._customDateFormat ? this._customDateFormat : this._format)) { return; }
    let tmp = this._getDate(this._m);
    if (this._m && !tmp) {
      tmp = this._parseDate(this._m as string) as Date;
    }
    if (tmp instanceof Date) {
      if (this._max && this._max.getTime() < tmp.getTime()) {
        tmp = this._max;
        this._m = this._dp.transform(tmp, this._customDateFormat ? this._customDateFormat : this.format);
      }
      if (this._min && this._min.getTime() > tmp.getTime()) {
        tmp = this._min;
        this._m = this._dp.transform(tmp, this._customDateFormat ? this._customDateFormat : this.format);
      }
      this._m = this._dp.transform(this._v, this._customDateFormat ? this._customDateFormat : this._format);
      this._v = tmp;
      if (this.valueChangeEvent === 'blur') {
        this.apply();
      }
    }
  }

  private _getDateExtended = (format: string, value: string): Date | null => {
    let m = this._getDate(parse(value, format, new Date(), {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true
    }));
    if (!m) {
      format = format.replace('y.', 'y');
      m = this._getDate(parse(value, format, new Date(), {
        useAdditionalDayOfYearTokens: true,
        useAdditionalWeekYearTokens: true
      }));
    }
    return m;
  }

  private _parseDate = (x: string): Date | string | null => {
    if (!x) { return null; }
    if (this._customDateFormat) {
      return this._getDateExtended(this._customDateFormat, x);
    }
    let tmp = x.split('. ').join('.').split('/ ').join('/').split(' /').join('/');
    const timeFormats: Array<string> = [];
    const dateFormats: Array<string> = [];
    const monthNames: Array<{ readonly local: Array<string>, readonly en: Array<string> }> = [];
    const weekNames: Array<{ readonly local: Array<string>, readonly en: Array<string> }> = [];
    const datetimeFormats: Array<string> = [];
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Short));
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Medium));
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Long));
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Full));
    monthNames.push({
      local: getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Wide) as string[],
      en: getLocaleMonthNames('en', FormStyle.Format, TranslationWidth.Wide) as string[]
    });
    monthNames.push({
      local: getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated) as string[],
      en: getLocaleMonthNames('en', FormStyle.Format, TranslationWidth.Abbreviated) as string[]
    });
    monthNames.push({
      local: getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Short) as string[],
      en: getLocaleMonthNames('en', FormStyle.Format, TranslationWidth.Short) as string[]
    });
    weekNames.push({
      local: getLocaleDayNames(this._locale, FormStyle.Format, TranslationWidth.Wide) as string[],
      en: getLocaleDayNames('en', FormStyle.Format, TranslationWidth.Wide) as string[]
    });
    weekNames.push({
      local: getLocaleDayNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated) as string[],
      en: getLocaleDayNames('en', FormStyle.Format, TranslationWidth.Abbreviated) as string[]
    });
    weekNames.push({
      local: getLocaleDayNames(this._locale, FormStyle.Format, TranslationWidth.Short) as string[],
      en: getLocaleDayNames('en', FormStyle.Format, TranslationWidth.Short) as string[]
    });
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Short));
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Medium));
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Long));
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Full));
    if (this._customDateFormat) {
      dateFormats.push(this._customDateFormat);
    }
    else {
      dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Short).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
      dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Medium).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
      dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Long).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
      dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Full).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
    }
    let replaced = false;
    for (let i = 0; i < monthNames.length; i++) {
      for (let j = 0; j < monthNames[i].local.length; j++) {
        if (tmp.indexOf(monthNames[i].local[j]) > -1) {
          tmp = tmp.replace(monthNames[i].local[j], monthNames[i].en[j]);
          replaced = true;
          break;
        }
      }
      if (replaced) { break; }
    }
    replaced = false;
    for (let i = 0; i < weekNames.length; i++) {
      for (let j = 0; j < weekNames[i].local.length; j++) {
        if (tmp.indexOf(weekNames[i].local[j]) > -1) {
          tmp = tmp.replace(weekNames[i].local[j], weekNames[i].en[j]);
          replaced = true;
          break;
        }
      }
      if (replaced) { break; }
    }
    let tmpFormat = '';
    let m: Date | null = null;
    if (tmp.indexOf(':') > 0) {
      for (let i = 0; i < dateFormats.length; i++) {
        for (let j = 0; j < timeFormats.length; j++) {
          tmpFormat = datetimeFormats[i].replace('{1}', dateFormats[i]).replace('{0}', timeFormats[j].split('(')[0]);
          m = this._getDateExtended(tmpFormat, tmp.split('(')[0]);
          if (!m) {
            tmpFormat = datetimeFormats[i].replace('{1} ', dateFormats[i]).replace('{0}', timeFormats[j]);
            m = this._getDateExtended(tmpFormat, tmp);
          }
          if (m) { break; }
        }
        if (m) { break; }
      }
    } else {
      for (let i = 0; i < dateFormats.length; i++) {
        tmpFormat = dateFormats[i];
        m = this._getDateExtended(tmpFormat, tmp);
        if (m) { break; }
      }
    }
    if (!m) {
      return x;
    } else {
      return m;
    }
  }

  private _getPlaceholder = (
    format: 'short' | 'medium' | 'long' | 'full' | 'shortDate' | 'mediumDate' | 'longDate' | 'fullDate' | 'shortTime' | 'mediumTime' | 'longTime' | 'fullTime' | null
  ): string => {
    if (this._customDateFormat) { return this._customDateFormat; }
    let dateFormat: string | null = null;
    let timeFormat: string | null = null;
    let dateTimeFormat: string | null = null;
    switch (format) {
      case 'short':
        dateFormat = getLocaleDateFormat(this._locale, FormatWidth.Short);
        timeFormat = getLocaleTimeFormat(this._locale, FormatWidth.Short);
        dateTimeFormat = getLocaleDateTimeFormat(this._locale, FormatWidth.Short);
        return dateTimeFormat.replace('{1}', dateFormat).replace('{0}', timeFormat);
      case 'medium':
        dateFormat = getLocaleDateFormat(this._locale, FormatWidth.Medium);
        timeFormat = getLocaleTimeFormat(this._locale, FormatWidth.Medium);
        dateTimeFormat = getLocaleDateTimeFormat(this._locale, FormatWidth.Medium);
        return dateTimeFormat.replace('{1}', dateFormat).replace('{0}', timeFormat);
      case 'long':
        dateFormat = getLocaleDateFormat(this._locale, FormatWidth.Long);
        timeFormat = getLocaleTimeFormat(this._locale, FormatWidth.Long);
        dateTimeFormat = getLocaleDateTimeFormat(this._locale, FormatWidth.Long);
        return dateTimeFormat.replace('{1}', dateFormat).replace('{0}', timeFormat);
      case 'full':
        dateFormat = getLocaleDateFormat(this._locale, FormatWidth.Full);
        timeFormat = getLocaleTimeFormat(this._locale, FormatWidth.Full);
        dateTimeFormat = getLocaleDateTimeFormat(this._locale, FormatWidth.Full);
        return dateTimeFormat.replace('{1}', dateFormat).replace('{0}', timeFormat);
      case 'shortDate':
        return getLocaleDateFormat(this._locale, FormatWidth.Short);
      case 'mediumDate':
        return getLocaleDateFormat(this._locale, FormatWidth.Medium);
      case 'longDate':
        return getLocaleDateFormat(this._locale, FormatWidth.Long);
      case 'fullDate':
        return getLocaleDateFormat(this._locale, FormatWidth.Full);
      case 'shortTime':
        return getLocaleTimeFormat(this._locale, FormatWidth.Short);
      case 'mediumTime':
        return getLocaleTimeFormat(this._locale, FormatWidth.Medium);
      case 'longTime':
        return getLocaleTimeFormat(this._locale, FormatWidth.Long);
      case 'fullTime':
        return getLocaleTimeFormat(this._locale, FormatWidth.Full);
      default:
        dateFormat = getLocaleDateFormat(this._locale, FormatWidth.Full);
        timeFormat = getLocaleTimeFormat(this._locale, FormatWidth.Full);
        dateTimeFormat = getLocaleDateTimeFormat(this._locale, FormatWidth.Full);
        return dateTimeFormat.replace('{1}', dateFormat).replace('{0}', timeFormat);
    }
  }
  private _zeroPad(value: number, len: number): string {
    let text = String(value);

    while (text.length < len) {
      text = '0' + text;
    }

    return text;
  }
  private _getDateAsIsoString = (input: Date | null): string => {
    return (input?.getFullYear() ?? 0) + '-' + this._zeroPad((1 + (input?.getMonth() ?? 0)), 2) + '-' + this._zeroPad((input?.getDate() ?? 0), 2) +
      'T' + this._zeroPad((input?.getHours() ?? 0), 2) + ':' + this._zeroPad((input?.getMinutes() ?? 0), 2) + ':00.000Z';
  }
  private _getDate = (input: string | Date | null): Date | null => {
    if (!input) {
      return null;
    }
    if (!(input instanceof Date)) {
      const timestamp = Date.parse(input);

      if (isNaN(timestamp) === false) {
        return new Date(input);
      }
      return null;
    } else {
      // invalid date
      if (isNaN(input.getTime())) {
        return null;
      }
    }
    return input as Date;
  }
}

import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalInterceptor, MsalService } from '@azure/msal-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, take, tap } from 'rxjs/operators';

import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';
import { VrcConfigService } from '../services/vrc-config.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';

@Injectable()
export class ValAuthInterceptor implements HttpInterceptor {
  constructor(
    private _bst: VrcBrowserStorageService,
    private _srvc: VrcDataService,
    private _config: VrcConfigService,
    private _st: VrcStorageService,
    private msalInterceptor: MsalInterceptor,
    private _authService: MsalService,
    private _r: Router,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    // AllowAnonymous endpoints
    if (req.url == this._config.getTokenUrl || req.url == this._config.getUserTokenUrl) {
      return next.handle(req);
    }

    let authReq = req.clone();
    // Only if it is azure domain user
    if (this._bst.isAzureUser && !this._bst.token) { // && !this._r.url.startsWith("/nice")
      return this.msalInterceptor.intercept(authReq, next).pipe(
        catchError(err => {
          return this.handleMsalResponseError(err);
        }));
    } else {
      authReq = this.addAuthHeader(authReq);
      // send cloned request with header to the next handler.
      return next.handle(authReq).pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleResponseError(err, authReq, next);
        })
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (!(request.url.includes(this._config.getTokenUrl) || request.url.includes(this._config.getUserTokenUrl))) {
      // If token exists add it to header
      if (this._bst.token) {
        return request.clone({
          headers: request.headers
            .set("Authorization", "Token " +
              (request.url.includes(this._config.refreshTokenUrl) ? this._bst.refreshToken : this._bst.token))
            .set("content-type", "application/json")
        });
      }
    }
    return request;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleResponseError(err: HttpErrorResponse, req?: HttpRequest<any>, next?: HttpHandler) {
    const er = { ...err };

    if (er.status === 401) {
      if (req?.url === this._config.refreshTokenUrl) {
        this._bst.clearAuthentication();
        this._r.navigate(['login']);
        return throwError(() => er);
      } else if (req?.url === this._config.getTokenUrl) {
        this._bst.clearAuthentication();
        this._r.navigate(['login']);
        return throwError(() => er);
      } else {
        // Nice authorization has priority
        if (this._st.sessionId) {
          if (this._st.isReauthorize === true) {
            return of(null)
              .pipe(
                delayWhen(() => this._st.isAuthorized),
                tap(() => {
                  if (req) {
                    req = this.addAuthHeader(req);
                    next?.handle(req);
                  }
                }));
          }
          this._st.isReauthorize = true;
          return this._srvc.getToken({ sessionId: this._st.sessionId, agentId: (this._bst.agentId ?? 0) }).pipe(
            take(1),
            tap(x => {
              this._st.sessionId = null;
              this._bst.token = x.token;
              this._bst.refreshToken = x.refreshToken;
              if (req) {
                req = this.addAuthHeader(req);
                this._st.isReauthorize = false;
                this._st.isAuthorized.emit();
                next?.handle(req);
              }
            }),
            catchError(e => {
              this._bst.clearAuthentication();
              this._r.navigate(['login']);
              this._st.isReauthorize = false;
              this._st.isAuthorized.emit();
              return throwError(() => e);
            }));
        } else {
          if (this._st.isReauthorize === true) {
            return of(null)
              .pipe(
                delayWhen(() => this._st.isAuthorized),
                tap(() => {
                  if (req) {
                    req = this.addAuthHeader(req);
                    next?.handle(req);
                  }
                }));
          }
          this._st.isReauthorize = true;
          return this._srvc.refreshToken().pipe(
            take(1),
            tap(x => {
              this._bst.token = x.token;
              this._bst.refreshToken = x.refreshToken;
              if (req) {
                req = this.addAuthHeader(req);
                this._st.isReauthorize = false;
                this._st.isAuthorized.emit();
                next?.handle(req);
              }
            }),
            catchError(e => {
              this._bst.clearAuthentication();
              this._r.navigate(['login']);
              this._st.isReauthorize = false;
              this._st.isAuthorized.emit();
              return throwError(() => e);
            }));
        }
      }
    }
    return throwError(() => er);
  }
  private handleMsalResponseError(err: HttpErrorResponse) {
    const er = { ...err };

    if (er.status === 401) {
      this._authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
      this._bst.clearAuthentication();
      this._r.navigate(['login']);
    }
    return throwError(() => er);
  }
}

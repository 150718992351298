<div class="card border-0">
  <div class="card-body border-0">
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs">
          <li *ngFor="let m of menu" class="nav-item">
            <a class="nav-link text-uppercase text-gray-700" [class.active]="m.isSelected" (click)="menuClick(m,$event)" aria-current="page" href="#">
              {{m.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div [ngSwitch]="selectedMenuId" class="row bg-loyalty-details">
      <ng-container *ngSwitchCase="1">
        <div class="col-12 p-5">
          <div class="row">
            <div class="col-auto">
              <i class="vi vi-dot-circle me-4"></i>
            </div>
            <div class="col">
              <div class="row">
                <h6 class="table-body-header">Info</h6>
              </div>
              <div class="row">
                <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                  <label class="form-label">Card Number</label>
                  <span class="form-control-plaintext disabled">{{model?.cardInformation?.cardNumber}}</span>
                </div>
                <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                  <label class="form-label">Registered Email</label>
                  <span class="form-control-plaintext disabled">{{model?.account?.email}}</span>
                </div>
                <div *ngIf="model?.program === 'IFF' && model?.level !=='Employee' && parentLoyaliteAccount" class="col-6 col-sm-3 mb-3 mb-sm-5">
                  <label class="form-label">Parent ValamArtist profile</label>
                  <span class="form-control-plaintext disabled">
                    <button (click)="openValamartisProfil()" class="btn btn-sm btn-secondary btn-popup w-auto mx-6">
                      {{parentLoyaliteAccount.firstName +" "+ parentLoyaliteAccount.lastName}}
                      <i class="vi vi-popup ms-4"></i>
                    </button>
                  </span>
                </div>
                <div class="col-3 col-sm-3 mb-5">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <button type="button" class="btn btn-sm btn-secondary text-nowrap" (click)="loyaltyNewMailModalVisible=true">
                          Change loyalty email
                          <i class="vi vi-swap-horiz ms-3"></i>
                        </button>
                      </div>
                      <div class="w-100"></div>
                      <div class="col">
                        <button *ngIf="model?.status===st.LoyaltyStatusPending;else notpending" type="button" class="btn btn-sm btn-secondary text-nowrap"
                          (click)="resendLoyaltyConfirmation(model?.mappingFields?.crm!.ad_loyaltyprofileid!,model?.account?.email!)">
                          Send card activation email
                          <i class="vi vi-swap-horiz ms-3"></i>
                        </button>
                        <ng-template #notpending>
                          <button type="button" class="btn btn-sm btn-secondary text-nowrap" (click)="loyaltyForgottenPasswordModalVisible=true">
                            Send reset password email
                            <i class="vi vi-swap-horiz ms-3"></i>
                          </button>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 col-sm-3 mb-5">
                  <button type="button" class="btn btn-sm btn-secondary text-nowrap"
                    (click)="ambassadorLinkModalVisible=true">
                    Send ambassador link
                    <i class="vi vi-swap-horiz ms-3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 border-gray-50 border-top"></div>
          <div class="row mt-5">
            <div class="col-auto">
              <i class="vi vi-dot-circle me-4"></i>
            </div>
            <div class="col">
              <div class="row">
                <h6 class="table-body-header">Loyalty info</h6>
              </div>
              <div class="row">
                <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                  <label class="form-label">Status</label>
                  <div class="w-100"></div>
                  <div class="badge badge-status text-gray-700 text-uppercase text-start" [class.bg-danger-1]="model?.status !=='Active'"
                    [class.bg-success-1]="model?.status ==='Active'">
                    <i class="vi vi-rectangle me-3"></i>
                    {{model?.status }}
                  </div>
                </div>
                <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                  <label class="form-label">Program</label>
                  <span class="form-control-plaintext disabled">{{model?.program}}</span>
                </div>
                <div class="col-6 col-sm-3 mb-5">
                  <label class="form-label">Level</label>
                  <span class="form-control-plaintext disabled">{{model?.level}}</span>
                </div>
                <div class="col-6 col-sm-3 mb-5">
                  <label class="form-label">Issued</label>
                  <span class="form-control-plaintext disabled">{{model?.issued}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 border-gray-50 border-top"></div>
          <div class="row mt-5">
            <div class="col-auto">
              <i class="vi vi-dot-circle me-4"></i>
            </div>
            <div class="col">
              <div class="row">
				<div class="d-flex">
					<h6 class="table-body-header">Loyalty Credits</h6>
				</div>
              	</div>
				<div *ngIf="st.showPoints; else showAmountLoyaltyPoints" >
					<div class="row">
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Available Points</label>
							<span class="form-control-plaintext disabled">{{model?.pointsData?.pointsAvailable | currency:' ':'symbol-narrow':'1.0-0':'hr'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Earned Points</label>
							<span class="form-control-plaintext disabled">{{model?.pointsData?.points | currency:' ':'symbol-narrow':'1.0-0':'hr'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Promo Points</label>
							<span class="form-control-plaintext disabled">{{model?.pointsData?.promoPoints | currency:' ':'symbol-narrow':'1.0-0':'hr'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Reserved Points</label>
							<span class="form-control-plaintext disabled">{{model?.pointsData?.pointsReserved | currency:' ':'symbol-narrow':'1.0-0':'hr'}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Value for Points</label>
							<span class="form-control-plaintext disabled">{{model?.pointsData?.valueForPoints | number:'1.2-2'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<button type="button" class="btn btn-sm btn-secondary" (click)="openTransferModal()">
								Transfer Points
								<i class="vi vi-swap-horiz ms-3"></i>
							</button>
						</div>
					</div>
				</div>
				<ng-template #showAmountLoyaltyPoints>
					<div class="row">
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Available Amount</label>
							<span class="form-control-plaintext disabled">{{model?.amountsData?.totalAmountAvailable | currency:'EUR':'symbol-narrow':'1.2-2':'hr'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-5">
							<label class="form-label">Earned Amount</label>
							<span class="form-control-plaintext disabled">{{model?.amountsData?.earnedAmountAvailable | currency:'EUR':'symbol-narrow':'1.2-2':'hr'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Promo Amount</label>
							<span class="form-control-plaintext disabled">{{model?.amountsData?.promoAmountAvailable | currency:'EUR':'symbol-narrow':'1.2-2':'hr'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-5">
							<label class="form-label">Reserved Amount</label>
							<span class="form-control-plaintext disabled">{{model?.amountsData?.reservedAmount | currency:'EUR':'symbol-narrow':'1.2-2':'hr'}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<label class="form-label">Value for Points</label>
							<span class="form-control-plaintext disabled">{{model?.pointsData?.valueForPoints | number:'0.2-2'}}</span>
						</div>
						<div class="col-6 col-sm-3 mb-3 mb-sm-5">
							<button type="button" class="btn btn-sm btn-secondary" (click)="openTransferModal()">
								Transfer Amount
								<i class="vi vi-swap-horiz ms-3"></i>
							</button>
						</div>
					</div>
				</ng-template>
              
            </div>
          </div>
          <div class="w-100 border-gray-50 border-top"></div>
          <div class="row mt-5">
            <div class="col-auto">
              <i class="vi vi-dot-circle me-4"></i>
            </div>
            <div class="col">
              <div class="row">
                <h6 class="table-body-header">Valamar Level Calculation</h6>
              </div>
			  <div *ngIf="st.showPoints; else showAmountValamarLevel" >
				<div class="row">
				  <div class="col-6 col-sm-3 mb-3 mb-sm-5">
					<label class="form-label">Level Points</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.levelPoints | currency:' ':'symbol-narrow':'1.0-0':'hr'}}</span>
				  </div>
				  <div class="col-6 col-sm-3 mb-3 mb-sm-5">
					<label class="form-label">Level Nights</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.levelNights | number:'1.0-0'}}</span>
				  </div>
				  <div class="col-6 col-sm-3 mb-5">
					<label class="form-label">Next Level Points</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.nextLevelPoints | currency:' ':'symbol-narrow':'1.0-0':'hr'}}</span>
				  </div>
				  <div class="col-6 col-sm-3 mb-5">
					<label class="form-label">Next Level Nights</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.nextLevelNights | number:'1.0-0'}}</span>
				  </div>
				</div>
			  </div>
			  <ng-template #showAmountValamarLevel >
				<div class="row">
				  <div class="col-6 col-sm-3 mb-3 mb-sm-5">
					<label class="form-label">Level Amount</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.levelAmount | currency:'EUR':'symbol-narrow':'1.2-2':'hr'}}</span>
				  </div>
				  <div class="col-6 col-sm-3 mb-3 mb-sm-5">
					<label class="form-label">Level Nights</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.levelNights | number:'1.0-0'}}</span>
				  </div>
				  <div class="col-6 col-sm-3 mb-5">
					<label class="form-label">Next Level Amount</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.nextLevelAmount | currency:'EUR':'symbol-narrow':'1.2-2':'hr'}}</span>
				  </div>
				  <div class="col-6 col-sm-3 mb-5">
					<label class="form-label">Next Level Nights</label>
					<span class="form-control-plaintext disabled">{{model?.levelCalculation?.nextLevelNights | number:'1.0-0'}}</span>
				  </div>
				</div>
			  </ng-template>
            </div>
          </div>
          <div class="w-100 border-gray-50 border-top"></div>
          <div class="row mt-5">
            <div class="col-auto">
              <i class="vi vi-dot-circle me-4"></i>
            </div>
            <div class="col">
              <div class="row">
                <h6 class="table-body-header">Card info</h6>
              </div>
              <div class="row">
                <div class="col-6 col-sm-3 mb-5">
                  <label class="form-label">Registration Date</label>
                  <span class="form-control-plaintext disabled">{{model?.cardInformation?.registrationDate | date: 'shortDate'}}</span>
                </div>
                <div class="col-6 col-sm-3 mb-5">
                  <label class="form-label">Expiration Date</label>
                  <span class="form-control-plaintext disabled">{{model?.cardInformation?.expirationDate | date: 'shortDate'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <div class="col-12 bg-secondary pt-5">
          <val-table-scroller>
            <p-table [value]="reservations" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]"
              [paginator]="true" currentPageReportTemplate="{first}-{last} OF {totalRecords}" [filterDelay]="0" [lazy]="true" sortMode="multiple"
              (onLazyLoad)="reservationsTableLazyLoad($event)" [scrollable]="true" [totalRecords]="reservationsTotalRecords">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="mappingFields.phobs.reservationCode">
                    Phobs Id
                    <p-sortIcon field="mappingFields.phobs.reservationCode"></p-sortIcon>
                    <p-columnFilter type="text" field="mappingFields.phobs.reservationCode" display="menu" class="float-end"> </p-columnFilter>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="property.code">
                    Property
                    <p-sortIcon field="property.code"></p-sortIcon>
                    <p-columnFilter type="text" field="property.code" display="menu" class="float-end"> </p-columnFilter>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="checkIn">
                    Arrival
                    <p-sortIcon field="checkIn"></p-sortIcon>
                    <p-columnFilter type="date" field="checkIn" display="menu" class="float-end">
                      <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                        <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                          dateFormat="dd.mm.yy." [showTime]="false" [showIcon]="true">
                        </p-calendar>
                      </ng-template>
                    </p-columnFilter>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="checkOut">
                    Departure
                    <p-sortIcon field="checkOut"></p-sortIcon>
                    <p-columnFilter type="date" field="checkOut" display="menu" class="float-end">
                      <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                        <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                          dateFormat="dd.mm.yy." [showTime]="false" [showIcon]="true">
                        </p-calendar>
                      </ng-template>
                    </p-columnFilter>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="unit.code">
                    Room type
                    <p-sortIcon field="unit.code"></p-sortIcon>
                    <p-columnFilter type="text" field="unit.code" display="menu" class="float-end"> </p-columnFilter>
                  </th>
                  <th style="width: 14rem" pResizableColumn pSortableColumn="loyalty.pointsEarnedStay">
                    {{st.showPoints ? "Earned Points" : "Earned Amount"}}
                    <p-sortIcon field="loyalty.pointsEarnedStay"></p-sortIcon>
                    <p-columnFilter type="text" field="loyalty.pointsEarnedStay" display="menu" class="float-end"></p-columnFilter>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="loyalty.usedPoints">
                    {{st.showPoints ? "Used Points" : "Used Amount"}}
                    <p-sortIcon field="loyalty.usedPoints"></p-sortIcon>
                    <p-columnFilter type="text" field="loyalty.usedPoints" display="menu" class="float-end"></p-columnFilter>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-reservation let-expanded="expanded">
                <tr>
                  <td>
                    <button [pRowToggler]="reservation" type="button" class="btn btn-sm btn-primary me-5" [class.btn-primary]="expanded"
                      [class.btn-secondary]="!expanded">
                      <i class="vi" [class.vi-chevron-down]="!expanded" [class.vi-chevron-up]="expanded"></i>
                    </button>
                    <span *ngIf="reservation.mappingFields?.phobs?.reservationCode" class="badge badge-open bg-secondary text-gray-700">
                      {{ reservation.mappingFields?.phobs?.reservationCode }}
                      <button class="btn btn-copy ms-4">
                        <i class="vi vi-open-in-new"></i>
                      </button>
                    </span>
                  </td>
                  <td>{{reservation.property.code}}</td>
                  <td>
                    {{reservation.checkIn | date:'shortDate'}}
                  </td>
                  <td>
                    {{reservation.checkOut | date:'shortDate'}}
                  </td>
                  <td>{{reservation.unit.code}}</td>
                  <td>
					{{st.showPoints ? 
						(reservation.loyalty.pointsEarnedStay | currency:' ':'symbol-narrow':'1.0-0':'hr') :
						(reservation.loyalty.amountEarned | currency:'EUR':'symbol-narrow':'1.2-2':'hr')}}
                    <button *ngIf="!reservation.loyalty.pointsEarnedStay && st.showPoints" (click)="openClaimPointsModal(reservation)" class="btn btn-sm btn-secondary ms-3 text-nowrap">
                      Claim points
                      <i class="vi vi-playlist-add ms-3"></i>
                    </button>
                  </td>
                  <td>
					{{st.showPoints ?
						(reservation.loyalty.usedPoints | currency:' ':'symbol-narrow':'1.0-0':'hr') :
						(reservation.loyalty.amountUsed | currency:'EUR':'symbol-narrow':'1.2-2':'hr')}}
				  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-reservation>
                <tr>
                  <td colspan="7">
                    <div class="row expanded-body">
                      <div class="col-3 col-sm-4 col-md-5 col-lg-6 col-xl-7 col-xxl-8 p-5">
                        <div class="row">
                          <div class="col-auto">
                            <i class="vi vi-dot-circle mx-3"></i>
                          </div>
                          <div class="col">
                            <div class="row">
                              <h6 class="table-body-header">Info</h6>
                            </div>
                            <div class="row">
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Opera id</label>
                                <span class="form-control-plaintext disabled">{{reservation.mappingFields?.opera?.CONFIRMATION_NO}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Status</label>
                                <div class="w-100"></div>
                                <div class="badge badge-status bg-danger text-gray-700 text-uppercase text-start"
                                  [class.bg-info-1]="reservation.status ==='NO SHOW' || reservation.status ==='CHECKED IN'"
                                  [class.bg-danger-1]="reservation.status ==='CANCELLED' || reservation.status ==='CHECKED OUT' || reservation.status ==='CANCEL'"
                                  [class.bg-info]="reservation.status ==='NEW' || reservation.status ==='MODIFY'"
                                  [class.bg-success-1]="reservation.status ==='RESERVED'">
                                  <i class="vi vi-rectangle me-3"></i>
                                  {{reservation.status }}
                                </div>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Property Name</label>
                                <span class="form-control-plaintext disabled">{{reservation.property?.name}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Room number</label>
                                <span class="form-control-plaintext disabled">{{reservation.roomNumber}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                                <label class="form-label">Room type</label>
                                <span class="form-control-plaintext disabled">{{reservation.unit?.code}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                                <label class="form-label">Stay room type</label>
                                <span class="form-control-plaintext disabled">{{reservation.unit?.stayCode}}</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Rate plan</label>
                                <span class="form-control-plaintext disabled">{{reservation.ratePlan?.code}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Meal plan</label>
                                <span class="form-control-plaintext disabled">{{reservation.mealPlan}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                                <label class="form-label">Market code</label>
                                <span class="form-control-plaintext disabled">{{reservation.marketCode}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 col-sm-6 mb-3 mb-sm-5">
                                <label class="form-label">Agency name</label>
                                <span class="form-control-plaintext disabled">{{reservation.agency?.name}}</span>
                              </div>
                              <div class="col-12 col-sm-6 mb-5">
                                <label class="form-label">Company</label>
                                <span class="form-control-plaintext disabled">{{reservation.company}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100 border-gray-50 border-top"></div>
                        <div class="row mt-5">
                          <div class="col-auto">
                            <i class="vi vi-dot-circle mx-3"></i>
                          </div>
                          <div class="col">
                            <div class="row">
                              <h6 class="table-body-header">Guests</h6>
                            </div>
                            <div class="row">
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Adults</label>
                                <span class="form-control-plaintext disabled">{{reservation.guests?.adults}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Children</label>
                                <span class="form-control-plaintext disabled">{{reservation.guests?.children}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                                <label class="form-label">Children age</label>
                                <span class="form-control-plaintext disabled">{{reservation.guests?.childrenAge}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100 border-gray-50 border-top"></div>
                        <div class="row mt-5">
                          <div class="col-auto">
                            <i class="vi vi-dot-circle mx-3"></i>
                          </div>
                          <div class="col">
                            <div class="row">
                              <h6 class="table-body-header">Aditional info</h6>
                            </div>
                            <div class="row">
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Agent name</label>
                                <span class="form-control-plaintext disabled">{{reservation.agentName}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Created on</label>
                                <span class="form-control-plaintext disabled">{{reservation.createdDate | date: 'shortDate'}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                                <label class="form-label">Source</label>
                                <span class="form-control-plaintext disabled">{{reservation.source}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100 border-gray-50 border-top"></div>
                        <div class="row mt-5">
                          <div class="col-auto">
                            <i class="vi vi-dot-circle mx-3"></i>
                          </div>
                          <div class="col">
                            <div class="row">
                              <h6 class="table-body-header">Costs</h6>
                            </div>
                            <div class="row">
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Total</label>
                                <span class="form-control-plaintext disabled">
                                  <a *ngIf="reservation.costs?.invoiceUrl;else displayonly" [href]="reservation.costs?.invoiceUrl" target="_blank"
                                    class="btn btn-secondary shadow-sm w-auto mx-5">
                                    {{reservation.costs?.total | number:'0.2-2'}}
                                    <i class="fas fa-external-link-alt ms-4"></i>
                                  </a>
                                  <ng-template #displayonly>
                                    {{reservation.costs?.total | number:'0.2-2'}}
                                  </ng-template>
                                </span>
                              </div>
                              <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                                <label class="form-label">Discount</label>
                                <span class="form-control-plaintext disabled">{{reservation.costs?.discount | number:'0.2-2'}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                                <label class="form-label">Voucher discount</label>
                                <span class="form-control-plaintext disabled">{{reservation.costs?.voucherDiscount | number:'0.2-2'}}</span>
                              </div>
                              <div class="col-6 col-sm-3 mb-5">
                                <label class="form-label">Voucher</label>
                                <span class="form-control-plaintext disabled">{{reservation.voucher?.code}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100 border-gray-50 border-top"></div>
                        <div class="row mt-5">
                          <div class="col-auto">
                            <i class="vi vi-dot-circle mx-3"></i>
                          </div>
                          <div class="col">
                            <div class="row">
                              <h6 class="table-body-header">Packages</h6>
                            </div>
                            <div class="row pb-4">
                              <div *ngFor="let p of reservation.packages | keyvalue" class="col-auto pe-2 pb-1">
                                <span *ngIf="p.value" class="badge bg-primary bg-gradient bg-opacity-50">
                                  {{p.key}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100 border-gray-50 border-top"></div>
                        <div class="row mt-5">
                          <div class="col-auto">
                            <i class="vi vi-dot-circle mx-3"></i>
                          </div>
                          <div class="col">
                            <div class="row">
                              <h6 class="table-body-header">Comments</h6>
                            </div>
                            <div class="row">
                              <div class="col-12 col-sm-6 mb-3 mb-sm-5">
                                <label class="form-label">Guest</label>
                                <span class="form-control-plaintext disabled">{{reservation.comments?.guest}}</span>
                              </div>
                              <div class="col-12 col-sm-6 mb-5">
                                <label class="form-label">Front desk</label>
                                <span class="form-control-plaintext disabled">{{reservation.comments?.frontDesk}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100 border-gray-50 border-top"></div>
                        <div class="row mt-5">
                          <div class="col-auto">
                            <i class="vi vi-dot-circle mx-3"></i>
                          </div>
                          <div class="col">
                            <div class="row">
                              <div class="col mb-4">
                                <h6 class="table-body-header">Traces</h6>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6 mb-3">
                                <label class="form-label">Type</label>
                              </div>
                              <div class="col-6 mb-3">
                                <label class="form-label">Value</label>
                              </div>
                            </div>
                            <div *ngFor="let t of reservation?.traces; last as isLast" class="row">
                              <div class="col-6 mb-3" [class.mb-5]="isLast">
                                <span class="form-control-plaintext disabled">{{t.type}}</span>
                              </div>
                              <div class="col-6 mb-3" [class.mb-5]="isLast">
                                <span class="form-control-plaintext disabled">{{t.value}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">No reservations found.</td>
                </tr>
              </ng-template>
            </p-table>
          </val-table-scroller>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <div class="col-12 bg-secondary pt-5">
          <val-table-scroller>
            <p-table #dt [value]="hierarchys" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true"
              currentPageReportTemplate="{first}-{last} OF {totalRecords}" [scrollable]="true" [totalRecords]="hierarchyTotalRecords">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="valamarArtistID">
                    Valamar Artist ID
                    <p-sortIcon field="valamarArtistID"></p-sortIcon>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="level">
                    Level
                    <p-sortIcon field="level"></p-sortIcon>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="email">
                    Email
                    <p-sortIcon field="email"></p-sortIcon>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="name">
                    Name
                    <p-sortIcon field="name"></p-sortIcon>
                  </th>
                  <th style="width: 12rem" pResizableColumn pSortableColumn="surname">
                    Surname
                    <p-sortIcon field="surname"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-hierarchy>
                <tr>
                  <td>{{hierarchy.valamarArtistID}}</td>
                  <td>{{hierarchy.level}}</td>
                  <td>{{hierarchy.email}}</td>
                  <td>{{hierarchy.name}}</td>
                  <td>{{hierarchy.surname}}</td>
                </tr>
              </ng-template>
            </p-table>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No family an friends found.</td>
              </tr>
            </ng-template>
          </val-table-scroller>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </div>
  </div>
</div>

<p-confirmDialog header="Claim Points"></p-confirmDialog>

<ng-template valModal="sm" [(valmVisible)]="modalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h5 class="modal-title">{{st.showPoints ? "Transfer points" : "Transfer amount"}}</h5>
    <button type="button" class="btn-close" (click)="modalVisible=false"></button>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <label class="form-label">Transfer from card</label>
      <div class="w-100"></div>
      <span class="badge badge-selected bg-primary">{{transferPointsModel.fromLoyaltyCardNumber}}</span>
    </div>
    <div class="mb-4">
      <label class="form-label">To card (ID)</label>
      <input type="text" [(ngModel)]="transferPointsModel.toLoyaltyCardNumber" class="form-control">
    </div>
    <div class="mb-4">
      <label class="form-label">{{st.showPoints ? "Number of points" : "Amount number"}}</label>
      <input type="text" *ngIf="st.showPoints" [(ngModel)]="transferPointsModel.pointsTransfer" class="form-control">
      <input type="text" *ngIf="!st.showPoints" [(ngModel)]="transferPointsModel.amountTransfer" class="form-control">
    </div>
  </div>
  <div class="modal-footer m-0">
    <button (click)="modalVisible=false" type="button" class="btn btn-secondary text-uppercase me-5">
      Cancel
      <i class="vi vi-times-circle ms-3"></i>
    </button>
    <button (click)="transferPoints()" type="button" class="btn btn-primary text-uppercase"
      [disabled]="awaitingResponse || !transferPointsModel.toLoyaltyCardNumber || !(this.transferPointsModel.pointsTransfer || this.transferPointsModel.amountTransfer )">
	  <span *ngIf="!awaitingResponse">Confirm <i class="vi vi-check-circle ms-3"></i></span>
	  <span *ngIf="awaitingResponse">Confirm <i class="pi pi-spin pi-spinner"></i></span>
    </button>
  </div>
</ng-template>

<ng-template valModal="sm" [(valmVisible)]="ambassadorLinkModalVisible" [valmCloseOnBackdrop]="false">
	<div class="modal-header">
	  <h5 class="modal-title">Ambassador link</h5>
	  <button type="button" class="btn-close" (click)="ambassadorLinkModalVisible=false"></button>
	</div>
	<div class="modal-body d-flex justify-content-center">
		<div class="">
			<button type="button" class="btn btn-sm btn-primary text-nowrap m-2" [vrcClipboard]="getAmbassadorLink('HR', model?.cardInformation?.cardNumber ?? null)!"
				pTooltip="Copy to clipboard" tooltipPosition="bottom">
				HR - Ambasador program<i class="pi pi-copy ms-3"></i>
			</button>
			<button type="button" class="btn btn-sm btn-secondary text-nowrap m-2" (click)="openAmbassadorLink('HR', model?.cardInformation?.cardNumber ?? null)">
				<i class="pi pi-link"></i>
			</button><br />

			<button type="button" class="btn btn-sm btn-primary text-nowrap m-2" [vrcClipboard]="getAmbassadorLink('DE', model?.cardInformation?.cardNumber ?? null)!"
				pTooltip="Copy to clipboard" tooltipPosition="bottom">
				DE - Botschafter-Programm<i class="pi pi-copy ms-3"></i>
			</button>
			<button type="button" class="btn btn-sm btn-secondary text-nowrap m-2" (click)="openAmbassadorLink('DE', model?.cardInformation?.cardNumber ?? null)">
				<i class="pi pi-link"></i>
			</button><br />

			<button type="button" class="btn btn-sm btn-primary text-nowrap m-2" [vrcClipboard]="getAmbassadorLink('EN', model?.cardInformation?.cardNumber ?? null)!"
				pTooltip="Copy to clipboard" tooltipPosition="bottom">
				EN - Ambassador programme<i class="pi pi-copy ms-3"></i>
			</button>
			<button type="button" class="btn btn-sm btn-secondary text-nowrap m-2" (click)="openAmbassadorLink('EN', model?.cardInformation?.cardNumber ?? null)">
				<i class="pi pi-link"></i>
			</button><br />

			<button type="button" class="btn btn-sm btn-primary text-nowrap m-2" [vrcClipboard]="getAmbassadorLink('IT', model?.cardInformation?.cardNumber ?? null)!"
				pTooltip="Copy to clipboard" tooltipPosition="bottom">
				IT - Programma ambassador<i class="pi pi-copy ms-3"></i>
			</button>
			<button type="button" class="btn btn-sm btn-secondary text-nowrap m-2" (click)="openAmbassadorLink('IT', model?.cardInformation?.cardNumber ?? null)">
				<i class="pi pi-link"></i>
			</button><br />

			<button type="button" class="btn btn-sm btn-primary text-nowrap m-2" [vrcClipboard]="getAmbassadorLink('SI', model?.cardInformation?.cardNumber ?? null)!"
				pTooltip="Copy to clipboard" tooltipPosition="bottom">
				SI - Program ambasador<i class="pi pi-copy ms-3"></i>
			</button>
			<button type="button" class="btn btn-sm btn-secondary text-nowrap m-2" (click)="openAmbassadorLink('SI', model?.cardInformation?.cardNumber ?? null)">
				<i class="pi pi-link"></i>
			</button><br />
		</div>
	</div>
	<div class="modal-footer m-0">
	  <button (click)="ambassadorLinkModalVisible=false" type="button" class="btn btn-secondary text-uppercase me-5">
		Cancel
		<i class="vi vi-times-circle ms-3"></i>
	  </button>
	</div>
  </ng-template>

<ng-template valModal="sm" [(valmVisible)]="claimPointModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h5 class="modal-title">Claim points</h5>
    <button type="button" class="btn-close" (click)="claimPointModalVisible=false"></button>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <h6>Are you sure that you want to claim points?</h6>
    </div>
  </div>
  <div class="modal-footer m-0">
    <button (click)="claimPointModalVisible=false" type="button" class="btn btn-secondary text-uppercase me-5">
      Cancel
      <i class="vi vi-times-circle ms-3"></i>
    </button>
    <button (click)="claimPoints()" type="button" class="btn btn-primary text-uppercase">
      Confirm
      <i class="vi vi-check-circle ms-3"></i>
    </button>
  </div>
</ng-template>

<ng-template valModal="sm" [(valmVisible)]="loyaltyForgottenPasswordModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h5 class="modal-title">Send reset password email</h5>
    <button type="button" class="btn-close" (click)="loyaltyForgottenPasswordModalVisible=false"></button>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <h6>Are you sure that you want to send loyalty reset password email?</h6>
    </div>
  </div>
  <div class="modal-footer m-0">
    <button (click)="loyaltyForgottenPasswordModalVisible=false" type="button" class="btn btn-secondary text-uppercase me-5">
      Cancel
      <i class="vi vi-times-circle ms-3"></i>
    </button>
    <button (click)="loyaltyForgottenPassword(model?.mappingFields?.crm?.ad_loyaltyprofileid??'',model?.account?.email??'')" type="button"
      class="btn btn-primary text-uppercase">
      Confirm
      <i class="vi vi-check-circle ms-3"></i>
    </button>
  </div>
</ng-template>

<ng-template valModal="sm" [(valmVisible)]="loyaltyNewMailModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h5 class="modal-title">New loyalty email</h5>
    <button type="button" class="btn-close" (click)="layaltyNewEmailModalCancel()"></button>
  </div>

  <div *ngIf="!layaltyNewEmailConfirmation; else loyaltyConfirm">
    <div class="modal-body">
      <div class="mb-4">
        <h6>Enter new email?</h6>
      </div>

      <val-text-box #v placeholder="Enter email" [(value)]="newLoyatyEmail" [showValidationIfEmpty]="true">
        <val-validation [showInParentElement]="false">
          <ng-template valValidator="email" valvMessage="{{errorMessageEmail}}"></ng-template>
        </val-validation>
      </val-text-box>
    </div>

    <div class="modal-footer m-0">
      <p><b class="text-danger">Email change is not instant, it will be completed once the guest confirms it via the link sent to their new email address.</b></p>
    </div>
    <div class="modal-footer m-0">
      <button (click)="layaltyNewEmailModalCancel()" type="button" class="btn btn-secondary text-uppercase me-5">
        Cancel
        <i class="vi vi-times-circle ms-3"></i>
      </button>
      <button (click)="layaltyNewEmailModalConfirm()" type="button" class="btn btn-primary text-uppercase">
        Confirm
        <i class="vi vi-check-circle ms-3"></i>
      </button>
    </div>
  </div>

  <ng-template #loyaltyConfirm>
    <div class="modal-body">
      <div class="mb-4">
        <h6>Confirm <span class="text-danger">{{newLoyatyEmail}}</span> as new email?</h6>
      </div>
    </div>

    <div class="modal-footer m-0">
      <button (click)="layaltyNewEmailModalCancel()" type="button" class="btn btn-secondary text-uppercase me-5">
        Cancel
        <i class="vi vi-times-circle ms-3"></i>
      </button>
      <button (click)="loyaltyNewEmail(model?.cardInformation?.cardNumber ?? '', model?.account?.email ?? '', newLoyatyEmail ?? '')" type="button"
        class="btn btn-primary text-uppercase">
        Confirm
        <i class="vi vi-check-circle ms-3"></i>
      </button>
    </div>
  </ng-template>
</ng-template>
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ValDropDownComponent } from './val-drop-down.component';

@Directive({
  selector: '[valDropDown],[valddVisible],[valddVisibleChange],[valddClass],[valddDisabled]'
})

export class ValDropDownDirective implements OnDestroy, AfterViewInit, OnInit {
  private _closeSub!: Subscription;
  private _loaded = false;
  private _visible = false;
  private _btnref!: HTMLElement;
  private _componentRef: ComponentRef<ValDropDownComponent> | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public viewRef!: EmbeddedViewRef<any>;
  private _class!: string;
  @Input('valDropDown') set btnref(x: HTMLElement) {
    this._btnref = x;
    this._btnref.onclick = this.toggle;
  }
  @Input('valddVisible') set visible(x: boolean) {
    if (this._loaded && (this._visible != x)) {
      this.toggle();
    }
  }
  @Input('valddClass') set class(x: string) {
    this._class = x;
  }
  @Input('valddDisabled') disabled = false;
  @Output('valddVisibleChange') visibleChange = new EventEmitter<boolean>();
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _templateRef: TemplateRef<any>,
    private _appRef: ApplicationRef,
    private _viewContainer: ViewContainerRef,
    @Inject(DOCUMENT) private _document: Document,
  ) {
  }
  private _loadComponent = (): ComponentRef<ValDropDownComponent> => {
    this._unloadComponent();

    const componentRef = this._viewContainer.createComponent(ValDropDownComponent);
    componentRef.instance.btn = this._btnref;
    componentRef.instance.class = this._class;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    this._document.body.appendChild(domElem);
    return componentRef;
  }
  private _loadTemplate = () => {
    this._componentRef?.instance.vc.createEmbeddedView(this._templateRef);
  }
  private _unloadComponent = () => {
    if (this._componentRef) {
      this._appRef.detachView(this._componentRef.hostView);
      this._componentRef.destroy();
      this._componentRef = null;
    }
  }

  private _closeDropDown = () => {
    if (this._closeSub) {
      this._closeSub.unsubscribe();
    }
    this._unloadComponent();
  }

  private _showDropDown = () => {
    this._componentRef = this._loadComponent();
    if (!this._componentRef) { return; }
    this._loadTemplate();
    this._closeSub = this._componentRef.instance.close.subscribe(() => {
      this._closeDropDown();
      this._visible = false;
      this.visibleChange.emit(this._visible);
    });
    this._componentRef.changeDetectorRef.detectChanges();
  }
  ngOnDestroy(): void {
    if (this._visible) {
      this._closeDropDown();
    }
  }

  ngOnInit(): void {
    this._loaded = false;
  }

  ngAfterViewInit(): void {
    this._loaded = true;
  }

  public toggle = () => {
    this._visible = this.disabled ? false : !this._visible;
    if (this._loaded) {
      if (this._visible) {
        this._showDropDown();
      } else {
        if (this._componentRef && this._componentRef.instance) {
          this._componentRef.instance.animationState = "hidden";
        }
      }
      this.visibleChange.emit(this._visible);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ValSelectBoxComponent } from './val-select-box.component';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
        ValSelectBoxComponent
    ],
    exports: [
        ValSelectBoxComponent
    ],
    providers: []
})
export class ValSelectBoxModule { }

import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { ParamsModel } from '../models/params.model';
import { ValUtilsService } from './val-utils.service';
import { VrcAccountUtilsService } from './vrc-account-utils.service';
import { VrcDataService } from './vrc-data.service';
import { VrcStorageService } from './vrc-storage.service';
import { AccountModel } from '../models/mdp/account.model';

export const vrcAccountResolver: ResolveFn<boolean> =
  (route: ActivatedRouteSnapshot) => {
    const _srvc = inject(VrcDataService);
    const _st = inject(VrcStorageService);
    const _ms = inject(MessageService);
    const _u = inject(ValUtilsService);
    const _r = inject(Router);
    const _au = inject(VrcAccountUtilsService);
    const id = route.params['id'];
    const _setReservationSummary = () => {
      const paramsModel = new ParamsModel();
      paramsModel.first = 0;
      paramsModel.rows = 5;
      paramsModel.multiSortMeta = [{ field: 'checkIn', order: -1 }];
      paramsModel.extraData = _st.model?.id;
      _srvc.getReservationPage(paramsModel)
        .pipe(
          take(1),
          tap(result => {
            if (result && result.data && _st.model) {
              _st.model.reservations = result.data;
            }
          }),
          catchError((err: HttpErrorResponse) => {
            _ms.add({ severity: 'error', summary: 'Error', detail: JSON.stringify(err?.error?.Errors) });
            return of(null);
          })
        )
        .subscribe();
    };
    const _setOfferSummary = () => {
      const paramsModel = new ParamsModel();
      paramsModel.first = 0;
      paramsModel.rows = 5;
      paramsModel.multiSortMeta = [{ field: 'checkIn', order: 1 }];
      paramsModel.filters['accountId'] = [
        {
          value: _st.model?.id,
          matchMode: 'equals',
          operator: 'and',
        },
      ];
      _srvc.getOfferPage(paramsModel)
        .pipe(
          take(1),
          tap(result => {
            if (result && result.data && _st.model) {
              _st.model.offers = result.data;
            }
          }),
          catchError((err: HttpErrorResponse) => {
            _ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.Errors });
            return of(null);
          })
        )
        .subscribe();
    };
    if (id == 'new') {
      _st.interestsLookup = _st.interestsLookup ? _st.interestsLookup : [];
      _st.isEditOn = true;
      _st.model = _au.InitNewAccount(_st.firstName, _st.lastName, _st.language, _st.phone, _st.email ?? '');
      _st.emails = _st.model.contact.additional.emails.map((e) => {
        return { id: 0, data: e };
      });
      _st.phones = _st.model.contact.additional.phones.map((e) => {
        return { id: 0, data: e };
      });
      _st.loyality = [];
      return of(true);
    }


    _st.niceNewContact = false;

    _ms.add({ severity: 'info', summary: 'Loading', detail: 'Loading account' });
    return _srvc.getAccountById(id).pipe(
      take(1),
      map(acc => {
        // model fix   
        if (acc) {
          acc = <AccountModel>_au.AccountFix(acc);
          _st.model = acc;
          _st.origModel = _u.deepCopyObject(acc);
          _st.loyality = acc.loyaltiesList;
          _st.loyalityOrig = _u.deepCopyObject(acc.loyaltiesList);
          if (_st.mergeAccounts && _st.duplicateAccount) {
            _st.model = _u.deepMergeObjectValues(_st.duplicateAccount, _st.model, false);
            _st.mergeAccounts = false;
            _st.duplicateAccount = null;
          }

        }
        // _st.isEditOn = false;
        if (_st.accountIsSelected) {
          _st.accountIsSelected = false;
          _au.UpdateAccountData();
        } else {
          _au.synchronizeActivatedContact();
        }
        _setOfferSummary();
        _setReservationSummary();

        _st.selectedLoyaltyNumber = _st.model?.loyaltiesList.filter(x => !x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
        _st.selectedValamartistNumber = _st.model?.loyaltiesList.filter(x => x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
        _st.emails = _st.model?.contact.additional.emails.map((e) => {
          return { id: 0, data: e };
        }) ?? [];
        _st.phones = _st.model?.contact.additional.phones.map((e) => {
          return { id: 0, data: e };
        }) ?? [];

        //_ms.clear();
        return true;
      }),
      catchError((err: HttpErrorResponse) => {
        _ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        _r.navigate(['../']);
        return of(false);
      })
    );
  };
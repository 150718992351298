import { Component, OnInit } from '@angular/core';
import { VrcStorageService } from '../services/vrc-storage.service';

import { LoyalityDetailsModel } from '../models/mdp/loyality-details.model';

@Component({
  selector: 'vrc-loyality',
  templateUrl: './vrc-loyality.component.html'
})
export class VrcLoyalityComponent implements OnInit {
  public loyaltiesList: LoyalityDetailsModel[] = [];
  public showPoints = true;
  
  constructor(
    public st: VrcStorageService
  ) { }

  public ngOnInit(): void {
    this.loyaltiesList = this.st.model?this.st.model?.loyaltiesList.filter(x => !x.cardInformation?.cardNumber?.startsWith("4")):[];
  }

  public menuClick = (m: LoyalityDetailsModel, e: MouseEvent) => {
    e.preventDefault();
    this.loyaltiesList.forEach(x => x.isSelected = false);
    m.isSelected = true;
    this.st.selectedLoyaltyNumber=m.cardInformation?.cardNumber as string;
  }
}



<div class="row val-serach-header">
  <div class="col-auto p-5">
    <h5 class="my-auto text-nowrap pt-3">
      {{(st.accountIds && this.st.accountIds.length > 1 && st.contactId && (bst.agentId??0)>0)?'All Guests':'Search results'}}
    </h5>
  </div>
  <div class="col py-5 px-5">
    <button *ngIf="!(st.accountIds && this.st.accountIds.length> 1 && st.contactId && (bst.agentId??0)>0)" type="button"
      class="btn btn-lg btn-primary float-end" (click)="closeSearch()">Close</button>
  </div>
</div>
<val-table-scroller>
  <p-table #searchTable [lazyLoadOnInit]="false" [value]="searchResults" dataKey="id" [rowHover]="true"
    [rows]="lastParams?lastParams.rows:5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5,10,25,50]"
    [loading]="isSearchLoading" [paginator]="true" currentPageReportTemplate="{first}-{last} OF {totalRecords}"
    [filterDelay]="0" [lazy]="false" [totalRecords]="totalRecords" sortMode="multiple" [resizableColumns]="true"
    [reorderableColumns]="true" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr class="table-header">
        <th style="width: 5rem" pReorderableColumn pResizableColumn>
          Action
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="firstName">
          First name
          <p-sortIcon field="firstName"></p-sortIcon>
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="lastName">
          Last name
          <p-sortIcon field="lastName"></p-sortIcon>
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="contact.phone">
          Phone
          <p-sortIcon field="contact.phone"></p-sortIcon>
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="contact.email">
          Email
          <p-sortIcon field="contact.email"></p-sortIcon>
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn>
          Loyalty Number
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="address.country">
          Country
          <p-sortIcon field="address.country"></p-sortIcon>
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="address.city">
          City
          <p-sortIcon field="address.city"></p-sortIcon>
        </th>
        <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="birthDate">
          Date of birth
          <p-sortIcon field="birthDate"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-account let-expanded="expanded">
      <tr>
        <td>
          <button [pRowToggler]="account" type="button" class="btn btn-sm btn-primary me-4"
            [class.btn-primary]="expanded" [class.btn-secondary]="!expanded">
            <i class="vi" [class.vi-chevron-down]="!expanded" [class.vi-chevron-up]="expanded"></i>
          </button>
          <button (click)="selectAccount(account)" type="button" class="btn btn-secondary">
            Select
          </button>
        </td>
        <td>{{ account.firstName }}</td>
        <td>{{ account.lastName }}</td>
        <td>{{ account.contact?.phone }}</td>
        <td>{{ account.contact?.email }}</td>
        <td>
          <div class="w-100">
            <ng-container *ngFor="let loyalty of account.loyaltiesList">
              <div class="badge badge-copy bg-gray-50 text-gray-700 mb-1">
                {{loyalty.cardInformation?.cardNumber}}
                <button class="btn btn-copy ms-4" [vrcClipboard]="loyalty.cardInformation?.cardNumber??''"
                  pTooltip="Copy to clipboard" tooltipPosition="bottom">
                  <i class="vi vi-copycontent"></i>
                </button>
              </div>
              <div class="badge badge-status text-gray-700 mb-1 ms-3 " [class.bg-danger-1]="loyalty.status!=='Active'" [class.bg-success-1]="loyalty.status==='Active'">{{loyalty.status}}</div>
              <div class="w-100"></div>
            </ng-container>
          </div>
        </td>
        <td>{{account.address?.country | countryByCode: st.countries }}</td>
        <td>{{ account.address?.city }}</td>
        <td>{{ account.birthDate | date: 'shortDate'}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-account>
      <tr>
        <td colspan="9">
          <div class="row expanded-body">
            <div class="col-3 col-sm-4 col-md-5 col-lg-6 col-xl-7 col-xxl-8 p-5">
              <div class="row">
                <div class="col-auto">
                  <i class="vi vi-dot-circle mx-3"></i>
                </div>
                <div class="col">
                  <div class="row">
                    <h6 class="table-body-header">Contact details</h6>
                  </div>
                  <div class="row">
                    <div class="col-4 mb-3">
                      <label class="form-label">Email</label>
                      <ng-container *ngFor="let e of account.contact.additional.emails">
                        <span class="form-control-plaintext disabled">{{e}}</span>
                        <div class="w-100"></div>
                      </ng-container>
                    </div>
                    <div class="col-4 mb-3"></div>
                    <div class="col-4 mb-3">
                      <label class="form-label">Phone</label>
                      <ng-container *ngFor="let e of account.contact.additional.phones">
                        <span class="form-control-plaintext disabled">{{e}}</span>
                        <div class="w-100"></div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-100 border-gray-50 border-top"></div>
              <div class="row mt-5">
                <div class="col-auto">
                  <i class="vi vi-dot-circle mx-3"></i>
                </div>
                <div class="col">
                  <div class="row">
                    <h6 class="table-body-header">Guest Details</h6>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 mb-3 mb-sm-5">
                      <label class="form-label">Country</label>
                      <span class="form-control-plaintext disabled">{{account.address?.country}}</span>
                    </div>
                    <div class="col-6 col-sm-4 mb-3 mb-sm-5">
                      <label class="form-label">Address</label>
                      <span class="form-control-plaintext disabled">
                        {{account.address?.street? account.address.street + ', ' +
                        account.address?.city?account.address.city:'': account.address?.city?account.address.city:''}}
                      </span>
                    </div>
                    <div class="col-6 col-sm-4 mb-5">
                      <label class="form-label">Date of birth</label>
                      <span class="form-control-plaintext disabled">{{account.birthDate | date:'shortDate'}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-100 border-gray-50 border-top"></div>
              <div class="row mt-5">
                <div class="col-auto">
                  <i class="vi vi-dot-circle mx-3"></i>
                </div>
                <div class="col">
                  <div class="row">
                    <h6 class="table-body-header">Status</h6>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 mb-3 mb-sm-5">
                      <label class="form-label">Offer</label>
                      <div class="w-100"></div>
                      <div class="badge badge-status text-gray-700 bg-success-1 text-uppercase text-start"
                        *ngIf="account.offers | hasActiveOfferPipe">
                        <i class="vi vi-check me-4"></i>
                        Offer
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 mb-3 mb-sm-5">
                      <label class="form-label">Reservation</label>
                      <div class="w-100"></div>
                      <div class="badge badge-status text-gray-700 bg-success-1 text-uppercase text-start"
                        *ngIf="account.reservations | hasActiveReservationPipe">
                        <i class="vi vi-check me-4"></i>
                        Reservation
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 mb-5">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="9">No accounts found.</td>
      </tr>
    </ng-template>
  </p-table>
</val-table-scroller>
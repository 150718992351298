import { Pipe, PipeTransform } from '@angular/core';

import { ValUtilsService } from '../services/val-utils.service';

@Pipe({ name: 'labelByKey' })
export class LabelByKeyPipe implements PipeTransform {
  constructor(private _u: ValUtilsService) { }
  transform(key: string, lookup: Array<{ key: string, label: string }>): string | null {
    return (lookup && this._u.isArray(lookup)) ?
      lookup.filter(x => x.key === key).map(x => x.label).reduceRight((_a, b) => b) : null;
  }
}

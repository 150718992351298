<div class="card border-0">
  <div class="card-body">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center" style="height: 80px;">
          <span>
            This contact type is not supported.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
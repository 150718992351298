import { Pipe, PipeTransform } from '@angular/core';

import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';

@Pipe({ name: 'isLoggedIn' })
export class IsLoggedInPipe implements PipeTransform {
    constructor(private _bst: VrcBrowserStorageService) { }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(trigger: number): boolean {
        if (this._bst.token || this._bst.isAzureUser) {
            return true;
        } else {
            return false;
        }
    }
}

export class DatePickerCellModel {
    constructor(
        public day: number = 1,
        public date: Date | null = null,
        public isSelected: boolean = false,
        public isToday: boolean = false,
        public isCurrentMonth: boolean = false,
        public isDisabled: boolean = false,
    ) { }
}

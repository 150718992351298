export class AccountNote {
	constructor(
		public id: string | null = null,
		public version: string | null = null,
		public accountId: string | null = null,
		public content: string | null = null,
		public createdBy: string | null = null,
		public createDate: Date | null = null,
		public modifiedBy: string | null = null,
		public modifyDate: Date | null = null,
		public editable: boolean | null = null
	) { }
}
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';

import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';

@Component({
  selector: 'vrc-login',
  templateUrl: './vrc-login.component.html'
})
export class VrcLoginComponent implements OnInit {

  public returnRoute = "";
  public userName = '';
  public password = '';

  public get isLocalOrAzureUser() {
    return this._bst.isAzureUser || this._bst.userData === 'API';
  }

  constructor(
    private r: Router,
    private cr: ActivatedRoute,
    private _srvc: VrcDataService,
    private _bst: VrcBrowserStorageService,
    private _authService: MsalService,
    private _ms: MessageService,
    public st: VrcStorageService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) { }


  public ngOnInit(): void {
    this.returnRoute = this.cr.snapshot.paramMap.get("url") ?? '';
    this.returnRoute = this.returnRoute ? this.returnRoute : "";
  }

  public login = () => {
    if (!this.userName || !this.password) { return; }
    this._bst.clearAuthentication();
    this._srvc.getUserToken({ userName: this.userName, password: this.password }).pipe(
      take(1),
      tap(r => {
        this._bst.token = r.token;
        this._bst.refreshToken = r.refreshToken;
        this._bst.subscription = this._bst.subscriptionFromToken;
        this.st.sessionId = null;
        this.r.navigate([this.returnRoute]);
      }),
      catchError((err: HttpErrorResponse) => {
        this._bst.clearAuthentication();
        this.userName = '';
        this.password = '';
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(false);
      })).subscribe();
  }

  public loginMsal = () => {
    this._bst.clearAuthentication();
    if (this.msalGuardConfig.authRequest) {

      this._authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).pipe(
        take(1),
        tap(response => {
          this._authService.instance.setActiveAccount(response.account);
        }),
        mergeMap(() => this._srvc.getSubscription().pipe(take(1), tap(r => {
          this._bst.subscription = r;
          this.r.navigate([this.returnRoute]);
        }))))
        .subscribe();
    } else {
      this._authService.loginPopup().pipe(
        take(1),
        tap((response: AuthenticationResult) => {
          this._authService.instance.setActiveAccount(response.account);
        }),
        mergeMap(() => this._srvc.getSubscription().pipe(take(1), tap(r => {
          this._bst.subscription = r;
          this.r.navigate([this.returnRoute]);
        }))))
        .subscribe();
    }
  }
}


import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { ValUtilsService } from '../services/val-utils.service';
import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';

@Component({
  selector: 'vrc-nice-login',
  templateUrl: './vrc-nice-login.component.html'
})
export class VrcNiceLoginComponent implements OnInit {
  private _url = "";
  constructor(
    private _r: Router,
    private _srvc: VrcDataService,
    private _bst: VrcBrowserStorageService,
    private _ms: MessageService,
    private _st: VrcStorageService,
    private _ars: ActivatedRoute,
    private _u: ValUtilsService,
  ) { }

  public ngOnInit(): void {
    const state = this._ars.snapshot
    this._st.contactId = +state.root.queryParams['contactId'];
    this._st.email = state.root.queryParams['email'];
    this._st.phone = state.root.queryParams['phone'];
    this._st.accountIds = this._u.isArray(state.root.queryParams['accountIds']) ? state.root.queryParams['accountIds'] : state.root.queryParams['accountIds'] ? [state.root.queryParams['accountIds']] : [];
    this._st.language = state.root.queryParams['language'];
    this._st.firstName = state.root.queryParams['firstName'];
    this._st.lastName = state.root.queryParams['lastName'];
    this._st.contactType = state.root.queryParams['contactType'];
    this._st.sessionId = state.root.queryParams['sessionId'];
    this._st.timeOffset = state.root.queryParams['timeOffset'];
    this._st.skillId = +state.root.queryParams['skill'];
    const agentId = +state.root.queryParams['agentId'];
    this._bst.agentId = agentId;

    if (!this._st.accountIds || this._st.accountIds.length === 0) {
      this._st.niceNewContact = true;
      this._url = 'main/account/new/details';
    } else if (this._st.accountIds.length === 1) {
      this._url = 'main/account/' + this._st.accountIds[0];
    } else {
      this._url = 'main/search/auto/' + encodeURIComponent(JSON.stringify({ contactId: this._st.contactId, accountIds: this._st.accountIds }));
    }

    if (this._bst.token && !this._bst.isTokenExpired) { // && !this._bst.isAzureUser) {
      this._initDisplayAccounts();
      return;
    }

    // Check if token is expired and refresh it, azure login commented because we want to force nice login
    if (this._bst.token && this._bst.isTokenExpired && !this._bst.isRefreshTokenExpired) { // && !this._bst.isAzureUser) {
      this._srvc.refreshToken().pipe(
        take(1),
        tap(x => {
          this._bst.clearAuthentication();
          this._bst.agentId = agentId;
          this._bst.token = x.token;
          this._bst.refreshToken = x.refreshToken;
          this._initDisplayAccounts();
        }),
        catchError(err => {

          // fallback to azure login if can
          if (this._bst.isAzureUser) {
            this._initDisplayAccounts();
          } else {
            this._bst.clearAuthentication();
            this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
            this._r.navigate(['login', { url: this._url }]);
          }
          return of(null);
        })).subscribe();
    } else {
      // If we have contactId app is oppened inside MAX iframe, check if user is logged in and do automatic login if neccesery
      if (agentId && this._st.sessionId) {
        this._loginNice(this._st.sessionId, agentId);
      }
    }
  }
  private _loginNice = (sessionId: string, agentId: number) => {
    if (!sessionId || !agentId) { return; }
    // this._bst.clear();
    this._srvc.getToken({ sessionId: sessionId, agentId: agentId }).pipe(
      take(1),
      tap(r => {
        this._bst.clearAuthentication();
        this._bst.agentId = agentId;
        this._bst.token = r.token;
        this._bst.refreshToken = r.refreshToken;
        this._bst.subscription = this._bst.subscriptionFromToken;
        this._bst.agentId = agentId;
        this._st.sessionId = null;
        this._initDisplayAccounts();
      }),
      catchError((err: HttpErrorResponse) => {

        // fallback to azure loginif can
        if (this._bst.isAzureUser) {
          this._initDisplayAccounts();
        } else {
          this._bst.clearAuthentication();
          this._st.sessionId = null;
          this._ms.add({ severity: 'error', summary: 'Error', detail: 'Error: ' + err?.error?.message });
          // Go to login component by default
          this._r.navigate(['login', { url: this._url }]);
        }
        return of();
      })).subscribe();
  }

  private _initDisplayAccounts = (): void => {
    // Check if user is logged in and get accounts
    if ((this._bst.token || this._bst.isAzureUser) && this._st.contactId && (this._bst.agentId ?? 0) > 0) {
      this._r.navigate([this._url]);
    }
  }
}


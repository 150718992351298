
<div class="row vrc-login-row">
  <div class="col-12 vrc-login-padding">
    <div class="text-start fw-bolder text-uppercase mb-4 text-secondary vrc-login-title">Login to vrc</div>
    <div class="card vrc-login">
      <div class="text-center vrc-login-top">
        <a class="nav-link" [routerLink]="returnRoute" routerLinkActive="active"><i class="vi vi-logo"></i></a>
        <div class="w-100"></div>
        <i class="vi vi-logo-title"></i>
      </div>
      <div class="card-body bg-secondary p-5">
        <div class="mb-5 mx-4">
          <label class="form-label">User name</label>
          <input type="text" [(ngModel)]="userName" class="form-control" placeholder="User name">
        </div>
        <div class="mb-5 mx-4">
          <label class="form-label">Password</label>
          <input type="password" [(ngModel)]="password" class="form-control" placeholder="Password">
        </div>
        <div class="pt-3 mx-4">
          <button (click)="login()" class="btn btn-primary text-uppercase w-100">Login</button>
          <button (click)="loginMsal()" class="btn btn-link text-uppercase text-decoration-none w-100">Login with corporate account</button>
        </div>
      </div>
    </div>
  </div>
</div>
import { ApplicationRef, ComponentRef, Directive, ElementRef, EmbeddedViewRef, Input, OnInit, ViewContainerRef } from '@angular/core';

import { ValValidationComponent } from './val-validation.component';
import { ValValidatorComponent } from './val-validator.component';


@Directive({
  selector: '[valValidator],[valvFunctionRef],[valvMessage],[valvCustomData]'
})

export class ValValidatorDirective implements OnInit {
  private _hidden = false;
  private _message: string | null = null;
  private _cRef!: ComponentRef<ValValidatorComponent> | null;
  @Input('valValidator') type!: "required" | "email" | "phone" | "number" | "date" | "custom";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input('valvFunctionRef') funcionRef!: (value: any, customData: any) => boolean;
  @Input('valvMessage') set message(x: string | null) {
    this._message = x;
    if (this._cRef) {
      this._cRef.instance.message = this.message;
    }
  }
  get message() { return this._message; }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input('valvCustomData') customData: any;
  public set hidden(x: boolean) {
    this._hidden = x;
    if (this._cRef) {
      this._cRef.instance.hidden = x;
    }
  }
  public get hidden() { return this._hidden; }
  constructor
    (
      private _validationComponent: ValValidationComponent,
      private _appRef: ApplicationRef,
      private _viewContainer: ViewContainerRef,
    ) { }
  ngOnInit(): void {
    this._validationComponent.validators.push(this);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate(value: any): boolean {
    let re: RegExp;
    switch (this.type) {
      case "required":
        return value ? true : false;
      case "email":
        if (value) {
          re = /^((?:[A-Za-z0-9!#$%&'*+\-\\/=?^_`{|}~]|(?<=^|\.)""|""(?=$|\.|@)|(?<="".*)[ .](?=.*"")|(?<!\.)\.){1,64})(@)((?:[A-Za-z0-9.\\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/i;
          return re.test(value);
        }
        return true;
      case "phone":
        if (value) {
          // re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
          re = /^\+[1-9]\d{7,14}$/;
          return !value ? true : re.test(value);
        }
        return true;
      case "number":
        if (value) {
          return parseFloat(value) ? true : false;
        }
        return true;
      case "date":
        if (value) {
          // eslint-disable-next-line no-constant-condition
          return (new Date(value)) ? true : false;
        }
        return true;
      case "custom":
        return this.funcionRef(value, this.customData);
      default:
        return true;
    }
  }

  public show = (vc: ViewContainerRef) => {
    this._cRef = vc.createComponent(ValValidatorComponent);
    this._cRef.instance.message = this.message;
    this._cRef.instance.hidden = this._hidden;
  }

  public showInParent = (elRef: ElementRef) => {
    this._cRef = this._viewContainer.createComponent(ValValidatorComponent);
    this._cRef.instance.message = this.message;
    this._cRef.instance.hidden = this._hidden;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const domElem = (this._cRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    (elRef.nativeElement as HTMLElement)?.parentElement?.parentElement?.appendChild(domElem);
    this._cRef.changeDetectorRef.detectChanges();
  }
  public unloadComponent = () => {
    if (this._cRef) {
      this._appRef.detachView(this._cRef.hostView);
      this._cRef.destroy();
      this._cRef = null;
    }
  }
}

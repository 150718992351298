<div class="row pb-5 bg-secondary pt-3">
  <div class="col-12">
    <val-table-scroller>
      <p-table [value]="communication" dataKey="contactId" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading" [paginator]="true" [multiSortMeta]="[{field: 'stateId', order: 1}, {field: 'createDate', order: -1}]"
        currentPageReportTemplate="{first}-{last} OF {totalRecords}" [filterDelay]="0" [lazy]="true" (onLazyLoad)="tableLazyLoad($event)" [totalRecords]="totalRecords"
        sortMode="multiple" [resizableColumns]="true" [reorderableColumns]="true" columnResizeMode="expand" [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem;" pResizableColumn pSortableColumn="channel">
              Channel
              <p-sortIcon field="channel"></p-sortIcon>
              <p-columnFilter type="text" field="channel" display="menu"></p-columnFilter>
            </th>
            <th style="width: 4rem;" pResizableColumn pSortableColumn="createDate">
              Date
              <p-sortIcon field="createDate"></p-sortIcon>
              <p-columnFilter type="date" field="createDate" display="menu">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy" [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th pResizableColumn pSortableColumn="subject">
              Subject
              <p-sortIcon field="subject"></p-sortIcon>
              <p-columnFilter type="text" field="subject" display="menu">
              </p-columnFilter>
            </th>
            <th style="width: 2rem" pResizableColumn pSortableColumn="statusSort">
              Status
              <p-sortIcon field="statusSort"></p-sortIcon>
              <p-columnFilter type="text" field="status" display="menu"></p-columnFilter>
            </th>
            <th style="width: 2rem" pResizableColumn>
              Replied
            </th>
            <th style="width: 2rem" pResizableColumn>
              Direction
            </th>
            <th style="width: 2rem" pResizableColumn pSortableColumn="agentName">
              Agent
              <p-sortIcon field="agentName"></p-sortIcon>
              <p-columnFilter type="text" field="agentName" display="menu"></p-columnFilter>
            </th>
			<th pResizableColumn>
				Note
			</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-comm let-expanded="expanded">
          <tr>
            <td>
              <button [disabled]="!(comm.data.childData && comm.data.childData.length>0)" [pRowToggler]="comm" type="button" class="btn btn-sm btn-primary me-4"
                [class.btn-primary]="expanded" [class.btn-secondary]="!expanded">
                <i class="vi" [class.vi-chevron-down]="!expanded" [class.vi-chevron-up]="expanded"></i>
              </button>
              <button (click)="openDetails(comm)" class="btn btn-sm btn-secondary btn-popup">
                {{ comm.channel==='Digital'? comm.channel + ' ' + comm.subChannel:comm.channel}}
                <i class="vi vi-popup ms-3"></i>
              </button>
            </td>
            <td>{{ comm.createDate | date:'short' }}</td>
            <td>
              <span>{{ comm.subject }}</span>
            </td>
            <td>
              <span class="badge badge-status text-gray-700 text-uppercase" 
                [class.bg-info]="comm.status | isCommInStatus:st.commStatuses.Open:comm.channel" 
                [class.bg-success-1]="comm.status | isCommInStatus:st.commStatuses.Active:comm.channel"
                [class.bg-gray-50]="comm.status | isCommInStatus:st.commStatuses.Parked"
                [class.bg-danger]="comm.status | isCommInStatus:st.commStatuses.Escalated"
                [class.bg-success]="comm.status | isCommInStatus:st.commStatuses.Resolved" 
                [class.bg-info-1]="comm.status | isCommInStatus:st.commStatuses.Pending"
                [class.bg-danger-1]="comm.status | isCommInStatus:st.commStatuses.Closed" >
                <i class="vi vi-rectangle me-3"></i>
                {{comm.status}}
              </span>
              <button *ngIf="((comm.status | isCommInStatus:st.commStatuses.Open:comm.channel) || (comm.status | isCommInStatus:st.commStatuses.Active:comm.channel))"
                (click)="showCloseDialog(comm)" class="btn btn-close-comm p-0 text-gray-700 bg-danger-1 mx-4">
                <i class="vi vi-times"></i>
              </button>
            </td>
            <td class="text-center">
              <i class="vi float-center me-3" [class.vi-radio-check]="(comm.data.childData && comm.data.childData.length>0)"
                [class.vi-radio-minus]="!(comm.data.childData && comm.data.childData.length>0)">
              </i>
            </td>
            <td>
              <span>{{ comm.isOutgoing ? 'Outbound' : 'Inbound' }}</span>
            </td>
            <td>
              <span>{{ comm.agentName }}</span>
            </td>
			<td>
				<span>{{ comm.lastDisposition?.notes }}</span>
			</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-comm>
          <tr *ngIf="comm.data && comm.data.childData && comm.data.childData.length>0">
            <td colspan="7">
              <div class="row expanded-body">
                <div class="col-7 col-sm-8 col-md-9 col-lg-10 col-xl-11 col-xxl-12 p-5">
                  <ng-template ngFor let-cd [ngForOf]="comm.data.childData" let-i="index">
                    <div *ngIf="i>0" class="w-100 border-gray-50 border-top"></div>
                    <div class="row">
                      <div class="col-auto">
                        <i class="vi vi-dot-circle mx-3"></i>
                      </div>
                      <div class="col">
                        <div class="row">
                          <h6 class="table-body-header">Replied communication</h6>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                            <label class="form-label">Channel</label>
                            <div class="w-100"></div>
                            <button (click)="openDetails(comm,cd.guid)" class="btn btn-sm btn-secondary btn-popup">
                              {{ cd.childChannel }}
                              <i class="vi vi-popup ms-3"></i>
                            </button>
                          </div>
                          <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                            <label class="form-label">Date</label>
                            <span class="form-control-plaintext disabled text-wrap">{{cd.data.createdDate | date:'short' }}</span>
                          </div>
                          <div class="col-6 col-sm-3 mb-5">
                            <label class="form-label">Subject</label>
                            <span class="form-control-plaintext disabled text-wrap">{{cd.data.subject}}</span>
                          </div>
                          <div class="col-6 col-sm-3 mb-5">
                            <label class="form-label">Agent</label>
                            <span class="form-control-plaintext disabled text-wrap">{{cd.data.firstName}} {{cd.data.lastName}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No communication found</td>
          </tr>
        </ng-template>
      </p-table>
    </val-table-scroller>
  </div>
</div>

<ng-template valModal="sm" [(valmVisible)]="closeModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h6 class="modal-title">
      Do you really want to close communication?
    </h6>
    <button type="button" class="btn-close" (click)="closeModalVisible=false"></button>
  </div>
  <div class="modal-footer m-0">
    <button (click)="closeModalVisible=false" type="button" class="btn btn-secondary w-25">No</button>
    <button class="btn btn-primary w-25" (click)="closeCommunication()">Yes</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="emailModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Email details
    </h6>
    <button type="button" class="btn-close" (click)="emailModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-email-details [model]="emailDetails" [communication]="selectedCommunication" [status]="selectedCommunication.status??''" [contactId]="selectedCommunication.contactId??0"></vrc-communication-email-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="emailModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="chatModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Chat details
    </h6>
    <button type="button" class="btn-close" (click)="chatModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-chat-details [model]="chatDetails" [communication]="selectedCommunication" [contactId]="selectedCommunication.contactId??0"></vrc-communication-chat-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="chatModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="voiceModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Voice details
    </h6>
    <button type="button" class="btn-close" (click)="voiceModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-call-details [model]="callDetails" [communication]="selectedCommunication" [id]="selectedCommunication.id??''" [contactId]="selectedCommunication.contactId??0"></vrc-communication-call-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="voiceModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="digitalModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      {{digitalModalTitle}}
    </h6>
    <button type="button" class="btn-close" (click)="digitalModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-dfo-details [model]="digitalDetails" [status]="selectedCommunication.status??''" [communication]="selectedCommunication" [contactId]="selectedCommunication.contactId??0"></vrc-communication-dfo-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="digitalModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>
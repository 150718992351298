import { Pipe, PipeTransform } from '@angular/core';

import { OfferModel } from '../models/mdp/offer.model';
import { VrcStorageService } from '../services/vrc-storage.service';

@Pipe({ name: 'hasActiveOfferPipe' })
export class HasActiveOfferPipe implements PipeTransform {
    constructor(private _s: VrcStorageService) { }
    transform(data: Array<OfferModel>): string | null {
        return (data && data.length > 0) ?
            data.filter(x => x.status?.toUpperCase() === this._s.offerStatuses.CREATED
                || x.status?.toUpperCase() === this._s.offerStatuses.SENT_EMAIL
                || x.status?.toUpperCase() === this._s.offerStatuses.OPENED)
                .map(x => x.phobsOfferId).reduceRight((_a, b) => b, null) : null;
    }
}

import { Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';

import { ValValidatorDirective } from './val-validator.directive';


@Component({
  selector: 'val-validation',
  template: '<ng-container #vc></ng-container>'
})
export class ValValidationComponent {
  @ViewChild('vc', { read: ViewContainerRef, static: true }) private _vc!: ViewContainerRef;
  private _hasFocus = false;
  public validators: ValValidatorDirective[];
  public isValid = true;
  public isValidated = false;
  @Input() hideOnFocusOut = true;
  @Input() showInParentElement = false;
  constructor(
    private _elRef: ElementRef,
  ) {
    this.validators = [];
  }
  public set hasFocus(x: boolean) {
    this._hasFocus = x;
    this.validators.forEach(x => x.hidden = (this.hideOnFocusOut && !this._hasFocus ? true : false));
  }
  public get hasFocus() { return this._hasFocus; }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public validate = (value: any, hasFocus = false) => {
    this._hasFocus = hasFocus === null ? this._hasFocus : hasFocus;
    let isValid = true;
    this._vc.clear();
    for (let i = 0; i < this.validators.length; i++) {
      this.validators[i].hidden = (this.hideOnFocusOut && !this._hasFocus ? true : false);
      this.validators[i].unloadComponent();
      if (!this.validators[i].validate(value)) {
        isValid = false;
        if (this._vc) {
          if (this.showInParentElement) {
            this.validators[i].showInParent(this._elRef);
          } else {
            this.validators[i].show(this._vc);
          }
        }
        break;
      }
    }
    this.isValidated = true;
    this.isValid = isValid;
    return isValid;
  }  
  public reset = () => {
    this._vc.clear();
    for (let i = 0; i < this.validators.length; i++) {
      this.validators[i].hidden = (this.hideOnFocusOut && !this._hasFocus ? true : false);
      this.validators[i].unloadComponent();
    }
    this.isValidated = false;
    this.isValid = true;
  }
}

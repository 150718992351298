import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { VrcDataService } from './vrc-data.service';
import { VrcStorageService } from './vrc-storage.service';
export const vrcLookupResolver: ResolveFn<boolean> =
    () => {
        const _srvc = inject(VrcDataService);
        const _st = inject(VrcStorageService);
        if (!_st.interestsLookup || !_st.advertisingPermissions || !_st.products) {
            return forkJoin([
                _srvc.getInterests().pipe(
                    take(1),
                    map(x => {
                        _st.interestsLookup = x;
                        _st.sessionId = null;
                        return true;
                    }),
                    catchError((err: HttpErrorResponse) => {
                        console.log(err?.error?.message);
                        return of(false);
                    })),
                _srvc.getAdvertisingPermissions().pipe(
                    take(1),
                    map(x => {
                        _st.advertisingPermissions = x;
                        _st.sessionId = null;
                        return true;
                    }),
                    catchError((err: HttpErrorResponse) => {
                        console.log(err?.error?.message);
                        return of(false);
                    })),
                _srvc.getProducts().pipe(
                    take(1),
                    map(x => {
                        _st.products = x;
                        _st.sessionId = null;
                        return true;
                    }),
                    catchError((err: HttpErrorResponse) => {
                        console.log(err?.error?.message);
                        return of(false);
                    }))]).pipe(
                        map(() => true),
                        catchError((err: HttpErrorResponse) => {
                            console.log(err?.error?.message);
                            return of(false);
                        }));

        }
        return true;
    };



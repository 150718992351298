<div class="row pb-5 bg-secondary pt-3">
  <div class="col-12 py-3 px-5">
    <button type="button" class="btn btn-lg btn-secondary float-end" (click)="close()">
      Close
    </button>
  </div>
  <div class="col-12 py-3 px-5">
    <label>Agent comment</label>
    <textarea class="form-control" style="min-height:3rem;" rows="3" (keyup)="insertTextinHTML($event)"></textarea>
  </div>
  <div *ngIf="st.offer?.html2insert" class="col-12 py-3 px-5">
    <angular-editor [(ngModel)]="st.offer!.html2insert" [config]="editorConfig"></angular-editor>
  </div>
  <div class="col-12 py-3 px-5">
    <label class="form-label">Status</label>
    <input type="text" disabled class="form-control-plaintext" [ngModel]="st.offer?.text2agent">
  </div>
  <div class="col-12 py-3 px-5">
    <button [disabled] = "st.offer?.status === st.offerStatusError" (click)="cancel()" type="button" class="btn btn-lg btn-secondary">
      Cancel offer
    </button>
    <button [disabled] = "st.offer?.status === st.offerStatusError" (click)="validateFromEmail()" type="button" class="btn btn-lg btn-primary float-end">
      Send offer
    </button>
  </div>
</div>
<ng-template valModal="sm" [(valmVisible)]="validationModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h6 class="modal-title">Offer validation</h6>
    <button type="button" class="btn-close" (click)="validationModalVisible=false"></button>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <h6>
        {{validationResult!.message}} <br> {{validationResult!.canContinue?"Do you want to send offer anyway?":""}}
      </h6>
    </div>
  </div>
  <div class="modal-footer m-0">
    <button (click)="validationModalVisible=false" type="button" class="btn btn-secondary text-uppercase me-5">
      {{validationResult!.canContinue?"No":"Close"}}
      <i class="vi vi-times-circle ms-3"></i>
    </button>
    <button *ngIf="validationResult!.canContinue" (click)="send();validationModalVisible=false" type="button" class="btn btn-primary text-uppercase">
      Yes
      <i class="vi vi-check-circle ms-3"></i>
    </button>
  </div>
</ng-template>

<ng-template valModal="sm" [(valmVisible)]="fromValidationModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h6 class="modal-title text-danger">From field mismatch</h6>
    <button type="button" class="btn-close" (click)="fromValidationModalVisible=false"></button>
  </div>
  <div class="modal-body">
    <div class="mb-4">
      <h6 class="text-danger">
        Recepient email in offer is not the same as email in the conversation from field!
        <Br/>
        Do you want to send offer anyway?
      </h6>
    </div>
  </div>
  <div class="modal-footer m-0">
    <button (click)="fromValidationModalVisible=false" type="button" class="btn btn-secondary text-uppercase me-5">
      No
      <i class="vi vi-times-circle ms-3"></i>
    </button>
    <button (click)="validateOffer();fromValidationModalVisible=false" type="button" class="btn btn-primary text-uppercase">
      Yes
      <i class="vi vi-check-circle ms-3"></i>
    </button>
  </div>
</ng-template>
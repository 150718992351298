import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AccountModel } from '../models/mdp/account.model';
import { ValUtilsService } from './val-utils.service';
import { VrcAccountUtilsService } from './vrc-account-utils.service';
import { VrcDataService } from './vrc-data.service';
import { VrcStorageService } from './vrc-storage.service';

export const vrcOfferResolver: ResolveFn<boolean> =
    (route: ActivatedRouteSnapshot) => {
        const _srvc = inject(VrcDataService);
        const _r = inject(Router);
        const _ms = inject(MessageService);
        const _st = inject(VrcStorageService);
        const _u = inject(ValUtilsService);
        const _au = inject(VrcAccountUtilsService);
        const offerId = route.params['id'];
        const accountId = route.params['accountId'];
        _ms.add({ severity: 'info', summary: 'Loading', detail: 'Loading offer' });
        if (!_st.model) {
            return forkJoin([
                _srvc.getOffer(offerId).pipe(
                    take(1),
                    map(x => {
                        _st.offer = x;
                        _ms.clear();
                        return true;
                    })
                ),
                _srvc.getAccountById(accountId).pipe(
                    take(1),
                    map(acc => {
                        // model fix   
                        if (acc) {
                            acc = <AccountModel>_au.AccountFix(acc);
                            _st.model = acc;
                            _st.origModel = _u.deepCopyObject(acc);
                            _st.loyality = acc.loyaltiesList;
                            _st.loyalityOrig = _u.deepCopyObject(acc.loyaltiesList);
                            _st.isEditOn = false;
                            _au.UpdateAccountData();
                            _st.selectedLoyaltyNumber = _st.model?.loyaltiesList.filter(x=>!x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
                            _st.selectedValamartistNumber = _st.model?.loyaltiesList.filter(x=>x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
                            _st.emails = _st.model.contact.additional.emails.map((e) => {
                                return { id: 0, data: e };
                            });
                            _st.phones = _st.model.contact.additional.phones.map((e) => {
                                return { id: 0, data: e };
                            });
                        }

                        _ms.clear();
                        return true;
                    })
                )
            ]
            ).pipe(take(1),
                map(() => true),
                catchError((err: HttpErrorResponse) => {
                    _ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
                    if (_st.origModel) {
                        _r.navigate(['main/account/' + _st.origModel.id]);
                    } else {
                        _r.navigate(['main']);
                    }
                    return of(false);
                }));
        }

        return _srvc.getOffer(offerId).pipe(
            take(1),
            map(x => {
                _st.offer = x;
                _ms.clear();
                return true;
            }),
            catchError((err: HttpErrorResponse) => {
                _ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
                if (_st.origModel) {
                    _r.navigate(['main/account/' + _st.origModel.id]);
                } else {
                    _r.navigate(['main']);
                }
                return of(false);
            })
        );
    };

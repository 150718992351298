import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({ name: 'htmlSanitizer' })
export class HtmlSanitizerPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(html: string) {

    const sanitizedHtml = DOMPurify.sanitize(html, {
      USE_PROFILES: { html: true, svg: true },
      IN_PLACE: true,
      FORCE_BODY: true,
    });

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
  }
}
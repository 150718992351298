import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

@Component({
    selector: 'val-modal-template',
    templateUrl: './val-modal-template.component.html',
    animations: [
        trigger(
            'valModalVoidAnimation',
            [
                state('in', style({ transform: 'scale(1)', opacity: 1 })),
                state('void', style({ transform: 'scale(0.3)', opacity: 0 })),
                transition('void=>*', animate('200ms ease-out'))
            ]
        ),
        trigger(
            'valModalAnimation',
            [
                state('visible', style({ transform: 'scale(1)', opacity: 1 })),
                state('hidden', style({ transform: 'scale(0)', opacity: 0 })),
                transition('visible=>hidden', animate('200ms ease-in'))
            ]
        )
    ]
})
export class ValModalTemplateComponent implements OnDestroy, OnInit, AfterViewInit {
    @HostBinding("class") private cssClass = "modal fade show";
    @HostBinding("style.display") private cssStyle = "block";
    @ViewChild('vc', { read: ViewContainerRef, static: true }) vc: ViewContainerRef | null = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onAnimationDone = new EventEmitter<any>();
    public sizeCss = "";
    public closeOnBackdropClick = true;
    public loaded = false;
    public set size(x: "lg" | "sm" | "xl" | "") {
        switch (x) {
            case "":
                this.sizeCss = "";
                break;
            case "sm":
                this.sizeCss = " modal-sm";
                break;
            case "lg":
                this.sizeCss = " modal-lg";
                break;
            case "xl":
                this.sizeCss = " modal-xl";
                break;
            default:
                this.sizeCss = "";
        }
    }
    public animationState = "visible";

    public close = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @HostListener("click", ["$event"]) protected click = (event: any) => {
        event.stopPropagation();
        if (event.target === this.vc?.element.nativeElement.parentElement.parentElement.parentElement && this.closeOnBackdropClick) {
            this.animationState = "hidden";
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public animationDone = (event: any) => {
        if (event.fromState === "visible") {
            this.close.emit();
        }
    }

    ngOnDestroy(): void {
        if (this.vc) {
            this.vc.clear();
        }
    }

    ngAfterViewInit(): void {
        this.loaded = true;
    }

    ngOnInit(): void {
        this.loaded = false;
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValValidationComponent } from './val-validation.component';
import { ValValidatorComponent } from './val-validator.component';
import { ValValidatorDirective } from './val-validator.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        ValValidatorDirective,
        ValValidatorComponent,
        ValValidationComponent
    ],
    exports: [
        ValValidatorDirective,
        ValValidationComponent
    ],
    providers: []
})
export class ValValidationModule { }

import {
    AfterViewInit,
    ApplicationRef,
    ComponentRef,
    Directive,
    EmbeddedViewRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ValModalBackdropComponent } from './val-modal-backdrop.component';
import { ValModalTemplateComponent } from './val-modal-template.component';

@Directive({
    selector: '[valModal],[valmVisible],[valmVisibleChange],[valmCloseOnBackdrop],[valmClosed],[valmShowed]'
})

export class ValModalDirective implements OnDestroy, AfterViewInit, OnInit {
    private _loaded = false;
    private _showSub!: Subscription;
    private _closeSub!: Subscription;
    private _visible = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public viewRef!: EmbeddedViewRef<any>;
    private _componentRef: ComponentRef<ValModalTemplateComponent> | null = null;
    private _backdropRef: ComponentRef<ValModalBackdropComponent> | null = null;
    private _closeOnBackDropClick = false;
    private _size!: "lg" | "sm" | "xl" | "";
    private _backDropClass!: string;

    @Input() set backDropClass(x: string) {
        this._backDropClass = x;
        if (this._backdropRef && this._backdropRef.instance) {
            this._backdropRef.instance.backdropClass = this._backDropClass;
        }
    }

    @Input('valmVisible') set visible(x: boolean) {
        this._visible = x;
        if (this._loaded) {
            if (this._visible) {
                this._showModal();
            } else {
                if (this._componentRef && this._componentRef.instance) {
                    this._componentRef.instance.animationState = "hidden";
                } else {
                    this._closeModal();
                }
            }
        }
    }

    @Input('valmCloseOnBackdrop') set closeOnBackDrop(x: boolean) {
        this._closeOnBackDropClick = x;
        if (this._componentRef && this._componentRef.instance) {
            this._componentRef.instance.closeOnBackdropClick = this._closeOnBackDropClick;
        }
    }

    @Input('valModal') set size(x: "lg" | "sm" | "xl" | "") {
        this._size = x;
        if (this._componentRef && this._componentRef.instance) {
            this._componentRef.instance.size = this._size;
        }
    }

    @Output('valmVisibleChange') visibleChange = new EventEmitter<boolean>();
    @Output('valmClosed') closed = new EventEmitter();
    @Output('valmShowed') showed = new EventEmitter();

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private templateRef: TemplateRef<any>,
        private _appRef: ApplicationRef,
        private _renderer: Renderer2,
        private _viewContainer: ViewContainerRef,
    ) { }

    ngOnDestroy(): void {
        this._unloadBackdrop();
        this._unloadComponent();

        if (this._showSub) {
            this._showSub.unsubscribe();
        }

        if (this._closeSub) {
            this._closeSub.unsubscribe();
        }

        this._renderer.removeClass(document.body, 'modal-open');
        this._renderer.removeStyle(document.body, "padding-right");
        if (this.viewRef) {
            this.viewRef.destroy();
        }
    }

    ngOnInit(): void {
        if (this._visible) {
            this._showModal();
        }
        this._loaded = false;
    }

    ngAfterViewInit(): void {
        this._loaded = true;
    }

    public render = (vcf: ViewContainerRef) => {
        this.viewRef = vcf.createEmbeddedView(this.templateRef);
    }

    public show = () => {
        this._visible = true;
        this.visibleChange.emit(this._visible);
        this._showModal();
    }

    public close = () => {
        if (this._componentRef && this._componentRef.instance) {
            this._componentRef.instance.animationState = "hidden";
        } else {
            this._closeModal();
        }
    }

    private _closeModal = () => {
        if (this._closeSub) {
            this._closeSub.unsubscribe();
        }
        this._visible = false;
        this.visibleChange.emit(this._visible);
        this.closed.emit();
        this._unloadBackdrop();
        this._unloadComponent();
        this._renderer.removeClass(document.body, 'modal-open');
        this._renderer.removeStyle(document.body, "padding-right");
        this._renderer.removeStyle(document.body, "overflow");
    }

    private _showModal = () => {
        const windowHeight = window.innerHeight;
        const bodyHeight = document.body.offsetHeight;
        const scrollBarWidth = window.innerWidth - document.body.clientWidth;
        this._backdropRef = this._loadBackdrop();
        this._componentRef = this._loadComponent();
        if (this._componentRef.instance.vc) {
            this.render(this._componentRef.instance.vc);
        }

        this._closeSub = this._componentRef.instance.close.subscribe(() => {
            this._closeModal();
        });

        this._renderer.addClass(document.body, 'modal-open');
        this._renderer.setStyle(document.body, "overflow", 'hidden');
        if (windowHeight < bodyHeight) {
            this._renderer.setStyle(document.body, "padding-right", scrollBarWidth + 'px');
        }

        this.showed.emit();
        this._backdropRef.changeDetectorRef.detectChanges();
        this._componentRef.changeDetectorRef.detectChanges();
    }

    private _loadComponent = (): ComponentRef<ValModalTemplateComponent> => {
        this._unloadComponent();

        const componentRef = this._viewContainer.createComponent(ValModalTemplateComponent);
        componentRef.instance.size = this._size;
        componentRef.instance.closeOnBackdropClick = this._closeOnBackDropClick;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);
        return componentRef;
    }

    private _loadBackdrop = (): ComponentRef<ValModalBackdropComponent> => {
        this._unloadBackdrop();
        const backdropRef = this._viewContainer.createComponent(ValModalBackdropComponent);
        backdropRef.instance.backdropClass = this._backDropClass;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const domElem = (backdropRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
        return backdropRef;
    }

    private _unloadComponent = () => {
        if (this._componentRef) {
            this._appRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }

    private _unloadBackdrop = () => {
        if (this._backdropRef) {
            this._appRef.detachView(this._backdropRef.hostView);
            this._backdropRef.destroy();
            this._backdropRef = null;
        }
    }

}

<div class="row pb-5 bg-secondary pt-3">
  <div class="col-12 p-0 m-0">
    <val-table-scroller>
      <p-table [value]="offers" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="offersLoading"
        [paginator]="true" currentPageReportTemplate="{first}-{last} OF {totalRecords}" [filterDelay]="0" [lazy]="true" (onLazyLoad)="offersTableLazyLoad($event)"
        [totalRecords]="offersTotalRecords" sortMode="multiple" [resizableColumns]="true" [reorderableColumns]="true" columnResizeMode="expand" [scrollable]="true"
        styleClass="w-100">
        <ng-template pTemplate="caption">
          <div class="table-header">Offers</div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 12rem" pResizableColumn pSortableColumn="mappingFields.phobs.offer_id">
              Offer Id
              <p-sortIcon field="mappingFields.phobs.offer_id"></p-sortIcon>
              <p-columnFilter type="text" field="mappingFields.phobs.offer_id" display="menu" class="float-end">
              </p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="mappingFields.phobs.item_id">
              Item id
              <p-sortIcon field="mappingFields.phobs.item_id"></p-sortIcon>
              <p-columnFilter type="text" field="mappingFields.phobs.item_id" display="menu" class="float-end">
              </p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="property.code">
              Property
              <p-sortIcon field="property.code"></p-sortIcon>
              <p-columnFilter type="text" field="property.code" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th class="text-center" style="width: 11rem" pResizableColumn pSortableColumn="checkIn">
              Arrival
              <p-sortIcon field="checkIn"></p-sortIcon>
              <p-columnFilter type="date" field="checkIn" display="menu" class="float-end">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy." [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th class="text-center" style="width: 11rem" pResizableColumn pSortableColumn="checkOut">
              Departure
              <p-sortIcon field="checkOut"></p-sortIcon>
              <p-columnFilter type="date" field="checkOut" display="menu" class="float-end">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy." [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="unit.code">
              Room Type
              <p-sortIcon field="unit.code"></p-sortIcon>
              <p-columnFilter type="text" field="unit.code" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="total">
              Total EU
              <p-sortIcon field="total"></p-sortIcon>
              <p-columnFilter type="numeric" field="total" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="additionalInformation.status">
              Status
              <p-sortIcon field="additionalInformation.status"></p-sortIcon>
              <p-columnFilter type="text" field="additionalInformation.status" display="menu" class="float-end">
              </p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="offerHolderData.firstName">
              Offer Holder
              <p-sortIcon field="offerHolderData.firstName"></p-sortIcon>
              <p-columnFilter type="text" field="offerHolderData.firstName" display="menu" class="float-end">
              </p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-offer let-expanded="expanded" let-columns="columns">
          <tr [class.expanded-row]="expanded">
            <td>
              <button [pRowToggler]="offer" type="button" class="btn btn-sm btn-primary me-5" [class.btn-primary]="expanded" [class.btn-secondary]="!expanded">
                <i class="vi" [class.vi-chevron-down]="!expanded" [class.vi-chevron-up]="expanded"></i>
              </button>
              <span class="badge badge-open bg-secondary text-gray-700">
                {{ offer.mappingFields?.phobs?.offer_id }}
                <button class="btn btn-copy ms-4">
                  <i class="vi vi-open-in-new"></i>
                </button>
              </span>
            </td>
            <td>{{ offer.mappingFields?.phobs?.item_id }}</td>
            <td>{{ offer.property.code }}</td>
            <td>{{ offer.checkIn | date: 'shortDate'}}</td>
            <td>{{ offer.checkOut | date: 'shortDate' }}</td>
            <td>{{ offer.unit?.code }}</td>
            <td>{{ offer.total | number:'0.2-2' }}</td>
            <td>
              <div class="badge badge-status text-gray-700 text-uppercase"
                [class.bg-danger-1]="offer.additionalInformation.status.toUpperCase() === st.offerStatuses.EXPIRED"
                [class.bg-info-1]="offer.additionalInformation.status.toUpperCase() === st.offerStatuses.CREATED"
                [class.bg-success-1]="offer.additionalInformation.status.toUpperCase() === st.offerStatuses.RESERVED">
                <i class="vi vi-rectangle me-3"></i>
                {{offer.additionalInformation?.status}}
              </div>
            </td>
            <td>{{ offer.offerHolderData?.firstName + ' ' + offer.offerHolderData?.lastName }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-offer>
          <tr>
            <td colspan="9">
              <div class="row expanded-body">
                <div class="col-5 col-sm-6 col-md-7 col-lg-8 col-xl-9 col-xxl-10 p-5">
                  <div class="row">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Info</h6>
                      </div>
                      <div class="row">
                        <div class="col-auto mb-5">
                          <label class="form-label">Status</label>
                          <div class="w-100"></div>
                          <div class="badge badge-status text-gray-700 bg-primary text-uppercase text-start"
                            [class.bg-danger-1]="offer.additionalInformation.status.toUpperCase() === st.offerStatuses.EXPIRED"
                            [class.bg-info-1]="offer.additionalInformation.status.toUpperCase() === st.offerStatuses.CREATED"
                            [class.bg-success]="offer.additionalInformation.status.toUpperCase() === st.offerStatuses.RESERVED">
                            <i class="vi vi-rectangle me-3"></i>
                            {{offer.additionalInformation.status}}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Room Type Name</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.unit.name}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Property Name</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.property.name}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Rate plan</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.ratePlan.code}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Rate</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.ratePlan.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Costs</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Total</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.total |
                            number:'0.2-2'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Currency</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.currency}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Guests</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Adults</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.guests?.adults}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Children</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.guests?.children}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Children age</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.guests?.childrenAge}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Aditional info</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Agent name</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.additionalInformation?.agentName}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Created on</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.additionalInformation?.createdDate
                            |
                            date: 'shortDate'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Expired on</label>
                          <span class="form-control-plaintext disabled text-wrap">{{offer.additionalInformation?.expiredDate
                            |
                            date: 'shortDate'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto ps-4 pe-5">
                    </div>
                    <div class="col">
                      <a [href]="offer.additionalInformation?.offerPreviewUrl" target="_blank" class="btn btn-sm btn-secondary btn-popup w-auto mx-4">
                        Phobs preview
                        <i class="vi vi-open-in-new ms-4"></i>
                      </a>
                      <button (click)="getEmailOffer(offer.mappingFields?.phobs?.offer_id)" class="btn btn-sm btn-secondary btn-popup w-auto mx-4">
                        Email preview
                        <i class="vi vi-open-in-new ms-4"></i>
                      </button>
                      <button (click)="getWebPreview(offer.mappingFields?.phobs?.offer_id)" class="btn btn-sm btn-secondary btn-popup w-auto mx-4">
                        Web preview
                        <i class="vi vi-open-in-new ms-4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">No offers found.</td>
          </tr>
        </ng-template>
      </p-table>
    </val-table-scroller>
  </div>
  <div class="col-12 p-0 m-0">
    <val-table-scroller>
      <p-table #reservationsTable [value]="reservations" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="reservationsLoading" [paginator]="true" currentPageReportTemplate="{first}-{last} OF {totalRecords}" [filterDelay]="0" [lazy]="true"
        (onLazyLoad)="reservationsTableLazyLoad($event)" [totalRecords]="reservationsTotalRecords" sortMode="multiple" [resizableColumns]="true"
        [reorderableColumns]="true" columnResizeMode="expand" [scrollable]="true" styleClass="w-100">
        <ng-template pTemplate="caption">
          <div class="table-header">Reservations</div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 12rem" pResizableColumn pSortableColumn="mappingFields.phobs.reservationCode">
              Phobs Id
              <p-sortIcon field="mappingFields.phobs.reservationCode"></p-sortIcon>
              <p-columnFilter type="text" field="mappingFields.phobs.reservationCode" display="menu" class="float-end">
              </p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="property.code">
              Property
              <p-sortIcon field="property.code"></p-sortIcon>
              <p-columnFilter type="text" field="property.code" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th class="text-center" style="width: 11rem" pResizableColumn pSortableColumn="checkIn">
              Arrival
              <p-sortIcon field="checkIn"></p-sortIcon>
              <p-columnFilter type="date" field="checkIn" display="menu" class="float-end">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="true" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy." [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th class="text-center" style="width: 11rem" pResizableColumn pSortableColumn="checkOut">
              Departure
              <p-sortIcon field="checkOut"></p-sortIcon>
              <p-columnFilter type="date" field="checkOut" display="menu" class="float-end">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy." [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="marketCode">
              Market Code
              <p-sortIcon field="marketCode"></p-sortIcon>
              <p-columnFilter type="text" field="marketCode" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="unit.code">
              Room Type
              <p-sortIcon field="unit.code"></p-sortIcon>
              <p-columnFilter type="text" field="unit.code" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="costs.guestBalance">
              Guest Balance
              <p-sortIcon field="costs.guestBalance"></p-sortIcon>
              <p-columnFilter type="numeric" field="costs.guestBalance" display="menu" class="float-end">
              </p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="offer.phobsItemId">
              Offer Item
              <p-sortIcon field="offer.phobsItemId"></p-sortIcon>
              <p-columnFilter type="text" field="offer.phobsItemId" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="status">
              Status
              <p-sortIcon field="status"></p-sortIcon>
              <p-columnFilter type="text" field="status" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pResizableColumn pSortableColumn="reservationHolderData.firstName">
              Reservation Holder
              <p-sortIcon field="reservationHolderData.firstName"></p-sortIcon>
              <p-columnFilter type="text" field="reservationHolderData.firstName" display="menu" class="float-end">
              </p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reservation let-expanded="expanded">
          <tr [class.expanded-row]="expanded">
            <td>
              <button [pRowToggler]="reservation" type="button" class="btn btn-sm btn-primary me-5" [class.btn-primary]="expanded" [class.btn-secondary]="!expanded">
                <i class="vi" [class.vi-chevron-down]="!expanded" [class.vi-chevron-up]="expanded"></i>
              </button>
              <span *ngIf="reservation.mappingFields?.phobs?.reservationCode" class="badge badge-open bg-secondary text-gray-700">
                <span>{{reservation.mappingFields?.phobs?.reservationCode}}</span>
                <button *ngIf="reservation.phobs?.previewUrl" class="btn btn-copy ms-4" (click)="u.openLink(reservation.phobs?.previewUrl)">
                  <i class="vi vi-open-in-new"></i>
                </button>
              </span>
            </td>
            <td>{{ reservation.property?.code }}</td>
            <td>{{ reservation.checkIn | date: "shortDate" }}</td>
            <td>{{ reservation.checkOut | date: "shortDate" }}</td>
            <td>{{ reservation.marketCode }}</td>
            <td>{{ reservation.unit?.code }}</td>
            <td>{{ reservation.costs?.guestBalance | number:'0.2-2' }}</td>
            <td>{{ reservation.offer?.phobsItemId }}</td>
            <td>
              <div class="badge badge-status text-gray-700 text-uppercase"
                [class.bg-info-1]="reservation.status === st.reservationStatuses.NO_SHOW || reservation.status === st.reservationStatuses.CHECKED_IN"
                [class.bg-danger-1]="reservation.status === st.reservationStatuses.CANCEL || reservation.status === st.reservationStatuses.CHECKED_OUT"
                [class.bg-info]="reservation.status === st.reservationStatuses.NEW || reservation.status === st.reservationStatuses.MODIFY"
                [class.bg-success-1]="reservation.status === st.reservationStatuses.RESERVED">
                <i class="vi vi-rectangle me-3"></i>
                {{reservation.status }}
              </div>
            </td>
            <td> {{ (reservation.reservationHolderData?.firstName??'') + ' ' +
              (reservation.reservationHolderData?.lastName??'') }} </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-reservation>
          <tr>
            <td colspan="9">
              <div class="row expanded-body">
                <div class="col-5 col-sm-6 col-md-7 col-lg-8 col-xl-9 col-xxl-10 p-5">
                  <div class="row">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Info</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Opera id</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.mappingFields?.opera?.CONFIRMATION_NO}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Status</label>
                          <div class="w-100"></div>
                          <div class="badge badge-status text-gray-700 text-uppercase"
                            [class.bg-info-1]="reservation.status === st.reservationStatuses.NO_SHOW || reservation.status === st.reservationStatuses.CHECKED_IN"
                            [class.bg-danger-1]="reservation.status === st.reservationStatuses.CANCEL || reservation.status === st.reservationStatuses.CHECKED_OUT"
                            [class.bg-info]="reservation.status === st.reservationStatuses.NEW || reservation.status === st.reservationStatuses.MODIFY"
                            [class.bg-success-1]="reservation.status === st.reservationStatuses.RESERVED">
                            <i class="vi vi-rectangle me-3"></i>
                            {{reservation.status }}
                          </div>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Online Check In</label>
                          <span *ngIf="reservation.onlineCheckIn" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-check"></i>Yes</span>
                          <span *ngIf="reservation.onlineCheckIn === false" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-times"></i>No</span>
                          <span *ngIf="reservation.onlineCheckIn === null" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-minus"></i>Not specified</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Property Name</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.property?.name}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Room number</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.roomNumber}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Room type</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.unit?.code}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Stay room type</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.unit?.stayCode}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Rate plan</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.ratePlan?.code}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Meal plan</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.mealPlan}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Market code</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.marketCode}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Agency name</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.agency?.name}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Company</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.company}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Group name</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.groupName}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Guests</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Adults</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.guests?.adults}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Children</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.guests?.children}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Children age</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.guests?.childrenAge}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Aditional info</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Agent name</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.agentName}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Created on</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.createdDate | date:
                            'shortDate'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Source</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.source}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Costs</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Total</label>
                          <span class="form-control-plaintext disabled text-wrap">
                            <a *ngIf="reservation.costs?.invoiceUrl;else displayonly" [href]="reservation.costs?.invoiceUrl" target="_blank"
                              class="btn btn-secondary shadow-sm w-auto mx-5">
                              {{reservation.costs?.total | number:'0.2-2'}}
                              <i class="fas fa-external-link-alt ms-4"></i>
                            </a>
                            <ng-template #displayonly>
                              {{reservation.costs?.total | number:'0.2-2'}}
                            </ng-template>
                          </span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Discount</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.costs?.discount |
                            number:'0.2-2'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Voucher discount</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.costs?.voucherDiscount |
                            number:'0.2-2'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Voucher</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.voucher?.code}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Packages</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Extra meal</label>
                          <span *ngIf="reservation.packages.extraMeal" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-check"></i>Yes</span>
                          <span *ngIf="!reservation.packages.extraMeal" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-times"></i>No</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Pets</label>
                          <span *ngIf="reservation.packages.pets" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-check"></i>Yes</span>
                          <span *ngIf="!reservation.packages.pets" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-times"></i>No</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Baby cot</label>
                          <span *ngIf="reservation.packages.babyCot" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-check"></i>Yes</span>
                          <span *ngIf="!reservation.packages.babyCot" class="form-control-plaintext disabled text-wrap">
                            <i class="vi float-start me-3 vi-radio-times"></i>No</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Comments</h6>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6 mb-3 mb-sm-5">
                          <label class="form-label">Guest</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.comments?.guest}}</span>
                        </div>
                        <div class="col-12 col-sm-6 mb-5">
                          <label class="form-label">Front desk</label>
                          <span class="form-control-plaintext disabled text-wrap">{{reservation.comment?.frontDesk}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col mb-4">
                          <h6 class="table-body-header">Traces</h6>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-3">
                          <label class="form-label">Type</label>
                        </div>
                        <div class="col-6 mb-3">
                          <label class="form-label">Value</label>
                        </div>
                      </div>
                      <div *ngFor="let t of reservation?.traces; last as isLast" class="row">
                        <div class="col-6 mb-3" [class.mb-5]="isLast">
                          <span class="form-control-plaintext disabled text-wrap">{{t.type}}</span>
                        </div>
                        <div class="col-6 mb-3" [class.mb-5]="isLast">
                          <span class="form-control-plaintext disabled text-wrap">{{t.value}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">No reservations found.</td>
          </tr>
        </ng-template>
      </p-table>
    </val-table-scroller>
  </div>
</div>

<ng-template valModal="xl" [(valmVisible)]="emailOfferVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title me-5">
      Email preview
    </h6>
    <button type="button" class="btn-close" (click)="emailOfferVisible=false"></button>
  </div>
  <div class="modal-body bg-light">
    <div class="row">
      <div class="col-12 p-4">
        <div class="card">
          <div class="card-body">
            <iframe class="w-100" style="height: 30rem;" [srcdoc]="(emailOfferContent.html2insert??'') | htmlSanitizer"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer m-0 p-4">
    <label class="col-auto col-form-label">Status</label>
    <div class="col">
      <input type="text" readonly class="form-control" [ngModel]="emailOfferContent.text2agent">
    </div>
  </div>
  <div class="modal-footer m-0">
    <button (click)="emailOfferVisible=false" type="button" class="btn btn-sm btn-secondary">Close</button>
  </div>
</ng-template>
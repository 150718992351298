export class AdditionalInformationModel {
  constructor(
    public revenueSegment: string | null = null,
    public isBlacklisted: boolean = false,
    public gdprDelete: boolean = false,
    public isVip: boolean = false,
    public customerMemberType: string | null = null,
    public isOtaRestricted: boolean = false,
    public extraMeal: boolean = false,
    public babyCot: boolean = false,
    public travelWithPets: boolean = false,
    public isTopGuest: boolean = false,
  ) { }
}
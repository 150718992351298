import { Injectable } from '@angular/core';
import { isDate } from 'date-fns';

@Injectable()
export class ValUtilsService {
    /**
     * Validates if input value is object
     * @param obj input value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isObject(obj: any) {
        return obj === Object(obj);
    }

    /**
     * Validates if input value is string
     * @param s input value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isString(s: any) {
        return typeof (s) === 'string' || s instanceof String;
    }

    /**
     * Validates if input value is primitive type
     * @param arg input value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isPrimitive(arg: any) {
        const type = typeof arg;

        return arg == null || (type !== 'object' && type !== 'function');
    }

    /**
     * Validates if input value is array
     * @param arr input value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isArray(arr: any) {
        return Array.isArray ? Array.isArray(arr) : arr instanceof Array;
    }

    /**
     * Validates if input value is numeric (number or string)
     * @param input value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isNumeric(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    /**
     * Validates if input value is function
     * @param f input value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isFunction(f: any): boolean {
        return typeof (f) === 'function';
    }

    /**
     * Checks if string is null or empty
     * @param value string to check
     */
    public isStringNullOrEmpty(value: string): boolean {
        if (!value || value === '') {
            return true;
        }

        return false;
    }

    /**
     * Checks if string is null or empty or whitespace
     * @param value string to check
     */
    public isStringNullOrWhitespace(value: string): boolean {
        if (!value || value === '' || value.trim() === '') {
            return true;
        }

        return false;
    }

    /**
     * Serializes date to ISO format without zone
     * @param date input value
     */
    public SerializeDate(date: Date): string {
        const builder = [1 + date.getMonth(), '/', date.getDate(), '/', date.getFullYear()];
        const h = date.getHours();
        const m = date.getMinutes();
        const s = date.getSeconds();
        const f = date.getMilliseconds();

        if (h + m + s + f > 0) {
            builder.push(' ', this.ZeroPad(h, 2), ':', this.ZeroPad(m, 2), ':', this.ZeroPad(s, 2), '.', this.ZeroPad(f, 3));
        }

        return builder.join('');
    }

    /**
     * Ads leading zeros if needed
     * @param value input value
     * @param len output string length
     */
    public ZeroPad(value: number, len: number): string {
        let text = String(value);

        while (text.length < len) {
            text = '0' + text;
        }

        return text;
    }

    /**
     * Copy the values of all enumerable properties from one or more source
     * objects to a target object. It will return the target object.
     * @param target Target object
     * @param source Source objects
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public ShallowCopy<TObject extends object>(target: TObject, ...sources: any[]): TObject {
        return Object.assign(target, ...sources);
    }

    /** Search max value of numeric array, or array of objects
     * @param arr Source array
     * @param fieldName field in object on which comparison is made or null if simple array
     */
    public arrayMax<TModel>(arr: Array<TModel>, fieldName?: string): TModel | null {
        if (!arr || arr.length === 0) { return null; }
        if (fieldName === null) {
            return arr.reduce((prev, curr) => {
                return (prev > curr ? prev : curr);
            });
        } else {
            return arr.reduce((prev, curr) => {
                return ((prev[fieldName as keyof typeof prev] as number) > (curr[fieldName as keyof typeof curr] as number) ? prev : curr);
            });
        }
    }

    /**
     * Search min value of numeric array, or array of objects
     * @param arr Source array
     * @param fieldName field in object on which comparison is made or null if simple array
     */
    public arrayMin<TModel>(arr: Array<TModel>, fieldName?: string): TModel | null {
        if (!arr || arr.length === 0) { return null; }
        if (fieldName === null) {
            return arr.reduce((prev, curr) => {
                return (prev < curr ? prev : curr);
            });
        } else {
            return arr.reduce((prev, curr) => {
                return (prev[fieldName as keyof typeof prev] < curr[fieldName as keyof typeof curr] ? prev : curr);
            });
        }
    }

    /**
     * Sorts numeric array, or array of objects
     * @param arr Source array
     * @param fieldName field in object on which comparison is made or null if simple array
     * @param desc descending
     */
    public arraySort<TModel>(arr: Array<TModel>, fieldName: string | null = null, desc = false): Array<TModel> {
        if (fieldName === null) {
            return arr.sort((a, b) => {
                return a < b ? (desc ? 1 : -1) : a === b ? 0 : (desc ? -1 : 1);
            });
        } else {
            return arr.sort((a, b) => {
                return a[fieldName as keyof typeof a] < b[fieldName as keyof typeof b] ? (desc ? 1 : -1) : a[fieldName as keyof typeof a] === b[fieldName as keyof typeof b] ? 0 : (desc ? -1 : 1);
            });
        }
    }

    /**
     * Copies input object, if input is primitive returns src
     * @param src Source object
     */
    public copyObject<TObject extends object>(src: TObject): TObject {
        if (this.isPrimitive(src)) { return src; }
        const dest = {} as TObject;
        Object.keys(src).forEach(o => {
            dest[o as keyof typeof dest] = src[o as keyof typeof src];
        });
        return dest;
    }

    /**
     * Deep copies input object (all child objects are copied), if input is primitive returns src
     * @param src Source object
     */
    public deepCopyObject<TObject extends object>(src: TObject): TObject {
        if (this.isPrimitive(src)) { return src; }
        const dest = {} as TObject;
        Object.keys(src).forEach(o => {
            if (this.isArray(src[o as keyof typeof src])) {
                dest[o as keyof typeof dest] = <object[keyof object]>this.copyArray(<Array<object>>src[o as keyof typeof src]);
            } else if (src[o as keyof typeof src] instanceof Date) {
                dest[o as keyof typeof dest] = <object[keyof object]>this.copyDate(<string | Date>src[o as keyof typeof src]);
            } else {
                dest[o as keyof typeof dest] = <object[keyof object]>this.deepCopyObject(<object>src[o as keyof typeof src]);
            }
        });
        return dest;
    }

    /**
     * Copies input array, if input is not array returns src
     * @param src Source object
     */
    public copyArray<TObject extends object>(src: Array<TObject>): Array<TObject> {
        if (!this.isArray(src)) { return src; }
        const dest = new Array<TObject>();
        src.forEach(x => {
            dest.push(this.copyObject(x));
        });
        return dest;
    }

    /**
     * Merges all common field values of two objects and returns desination object
     * @param src Source object
     * @param dst Destination object
     */
    public mergeObjectValues<SObject extends object, DObject extends object>(src: SObject, dst: DObject): DObject {
        if (this.isPrimitive(src)) { return dst; }
        Object.keys(src).forEach(o => {
            if (dst[o as keyof typeof dst] !== undefined) {
                dst[o as keyof typeof dst] = <object[keyof object]>src[o as keyof typeof src];
            }
        });
        return dst;
    }

    /**
     * Deep merges all common field values of two objects and returns desination object
     * @param src Source object, values from source are merged in destination
     * @param dst Destination object
     * @param rewriteNulls If set rewrites destination value if source value is null
     */
    public deepMergeObjectValues<SObject extends object, DObject extends object>(src: SObject, dst: DObject, rewriteNulls = true): DObject {
        if (this.isPrimitive(src)) { return dst; }

        Object.keys(src).forEach(o => {
            if (dst !== null && dst[o as keyof typeof dst] !== undefined) {
                if (this.isPrimitive(src[o as keyof typeof src])) {
                    if (!rewriteNulls) {
                        const srcVal = <object[keyof object]>src[o as keyof typeof src];
                        if (srcVal) {
                            dst[o as keyof typeof dst] = srcVal;
                        }
                    } else {
                        dst[o as keyof typeof dst] = <object[keyof object]>src[o as keyof typeof src];
                    }
                } else {
                    dst[o as keyof typeof dst] = <object[keyof object]>this.deepMergeObjectValues(<object>src[o as keyof typeof src], <object>dst[o as keyof typeof dst]);
                }
            }
        });

        return dst;
    }

    /**
     * Merges fields of two objects, returns dst
     * @param src Source object
     * @param dst Destination object
     */
    public mergeObjects<SObject extends object, DObject extends object>(src: SObject, dst: DObject): DObject {
        if (this.isPrimitive(src)) { return dst; }
        Object.keys(src).forEach(o => {
            dst[o as keyof typeof dst] = <object[keyof object]>src[o as keyof typeof src];
        });
        return dst;
    }

    /**
     * Detects difference beetwen values in object
     * @param obj1 first object
     * @param obj2 second object
     */
    public areObjectsEqual<SObject extends object, DObject extends object>(obj1: SObject, obj2: DObject, maxDecimals = 4): boolean {
        if (this.isPrimitive(obj1)) { return (obj2 as object) === (obj1 as object); }
        const keys = Object.keys(obj1);
        for (let i = 0; i < keys.length; i++) {
            const o = keys[i];
            // do not compare guids
            if (o !== 'guid') {
                if (obj2 === null || obj2[o as keyof typeof obj2] === undefined) { return false; }
                if (this.isPrimitive(obj1[o as keyof typeof obj1])) {
                    // compare numerics to 4 decimale
                    if (this.isNumeric(obj1[o as keyof typeof obj1])) {
                        const s = this.roundNumber(<number>obj1[o as keyof typeof obj1], maxDecimals);
                        if (this.isNumeric(obj2[o as keyof typeof obj2])) {
                            const d = this.roundNumber(<number>obj2[o as keyof typeof obj2], maxDecimals);
                            if (s !== d) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    } else {
                        if (obj2[o as keyof typeof obj2] !== <object[keyof object]>obj1[o as keyof typeof obj1]) {
                            return false;
                        }
                    }
                } else {
                    if (this.areObjectsEqual(<object>obj1[o as keyof typeof obj1], <object>obj2[o as keyof typeof obj2]) === false) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     * Returns numerical representation of string
     * @param input Source string
     */
    public getStringHashCode(input: string): number {
        let hash = 0;
        let chr = 0;
        if (input.length === 0) { return hash; }
        for (let i = 0; i < input.length; i++) {
            chr = input.charCodeAt(i);
            // tslint:disable-next-line:no-bitwise
            hash = ((hash << 5) - hash) + chr;
            // tslint:disable-next-line:no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    /**
     * Returns numeric representation of object
     * @param obj object
     */
    public getObjectHashCode<SObject extends object>(
        obj: SObject, excludeFields: Array<string> = ['guid'], maxDecimals = 4
    ): number {
        let ret = 0;
        if (this.isPrimitive(obj)) {
            return this.getStringHashCode(obj + '');
        }
        if (obj instanceof Date) {
            return this.getStringHashCode((obj as Date).toString());
        }
        if (this.isArray(obj)) {
            (obj as Array<object>).forEach(x => {
                ret += this.getObjectHashCode(x, excludeFields);
            });
            return ret;
        }
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
            const o = keys[i];
            // do not calculate guids
            if (!excludeFields.includes(o)) {
                if (this.isPrimitive(obj[o as keyof typeof obj])) {
                    if (this.isNumeric(obj[o as keyof typeof obj])) {
                        const s = this.roundNumber(<number>obj[o as keyof typeof obj], maxDecimals);
                        ret += this.getStringHashCode(o + '') + this.getStringHashCode(s + '');
                    } else {
                        ret += this.getStringHashCode(o + '') + this.getStringHashCode(obj[o as keyof typeof obj] + '');
                    }
                } else if (obj instanceof Date) {
                    ret += this.getStringHashCode(o + '') + this.getStringHashCode((obj as Date).toString());
                } else {
                    ret += this.getObjectHashCode(<object>obj[o as keyof typeof obj], excludeFields);
                }
            }
        }

        return ret;
    }

    /**
     * Deeps scan object and sets specified property to specified value
     * @param obj Object to scan
     * @param propertName property name
     * @param value new value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deepSetObjectProperty<T extends object>(obj: T, propertyName: string, value: any): void {
        if (!obj) {
            return;
        }

        Object.keys(obj).forEach(key => {
            const o = obj[key as keyof typeof obj];

            if (o) {
                if (this.isArray(o)) {
                    (o as Array<object>).forEach(el => {
                        this.deepSetObjectProperty(el, propertyName, value);
                    });
                } else {
                    if (Object.prototype.hasOwnProperty.call(o, propertyName)) {
                        o[propertyName as keyof typeof o] = value;
                    } else if (!this.isPrimitive(o) && !this.isFunction(o)) {
                        this.deepSetObjectProperty(o, propertyName, value);
                    } else if (key === propertyName) {
                        obj[key as keyof typeof obj] = value;
                    }
                }
            }
        });
    }

    /**
     * Copies date object, returns new date
     * @param input Source date or string in date format
     */
    public copyDate = (input: string | Date): Date | null => {
        let tmp: Date = input as Date;
        if (!input) {
            return null;
        }
        if (!(input instanceof Date)) {
            tmp = new Date(input);
        }
        const hours = tmp.getHours();
        const minutes = tmp.getMinutes();
        const miliseconds = tmp.getMilliseconds();
        const seconds = tmp.getSeconds();
        const year = tmp.getFullYear();
        const month = tmp.getMonth();
        const day = tmp.getDate();
        return new Date(year, month, day, hours, minutes, seconds, miliseconds);
    }

    /**
     * Always returns date, except input is null or undefined, then returns null
     * @param input Source date or string in date format
     */
    public getDate = (input: string | Date): Date | null => {
        if (!input) {
            return null;
        }
        if (!(input instanceof Date)) {
            const timestamp = Date.parse(input);

            if (isNaN(timestamp) === false) {
                return new Date(input);
            }
            return null;
        } else {
            // invalid date
            if (isNaN(input.getTime())) {
                return null;
            }
        }
        return input as Date;
    }

    /**
     * Always returns date, except input is null or undefined, then returns null
     * @param input Source date or string in date format
     */
    public getUtcDate = (input: string | Date): Date | null => {
        if (!input) {
            return null;
        }
        if (!(input instanceof Date)) {
            const timestamp = Date.parse(input);

            if (isNaN(timestamp) === false) {
                const d = new Date(input);
                return new Date(
                    d.getUTCFullYear(),
                    d.getUTCMonth(),
                    d.getUTCDate(),
                    d.getUTCHours(),
                    d.getUTCMinutes(),
                    d.getUTCSeconds(),
                    d.getUTCMilliseconds());
            }
            return null;
        } else {
            // invalid date
            if (isNaN(input.getTime())) {
                return null;
            }
        }
        const d = input as Date;
        return new Date(
            d.getUTCFullYear(),
            d.getUTCMonth(),
            d.getUTCDate(),
            d.getUTCHours(),
            d.getUTCMinutes(),
            d.getUTCSeconds(),
            d.getUTCMilliseconds());
    }

    /**
     * Always returns string, except input is null or undefined, then returns null
     * @param input Source date or string in date format
     */
    public getDateOnlyUtcIsoString = (input: string | Date): string | null => {
        let d: Date | null = null;
        if (!input) {
            return null;
        }
        if (!(input instanceof Date)) {
            const timestamp = Date.parse(input);

            if (isNaN(timestamp) === false) {
                d = new Date(input);
            }
            return null;
        } else {
            // invalid date
            if (isNaN(input.getTime())) {
                return null;
            }
            d = input;
        }
        return d.getFullYear() + '-' + this.ZeroPad((1 + d.getMonth()), 2) + '-' + this.ZeroPad(d.getDate(), 2) + 'T00:00:00.000Z';
    }

    /**
     * Always returns string, except input is null or undefined, then returns null
     * @param input Source date or string in date format
     */
    public getDateAsIsoString = (input: Date): string | null => {
        if (!input) { return null; }
        if (!isDate(input)) { return input as never; }
        return input.getFullYear() + '-' + this.ZeroPad((1 + input.getMonth()), 2) + '-' + this.ZeroPad(input.getDate(), 2) +
            'T' + this.ZeroPad(input.getHours(), 2) + ':' + this.ZeroPad(input.getMinutes(), 2) + ':00.000Z';
    }

    /**
     * Always returns UTC date, except input is null or undefined, then returns null
     * @param input Source date or string in date format
     */
    public getDateWithTimeSetToZero = (input: string | Date): Date | null => {
        if (!input) {
            return null;
        }
        if (!(input instanceof Date)) {
            input = new Date(input);
        }
        return new Date(input.getFullYear(), input.getMonth(), input.getDate(), 0, 0, 0, 0);
    }
    public getUtcDateWithTimeSetToZero = (input: string | Date): Date | null => {

        const d = this.getUtcDate(input);
        if (!d) {
            return null;
        }
        return new Date(
            d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0, 0);
    }

    /**
     * Always returns date, except input is null, undefined or in wrong format, then returns null
     * @param input Source string in date format
     */
    public getDateOnlyFromString = (input: string | Date | null): Date | null => {
        if (!input) {
            return null;
        }
        if (!((input as object) instanceof Date)) {
            return input as Date;
        }
        const strArrDate = (input as string).split('T')[0].split('-');
        if (strArrDate.length === 3) {
            const year = +strArrDate[0];
            const month = +strArrDate[1];
            const day = +strArrDate[2];
            return new Date(year, month, day, 0, 0, 0, 0);
        }
        return null;
    }

    /**
     * Rounds a number to specific decimal places, if input is null or undefined returns 0
     * @param input - number
     * @param decimalPlaces - decimal places
     * @param return0 - if TRUE and input is null or undefined returns 0 else returns input
     */
    public roundNumber = (input: number, decimalPlaces = 0, return0 = false): number => {
        if (!input) { return return0 ? 0 : input; }
        if (!decimalPlaces) { decimalPlaces = 0; }
        const tmp = Math.pow(10, decimalPlaces);
        return Math.round(input * tmp) / tmp;
    }

    /**
     * Create unique ID - integer only - from current timestamp with some randomization
     * @param length - wanted length of ID, default is 15
     */
    public uniqueIdGenerator = (length = 15): number => {
        const _getRandomInt = (min: number, max: number) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        const timestamp = +new Date();
        const ts = timestamp.toString();
        const parts = ts.split('').reverse();
        let id = '';

        for (let i = 0; i < length; ++i) {
            const index = _getRandomInt(0, parts.length - 1);
            id += parts[index];
        }

        return parseInt(id, 10);
    }

    /**
     * Create unique fake GUID (in correct format) from random numbers
     */
    public guid = () => {
        // tslint:disable-next-line:no-bitwise
        return ((((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
            // tslint:disable-next-line:no-bitwise
            (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + '-' +
            // tslint:disable-next-line:no-bitwise
            (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + '-4' +
            // tslint:disable-next-line:no-bitwise
            (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1).substr(0, 3) + '-' +
            // tslint:disable-next-line:no-bitwise
            (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + '-' +
            // tslint:disable-next-line:no-bitwise
            (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
            // tslint:disable-next-line:no-bitwise
            (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
            // tslint:disable-next-line:no-bitwise
            (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)).toLowerCase();
    }

    /**
     * Base 64 encode unicode string
     */
    public base64Encode = (input: string): string => {
        const arr = this._strToUTF8Arr(input);
        return this._base64EncArr(arr);
    }

    /**
     * Base 64 decode unicode string
     */
    public base64Decode = (input: string): string => {
        const arr = this._base64DecToArr(input);
        return this._UTF8ArrToStr(arr);
    }

    /**
     * Validates Oib value
     * @param oib Oib to check
     */
    public isOibValid(oib: string): boolean {
        if (!oib) { return false; }

        oib = oib.toString();

        if (oib.length !== 11) { return false; }

        if (isNaN(parseInt(oib, 10))) { return false; }

        let tmp = 10;
        for (let i = 0; i < 10; i++) {
            tmp += parseInt(oib.substr(i, 1), 10);
            tmp = tmp % 10;
            tmp = tmp === 0 ? 10 : tmp;
            tmp *= 2;
            tmp = tmp % 11;
        }
        let cont = 11 - tmp;
        cont = cont === 10 ? 0 : cont;

        return cont === parseInt(oib.substr(10, 1), 10);
    }

    public toMnemonic(value: string): string {
        if (!value) {
            return value;
        }

        value = value.replace(/[^a-zA-Z0-9_]/gi, '');

        return value ? value.toUpperCase() : value;
    }

    /**
     * Base 64 to Blob converter
     * @param base64Data String to convert
     * @param contentType String with content type
     */
    public convertBase64ToBlob = (base64Data: string, contentType: string): Blob => {
        const decodedData = window.atob(base64Data);

        const uInt8Array = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    /**
     * Get file extension
     * @param path Filename or path
     */
    public getExtension = (path: string): string => {
        const filename = path.split(/[\\/]/).pop();

        const pos = filename?.lastIndexOf(".") ?? 0;

        if (!filename || filename === "" || pos < 1) { return ""; }

        return filename?.slice(pos + 1) ?? '';
    }

    /**
     * Open link in new window
     * @param url Url
     */
    public openLink = (url: string): WindowProxy | null => {
        const test = encodeURI(url);
        return window.open(test, '_blank', 'noreferrer');
    }

    private _b64ToUint6 = (nChr: number): number => {
        return nChr > 64 && nChr < 91
            ? nChr - 65
            : nChr > 96 && nChr < 123
                ? nChr - 71
                : nChr > 47 && nChr < 58
                    ? nChr + 4
                    : nChr === 43
                        ? 62
                        : nChr === 47
                            ? 63
                            : 0;
    }

    private _base64DecToArr = (sBase64: string, nBlocksSize?: number): Uint8Array => {
        const sB64Enc = sBase64.replace(/[^A-Za-z0-9+/]/g, "");
        const nInLen = sB64Enc.length;
        const nOutLen = nBlocksSize
            ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
            : (nInLen * 3 + 1) >> 2;
        const taBytes = new Uint8Array(nOutLen);

        let nMod3: number;
        let nMod4: number;
        let nUint24 = 0;
        let nOutIdx = 0;
        for (let nInIdx = 0; nInIdx < nInLen; nInIdx++) {
            nMod4 = nInIdx & 3;
            nUint24 |= this._b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (6 * (3 - nMod4));
            if (nMod4 === 3 || nInLen - nInIdx === 1) {
                nMod3 = 0;
                while (nMod3 < 3 && nOutIdx < nOutLen) {
                    taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
                    nMod3++;
                    nOutIdx++;
                }
                nUint24 = 0;
            }
        }

        return taBytes;
    }

    private _uint6ToB64 = (nUint6: number): number => {
        return nUint6 < 26
            ? nUint6 + 65
            : nUint6 < 52
                ? nUint6 + 71
                : nUint6 < 62
                    ? nUint6 - 4
                    : nUint6 === 62
                        ? 43
                        : nUint6 === 63
                            ? 47
                            : 65;
    }

    private _base64EncArr = (aBytes: Uint8Array): string => {
        let nMod3 = 2;
        let sB64Enc = "";

        const nLen = aBytes.length;
        let nUint24 = 0;
        for (let nIdx = 0; nIdx < nLen; nIdx++) {
            nMod3 = nIdx % 3;
            if (nIdx > 0 && ((nIdx * 4) / 3) % 76 === 0) {
                sB64Enc += "\r\n";
            }

            nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
            if (nMod3 === 2 || aBytes.length - nIdx === 1) {
                sB64Enc += String.fromCodePoint(
                    this._uint6ToB64((nUint24 >>> 18) & 63),
                    this._uint6ToB64((nUint24 >>> 12) & 63),
                    this._uint6ToB64((nUint24 >>> 6) & 63),
                    this._uint6ToB64(nUint24 & 63)
                );
                nUint24 = 0;
            }
        }
        return (
            sB64Enc.substring(0, sB64Enc.length - 2 + nMod3) +
            (nMod3 === 2 ? "" : nMod3 === 1 ? "=" : "==")
        );
    }

    private _UTF8ArrToStr = (aBytes: Uint8Array): string => {
        let sView = "";
        let nPart: number;
        const nLen = aBytes.length;
        for (let nIdx = 0; nIdx < nLen; nIdx++) {
            nPart = aBytes[nIdx];
            sView += String.fromCodePoint(
                nPart > 251 && nPart < 254 && nIdx + 5 < nLen /* six bytes */
                    ? /* (nPart - 252 << 30) may be not so safe in ECMAScript! So…: */
                    (nPart - 252) * 1073741824 +
                    ((aBytes[++nIdx] - 128) << 24) +
                    ((aBytes[++nIdx] - 128) << 18) +
                    ((aBytes[++nIdx] - 128) << 12) +
                    ((aBytes[++nIdx] - 128) << 6) +
                    aBytes[++nIdx] -
                    128
                    : nPart > 247 && nPart < 252 && nIdx + 4 < nLen /* five bytes */
                        ? ((nPart - 248) << 24) +
                        ((aBytes[++nIdx] - 128) << 18) +
                        ((aBytes[++nIdx] - 128) << 12) +
                        ((aBytes[++nIdx] - 128) << 6) +
                        aBytes[++nIdx] -
                        128
                        : nPart > 239 && nPart < 248 && nIdx + 3 < nLen /* four bytes */
                            ? ((nPart - 240) << 18) +
                            ((aBytes[++nIdx] - 128) << 12) +
                            ((aBytes[++nIdx] - 128) << 6) +
                            aBytes[++nIdx] -
                            128
                            : nPart > 223 && nPart < 240 && nIdx + 2 < nLen /* three bytes */
                                ? ((nPart - 224) << 12) +
                                ((aBytes[++nIdx] - 128) << 6) +
                                aBytes[++nIdx] -
                                128
                                : nPart > 191 && nPart < 224 && nIdx + 1 < nLen /* two bytes */
                                    ? ((nPart - 192) << 6) + aBytes[++nIdx] - 128
                                    : /* nPart < 127 ? */ /* one byte */
                                    nPart
            );
        }
        return sView;
    }

    private _strToUTF8Arr = (sDOMStr: string): Uint8Array => {
        let nChr: number | undefined;
        const nStrLen = sDOMStr.length;
        let nArrLen = 0;

        /* mapping… */
        for (let nMapIdx = 0; nMapIdx < nStrLen; nMapIdx++) {
            nChr = sDOMStr.codePointAt(nMapIdx);

            if ((nChr ?? 0) > 65536) {
                nMapIdx++;
            }

            nArrLen +=
                (nChr ?? 0) < 0x80
                    ? 1
                    : (nChr ?? 0) < 0x800
                        ? 2
                        : (nChr ?? 0) < 0x10000
                            ? 3
                            : (nChr ?? 0) < 0x200000
                                ? 4
                                : (nChr ?? 0) < 0x4000000
                                    ? 5
                                    : 6;
        }

        const aBytes = new Uint8Array(nArrLen);

        /* transcription… */
        let nIdx = 0;
        let nChrIdx = 0;
        while (nIdx < nArrLen) {
            nChr = sDOMStr.codePointAt(nChrIdx);
            if ((nChr ?? 0) < 128) {
                /* one byte */
                aBytes[nIdx++] = <number>nChr;
            } else if ((nChr ?? 0) < 0x800) {
                /* two bytes */
                aBytes[nIdx++] = 192 + ((nChr ?? 0) >>> 6);
                aBytes[nIdx++] = 128 + ((nChr ?? 0) & 63);
            } else if ((nChr ?? 0) < 0x10000) {
                /* three bytes */
                aBytes[nIdx++] = 224 + ((nChr ?? 0) >>> 12);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 6) & 63);
                aBytes[nIdx++] = 128 + ((nChr ?? 0) & 63);
            } else if ((nChr ?? 0) < 0x200000) {
                /* four bytes */
                aBytes[nIdx++] = 240 + ((nChr ?? 0) >>> 18);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 12) & 63);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 6) & 63);
                aBytes[nIdx++] = 128 + ((nChr ?? 0) & 63);
                nChrIdx++;
            } else if ((nChr ?? 0) < 0x4000000) {
                /* five bytes */
                aBytes[nIdx++] = 248 + ((nChr ?? 0) >>> 24);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 18) & 63);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 12) & 63);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 6) & 63);
                aBytes[nIdx++] = 128 + ((nChr ?? 0) & 63);
                nChrIdx++;
            } /* if (nChr <= 0x7fffffff) */ else {
                /* six bytes */
                aBytes[nIdx++] = 252 + ((nChr ?? 0) >>> 30);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 24) & 63);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 18) & 63);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 12) & 63);
                aBytes[nIdx++] = 128 + (((nChr ?? 0) >>> 6) & 63);
                aBytes[nIdx++] = 128 + ((nChr ?? 0) & 63);
                nChrIdx++;
            }
            nChrIdx++;
        }

        return aBytes;
    }
}

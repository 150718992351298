import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EMPTY, Subject, of } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ValComponentBaseComponent } from '../common/modules/val-component-base/val-component-base.component';
import { AccountModel } from '../models/mdp/account.model';
import { CommunicationRequestModel } from '../models/mdp/communication-request.model';
import { CommunicationModel } from '../models/mdp/communication.model';
import { CreateLoyaltyModel } from '../models/mdp/create-loyalty.model';
import { EmailValidationModel } from '../models/mdp/email-validation.model';
import { PhoneValidationModel } from '../models/mdp/phone-validation.model';
import { VrcAccountUtilsService } from '../services/vrc-account-utils.service';
import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { ParamsModel } from './../models/params.model';
import { ValUtilsService } from './../services/val-utils.service';
import { MergeSource } from '../models/merge-source.enum';

@Component({
  selector: 'vrc-account',
  templateUrl: './vrc-account.component.html'
})
export class VrcAccountComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('v') private _validationFields!: QueryList<ValComponentBaseComponent>;
  private _subject$ = new Subject<void>;
  private _validationMessage = '';
  private _validatedEmail = "";
  private _validatedPhone = "";
  private _emailValidationResponse!: EmailValidationModel;
  private _phoneValidationResponse!: PhoneValidationModel;
  private accountNotesCounterDaysAgo = 7;
  public accountNotesVisible = false;
  public accountNotesCounter = 0;
  public theme = environment.theme;
  public trigger = 0;
  public isNewOn = false;
  public isSaving = false;
  public offerId = '';
  public dialogTitle = "";
  public phoneValidationDialogDescription = "";
  public emailValidationDialogDescription = "";
  public showModal = false;
  public isValidPhone = true;
  public isValidMail = false;
  public showRefreshAccount = false;
  public searchType: 'ALL' | 'EMAIL' | 'VOUCHER' = 'ALL';
  public searchOptions: Array<{ key: string, label: string }> = [
    { key: 'ALL', label: 'All' },
    { key: 'EMAIL', label: 'Email' },
    { key: 'VOUCHER', label: 'Voucher' },
  ];
  public showDuplicatePrimaryModal = false;
  public duplicatePrimaryModalError = '';
  public duplicateAccountIds: Array<{ id: string, mergeSource: MergeSource, window: WindowProxy | null }> = [];
  public isMerge = false;
  private _sourceAccountId = '';
  private _broadcastChannel: BroadcastChannel | null = null;
  private _parentWindow: WindowProxy | null = null;

  public get isLocalOrAzureUser() {
    return this._bst.isAzureUser || this._bst.userData === 'API';
  }

  // Intelisence helpers
  public account!: AccountModel;

  constructor(
    private _srvc: VrcDataService,
    private _bst: VrcBrowserStorageService,
    private _u: ValUtilsService,
    private _ms: MessageService,
    public st: VrcStorageService,
    private _r: Router,
    private _au: VrcAccountUtilsService,
    private _ar: ActivatedRoute,
    private _ngZone: NgZone
  ) { }

  ngOnDestroy(): void {
    this._subject$.next()
    this._subject$.complete();
  }

  public ngOnInit(): void {
    const isEdit = this._ar.snapshot.queryParams['isEdit'];
    const isMerge = this._ar.snapshot.queryParams['isMerge'];
    this._sourceAccountId = this._ar.snapshot.queryParams['sourceAccountId'] ?? '';
    this.st.mergeSource = this._ar.snapshot.queryParams['mergeSource'] ? +this._ar.snapshot.queryParams['mergeSource'] : MergeSource.None;
    this._ngZone.run(() => {
      this.st.isEditOn = isEdit === null || isEdit === undefined ? this.st.isEditOn : !!(+isEdit);
      this.isMerge = isMerge === null || isMerge === undefined ? this.isMerge : !!(+isMerge);
    });

    this.st.updateClosedCommunicationEvent.pipe(takeUntil(this._subject$), tap(communication => {
      if (this.st.model && this.st.model.communication && this.st.model.communication.length > 0) {
        const communicationIndex = this.st.model.communication.findIndex(item => item.contactId === communication.contactId);

        if (communicationIndex > -1) {
          this.st.model.communication[communicationIndex] = communication;
        }
      }
    })).subscribe();

    this.st.createNewClicked.pipe(takeUntil(this._subject$), tap(() => {
      this.accountNotesVisible = false
      this.accountNotesCounter = 0;
    })).subscribe();
    this.st.validateEmailChange.pipe(takeUntil(this._subject$), tap(() => this._remoteValidateEmailAndPhone(false))).subscribe();
    this.st.validatePhoneChange.pipe(takeUntil(this._subject$), tap(() => this._remoteValidatePhone(false))).subscribe();
  }


  public new = () => {
    this._r.navigate(['main/account/new/details']);
  }

  ngAfterViewInit(): void {
    this._validatedEmail = this.st.model?.contact?.email ?? '';
    this._validatedPhone = this.st.model?.contact?.phone ?? '';
    this.isValidPhone = true;
    this.isValidMail = true;
    this._resetValidation(false);
    if (this.st.model?.id) {
      this.setAccountNotesCounter();
    }
  }

  public openExistingAccountInNewWindow = () => {
    this.duplicateAccountIds.forEach(x => {
      this._u.openLink(location.origin + '/main/account/' + x.id + '/details?isEdit=1');
    });
  }

  public merge = (from = false) => {
    const request = { accountId: this.st.model?.id, sourceAccountId: this._sourceAccountId, from: from, mergeSource: this.st.mergeSource };
    this.st.parentWindow?.postMessage(request, location.origin);
  }

  public mergeExistingAccount = () => {
    this.st.isEditOn = true;
    this.st.mergeAccounts = true;
    this.st.duplicateAccount = this._u.deepCopyObject(this.st.model as AccountModel);
    if (this.duplicateAccountIds.length == 1) {
      if (this._broadcastChannel) {
        this._broadcastChannel.close();
        this._broadcastChannel = null;
      }
      this.showDuplicatePrimaryModal = false;
      const d = this.duplicateAccountIds[0];
      this._r.navigate(['main/account/' + d.id + '/details'], { queryParams: { isEdit: 1 } });
      return;
    }
    window.addEventListener('message', this._handleMessage);
    this.duplicateAccountIds.forEach(x => {
      x.window = window.open(location.origin + '/main/account/' + x.id + '/details?isMerge=1&sourceAccountId=' + this.st.model?.id + '&mergeSource=' + x.mergeSource, '_blank');
      if (x.window) {
        x.window.addEventListener('load', this._onLoad);
      }
    });
  }
  private _handleMessage = (event: Event) => {
    const messageevent = event as MessageEvent;
    if (messageevent.origin === location.origin) {
      this._ngZone.run(() => {
        if (this.st.mergeAccounts) {
          const data = messageevent.data as { accountId: string, sourceAccountId: string, from: boolean, mergeSource: MergeSource }
          this.showDuplicatePrimaryModal = false;
          this.st.mergeSource = data.mergeSource;
          window.focus();
          window.removeEventListener('message', this._handleMessage);
          this.duplicateAccountIds.forEach(x => {
            if (x.window) {
              x.window.close();
            }
          });
          this._r.navigate(['main/account/' + data.accountId + '/details'], { queryParams: { isEdit: 1 } });
        }
      });
    } else {
      console.warn('Message received from an unknown origin:', messageevent.origin);
    }
  }
  private _onLoad = (event: Event) => {
    const win = event.currentTarget as Window;
    win.postMessage("Init", location.origin);
    win.removeEventListener('load', this._onLoad);
  };
  public setAccountNotesCounter() {
    if (this.st.model?.id == null) return;

    const date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    date.setDate(date.getDate() - this.accountNotesCounterDaysAgo);

    this._srvc.GetNoteCountSinceDate(this.st.model?.id, date.toISOString())
      .pipe(
        take(1),
        tap(data => {
          this.accountNotesCounter = data;
        }),
        catchError(error => {
          console.error('Error:', error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public openAccountNotes() {
    this.accountNotesVisible = !this.accountNotesVisible;
    this.accountNotesCounter = 0;
  }

  public isProduction = () => {
    return environment.production
  }

  public edit = () => {
    if (!this.st.model) { return; }
    this._srvc.getAccountById(this.st.model.id ?? '').pipe(
      take(1),
      tap(acc => {
        // model fix          
        acc = <AccountModel>this._au.AccountFix(acc);
        this._validatedEmail = acc.contact.email ?? '';
        this._validatedPhone = acc.contact.phone ?? '';
        this.st.model = acc;
        this.st.origModel = this._u.deepCopyObject(acc);
        this.st.loyality = acc.loyaltiesList;
        this.st.loyalityOrig = this._u.deepCopyObject(acc.loyaltiesList);
        this.st.isEditOn = false;
        this._au.UpdateAccountData();
        this.st.selectedLoyaltyNumber = this.st.model?.loyaltiesList.filter(x => !x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
        this.st.selectedValamartistNumber = this.st.model?.loyaltiesList.filter(x => x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
        this.st.emails = this.st.model.contact.additional.emails.map((e) => {
          return { id: 0, data: e };
        });
        this.st.phones = this.st.model.contact.additional.phones.map((e) => {
          return { id: 0, data: e };
        });
        this.st.isEditOn = true;
        this._r.navigate(['main/account/' + this.st.model.id + '/details']);
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        this._r.navigate(['../']);
        return of(false);
      })
    ).subscribe();
  }

  public refresh = () => {
    location.reload();
  }

  public cancel = () => {
    this.st.isEditOn = false;
    this.isNewOn = false;
    this.isSaving = false;
    if (this.st.model && this.st.model.id) {
      if (this.st.origModel) {
        this.st.model = this._u.deepCopyObject(this.st.origModel);
      }
      this.st.emails = this.st.model.contact.additional.emails.map((e) => {
        return { id: 0, data: e };
      });
      this.st.phones = this.st.model.contact.additional.phones.map((e) => {
        return { id: 0, data: e };
      });
      this.st.loyality = this.st.loyalityOrig && this._u.isArray(this.st.loyalityOrig) ? [...this.st.loyalityOrig] : [];
      this._resetValidation(false);
    } else {
      if (this.st.origModel && this.st.origModel.id) {
        this.st.model = this._u.deepCopyObject(this.st.origModel);
        this.st.emails = this.st.model.contact.additional.emails.map((e) => {
          return { id: 0, data: e };
        });
        this.st.phones = this.st.model.contact.additional.phones.map((e) => {
          return { id: 0, data: e };
        });
        this.st.loyality = this.st.loyalityOrig && this._u.isArray(this.st.loyalityOrig) ? [...this.st.loyalityOrig] : [];
        this._resetValidation(false);
        this._r.navigate(['main/account' + this.st.model.id + '/summary']);
      } else {
        this.st.model = null;
        this.st.emails = [];
        this.st.phones = [];
        this._resetValidation(false);
        this._r.navigate(['main']);
      }
    }
  }

  public createLoyalty = () => {
    if (this.isSaving || !this.st.model) { return; }
    this.st.isCreateLoyalty = true;
    if (!this._validate()) { return; }
    this.isSaving = true;
    const lm = new CreateLoyaltyModel(
      this.st.model.contact.email,
      this.st.model.mappingFields?.crm?.ad_accountid,
      this.st.model.firstName,
      this.st.model.lastName,
      (this.st.model.birthDate instanceof Date) ? this._u.getDateOnlyUtcIsoString(this.st.model.birthDate) : this.st.model.birthDate,
      this.st.model.contact.phone,
      this.st.model.address.street,
      this.st.model.address.zipCode,
      this.st.model.address.city,
      this.st.model.gender,
      this.st.model.language,
      this.st.model.id
    );
    this._ms.add({ severity: 'info', summary: 'Saving', detail: 'Creating loyalty' });
    this._srvc.postLoyalty(lm).pipe(
      take(1),
      tap(acc => {
        if (acc) {
          // model fix
          acc = <AccountModel>this._au.AccountFix(acc);

          // save communication data
          let communication: CommunicationModel[] = [];
          communication = this.st.model?.communication ?? [];
          this.st.model = acc;
          this.st.model.communication = communication;
          this.st.origModel = this._u.deepCopyObject(acc);
          this.st.isEditOn = false;
          this.isNewOn = false;
          this.isSaving = false;
          this.st.isValidated = false;
          this._resetValidation(this.st.isValidated);
          this.st.loyality = acc.loyaltiesList;
          this.st.loyalityOrig = [...acc.loyaltiesList];
          this.st.selectedLoyaltyNumber = this.st.loyality.filter(x => !x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
          this.st.selectedValamartistNumber = this.st.loyality.filter(x => x.cardInformation?.cardNumber?.startsWith("4")).map(x => x.cardInformation?.cardNumber).reduceRight((_a, b) => b, null) ?? null;
          this._ms.add({ severity: 'success', summary: 'Success', detail: 'Created loyalty' });
          this.trigger += 1;
          this.st.refreshAccount.emit();
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        this.isSaving = false;
        return of(null);
      })).subscribe();
  }

  public getOffer = () => {
    this._r.navigate(['main/offer/' + this.offerId + '/' + this.st.model?.id]);
  }

  public closeModal() {
    this.st.validatePhone.emit(this.isValidPhone);
    this.st.validate.emit();
  }

  public validateEmailOrPhone(isCreateLoyalty: boolean) {
    if (this.isSaving) { return; }
    this.st.isCreateLoyalty = isCreateLoyalty;

    // First check if everything is localy valid
    if (!this._validate()) { return; }
    this._remoteValidateEmailAndPhone(true);
  }

  public saveAccount() {
    if (!this.st.isCreateLoyalty) {
      this._confirm();
    }
    else {
      this._confirmAndCreateLoyalty();
    }
  }

  private _confirm = () => {
    if (!this.st.model) { return; }
    this.isSaving = true;
    this.st.model.birthDate = (this.st.model.birthDate instanceof Date) ?
      this._u.getDateOnlyUtcIsoString(this.st.model.birthDate) : this.st.model.birthDate;
    if (this.st.model.contact.additional) {
      this.st.model.contact.additional.emails = this.st.emails.map((e) => e.data ?? '');
      this.st.model.contact.additional.phones = this.st.phones.map((e) => e.data ?? '');
    }
    this.st.model.communication = [];
    this.st.model.reservations = [];
    this.st.model.offers = [];
    if (this.st.mergeAccounts) {
      this.st.model.isMerge = true;
      this.st.model.mergeSource = this.st.mergeSource;
      this.st.mergeAccounts = false;
    }
    this._ms.add({ severity: 'info', summary: 'Saving', detail: 'Saving account' });
    const isNew = this.st.model.id ? false : true;
    this._srvc.postAccount(this.st.model).pipe(
      take(1),
      tap(acc => {
        if (acc) {
          if (acc.mappingFields?.crm.ad_accountid) {
            this._ms.add({ severity: 'success', summary: 'Success', detail: 'Saved account' });
          }
          else {
            if (this.st.model?.id) {
              this._ms.add({ severity: 'info', summary: 'Info', detail: 'Account in CRM will be updated as soon as CRM service is back online.', life: 10000 });
            }
            else {
              this._ms.add({ severity: 'warn', summary: 'Warning', detail: 'Account in CRM will be created as soon as CRM service is back online.', life: 10000 });
            }
          }
          // model fix          
          acc = <AccountModel>this._au.AccountFix(acc);

          this.st.model = acc;
          this.st.origModel = this._u.deepCopyObject(acc);
          this.st.isEditOn = false;
          this.isNewOn = false;
          this.isSaving = false;
          this.st.isValidated = false;
          this._resetValidation(this.st.isValidated);
          // remove saved intermediate communication data
          this._setCommunicationSummary();
          if (isNew) {
            this.st.accountIsSelected = this.st.niceNewContact;
            this.st.niceNewContact = false;
            this._r.navigate(['main/account/' + this.st.model.id]);
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err?.status === 409) {
          this._ms.add({ severity: 'error', summary: 'Error', detail: 'Error: ' + 'Account has been updated by someone else, please refresh' });
          this.showRefreshAccount = true;
        } else {
          if (err?.error?.errors['AccountId'][0]) {
            this.duplicatePrimaryModalError = (err?.error?.message as string);
            this.duplicateAccountIds = err?.error?.errors['AccountId'][0];
            this.showDuplicatePrimaryModal = true;
          } else {
            this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          }
        }

        this.isSaving = false;
        return of(null);
      })).subscribe();
  }

  private _confirmAndCreateLoyalty = () => {
    if (!this.st.model) { return; }
    this.isSaving = true;
    this._ms.add({ severity: 'info', summary: 'Saving', detail: 'Creating account and loyalty' });
    this.st.model.birthDate = (this.st.model.birthDate instanceof Date) ? this._u.getDateOnlyUtcIsoString(this.st.model.birthDate) : this.st.model.birthDate;
    this.st.model.communication = [];
    this.st.model.reservations = [];
    this.st.model.offers = [];
    if (this.st.mergeAccounts) {
      this.st.model.isMerge = true;
      this.st.model.mergeSource = this.st.mergeSource;
      this.st.mergeAccounts = false;
    }
    this._srvc.postAccountAndLoyalty(this.st.model).pipe(
      take(1),
      tap(acc => {
        if (acc) {
          this._ms.add({ severity: 'success', summary: 'Success', detail: 'Created account and loyalty' });
          // model fix
          if (acc) {
            acc = <AccountModel>this._au.AccountFix(acc);
          }

          // save communication data
          let communication: CommunicationModel[] = [];

          this.st.model = acc;
          communication = this.st.model.communication;
          this.st.model.communication = communication;
          this.st.origModel = this._u.deepCopyObject(acc);
          this.st.isEditOn = false;
          this.isNewOn = false;
          this.isSaving = false;
          this.st.isValidated = false;
          this._resetValidation(this.st.isValidated);
          this.st.loyality = acc.loyaltiesList;
          this.st.loyalityOrig = [...acc.loyaltiesList];
          this.st.accountIsSelected = this.st.niceNewContact;
          this.st.niceNewContact = false;
          this._r.navigate(['main/account/' + this.st.model?.id]);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        this.isSaving = false;
        if (err?.error?.errors['AccountId'][0]) {
          this.duplicatePrimaryModalError = (err?.error?.message as string);
          this.duplicateAccountIds = err?.error?.errors['AccountId'][0];
          this.showDuplicatePrimaryModal = true;
        } else {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        }
        return of(null);
      })).subscribe();
  }

  private _validate = () => {
    let valid = true;

    if (this._validationFields) {
      this._validationFields.forEach(x => {
        const v = x.validate();
        if (!v) {
          valid = false;
        }
      });
    }
    if (valid) {
      // emit validation event
      this.st.validate.emit();
      //this.st.validateEmail.emit(this.st.model!.contact.email);
      // Show popup because field are not visible
      let v = this._validateCountry();
      if (!v) {
        valid = false;
      }
      v = this._validateLanguage();
      if (!v) {
        valid = false;
      }
      v = this._validateGender();
      if (!v) {
        valid = false;
      }
      v = this._validateEmail();
      if (!v) {
        valid = false;
      }
      v = this._validateTel();
      if (!v) {
        valid = false;
      }
      v = this._validateAdditionalEmails();
      if (!v) {
        valid = false;
      }
      v = this._validateAdditionalTel();
      if (!v) {
        valid = false;
      }
      v = this._validateFirstName();
      if (!v) {
        valid = false;
      }
      v = this._validateLastName();
      if (!v) {
        valid = false;
      }
      if (!valid) {
        this._ms.add({ severity: 'error', summary: 'Error', detail: this._validationMessage });
      }

    }
    this.st.isValidated = true;
    return valid;
  }

  // INFO: _validateBirthDate method is currently not used anywhere
  private _validateBirthDate = (): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!this.st.model?.birthDate) {
      this._validationMessage = 'Birth date is required';
      return false;
    }
    // eslint-disable-next-line no-constant-condition
    return (new Date(this.st.model.birthDate)) ? true : false;
  }

  private _validateEmail = (): boolean => {
    if (this.st.isCreateLoyalty) {
      if (!this.st.model?.contact.email) {
        this._validationMessage = 'Email is required';
        return false;
      }
    } else {
      if (!(this.st.model?.contact.email || this.st.model?.contact.phone)) {
        this._validationMessage = 'Email or Phone is required';
        return false;
      }
    }
    if (!this.st.model?.contact.email) { return true; }
    const re = /^(([^<>()[\].,;:\s@']+(\.[^<>()[\].,;:\s@']+)*)|('.+'))@(([^<>()[\].,;:\s@']+\.)+[^<>()[\].,;:\s@']{2,})$/i;
    if (!re.test(this.st.model?.contact.email ?? '')) {
      this._validationMessage = 'Email is not in good format';
      return false;
    }
    return true;
  }

  private _validateAdditionalEmails = (): boolean => {
    let isValid = true;
    if (!this.st.emails) { return isValid; }
    this.st.emails.forEach(x => {
      const re = /^(([^<>()[\].,;:\s@']+(\.[^<>()[\].,;:\s@']+)*)|('.+'))@(([^<>()[\].,;:\s@']+\.)+[^<>()[\].,;:\s@']{2,})$/i;
      if (!x || !re.test(x.data ?? '')) {
        this._validationMessage = 'Additional email is invalid';
        isValid = false;
      }
    });
    return isValid;
  }

  private _validateAdditionalTel = (): boolean => {
    let isValid = true;
    if (!this.st.phones) { return isValid; }
    this.st.phones.forEach(x => {
      // re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
      const re = /^\+[1-9]\d{1,14}$/;
      if (!x || !re.test(x.data ?? '')) {
        this._validationMessage = 'Additional phone is invalid';
        isValid = false;
      }
    });
    return isValid;
  }

  private _validateTel = (): boolean => {
    if (this.st.isCreateLoyalty) {
      if (!this.st.model?.contact.phone) {
        this._validationMessage = 'Phone is required';
        return false;
      }
    } else {
      if (!(this.st.model?.contact.email || this.st.model?.contact.phone)) {
        this._validationMessage = 'Email or Phone is required';
        return false;
      }
    }
    if (!this.st.model?.contact.phone) { return true; }
    // re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    const re = /^\+[1-9]\d{7,14}$/;
    if (!re.test(this.st.model?.contact?.phone ?? '')) {
      this._validationMessage = 'Phone number is invalid, format is like +38598123456';
      return false;
    }
    return true;
  }

  private _validateCountry = (): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!this.st.model?.address.country) {
      this._validationMessage = 'Country field is required';
      return false;
    }
    return true;
  }

  private _validateLanguage = (): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!this.st.model?.language) {
      this._validationMessage = 'Language field is required';
      return false;
    }
    return true;
  }

  private _validateGender = (): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!this.st.model?.gender) {
      this._validationMessage = 'Gender field is required';
      return false;
    }
    return true;
  }
  private _validateFirstName = (): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!this.st.model?.firstName) {
      this._validationMessage = 'First name field is required';
      return false;
    }
    return true;
  }
  private _validateLastName = (): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!this.st.model?.lastName) {
      this._validationMessage = 'Last name field is required';
      return false;
    }
    return true;

  }
  private _setCommunicationSummary = () => {
    const extraData = new CommunicationRequestModel(this.st.model?.id,
      this.st.model?.contact && this.st.model.contact.email ? this.st.model.contact.email : '',
      this.st.model?.contact && this.st.model.contact.phone ? this.st.model.contact.phone : '');

    const paramsModel = new ParamsModel(
      0, 5, undefined, 1, [{ field: 'stateId', order: 1 }, { field: 'createDate', order: -1 }], undefined, null, extraData);

    this._srvc.getCommunicationPage(paramsModel)
      .pipe(
        take(1),
        tap(result => {
          if (result && result.data && this.st.model) {
            this.st.model.communication = result.data;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          return of(null);
        })
      )
      .subscribe();
  }

  // v = is state validated
  private _resetValidation = (v: boolean) => {
    if (this._validationFields) {
      this._validationFields.forEach(x => {
        x.isValidated = v;
      });
    }
    this.st.resetValidation.emit();
  }

  private _remoteValidateEmailAndPhone = (isSaveRequest: boolean): void => {
    const email = this.st.model?.contact.email ?? '';
    if (!email) {
      this._validatedEmail = email;
      this.isValidMail = true;
      this._remoteValidatePhone(isSaveRequest);
      return;
    }
    if (this._validatedEmail !== email) {
      if (!this._validateEmail()) {
        this.isValidMail = true;
        this._remoteValidatePhone(isSaveRequest);
        return;
      }
      this._srvc.validateEmail(email).pipe(
        take(1),
        map(x => {
          this._emailValidationResponse = x;
          this._validatedEmail = email;
          if (x.validateStatus !== "deliverable") {
            this.isValidMail = false;
          }
          else {
            this.isValidMail = true;
          }
          this.st.validateEmail.emit(x);
          this._remoteValidatePhone(isSaveRequest);
          return true;
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          this.isValidMail = true;
          return of(false);
        })
      ).subscribe();
    } else {
      this._remoteValidatePhone(isSaveRequest);
    }
  }

  private _remoteValidatePhone = (isSaveRequest: boolean): void => {
    const phone = this.st.model?.contact.phone ?? null;
    if (!phone) {
      this._validatedPhone = phone ?? '';
      this.isValidPhone = true;
      if (!this._showEmailAndPhoneValidationModalOrSave(isSaveRequest)) {
        this.saveAccount();
      }
      return;
    }
    if (phone && phone !== this._validatedPhone) {
      if (!this._validateTel()) {
        this.isValidPhone = true;
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const countryCode: any = null;
      this._srvc.validatePhone(phone, countryCode).pipe(
        take(1),
        map(x => {
          this._phoneValidationResponse = x;
          this._validatedPhone = phone;
          if (x.validateStatus !== "delivered") {
            this.isValidPhone = false;
            this.st.validatePhone.emit(this.isValidPhone);
            this._showEmailAndPhoneValidationModalOrSave(isSaveRequest);
            return true;
          } else {
            this.isValidPhone = true;
            if (!this._showEmailAndPhoneValidationModalOrSave(isSaveRequest)) {
              this.saveAccount();
            }
            this.st.validatePhone.emit(this.isValidPhone);
            return true;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          this.isValidPhone = true;
          return of(false);
        })
      ).subscribe();
    } else {
      if (!this._showEmailAndPhoneValidationModalOrSave(isSaveRequest)) {
        this.saveAccount();
      }
      return;
    }
  }

  private _showEmailAndPhoneValidationModalOrSave = (isSaveRequest: boolean): boolean => {
    if (!this.isValidMail && !this.isValidPhone) {
      this.dialogTitle = "There is a problem with phone and email validation. Do you want to save account anyway?";
      this.showModal = isSaveRequest;
      this.phoneValidationDialogDescription = this._phoneValidationResponse.validateReason ?? "Response reason not provided.";
      this.emailValidationDialogDescription = this._emailValidationResponse.validateReason ?? "Response reason not provided.";
      return true;
    }
    if (!this.isValidPhone) {
      this.dialogTitle = "Phone status is marked as " + this._phoneValidationResponse.validateStatus + ". Do you want to save account anyway?"
      this.showModal = isSaveRequest;
      this.phoneValidationDialogDescription = this._phoneValidationResponse.validateReason ?? "Response reason not provided.";
      return true;
    }
    if (!this.isValidMail) {
      this.dialogTitle = "Email status is marked as " + this._emailValidationResponse.validateStatus + ". Do you want to save account anyway?";
      this.emailValidationDialogDescription = this._emailValidationResponse.validateReason ?? "Response reason not provided.";
      this.showModal = isSaveRequest;
      return true;
    }
    return !isSaveRequest;
  }
}



import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ValTextBoxComponent } from './val-text-box.component';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
        ValTextBoxComponent
    ],
    exports: [
        ValTextBoxComponent
    ],
    providers: []
})
export class ValTextBoxModule { }

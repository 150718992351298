import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ValTableScrollerComponent } from './val-table-scroller.component';


@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
        ValTableScrollerComponent
    ],
    exports: [
        ValTableScrollerComponent
    ],
    providers: []
})
export class ValTableScrollerModule { }

import { Pipe, PipeTransform } from '@angular/core';

import { VrcStorageService } from '../services/vrc-storage.service';

@Pipe({ name: 'isCommInStatus' })
export class IsCommInStatusPipe implements PipeTransform {
    constructor(private _st: VrcStorageService) { }
    transform(currentStatus: string, status: string, channel=""): boolean {
        switch (status) {
            case this._st.commStatuses.Active:
                return (currentStatus === this._st.commStatuses.Active && channel !== this._st.channelTypes.Digital) ||
                    (currentStatus === this._st.commStatuses.Open && channel === this._st.channelTypes.Digital);
            case this._st.commStatuses.Closed:
                return currentStatus === this._st.commStatuses.Closed;
            case this._st.commStatuses.New:
            case this._st.commStatuses.Open:
                return (currentStatus === this._st.commStatuses.Open && channel !== this._st.channelTypes.Digital) ||
                    (currentStatus === this._st.commStatuses.New && channel === this._st.channelTypes.Digital);
            case this._st.commStatuses.Parked:
                return currentStatus === this._st.commStatuses.Parked;
            case this._st.commStatuses.EmailTransfer:
                return currentStatus === this._st.commStatuses.EmailTransfer;
            case this._st.commStatuses.Escalated:
                return currentStatus === this._st.commStatuses.Escalated;
            case this._st.commStatuses.Inqueue:
                return currentStatus === this._st.commStatuses.Inqueue;
            case this._st.commStatuses.Pending:
                return currentStatus === this._st.commStatuses.Pending;

        }
        return false;
    }
}

import { AdditionalModel } from './additional.model';

export class ContactModel {
  constructor(    
    public phone: string | null = null,
    public email: string | null = null,
    public viber: string | null = null,
    public messenger: string | null = null,
    public whatsApp: string | null = null,
    public additional: AdditionalModel = new AdditionalModel(),
  ) { }
}
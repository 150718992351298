export class ClaimPointsModel {
    constructor(
        public loyaltyCardNumber: string | null = null,
        public resort: string | null = null,
        public roomClass: string | null = null,
        public arrivalDate: string | Date | null = null,
        public departureDate: string | Date | null = null,
        public bookingReferenceNumber: string | null = null,
        public totalRevenue: string | null = null,
    ) { }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValDropDownComponent } from './val-drop-down.component';
import { ValDropDownDirective } from './val-drop-down.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        ValDropDownComponent,
        ValDropDownDirective
    ],
    exports: [
        ValDropDownDirective
    ],
    providers: []
})
export class ValDropDownModule { }

<div class="card main border-0 rounded-0">
  <div class="card-body mt-0 pt-0 px-0">
    <div *ngIf="!st.isEditOn" [class]="'row sticky-top ' + theme">
      <div class="col-12 py-5">
        <div class="row">
          <div class="col ms-0">
            <div class="row ps-5">
              <div class="col-auto me-5">
                <button class="btn btn-lg btn-secondary btn-logo p-3">
                  <img src="favicon.ico" class="img-fluid" alt="...">
                </button>
              </div>
              <div class="col me-5" style="min-width:20rem; max-width:30rem;">
                <div class="input-group input-group-lg mb-0 w-100">
                  <input [(ngModel)]="searchString" type="text" style="min-width:11.25rem ;" class="form-control" placeholder="Search text" aria-label="Search" (keypress)="search($event)" #searchInput>
                  <select class="form-select" [(ngModel)]="searchType">
                    <option *ngFor="let s of searchOptions" [ngValue]="s.key">{{s.label}}</option>
                  </select>

                  <button type="button" class="btn btn-primary" (click)="search()" [disabled]="!searchString">
                    <i class="vi vi-search"></i>
                  </button>
                </div>
              </div>
              <div class="col me-5">
                <button (click)="new()" type="button" class="btn btn-lg btn-primary text-nowrap">
                  Create new
                  <i class="vi vi-person-add-alt ms-5"></i>
                </button>
              </div>
              <div class="col text-light fs-2">
                {{envText}}
              </div>
            </div>
          </div>
          <div class="col-auto ms-0 me-5">
            <div class="w-auto float-end fs-7 fw-bolder" [ngClass]="{'text-light': !isProduction()}">
              Logout
              <button (click)="logOff()" type="button" class="btn btn-lg btn-secondary btn-logout ms-4">
                <i class="vi vi-logout"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-0 align-top bg-lighter pt-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'val-modal-backdrop',
    template: ''
})
export class ValModalBackdropComponent {
    @HostBinding("class") private get cssClass() {
        return "modal-backdrop fade show" + (this.backdropClass ? " " + this.backdropClass : "");
    }
    public backdropClass!: string;
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { CommunicationCallDetailsModel } from '../models/mdp/communication-call-details.model';
import { ValUtilsService } from '../services/val-utils.service';
import { VrcDataService } from '../services/vrc-data.service';
import { CommunicationModel } from '../models/mdp/communication.model';

@Component({
  selector: 'vrc-communication-call-details',
  templateUrl: './vrc-communication-call-details.component.html'
})
export class VrcCommunicationCallDetailsComponent implements OnInit {
  public sources: Array<SafeResourceUrl> = [];
  public recordingLoaded = false;

  @Input() model: CommunicationCallDetailsModel | null = null;
  @Input() contactId!: number;
  @Input() id!: string;
  @Input() communication: CommunicationModel | null = null;

  constructor(
    private _srvc: VrcDataService,
    private _u: ValUtilsService,
    private sanitizer: DomSanitizer,
    private _ms: MessageService) { }
  ngOnInit(): void {
    // this.model.fileName = "preuzmi.wav";
    // this.model.fullFileName = "preuzmi.wav";
    // this.id = "62d25edf0002ae871072a5a7";
    if (this.model?.recordingExists !== false) {
      this._srvc.getRecordingFiles(this.model?.recordings ?? [], this.id)
        .pipe(
          take(1),
          tap(r => {
            if (r.base64Data) {
              r.base64Data.forEach(x => {
                const blob = this._u.convertBase64ToBlob(x, "audio/wav");
                const url = URL.createObjectURL(blob);
                this.sources.push(this.sanitizer.bypassSecurityTrustResourceUrl(url));
              });
            }
            this.recordingLoaded = true;
          }),
          catchError((err: HttpErrorResponse) => {
            this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
            this.recordingLoaded = true;
            return of(null);
          })
        )
        .subscribe();
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { ValUtilsService } from '../../services/val-utils.service';

@Pipe({ name: 'deleted' })
export class DeletedPipe implements PipeTransform {
    constructor(private _u: ValUtilsService) { }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    transform(model: Array<any>, trigger = 0): any {
        return model && this._u.isArray(model) ? model.filter(x => !x.isDeleted) : model;
    }
}

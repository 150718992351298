<div class="row">
  <div class="col-12 col-sm-6 pe-sm-5 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Personal details
        </h6>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Email address</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerAddress">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">First name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerFirstName">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerLastName">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Details
        </h6>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Work time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="(model?.workTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Queue time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="(model?.queueTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last agent</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.lastAgent">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Contact
        </h6>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Contact Id</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="contactId">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">Channel</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.channel">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Start date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.startDate | date:'short'">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">End date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.endDate | date:'short'">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">State</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.state">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">Direction</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.direction">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-4">
	<div class="card card-shadow border-0 mb-4">
		<div class="card-body">
			<h6>
				Dispositions
			</h6>
			<div *ngIf="communication?.contacts?.length === 0; else dispositionTable">
				<span class="sendto-cc-text-style"> <i>There is no disposition notes!</i></span>
			</div>
			<ng-template #dispositionTable>
				<p-table [value]="communication?.contacts!" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll">
					<ng-template pTemplate="header">
					  <tr>
						<th style="width: 2rem" pResizableColumn></th>
						<th style="width: 2rem" pResizableColumn>
						  Name
						</th>
						<th style="width: 2rem" pResizableColumn>
						  Agent
						</th>
						<th pResizableColumn>
						  Note
						</th>
					  </tr>
					</ng-template>
					<ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
					  <tr>
						<td>{{rowIndex + 1}}</td>
						<td>{{contact.disposition.dispositionName}}</td>
						<td>{{contact.agent}}</td>
						<td>{{contact.disposition.notes}}</td>
					  </tr>
					</ng-template>
				  </p-table>
			</ng-template>
		</div>
	</div>
</div>
  <div class="col-12 pb-5">
    <h6>
      {{model?.channel==='Digital'?model?.subChannel:'Chat'}} transcript
    </h6>
  </div>
  <div class="col-12">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <p *ngIf="!model?.chatTranscript || (model?.chatTranscript?.length??0) === 0">
          No chat transcript is available
        </p>
        <ng-container *ngIf="model?.chatTranscript && (model?.chatTranscript?.length??0)> 0">
          <div class="row my-2" *ngFor="let chatMessage of model?.chatTranscript">
            <ng-container *ngIf="chatMessage.partyType && chatMessage.partyType === 'Client'">
              <div class="col-4 col-sm-3 col-md-2 ps-1 pe-3">
                <label class="form-label" *ngIf="chatMessage.label">
                  {{ chatMessage.label }} <br> <span class="fst-italic fw-lighter">{{ chatMessage.partyType }}</span>
                </label>
                <label class="form-label" *ngIf="!chatMessage.label">
                  {{ chatMessage.partyType }}
                </label>
              </div>
              <div class="col">
                <p class="vrc-chat-text mb-0 rounded bg-success-1 px-2 py-1 h-100">
                  <ng-container *ngFor="let c of chatMessage.text??'' | chatText">
                    {{c}}<br>
                  </ng-container>
                </p>
              </div>
              <div class="col-auto ps-3 pe-1">
                <p class="vrc-chat-timestamp fw-lighter">
                  {{ chatMessage.timestamp | date:'shortDate' }}<br>{{ chatMessage.timestamp | date:'mediumTime' }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="chatMessage.partyType && (chatMessage.partyType === 'System' || chatMessage.partyType === 'Agent')">
              <div class="col-auto ps-1 pe-3">
                <p class="vrc-chat-timestamp fw-lighter">
                  {{ chatMessage.timestamp | date:'shortDate' }}<br>{{ chatMessage.timestamp | date:'mediumTime' }}
                </p>
              </div>
              <div class="col">
                <p class="vrc-chat-text mb-0 rounded bg-info px-2 py-1 h-100">
                  <ng-container *ngFor="let c of (chatMessage.text??'') | chatText">
                    {{c}}<br>
                  </ng-container>
                </p>
              </div>
              <div class="col-4 col-sm-3 col-md-2 ps-3 pe-1">
                <label class="form-label" *ngIf="chatMessage.label">
                  {{ chatMessage.label }}<br> <span class="fst-italic fw-lighter">{{ chatMessage.partyType }}</span>
                </label>
                <label class="form-label" *ngIf="!chatMessage.label">
                  {{ chatMessage.partyType }}
                </label>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
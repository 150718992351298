<div class="val-date-picker-card">
  <div class="val-date-picker-card-header">
    <a class="val-date-picker-left" (click)="month=month-1">
      <i class="vi vi-caret-left-fill"></i>
    </a>
    <ng-template [ngIf]="showMonth && showYear">
      <div class="val-date-picker-month-year">
        <select class="val-date-picker-month" [(ngModel)]="month">
          <option *ngFor="let m of months" [ngValue]="m.id">{{m.name}} </option>
        </select>
        <select class="val-date-picker-year" [(ngModel)]="year">
          <option *ngFor="let y of years" [ngValue]="y">{{y}} </option>
        </select>
      </div>
    </ng-template>
    <ng-template [ngIf]="showMonth && !showYear">
      <select *ngIf="showMonth" class="val-date-picker-month" [(ngModel)]="month">
        <option *ngFor="let m of months" [ngValue]="m.id">{{m.name}} </option>
      </select>
    </ng-template>
    <ng-template [ngIf]="!showMonth && showYear">
      <select *ngIf="showYear" class="val-date-picker-year" [(ngModel)]="year">
        <option *ngFor="let y of years" [ngValue]="y">{{y}} </option>
      </select>
    </ng-template>
    <a class="val-date-picker-right" (click)="month=month+1">
      <i class="vi vi-caret-right-fill"></i>
    </a>
  </div>
  <div class="card-body p-1">
    <table class="table-condensed mx-auto">
      <thead>
        <tr>
          <th class="val-date-picker-header" *ngFor="let wd of weekdays; index as j">
            <div class="val-date-picker-header-cell">{{wd.name}}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let week of model; index as k">
          <td *ngFor="let day of week.days; index as l" [class.val-date-picker-cell-disabled]="day.isDisabled"
            [class.val-date-picker-cell-hover]="isHoverEnabled && !day.isDisabled" style="cursor: default;">
            <a #dayButton [attr.disabled]="day.isDisabled"
              class="val-date-picker-cell"
              [class.val-date-picker-muted]="day.isCurrentMonth===false"
              [class.val-date-picker-selected]="day.isSelected"
              [class.val-date-picker-today]="day.isToday" 
              [class.val-date-picker-cursor-disabled]="day.isDisabled"
              (mouseover)="dayMouseover(day.date,dayButton)" (click)="dayClick(day)">{{day.day}}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="showTime" class="card-body p-1 d-flex flex-row justify-content-center border-top">
    <select class="form-select w-auto" [(ngModel)]="hour">
      <option *ngFor="let h of hours" [ngValue]="h.value">{{h.displayValue}} </option>
    </select>
    <span class="fw-bold mx-1">:</span>
    <select class="form-select w-auto" [(ngModel)]="minute">
      <option *ngFor="let m of minutes" [ngValue]="m.value">{{m.displayValue}} </option>
    </select>
  </div>
  <div *ngIf="showControls" class="card-footer">
    <button type="button" class="btn btn-sm btn-secondary float-end" (click)="cancel()"><i class="fas fa-times"></i></button>
    <button type="button" class="btn btn-sm btn-primary float-end" (click)="confirm()"><i class="fas fa-check"></i></button>
  </div>
</div>
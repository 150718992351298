import { AmountsDataModel } from './amounts-data.model';
import { CardInformationModel } from './card-information.model';
import { LevelCalculationModel } from './level-calculation.model';
import { LoyalityAccountModel } from './loyality-account.model';
import { LoyalityReservationModel } from './loyality-reservation.model';
import { MappingFieldsModel } from './mapping-fields.model';
import { PointsDataModel } from './points-data.model';
import { SocialNetworksModel } from './social-networks.model';

export class LoyalityDetailsModel {
    constructor(
        public id: string | null = null,
        public isActive: boolean = true,
        public isDeleted: boolean = false,
        public createdByUser: string | null = null,
        public createdDate: string | null = null,
        public modifiedByUser: string | null = null,
        public modifiedDate: string | null = null,
        public cardInformation: CardInformationModel | null = new CardInformationModel(),
        public amountsData: AmountsDataModel = new AmountsDataModel(),
        public pointsData: PointsDataModel = new PointsDataModel(),
        public account: LoyalityAccountModel = new LoyalityAccountModel(),
        public levelCalculation: LevelCalculationModel = new LevelCalculationModel(),
        public status: string | null = null,
        public program: string | null = null,
        public level: string | null = null,
        public issued: string | null = null,
        public reservations: LoyalityReservationModel[] = [],
        public socialNetworks: SocialNetworksModel = new SocialNetworksModel(),
        public isSelected = false,
        public mappingFields: MappingFieldsModel = new MappingFieldsModel(),
        public dataHubVersion: number = 0,
        public parentLoyaltyId: string | null = null,
        public version: number = 0
    ) { }
}

import { DOCUMENT } from '@angular/common';
import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { MessageService } from 'primeng/api';

@Directive({
  selector: '[vrcClipboard]',
})
export class VrcClipboardDirective {
  @Input() vrcClipboard = '';
  @Output() myClipboardSuccess = new EventEmitter<boolean>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private messageService: MessageService
  ) { }

  @HostListener('click')
  onClick() {
    this.copyTextToClipboard(this.vrcClipboard).then(() => {
      this.messageService.add({
        severity: 'success',
        detail: 'Copied to clipboard',
      });
      this.myClipboardSuccess.emit(true);
    });
  }

  private fallbackCopyTextToClipboard(text: string) {
    const textArea = this.document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    this.document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      this.document.execCommand('copy');
      this.document.body.removeChild(textArea);
      return Promise.resolve();
    } catch (err) {
      this.document.body.removeChild(textArea);
      return Promise.reject();
    }
  }

  private copyTextToClipboard(text : string): Promise<void> {
    if (!navigator.clipboard) {
      return this.fallbackCopyTextToClipboard(text);
    }
    return navigator.clipboard.writeText(text);
  }
}

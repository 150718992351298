import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { AccountModel } from '../models/mdp/account.model';
import { AdditionalInformationModel } from '../models/mdp/additional-information.model';
import { AdditionalModel } from '../models/mdp/additional.model';
import { AddressModel } from '../models/mdp/address.model';
import { CommunicationRequestModel } from '../models/mdp/communication-request.model';
import { CommunicationUpdateAccountRequestModel } from '../models/mdp/communication-update-account-request.model';
import { ContactModel } from '../models/mdp/contact.model';
import { InterestModel } from '../models/mdp/interest.model';
import { MappingFieldsModel } from '../models/mdp/mapping-fields.model';
import { ParamsModel } from '../models/params.model';
import { VrcBrowserStorageService } from './vrc-browser-storage.service';
import { VrcDataService } from './vrc-data.service';
import { VrcStorageService } from './vrc-storage.service';
import { ValUtilsService } from './val-utils.service';

@Injectable()
export class VrcAccountUtilsService {
  constructor(
    private _ms: MessageService,
    private _srvc: VrcDataService,
    private _st: VrcStorageService,
    private _bst: VrcBrowserStorageService,
    private _u: ValUtilsService,
  ) { }

  public InitNewAccount = (
    firstName: string | null = null, lastName: string | null = null, language: string | null = null, phone: string | null = null, email?: string
  ): AccountModel => {
    return new AccountModel(
      null,
      true,
      false,
      null, null, null, null,
      firstName,
      lastName,
      null,
      null,
      language ? this._st.languages.filter(x => x.name.toLowerCase() === language.toLowerCase())
        .map(x => x.code).reduceRight((_a, b) => b, null) : null,
      null, null, null,
      [],
      new AddressModel(),
      new ContactModel(phone, email, null, null, null, new AdditionalModel()),
      new AdditionalInformationModel(),
      [],
      this.FixAdvertisingPermissions(null),
      this.FixProducts(null),
      this._st.interestsLookup?.map(x => new InterestModel(x.id, x.name, x.category, false)),
      [],
      [],
      new MappingFieldsModel(),
      []
    );
  }

  public AccountFix = (m: AccountModel | null): AccountModel | null => {
    if (!m) { return m; }
    m.contact.additional = m.contact.additional ? m.contact.additional : new AdditionalModel();
    m.contact.additional.emails = m.contact.additional.emails ? m.contact.additional.emails : [];
    m.contact.additional.phones = m.contact.additional.phones ? m.contact.additional.phones : [];

    m.advertisingPermissions = this.FixAdvertisingPermissions(m.advertisingPermissions);
    m.products = this.FixProducts(m.products);
    m.address = m.address ? m.address : new AddressModel();
    m.birthDate = this._u.getDateOnlyFromString(m.birthDate);
    m.loyalties = m.loyalties ? m.loyalties : [];
    return m;
  }

  public FixAdvertisingPermissions = (input: { [key: string]: 'Yes' | 'No' | 'Not specified' } | null): { [key: string]: 'Yes' | 'No' | 'Not specified' } => {
    const tmp = new Object() as { [key: string]: 'Yes' | 'No' | 'Not specified' };
    if (input) {
      this._st.advertisingPermissions.forEach(x => {
        if (!Object.keys(input).includes(x.key)) {
          tmp[x.key] = 'Not specified';
        } else {
          tmp[x.key] = input[x.key];
        }
      });
      return tmp;
    } else {
      this._st.advertisingPermissions.forEach(x => {
        tmp[x.key] = 'Not specified';
      });
      return tmp;
    }
  }

  public FixProducts = (input: { [key: string]: 'Yes' | 'No' | 'Not specified' } | null): { [key: string]: 'Yes' | 'No' | 'Not specified' } => {
    const tmp = new Object() as { [key: string]: 'Yes' | 'No' | 'Not specified' };
    if (input) {
      this._st.products.forEach(x => {
        if (!Object.keys(input).includes(x.key)) {
          tmp[x.key] = 'Not specified';
        } else {
          tmp[x.key] = input[x.key];
        }
      });
      return tmp;
    } else {
      this._st.products.forEach(x => {
        tmp[x.key] = 'Not specified';
      });
      return tmp;
    }
  }

  public UpdateAccountData = () => {
    if (this._st.model && this._st.contactId && this._st.model.id) {
      this._srvc.updateCommunicationAccount(new CommunicationUpdateAccountRequestModel(this._st.contactId, this._st.model.id))
        .pipe(
          take(1),
          tap(result => {
            if (!result) {
              this._ms.add({ severity: 'warn', summary: 'Warning', detail: 'Warning: Account data were not updated' });
              // this._bst.deleteAgentId();
              // this._st.contactId = null; 
            }

            this.synchronizeActivatedContact();
          }),
          catchError((err: HttpErrorResponse) => {
            this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
            this.synchronizeActivatedContact();
            return of(null);
          })
        )
        .subscribe();
    }
    else {
      this.synchronizeActivatedContact();
    }
  }

  public synchronizeActivatedContact = () => {
    if (this._st.model) {
      this._setCommunicationSummary();
    }
  }

  private _setCommunicationSummary = () => {
    const extraData = new CommunicationRequestModel(this._st.model?.id,
      this._st.model?.contact && this._st.model?.contact.email ? this._st.model?.contact.email : '',
      this._st.model?.contact && this._st.model?.contact.phone ? this._st.model?.contact.phone : '');

    const paramsModel = new ParamsModel(
      0, 5, undefined, 1, [{ field: 'stateId', order: 1 }, { field: 'createDate', order: -1 }], undefined, null, extraData);

    this._srvc.getCommunicationPage(paramsModel)
      .pipe(
        take(1),
        tap(result => {
          if (result && result.data && this._st.model) {
            this._st.model.communication = result.data;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          return of(null);
        })
      )
      .subscribe();
  }
}

import { Component, Input } from '@angular/core';

import { CommunicationChatDetailsModel } from '../models/mdp/communication-chat-details.model';
import { CommunicationModel } from '../models/mdp/communication.model';

@Component({
  selector: 'vrc-communication-chat-details',
  templateUrl: './vrc-communication-chat-details.component.html'
})
export class VrcCommunicationChatDetailsComponent {
  @Input() model: CommunicationChatDetailsModel | null=null;
  @Input() contactId!: number;
  @Input() communication: CommunicationModel | null = null;
}
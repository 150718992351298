import '@angular/common/locales/global/hr';

import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { environment } from 'src/environments/environment';

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ValDateInputMaskModule } from './common/modules/val-date-input-mask/val-date-input-mask.module';
import { ValDateInputModule } from './common/modules/val-date-input/val-date-input.module';
import { ValDropDownModule } from './common/modules/val-drop-down/val-drop-down.module';
import { ValModalModule } from './common/modules/val-modal/val-modal.module';
import { ValSelectBoxModule } from './common/modules/val-select-box/val-select-box.module';
import { ValTableScrollerModule } from './common/modules/val-table-scroller/val-table-scroller.module';
import { ValTextBoxModule } from './common/modules/val-text-box/val-text-box.module';
import { ValValidationModule } from './common/modules/val-validation/val-validation.module';
import { ValCommonModule } from './common/val-common.module';
import { VrcAccountComponent } from './components/vrc-account.component';
import { VrcCampaignsComponent } from './components/vrc-campaings.component';
import { VrcCommunicationCallDetailsComponent } from './components/vrc-communication-call-details.component';
import { VrcCommunicationChatDetailsComponent } from './components/vrc-communication-chat-details.component';
import { VrcCommunicationDfoDetailsComponent } from './components/vrc-communication-dfo-details.component';
import { VrcCommunicationEmailDetailsComponent } from './components/vrc-communication-email-details.component';
import { VrcCommunicationHistoryComponent } from './components/vrc-communication-history.component';
import { VrcCommunicationThreadDetailsComponent } from './components/vrc-communication-thread-details.component';
import { VrcCommunicationThreadMessagesContainerComponent } from "./components/vrc-communication-thread-messages-container.component";
import { VrcCommunicationThreadComponent } from './components/vrc-communication-thread.component';
import { VrcCommunicationUnknownDetailsComponent } from './components/vrc-communication-unknown-details.component';
import { VrcCommunicationComponent } from './components/vrc-communication.component';
import { VrcEmptyComponent } from './components/vrc-empty.component';
import { VrcInformationComponent } from './components/vrc-information.component';
import { VrcInterestsComponent } from './components/vrc-interests.component';
import { VrcLoginComponent } from './components/vrc-login.component';
import { VrcLoyalityDetailsComponent } from './components/vrc-loyality-details.component';
import { VrcLoyalityComponent } from './components/vrc-loyality.component';
import { VrcMainComponent } from './components/vrc-main.component';
import { VrcNiceLoginComponent } from './components/vrc-nice-login.component';
import { VrcOfferComponent } from './components/vrc-offer.component';
import { VrcOptionsComponent } from './components/vrc-options.component';
import { VrcReservationsComponent } from './components/vrc-reservations.component';
import { VrcScrollToTopComponent } from './components/vrc-scroll-to-top.component';
import { VrcSearchComponent } from './components/vrc-search.component';
import { VrcSummaryComponent } from './components/vrc-summary.component';
import { VrcValamartistDetailsComponent } from './components/vrc-valamartist-details.component';
import { VrcValamartistComponent } from './components/vrc-valamartist.component';
import { ValAuthInterceptor } from './http-interceptors/val-auth.interceptor';
import { ValCommonHeadersInterceptor } from './http-interceptors/val-common-headers.interceptor';
import { AttachmentIsSupportedPipe } from './pipes/attachment-is-supported.pipe';
import { ChatTextPipe } from './pipes/chatText.pipe';
import { CountryByCodePipe } from './pipes/country-by-code.pipe';
import { HasActiveOfferPipe } from './pipes/has-active-offer.pipe';
import { HasActiveReservationPipe } from './pipes/has-active-reservation.pipe';
import { HtmlSanitizerPipe } from './pipes/html-sanitizer.pipe';
import { InterestsByCategoryPipe } from './pipes/interests-by-category.pipe';
import { IsLoggedInPipe } from './pipes/is-logged-in.pipe';
import { IsSelectedLoyaltyProgramPipe } from './pipes/is-selected-loyalty-program.pipe';
import { LabelByKeyPipe } from './pipes/label-by-key.pipe';
import { LoyaltyIsExpiredPipe } from './pipes/loyalty-is-expired.pipe';
import { SafeUrlPipe } from './pipes/save-url.pipe';
import { SecondsToTimePeriodPipe } from './pipes/seconds-to-time-period.pipe';
import { SelectLoyaltyProgramPipe } from './pipes/select-loyalty-program.pipe';
import { SelectedInterestsPipe } from './pipes/selected-interests.pipe';
import { ValUtilsService } from './services/val-utils.service';
import { VrcAccountUtilsService } from './services/vrc-account-utils.service';
import { VrcBrowserStorageService } from './services/vrc-browser-storage.service';
import { VrcConfigService } from './services/vrc-config.service';
import { VrcDataService } from './services/vrc-data.service';
import { VrcStorageService } from './services/vrc-storage.service';
import { IsCommInStatusPipe } from './pipes/is-comm-in-status.pipe';
import { DialogModule } from 'primeng/dialog';
import { VrcAccountNotesComponent } from './components/vrc-account-notes/vrc-account-notes.component';
import { VrcAccountNoteComponent } from './components/vrc-account-notes/vrc-account-notes-subcomponents/vrc-account-note.component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'c47cdd37-7d72-4fec-99d1-f31ff8427769',
      authority: 'https://login.microsoftonline.com/c7223f2c-1ba2-43c8-be7f-57e6e1465036',
      redirectUri: environment.app_url,
      postLogoutRedirectUri: environment.app_url
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = getProtectedResourcesMap();

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: []
    },
    loginFailedRoute: '/login'
  };
}

const getProtectedResourcesMap = (): Map<string, Array<string>> => {
  const ret = new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]]);
  ret.set(environment.scheme + environment.host + environment.port + '/api/v' + environment.version , ['api://f2d1e384-7ca1-4f6a-b799-567cfe6b0e58/.default']);
  ret.set(environment.scheme + environment.crm_host + environment.port + '/api/v' + environment.crmVersion , ['api://f2d1e384-7ca1-4f6a-b799-567cfe6b0e58/.default']);
  ret.set(environment.scheme + environment.offer_host + environment.port + '/api/v' + environment.offerVersion , ['api://f2d1e384-7ca1-4f6a-b799-567cfe6b0e58/.default']);
  ret.set(environment.scheme + environment.auth_host + environment.port + '/api/v' + environment.authVersion , ['api://f2d1e384-7ca1-4f6a-b799-567cfe6b0e58/.default']);
  ret.set(environment.scheme + environment.validation_host + environment.port + '/api/v' + environment.validationVersion , ['api://f2d1e384-7ca1-4f6a-b799-567cfe6b0e58/.default']);
  return ret;
};

@NgModule({
  declarations: [
    AppComponent,
    VrcMainComponent,
    VrcSummaryComponent,
    VrcInformationComponent,
    VrcCommunicationHistoryComponent,
    VrcCommunicationThreadComponent,
    VrcCommunicationThreadDetailsComponent,
    VrcCommunicationThreadMessagesContainerComponent,
    VrcReservationsComponent,
    VrcCampaignsComponent,
    VrcLoyalityComponent,
    VrcLoyalityDetailsComponent,
    VrcInterestsComponent,
    SelectedInterestsPipe,
    InterestsByCategoryPipe,
    IsLoggedInPipe,
    SafeUrlPipe,
    CountryByCodePipe,
    LoyaltyIsExpiredPipe,
    VrcCommunicationComponent,
    VrcCommunicationUnknownDetailsComponent,
    HtmlSanitizerPipe,
    SecondsToTimePeriodPipe,
    LabelByKeyPipe,
    VrcLoginComponent,
    VrcOptionsComponent,
    AttachmentIsSupportedPipe,
    VrcCommunicationCallDetailsComponent,
    VrcCommunicationChatDetailsComponent,
    VrcCommunicationEmailDetailsComponent,
    HasActiveOfferPipe,
    HasActiveReservationPipe,
    VrcSearchComponent,
    VrcEmptyComponent,
    VrcAccountComponent,
    VrcOfferComponent,
    ChatTextPipe,
    VrcValamartistComponent,
    VrcValamartistDetailsComponent,
    IsSelectedLoyaltyProgramPipe,
    VrcValamartistDetailsComponent,
    VrcNiceLoginComponent,
    SelectLoyaltyProgramPipe,
    VrcScrollToTopComponent,
    VrcCommunicationDfoDetailsComponent,
    IsCommInStatusPipe,
	VrcAccountNotesComponent,
	VrcAccountNoteComponent
  ],
  imports: [
	DialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    ListboxModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    ToastModule,
    MenuModule,
    ConfirmDialogModule,
    TooltipModule,
    DynamicDialogModule,
    AngularEditorModule,
    CalendarModule,
    InputMaskModule,
    TriStateCheckboxModule,
    ValCommonModule,
    ValDateInputModule,
    ValTextBoxModule,
    ValSelectBoxModule,
    ValValidationModule,
    AppRoutingModule,
    ValDateInputMaskModule,
    ValModalModule,
    ValDropDownModule,
    ValTableScrollerModule,
    InfiniteScrollModule,
    MsalModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker, // environment.enableServiceWorker == false,
      registrationStrategy: 'registerWhenStable:30000' //'registerImmediately'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ValCommonHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ValAuthInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalInterceptor,
    MessageService,
    DialogService,
    DynamicDialogRef,
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'hr' },
    VrcConfigService,
    VrcStorageService,
    VrcBrowserStorageService,
    VrcDataService,
    ValUtilsService,
    VrcAccountUtilsService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class CreateLoyaltyModel {
    constructor(
        public emailAddress: string | null = null,
        public crmAccountId: string | null = null,
        public firstName: string | null = null,
        public lastName: string | null = null,
        public birthday: string | Date | null = null,
        public mobilePhone: string | null = null,
        public street: string | null = null,
        public postCode: string | null = null,
        public city: string | null = null,
        public gender: string | null = null,
        public language: string | null = null,
        public accountId: string | null = null,
    ) { }
}
<div class="row">
  <ng-container *ngFor="let key of object.keys(model)">
    <ng-container *ngIf="key.toLocaleLowerCase()!==exclude">
      <div class="col-12 border-bottom border-info-1 border-1 me-5 py-4">
        <label class="label-options text-nowrap">{{key | labelByKey:lookup}}:</label>
        <ng-container *ngIf="isEditOn">
          <div *ngFor="let v of dictionaryValues" class="form-check form-check-inline float-end">
            <input class="form-check-input" type="radio" [name]="key" [value]="v" [(ngModel)]="model[key]">
            <label class="form-check-label label-radio text-uppercase">{{v}}</label>
          </div>
        </ng-container>
        <ng-container *ngIf="!isEditOn">
          <label class="label-radio bg-secondary text-gray-700 text-nowrap text-uppercase float-end mt-2">
            {{model[key]}}
          </label>
          <i class="vi float-end me-3"
            [class.vi-radio-check]="model[key]==='Yes'"
            [class.vi-radio-minus]="model[key]==='Not specified'"
            [class.vi-radio-times]="model[key]==='No'">
          </i>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
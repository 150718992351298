<p class="small text-muted mb-3" [class.agent-note]="accountNote.editable">
	<i class="pi me-3" [class]="accountNote.editable ? 'pi-user-edit' : 'pi-user'"></i>
	{{accountNote.createdBy}}</p>
<div class="note-text rounded p-3 mb-2" [class.agent-note]="accountNote.editable" [class.editing] = "primaryButtonState == 'editing'" [class.loading] = "primaryButtonState == 'loading'">
	<p #contentContainer data-placeholder (input)="validateContent()" class="lh-sm mb-0" [contentEditable]="primaryButtonState == 'editing'" [innerText]="accountNote.content"></p>
</div>
<div class="d-flex">
	<p class="small text-muted ms-2">{{accountNote.createDate | date: 'd/M/yy, HH:mm'}}</p>
	<p *ngIf="accountNote.modifiedBy" class="small text-muted ms-5">Edited
		<i class="pi pi-info-circle" [escape]="false"
		[pTooltip]="('Edited by ' + accountNote.modifiedBy + '\<br>' +
					'Edited on ' + (accountNote.modifyDate | date: 'd/M/yy, HH:mm'))!"></i></p>
	<button *ngIf="accountNote.editable" [disabled]="primaryButtonState == 'disabled' || !validContent" class="btn btn-sm btn-primary ms-auto" type="button" (click)="notePrimaryButtonHandler()">
		<p *ngIf="primaryButtonState == 'normal'">Edit</p>
		<p *ngIf="primaryButtonState == 'editing' && validContent">Save</p>
		<i *ngIf="primaryButtonState == 'loading'" class="pi pi-spin pi-spinner"></i>
	</button>
	<button *ngIf="accountNote.editable" [disabled]="secondaryButtonState == 'disabled'" class="btn btn-sm btn-primary ms-3" type="button" style="width:60px" (click)="noteSecondaryButtonHandler()">
		<p *ngIf="secondaryButtonState == 'normal'">Delete</p>
		<p *ngIf="secondaryButtonState == 'editing'">Cancel</p>
		<i *ngIf="secondaryButtonState == 'loading'" class="pi pi-spin pi-spinner"></i>
	</button>
</div>
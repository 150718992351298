import { Component, Input, Renderer2 } from '@angular/core';

import { CommunicationDfoDetailsModel } from '../models/mdp/communication-dfo-details.model';
import { CommunicationEmailAttachmentResponseModel } from '../models/mdp/communication-email-attachment-response.model';
import { CommunicationEmailAttachmentRequestModel } from '../models/mdp/communication-email-attachment-request.model';
import { VrcStorageService } from '../services/vrc-storage.service';
import { VrcDataService } from '../services/vrc-data.service';
import { MessageService } from 'primeng/api';
import { ValUtilsService } from '../services/val-utils.service';
import { catchError, of, take, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationModel } from '../models/mdp/communication.model';

@Component({
  selector: 'vrc-communication-dfo-details',
  templateUrl: './vrc-communication-dfo-details.component.html'
})
export class VrcCommunicationDfoDetailsComponent {
  @Input() model: CommunicationDfoDetailsModel | null = null;
  @Input() contactId!: number;
  @Input() status!: string;
  @Input() communication: CommunicationModel | null = null;
  constructor(
    private _st: VrcStorageService,
    private _srvc: VrcDataService,
    private _ms: MessageService,
    private _u: ValUtilsService,
    private _rend: Renderer2,
  ) { }
  public processAttachment = (emailGuid: string | null, att: string, operation: 'open' | 'download', e: Event) => {
    e.preventDefault();
    if (emailGuid && this.status && this.contactId && att) {
      const contentType = this._getAttachmentContentType(att);
      const attachmentRequest =
        new CommunicationEmailAttachmentRequestModel(
          att, emailGuid, this.status, this.contactId
        );

      this._srvc.getEmailAttachment(attachmentRequest)
        .pipe(
          take(1),
          tap((result) => {
            if (operation === "open") {
              this._openAttachment(result, contentType);
            }
            else if (operation === "download") {
              this._downloadAttachment(result, contentType, att);
            }
          }),
          catchError((err: HttpErrorResponse) => {
            this._ms.add({
              severity: 'error',
              summary: 'Error',
              detail: err?.error?.message,
            });
            return of(null);
          })
        )
        .subscribe();
    }
    else {
      this._ms.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error: Cannot process attachment',
      });
    }
  }

  private _getAttachmentContentType = (attachmentName: string): string => {
    let contentType = 'application/octet-stream';

    attachmentName = attachmentName.trim().toLowerCase();

    if (attachmentName) {
      const extension = this._u.getExtension(attachmentName);

      // eslint-disable-next-line no-prototype-builtins
      if (extension && this._st.supportedToOpenContentTypes.hasOwnProperty(extension)) {
        contentType = this._st.supportedToOpenContentTypes[extension];
      }
    }

    return contentType;
  }

  private _openAttachment = (response: CommunicationEmailAttachmentResponseModel, contentType: string) => {
    if (response && response.base64Data && contentType) {
      try {
        const blob = this._u.convertBase64ToBlob(response.base64Data, contentType);
        const url = URL.createObjectURL(blob);

        window.open(url, '_blank', 'noopener');

        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 1000);
      } catch {
        this._ms.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error: Cannot open the file.',
        });
      }
    }
    else {
      this._ms.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error: Cannot open the file. Attachment and/or its content type is not defined.',
      });
    }
  }

  private _downloadAttachment = (response: CommunicationEmailAttachmentResponseModel, contentType: string, name: string) => {
    if (response && response.base64Data && contentType) {
      try {
        const blob = this._u.convertBase64ToBlob(response.base64Data, contentType);
        const url = URL.createObjectURL(blob);

        const link = this._rend.createElement('a');
        this._rend.setAttribute(link, 'download', name);
        this._rend.setAttribute(link, 'href', url);
        this._rend.setAttribute(link, 'target', '_blank');
        this._rend.appendChild(document.body, link);
        link.click();
        this._rend.removeChild(document.body, link)

        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 1000);
      } catch {
        this._ms.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error: Cannot download the file.',
        });
      }
    }
    else {
      this._ms.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error: Cannot download the file. Attachment and/or its content type is not defined.',
      });
    }
  }
}
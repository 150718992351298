import { Pipe, PipeTransform } from '@angular/core';

import { ValUtilsService } from '../services/val-utils.service';

@Pipe({ name: 'countryByCode' })
export class CountryByCodePipe implements PipeTransform {
    constructor(private _u: ValUtilsService) { }
    transform(code: string, lookup: Array<{ name: string, code: string | null  }>): string | null {
        return (lookup && this._u.isArray(lookup)) ?
            lookup.filter(x => x.code === code).map(x => x.name).reduceRight((_a: string | null, b) => b, null) : null;
    }
}

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';

@Injectable()
export class ValCommonHeadersInterceptor implements HttpInterceptor {
  constructor(
    private _bst: VrcBrowserStorageService
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let authReq: HttpRequest<any>;
      if (this._bst.subscription) {
        authReq = req.clone({
          headers: req.headers.set("Ocp-Apim-Subscription-Key", this._bst.subscription)
        });
      } else {
        authReq = req.clone();
      }
      if (authReq.headers.get('upload')) {
        authReq.headers.delete('upload');
      } 

      // send cloned request with header to the next handler.
      return next.handle(authReq);
  }
}

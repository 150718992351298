import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ValComponentBaseComponent } from '../common/modules/val-component-base/val-component-base.component';
import { VrcStorageService } from '../services/vrc-storage.service';
import { AccountModel } from '../models/mdp/account.model';

@Component({
  selector: 'vrc-information',
  templateUrl: './vrc-information.component.html'
})
export class VrcInformationComponent implements OnInit, OnDestroy, OnInit {
  @ViewChildren('v') private _validationFields!: QueryList<ValComponentBaseComponent>;
  private _sub!: Subscription;
  private _sub1!: Subscription;
  private _subEmail!: Subscription;
  private _subPhone!: Subscription;
  private _isValidated = false;
  public testDate!: Date;
  public objectTmp = Object;
  public isValidMail = true;
  public isValidPhone = true;
  public errorMessageEmail = "";
  public errorMessagePhone = "";
  public todayDate = new Date();

  constructor(
    public st: VrcStorageService,
  ) {
    if (!this.st.model) { this.st.model = new AccountModel(); }
  }
  ngOnDestroy(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
    if (this._sub1) {
      this._sub1.unsubscribe();
    }
    if (this._subEmail) {
      this._subEmail.unsubscribe();
    }
    if (this._subPhone) {
      this._subPhone.unsubscribe();
    }
  }
  public ngOnInit(): void {
    this._sub1 = this.st.validate.pipe(tap(() => {
      this.validate();
    })).subscribe();

    this._subEmail = this.st.validateEmail.pipe(tap(x => {
      this.errorMessageEmail = "";
      if (x.validateStatus !== "deliverable") {

        if (x.didYouMean) {
          this.errorMessageEmail += "Email is not valid, did you mean " + x.didYouMean + "? ";
        }
        else {
          this.errorMessageEmail = "Email address is not valid";
        }
        this.isValidMail = false;
      }
      else {
        this.isValidMail = true;
      }
      this.validMailReq();
    })).subscribe();

    this._subPhone = this.st.validatePhone.pipe(tap(x => {
      this.isValidPhone = x;
    })).subscribe();

    this._sub = this.st.resetValidation.pipe(tap(() => {
      if (this._validationFields) {
        this._validationFields.forEach(x => {
          x.isValidated = false;
        });
      }
    })).subscribe();
    if (!this._isValidated) {
      this.validate();
    }
  }
  public addEmail = () => {
    this.st.emails.push({ id: 0, data: '' });
  }
  public addPhone = () => {
    const p = { id: 0, data: '' };
    this.st.phones.push(p);
  }
  public deleteAdditionalPhone = (m: { id: number; data: string | null }) => {
    const index = this.st.phones.indexOf(m);
    this.st.phones.splice(index, 1);
  }
  public deleteAdditionalEmail = (m: { id: number; data: string | null }) => {
    const index = this.st.emails.indexOf(m);
    this.st.emails.splice(index, 1);
  }

  // INFO: validateBirthDate method is currently not used anywhere
  public validateBirthDate = (birthDate: Date | string): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!birthDate) { return false; }
    // eslint-disable-next-line no-constant-condition
    return (new Date(birthDate)) ? true : false;
  }
  public validateTel = (tel: string): boolean => {
    const re = /^\+[1-9]\d{1,14}$/;
    if (!tel && this.st.isCreateLoyalty) {
      this.errorMessagePhone = "Phone is required"
      return false;
    }
    else if (tel && !re.test(tel)) {
      this.errorMessagePhone = "Phone number is invalid, format is like +38598123456"
      return false;
    }
    else if (!this.isValidPhone) {
      if (!tel) {
        this.errorMessagePhone = "Phone is required"
      }
      else {
        this.errorMessagePhone = "Phone number is not valid"
      }
      return false;
    }
    else {
      if (!(this.st.model?.contact?.email || this.st.model?.contact?.phone)) {
        this.errorMessagePhone = "Email or Phone is required"
        return false;
      }
    }


    // let re: RegExp;
    // // re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    // re = /^\+[1-9]\d{1,14}$/;
    // return re.test(tel);
    return true;
  }
  public validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (!email && this.st.isCreateLoyalty) {
      this.errorMessageEmail = "Email is required"
      return false;
    }
    else if (email && !re.test(email)) {
      this.errorMessageEmail = "Email is not in good format"
      return false;
    }
    else if (!this.isValidMail) {
      return false;
    }
    else {
      // if (this.st.isCreateLoyalty) { return true; }
      if (!(this.st.model?.contact?.email || this.st.model?.contact?.phone)) {
        this.errorMessageEmail = "Email or Phone is required"
        return false;
      }
    }
    return true;
  }
  // public validateEmailAndPhone = (v: string): boolean => {
  //   if (this.st.isCreateLoyalty) { return true; }
  //   if (!(this.st.model!.contact.email || this.st.model!.contact.phone)) { return false; }
  //   return true;
  // }
  public validateCountry = (country: string): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!country) { return false; }
    return true;
  }
  public validateFirstName = (firstName: string): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!firstName) { return false; }
    return true;
  }
  public validateLastName = (lastName: string): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!lastName) { return false; }
    return true;

  }
  public validateLanguage = (language: string): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!language) { return false; }
    return true;
  }
  public validateGender = (gender: string): boolean => {
    if (!this.st.isCreateLoyalty) { return true; }
    if (!gender) { return false; }
    return true;
  }
  public validate = () => {
    let valid = true;
    if (this._validationFields) {
      this._validationFields.forEach(x => {
        const v = x.validate();
        if (!v) {
          valid = false;
        }
      });
    }
    return valid;
  }

  public onProductModelChange = () => {
    let newsLetterValue: 'Yes' | 'No' | 'Not specified' = 'Not specified';

    const array = Object.values(this.st.model?.products ?? []);

    for (let index = 0; index < array.length; index++) {
      const value = array[index];
      // if any are true, we break immidiatley, we don't care abotu the rest  
      if (value === 'Yes') {
        newsLetterValue = 'Yes';
        break;
      }
      // we don't break in case of false, because if one is true after it, it take higher priority
      if (value === 'No') {
        newsLetterValue = 'No';
      }
    }
    if (this.st.model?.advertisingPermissions) {
      this.st.model.advertisingPermissions['email'] = newsLetterValue;
    }
  }

  public validMailReq = (): boolean => {
    if (!this.isValidMail) { return false; }
    return true;
  }

  public validPhoneReq = (): boolean => {
    if (!this.isValidPhone) { return false; }
    return true;
  }

  emailChange() {
    this.st.validateEmailChange.emit();
  }

  phoneChange() {
    this.st.validatePhoneChange.emit();
  }

}


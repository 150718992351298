import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondsToTimePeriod' })
export class SecondsToTimePeriodPipe implements PipeTransform {

  transform(seconds: number) {
    const hours = Math.floor(seconds / 3600),
      minutes = Math.floor(seconds % 3600 / 60),
      sesonds = Math.floor(seconds % 60);

    if (hours === 0) {
      return minutes.toString().padStart(2, '0') +
        ':' +
        sesonds.toString().padStart(2, '0');
    }
    else {
      return hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        sesonds.toString().padStart(2, '0');
    }
  }
}
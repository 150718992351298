import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValModalBackdropComponent } from './val-modal-backdrop.component';
import { ValModalTemplateComponent } from './val-modal-template.component';
import { ValModalDirective } from './val-modal.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        ValModalDirective,
        ValModalTemplateComponent,
        ValModalBackdropComponent,
    ],
    exports: [
        ValModalDirective
    ],
    providers: []
})
export class ValModalModule { }

<div class="d-flex" tabindex="-1" >
  <p-inputMask #input [disabled]="disabled" [(ngModel)]="model" [mask]="mask" [size]="100" placeholder="DD.MM.YYYY"
    [styleClass]="'form-control vrc-inner-control border-0 m-0 p-0'"
    (onFocus)="focus(true)" (onBlur)="focus(false);focusOut();" (click)="inputClick($event)"></p-inputMask>
  <button *ngIf="showButton && !disabled" class="btn vrc-calendar" type="button">
    <i class="vi vi-calendar"></i>
  </button>
</div>
<ng-template [valDropDown]="rootElement" [(valddVisible)]="ddVisible" [valddDisabled]="disabled">
  <val-date-picker [disabledDays]="disabledDays" [returnUtcValue]="returnUtcValue" [min]="min" [max]="max" [firstDayOfTheWeek]="1" (valueChange)="setDate($event)" [startYear]="startYear"
    [endYear]="endYear" [value]="value"></val-date-picker>
</ng-template>
<ng-content></ng-content>
<div class="row pb-5 bg-secondary pt-3">
  <div class="col-12">
    <val-table-scroller>
      <p-table [value]="communication" dataKey="contactId" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [paginator]="true"
        [multiSortMeta]="[{field: 'status', order: 1}, {field: 'date', order: -1}]"
        currentPageReportTemplate="{first}-{last} OF {totalRecords}" [filterDelay]="0" [lazy]="true"
        (onLazyLoad)="tableLazyLoad($event)" [totalRecords]="totalRecords" sortMode="multiple" [resizableColumns]="true"
        [reorderableColumns]="true" columnResizeMode="expand" [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <th pResizableColumn pSortableColumn="channel">
              Channel
              <p-sortIcon field="channel"></p-sortIcon>
              <p-columnFilter type="text" field="channel" display="menu"></p-columnFilter>
            </th>
            <th pResizableColumn pSortableColumn="date">
              Date
              <p-sortIcon field="date"></p-sortIcon>
              <p-columnFilter type="date" field="date" display="menu">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value"
                    (ngModelChange)="filterCallback($event)" dateFormat="dd.mm.yy" [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th pResizableColumn pSortableColumn="subject">
              Subject
              <p-sortIcon field="subject"></p-sortIcon>
              <p-columnFilter type="text" field="subject" display="menu">
              </p-columnFilter>
            </th>
            <th style="width: 2rem" pResizableColumn pSortableColumn>
              Status
              <p-sortIcon field="status"></p-sortIcon>
              <p-columnFilter type="text" field="status" display="menu"></p-columnFilter>
            </th>
            <th pResizableColumn pSortableColumn="agent">
              Agent
              <p-sortIcon field="agent"></p-sortIcon>
              <p-columnFilter type="text" field="agent" display="menu"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-comm>
          <tr>
            <td>
              <button
                (click)="comm.channel.toLowerCase()==='email'?
                openEmailDetails(comm):comm.channel.toLowerCase()==='chat'?
                openChatDetails(comm):comm.channel.toLowerCase()==='voice'?
                openVoiceDetails(comm):null"
                class="btn btn-sm btn-secondary btn-popup">
                {{ comm.channel }}
                <i class="vi vi-popup ms-3"></i>
              </button>
            </td>
            <td>{{ comm.date | date:'shortDate' }}</td>
            <td>
              <span>{{ comm.subject }}</span>
            </td>
            <td>
              <span class="badge badge-status text-gray-700 text-uppercase"
                [class.bg-success-1]="comm.status==='Active'"
                [class.bg-info]="comm.status==='Open'"
                [class.bg-gray-50]="comm.status==='Parked'"
                [class.bg-danger-1]="comm.status==='Closed'">
                <i class="vi vi-rectangle me-3"></i>
                {{comm.status}}
              </span>
            </td>
            <td>
              <span>{{ comm.agent }}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">No communication found</td>
          </tr>
        </ng-template>
      </p-table>
    </val-table-scroller>
  </div>
</div>

<ng-template valModal="xl" [(valmVisible)]="emailModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Email details
    </h6>
    <button type="button" class="btn-close" (click)="emailModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-email-details [model]="emailDetails" [status]="selectedCommunication.status??''" [contactId]="selectedCommunication.contactId??0"></vrc-communication-email-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="emailModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="chatModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Chat details
    </h6>
    <button type="button" class="btn-close" (click)="chatModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-chat-details [model]="chatDetails" [contactId]="selectedCommunication.contactId??0"></vrc-communication-chat-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="chatModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="voiceModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Call details
    </h6>
    <button type="button" class="btn-close" (click)="voiceModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-call-details [model]="callDetails" [contactId]="selectedCommunication.contactId??0" [id]="selectedCommunication.id??''"></vrc-communication-call-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="voiceModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>
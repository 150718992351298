import { ValComponent } from '../../interfaces/val-component.interface';
import { Component, ContentChild, ElementRef, EventEmitter, HostBinding, HostListener, Input, ViewChild } from '@angular/core';

import { ValValidationComponent } from '../val-validation/val-validation.component';
import { ValValidator } from '../../interfaces/val-validator.interface';

@Component({
	selector: 'val-component-base',
	template: '',
})
export class ValComponentBaseComponent implements ValValidator, ValComponent {
	@ContentChild(ValValidationComponent, { static: true }) protected _validator!: ValValidationComponent;
	@ViewChild('input', { static: true }) protected _inputEl!: ElementRef;
	protected _applied = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected _v: any;
	protected _css = "form-control";
	protected __isFocused = false;
	protected _isValidated = false;
	protected _isRequired = false;
	protected _isValid = false;
	protected _disabled = false;

	// to stop loop change
	protected set _isFocused(x: boolean) {
		this.__isFocused = x;
		this._setCss();
	}
	protected get _isFocused() {
		return this.__isFocused;
	}
	public set isValid(x: boolean) {
		this._isValid = x;
		this._setCss();
	}
	public get isValid() {
		return this._isValid;
	}
	public set isValidated(x: boolean) {
		this._isValidated = x;
		if (!this.isValidated) {
			this._isValid = true;
			this.resetValidation();
		}
		this._setCss();
	}
	public get isValidated() {
		return this._isValidated;
	}
	public styleChange = new EventEmitter<void>();
	@Input() set required(x: boolean) {
		this._isRequired = x;
		this._setCss();
	}
	get required() {
		return this._isRequired;
	}
	@HostBinding('class') get cssClass() {
		return this._css;
	}
	@Input() set disabled(x: boolean) {
		this._disabled = x;
		this._setCss();
	}
	public get disabled() {
		return this._disabled;
	}

	@Input() showValidationIfEmpty = true;
	@Input() size: 'lg' | 'sm' | null = null;
	@Input() valueChangeEvent: 'change' | 'blur' | 'none' = 'change';
	@Input() setFocus = false;
	@Input() setFocusOnValidationError = false;
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	apply(): void { }
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
	public click(e: any) {
		if (!this._isFocused) {
			this._inputEl.nativeElement.focus();
		}
	}
	@HostListener('mousedown', ['$event'])
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public mousedown(e: any) {
		e.stopPropagation();
	}
	@HostListener('mouseup', ['$event'])
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public mouseup(e: any) {
		e.stopPropagation();
	}
	public validate(): boolean {
		if (!this._validator) { return true; }

		this.isValid = this._validator.validate(this._v, this._isFocused);
		if (!this.isValid && this.setFocusOnValidationError) {
			this._inputEl.nativeElement.focus();
		}
		this.isValidated = true;
		return this.isValid;
	}
	public focus = (e: boolean) => {
		if (this._disabled) { return; }
		this._isFocused = e;
		if (this._validator) {
			this._validator.hasFocus = e;
		}
	}
	public resetValidation() {
		if (!this._validator) { return; }
		this._isValidated = false;
		this._validator.reset();
	}
	private _setCss = () => {
		setTimeout(() => {
			this._css = (this.__isFocused ?
				this._isValidated ?
					this._isValid ?
						!this._v && !this.showValidationIfEmpty ?
							'form-control vrc-control component-focus' :
							'form-control vrc-control component-focus is-valid' :
						'form-control vrc-control position-relative component-focus is-invalid' :
					'form-control vrc-control component-focus' + (this._isRequired ? ' is-required' : '') :
				this._isValidated ?
					this._isValid ?
						!this._v && !this.showValidationIfEmpty ?
							'form-control vrc-control' :
							'form-control vrc-control is-valid' :
						'form-control vrc-control position-relative is-invalid' :
					'form-control vrc-control' + (this._isRequired ? ' is-required' : '')) + (this.disabled ? (this._isValid ? ' disabled disabled-border' : ' disabled') : '');
			this.styleChange.emit();
		});
	}
}

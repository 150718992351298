import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'val-validator',
  template: '{{msg}}',
})
export class ValValidatorComponent implements OnInit {

  private _cssClass!: string;
  private _cssStyle!: string;
  private _msg: string | null = null;
  public msg: string | null = null;
  public _hidden = false;
  @HostBinding("class") get _class() { return this._cssClass; }
  @HostBinding("style.display") get _style() { return this._cssStyle; }

  ngOnInit(): void {
    this._cssClass = "invalid-tooltip";
    this._cssStyle = this._hidden ? "none" : "block";
    this.msg = this._msg;
  }
  public set message(x: string | null) {
    this._msg = x;
  }
  public get message(): string | null {
    return this._msg;
  }
  public set hidden(x: boolean) {
    this._hidden = x;
    this._cssStyle = this._hidden ? "none" : "block";
  }
  public get hidden() {
    return this._hidden;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ValDatePickerModule } from '../val-date-picker/val-date-picker.module';
import { ValDropDownModule } from '../val-drop-down/val-drop-down.module';
import { ValDateInputComponent } from './val-date-input.component';

@NgModule({
    imports: [
        CommonModule, 
        FormsModule,
        ValDatePickerModule,
        ValDropDownModule
    ],
    declarations: [
        ValDateInputComponent
    ],
    exports: [ValDateInputComponent],
    providers: []
})
export class ValDateInputModule { }

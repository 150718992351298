import { Pipe, PipeTransform } from '@angular/core';

import { ValUtilsService } from '../../services/val-utils.service';

@Pipe({ name: 'anyById' })
export class AnyByIdPipe implements PipeTransform {
    constructor(private _u: ValUtilsService) { }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(id: any, lookup: Array<any>, field = 'name'): any {
        const tmp = lookup && this._u.isArray(lookup) ? lookup.find(x => x.id === id || x.Id === id) : null;

        return tmp ? tmp[field] : '';
    }
}

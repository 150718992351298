import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable()
export class VrcConfigService {
	private getVrcHostUrl = () => {
		const url = environment.scheme +
		  environment.host +
		  environment.port +
		  '/';
		return url;
	  };  
  private getVrcBaseUrl = () => {
    const url = environment.scheme +
      environment.host +
      environment.port +
      '/api/v' +
      environment.version +
      '/VrcApi/';
    return url;
  };  
  private getCrmBaseUrl = () => {
    const url = environment.scheme +
      environment.crm_host +
      environment.port +
      '/api/v' +
      environment.crmVersion +
      '/CrmMdpApi/';
    return url;
  };
  private getValidBaseUrl = () => {
    const url = environment.scheme +
      environment.validation_host +
      environment.port +
      '/api/v' +
      environment.validationVersion +
      '/Validation/';
    return url;
  };
  private getOfferBaseUrl = () => {
    const url = environment.scheme +
      environment.offer_host +
      environment.port +
      '/api/v' +
      environment.offerVersion +
      '/VrcOffer/';
    return url;
  };
  
  private readonly _getBaseUrlAuth =
    environment.scheme +
    environment.auth_host +
    environment.port +
    '/api/v' +
    environment.authVersion +
    '/VrcApi/';

  public readonly getTokenUrl = this._getBaseUrlAuth + 'getToken';
  public readonly refreshTokenUrl = this.getVrcBaseUrl() + 'renewToken';
  public readonly getUserTokenUrl = this._getBaseUrlAuth + 'getUserToken';
  public readonly getSubscriptionUrl = this._getBaseUrlAuth + 'subscriptionkey';
  public readonly accountUrl = this.getVrcBaseUrl() + 'account';
  public readonly accountAndLoyaltyUrl = this.getVrcBaseUrl() + 'account/accountandloyalty';
  public readonly accountByIdsUrl = this.getVrcBaseUrl() + 'account/allByIds';
  public readonly accountByIdUrl = this.getVrcBaseUrl() + 'account/byId';
  public readonly accountByEmailUrl = this.getVrcBaseUrl() + 'account/byEmail';
  public readonly accountByVoucherCodeUrl = this.getVrcBaseUrl() + 'account/byVoucherCode';
  public readonly accountByLoyaltiesEmailUrl = this.getVrcBaseUrl() + 'account/byLoyaltiesEmail';
  public readonly accountNoteUrl = this.getVrcHostUrl() + 'api/AccountNotes';
  public readonly offerUrl = this.getVrcBaseUrl() + 'offer';
  public readonly reservationUrl = this.getVrcBaseUrl() + 'reservation';
  public readonly voucherUrl = this.getVrcBaseUrl() + 'voucher';
  public readonly loyaltyUrl = this.getVrcBaseUrl() + 'loyalty';
  public readonly interestUrl = this.getVrcBaseUrl() + 'interest';
  public readonly advertisingPermissionsUrl = this.getVrcBaseUrl() + 'advertisingPermissions';
  public readonly productsUrl = this.getVrcBaseUrl() + 'products';
  public readonly familyStatusUrl = this.getVrcBaseUrl() + 'familyStatus';
  public readonly getOfferUrl = this.getOfferBaseUrl() + 'getOffer';
  public readonly cancelOfferUrl = this.getOfferBaseUrl() + 'cancelOffer';
  public readonly confirmOfferUrl = this.getOfferBaseUrl() + 'confirmOffer';
  public readonly getWebOfferLinkUrl = this.getOfferBaseUrl() + 'getWebOfferLink';
  public readonly offerEmailUrl = this.getVrcBaseUrl() + 'communication/offerEmail';
  public readonly getTransferPointsUrl = this.getCrmBaseUrl() + 'transferPoints';
  public readonly getClaimPointsUrl = this.getCrmBaseUrl() + 'claimPoints';
  public readonly resendLoyaltyConfirmationUrl = this.getCrmBaseUrl() + 'resendLoyaltyConfirmation';
  public readonly loyaltyForgottenPasswordUrl = this.getCrmBaseUrl() + 'loyaltyForgottenPassword';
  public readonly loyaltyNewEmailUrl = this.getCrmBaseUrl() + 'loyaltyEmailChange';
  public readonly communicationUrl = this.getVrcBaseUrl() + 'communication';
  public readonly communicationHistoryUrl = this.getVrcBaseUrl() + 'communicationHistory';
  public readonly getRetrieveHierarchyForEmployee = this.getCrmBaseUrl();
  public readonly valamarProfileNewTabUrl  = environment.app_url;
  public readonly getValidOfferUrl = this.getVrcBaseUrl() + 'getOffer/validOffer';
  public readonly validateEmail = this.getValidBaseUrl() + 'validateEmail'
  public readonly validatePhone = this.getValidBaseUrl() + 'validatePhone'
}

import { Pipe, PipeTransform } from '@angular/core';

import { ValUtilsService } from '../services/val-utils.service';

@Pipe({ name: 'attachmentIsSupported' })
export class AttachmentIsSupportedPipe implements PipeTransform {
    constructor(private _u: ValUtilsService) { }
    transform(name: string, supportedTypes: { [extension: string]: string }): boolean {
        const extension = this._u.getExtension(name);

        // eslint-disable-next-line no-prototype-builtins
        return !(!extension) && supportedTypes.hasOwnProperty(extension);
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FilterMetadata, MessageService } from 'primeng/api';
import { of, timer } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { PromotionPropertyModel } from '../models/mdp/promotion-property.model';
import { ParamsModel } from '../models/params.model';
import { CampaignModel } from './../models/mdp/campaign.model';
import { VoucherModel } from './../models/mdp/voucher.model';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { PromotionPropertyRoomTypeModel } from '../models/mdp/promotion-property-room-type.model';
import { ValUtilsService } from '../services/val-utils.service';

@Component({
  selector: 'vrc-campaigns',
  templateUrl: './vrc-campaigns.component.html'
})
export class VrcCampaignsComponent implements OnInit {
  private _accountId!: string;
  public vouchersTotalRecords = 0;
  public vouchersLoading!: boolean;
  public object = Object;
  public vouchers: VoucherModel[] = [];

  // intellisense helpers
  public campaign!: CampaignModel;
  public voucher!: VoucherModel;

  public selectedProperty: PromotionPropertyModel | null = null

  constructor(
    private _srvc: VrcDataService,
    private _ms: MessageService,
    public st: VrcStorageService,
    private _u: ValUtilsService
  ) { }
  public ngOnInit(): void {
    this._accountId = this.st.model?.id ?? '';
    this.vouchersLoading = true;
  }

  public vouchersTableLazyLoad = (p: ParamsModel): void => {
    p.filters['recipientAccountId'] =
      [{
        value: this._accountId,
        matchMode: "equals",
        operator: "and"
      }];
    if (!this._accountId) {
      // ExpressionChangedAfterItHasBeenCheckedError fix
      timer(0).pipe(take(1), tap(() => this.vouchersLoading = false)).subscribe();
      return;
    }
    const pCopy = this._u.deepCopyObject(p);
    if (pCopy.filters['expiryDate']) {
      for (const x of (<FilterMetadata[]>pCopy.filters['expiryDate'])) {
        x.value = this._u.getDateAsIsoString(x.value);
      }
    }
    if (pCopy.filters['promotion.usageRules.bookingDateFrom']) {
      for (const x of (<FilterMetadata[]>pCopy.filters['promotion.usageRules.bookingDateFrom'])) {
        x.value = this._u.getDateAsIsoString(x.value);
      }
    }
    if (pCopy.filters['promotion.usageRules.bookingDateTo']) {
      for (const x of (<FilterMetadata[]>pCopy.filters['promotion.usageRules.bookingDateTo'])) {
        x.value = this._u.getDateAsIsoString(x.value);
      }
    }
    this.getVouchers(pCopy);
  }
  public getVouchers = (p: ParamsModel): void => {
    this.vouchersLoading = true;
    this._srvc.getVoucherPage(p).pipe(
      take(1),
      tap(x => {
        this.vouchersTotalRecords = x.totalRecords;
        this.vouchers = x.data.map(this.getUniqueVouchers);
        this.vouchersLoading = false;
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }

  /**
   * Filters properties based on name
   * and adds all other roomTypes into filtered item
   */
  public getUniqueVouchers = (vouchers: VoucherModel): VoucherModel => {
    const mapToDefaultRoomName = (roomType: PromotionPropertyRoomTypeModel) => ({ ...roomType, name: roomType.name || '-' });

    const uniqueProperties = vouchers.promotion?.properties.reduce((resultArray: Array<PromotionPropertyModel>, curr) => {
      const alreadyExists = resultArray.find((el: PromotionPropertyModel) => {
        return el.name === curr.name
      })
      if (alreadyExists) {
        return resultArray.map(item => {
          if (item.name === alreadyExists.name) {
            return {
              ...item,
              roomTypes: [...item.roomTypes, ...curr.roomTypes]
            }
          }
          return item
        })
      }

      return [...resultArray, curr]
    }, []) ?? [];

    return {
      ...vouchers,
      promotion: vouchers.promotion ? {
        ...vouchers.promotion,
        properties: uniqueProperties.map(property => ({
          ...property,
          roomTypes: property.roomTypes.map(mapToDefaultRoomName)
        }))
      } : null
    }
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { OfferEmailModel } from '../models/mdp/offer-email.model';
import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';

import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { ValidOffer } from '../models/mdp/valid-offer.model';
import { OfferValidationResponseModel } from '../models/mdp/offer-validation-response.model';
import { SendOfferModel } from '../models/mdp/send-offer.model';

@Component({
  selector: 'vrc-offer',
  templateUrl: './vrc-offer.component.html'
})
export class VrcOfferComponent implements OnInit {
  private lastTime = new Date();
  private active = false;
  private text: any;
  private textDelayMs = 500;
  private offerId = '';
  private accountId = '';
  private origBodyTag = '';
  private html2insertCopy: string | null = null;
  public outputTest = "";
  public validationResult: OfferValidationResponseModel | null = null;
  public validationModalVisible = false;
  private validOffer!: ValidOffer;
  public editorConfig = {
    editable: false,
    spellcheck: false,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  } as AngularEditorConfig;
  constructor(
    public st: VrcStorageService,
    public _bst: VrcBrowserStorageService,
    private _r: Router,
    private _srvc: VrcDataService,
    private _ar: ActivatedRoute,
    private _ms: MessageService,
  ) {
    if (!this.st.offer) { this.st.offer = new SendOfferModel(); }
  }

  ngOnInit(): void {
    this.offerId = this._ar.snapshot.params['id'];
    this.accountId = this._ar.snapshot.params['accountId'];
    this.origBodyTag = this.getOrigBodyTag(this.st.offer?.html2insert ?? '');
    this.html2insertCopy = this.st.offer?.html2insert ?? null;
  }

  public close = () => {
    if (this.accountId) {
      this._r.navigate(['main/account/' + this.accountId]);
    } else {
      this._r.navigate(['main']);
    }
  }

  public cancel = () => {
    this._ms.add({ severity: 'info', summary: 'Cancelling', detail: 'Cancelling offer' });
    this._srvc.cancelOffer(this.offerId).pipe(
      take(1),
      tap(x => {
        this.st.offer = x;
        this.offerId = '';
        this._ms.clear();
        this.close();
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }

  public send = () => {
    // if (!this._bst.agentId || !this._bst.contactId || !this._bst.skillId) { return; }
    this._ms.add({ severity: 'info', summary: 'Sending', detail: 'Sending offer' });
    const offer: OfferEmailModel = new OfferEmailModel(
      this.st.model?.id,
      this._bst.agentId,
      this.st.contactId,
      this.st.offer?.mailsubject,
      this.st.offer?.html2insert ? this.fixEmail(this.st.offer.html2insert) : null,
      [],
      this.st.offer?.guestemailaddress,
      this.st.offer?.text2agent,
      this.st.offer?.status,
      this.st.skillId,
      this.offerId
    );
    this._srvc.sendOffer(offer).pipe(
      take(1),
      tap(() => {
        this.offerId = '';
        this._ms.clear();
        this.close();
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }
  private fixEmail = (email: string): string => {
    if (email.indexOf("<body") > 0) { return email; }
    const startIndexStyle = email.lastIndexOf("</style>");
    const endIndexStayle = startIndexStyle + 9;
    email = email.substring(0, endIndexStayle) + '</head>' + this.origBodyTag + email.substring(endIndexStayle, email.length - 1);
    email = '<!DOCTYPE html><html lang="en" xmlns="https://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"><head>' + email;
    email += '</body></html>';
    return email;
  }
  private getOrigBodyTag = (email: string): string => {
    const startIndex = email.indexOf("<body");
    const endIndex = email.indexOf(">", startIndex);
    return email.substring(startIndex, endIndex + 1);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public insertTextinHTML = (e: any) => {
    this.text = e.target.value;
    this.lastTime = new Date();

    if (!this.active) {
      this.active = true;
      this.insertTextinHTMLHelper();
    }
  }

  private insertTextinHTMLHelper() {
    if (new Date().getTime()- this.lastTime.getTime() < this.textDelayMs) {
      setTimeout(() => {this.insertTextinHTMLHelper()}, this.textDelayMs);
    }

    else if (this.st.offer) {
      this.st.offer.html2insert = this.html2insertCopy;
      if (this.st.offer.html2insert) {
        const startIndex = this.st.offer.html2insert.indexOf(`</span>`) + 7;
        const text = this.text.replaceAll("\n", "<br>");
        this.st.offer.html2insert = this.st.offer.html2insert.substring(0, startIndex).concat("<br><br><p>" + text + "</p><br>").concat(this.st.offer?.html2insert.substring(startIndex, this.st.offer?.html2insert.length));
      }
      this.active = false;
    }
  }

  public checkOffer = () => {
    this.validOffer = new ValidOffer();
    if (this.st.offer) {
      this.validOffer.email = this.st.offer.guestemailaddress;
    }
    this.validOffer.offerId = this.offerId;
    this.validOffer.accountId = this.accountId;
    this._srvc.validOffer(this.validOffer).pipe(
      take(1),
      tap(x => {
        this.validationResult = x;
        if (this.validationResult.isValid) {
          this.send();
        }
        else {
          this.validationModalVisible = true;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }
}



import { Pipe, PipeTransform } from '@angular/core';

import { ValUtilsService } from '../services/val-utils.service';

@Pipe({ name: 'loyaltyIsExpired' })
export class LoyaltyIsExpiredPipe implements PipeTransform {
    constructor(private _u: ValUtilsService) { }
    transform(expirationDate: string | Date): boolean {
        const d = new Date();
        const d1 = this._u.getDate(expirationDate);
        return d1 ? d1 < d : true;
    }
}

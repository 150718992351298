import { delay, tap } from 'rxjs/operators';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChild, HostListener, OnDestroy } from '@angular/core';
import { Table } from 'primeng/table';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'val-table-scroller',
  templateUrl: './val-table-scroller.component.html'
})
export class ValTableScrollerComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  @ContentChild(Table) table!: Table;
  private _sub!: Subscription;
  private _subScroll!: Subscription;
  public visible = true;
  constructor(
    private _cd: ChangeDetectorRef
  ) { }
  ngOnDestroy(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    if (this.table && this.table.wrapperViewChild) {
      if (this.table.el.nativeElement.offsetWidth === this.table.wrapperViewChild.nativeElement.scrollWidth) {
        setTimeout(() => {
          this.visible = false;
        });
      } else {
        setTimeout(() => {
          this.visible = true;
        });
      }
    }
  }
  ngAfterContentInit(): void {
    if (this.table) {
      this.table.onColResize.pipe(tap(() => {
        const oldValue = this.visible;
        if (this.table.el.nativeElement.offsetWidth === this.table.wrapperViewChild.nativeElement.scrollWidth) {
          this.visible = false;
        } else {
          this.visible = true;
        }
        if (oldValue != this.visible) {
          this._cd.detectChanges();
        }
      })).subscribe();
    }
  }
  @HostListener('window:resize')
  public onGlobalResize() {
    const oldValue = this.visible;
    if (this.table.el.nativeElement.offsetWidth === this.table.wrapperViewChild.nativeElement.scrollWidth) {
      this.visible = false;
    } else {
      this.visible = true;
    }
    if (oldValue != this.visible) {
      this._cd.detectChanges();
    }
  }

  public leftDown = (e: MouseEvent) => {
    e.preventDefault();
    this._scrollLeft();
    this._subScroll = interval(100).pipe(
      delay(500),
      tap(() => {
        this._scrollLeft();
      })).subscribe()
  }

  public rightDown = (e: MouseEvent) => {
    e.preventDefault();
    this._scrollRight();
    this._subScroll = interval(100).pipe(
      delay(500),
      tap(() => {
        this._scrollRight();
      })).subscribe()
  }

  public scrollUp = (e: MouseEvent) => {
    e.preventDefault();
    if (this._subScroll) {
      this._subScroll.unsubscribe();
    }
  }

  private _scrollLeft = () => {
    let lastScroll = this.table.wrapperViewChild.nativeElement.scrollLeft;
    if (lastScroll > 0) {
      lastScroll -= 40;
    } else { lastScroll = 0; }
    this.table.scrollTo({ left: lastScroll, behavior: 'smooth' });
  }

  private _scrollRight = () => {
    let lastScroll = this.table.wrapperViewChild.nativeElement.scrollLeft;
    const scrollWidth = this.table.wrapperViewChild.nativeElement.scrollWidth;
    const clientWidth = this.table.el.nativeElement.offsetWidth;
    if (scrollWidth - clientWidth > lastScroll) {
      lastScroll += 40;
    }
    this.table.scrollTo({ left: lastScroll, behavior: 'smooth' });
  }
}

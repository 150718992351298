import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { AnyByIdPipe } from './pipes/any-by-id.pipe';
import { DeletedPipe } from './pipes/deleted.pipe';
import { VrcClipboardDirective } from './directives/vrc-clipboard.directive';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
        AnyByIdPipe,
        DeletedPipe,
        VrcClipboardDirective,
    ],
    exports: [
        AnyByIdPipe,
        DeletedPipe,
        VrcClipboardDirective,
    ],
    providers: []
})
export class ValCommonModule { }

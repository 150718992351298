
<div class="row vrc-login-row">
  <div class="col-12 vrc-login-padding">
    <div class="text-start fw-bolder text-uppercase mb-4 text-secondary vrc-login-title">Logging in</div>
    <div class="card vrc-login">
      <div class="text-center vrc-login-top">
        <a class="nav-link"><i class="vi vi-logo"></i></a>
        <div class="w-100"></div>
        <i class="vi vi-logo-title"></i>
      </div>
      <div class="card-body bg-secondary p-5">
      </div>
    </div>
  </div>
</div>
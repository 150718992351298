import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { VrcBrowserStorageService } from './vrc-browser-storage.service';
import { VrcDataService } from './vrc-data.service';

export const vrcAuthGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const _bst = inject(VrcBrowserStorageService);
    const _r = inject(Router);
    const _srvc = inject(VrcDataService);
    const _ms = inject(MessageService);
    const _url = state.url;
    const _isInIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };

    if (!_isInIframe()) {
      //_st.contactId = null; 
    }
    if (_bst.token && !_bst.isTokenExpired && !_bst.isAzureUser) {
      return true;
    }

    // This is possible only if first route was not nice
    if (_bst.token && _bst.isTokenExpired && !_bst.isAzureUser) {
      _bst.deleteAgentId();
      // _st.contactId = null;
      if (!_bst.isRefreshTokenExpired) {
        return _srvc.refreshToken().pipe(
          take(1),
          map(x => {
            _bst.token = x.token;
            _bst.refreshToken = x.refreshToken;
            return true;
          }),
          catchError(err => {
            _bst.clearAuthentication();
            _ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
            _r.navigate(['login', { url: _url }]);
            return of(false);
          }));
      }
      _r.navigate(["login", { url: state.url }]);
      return false;
    }
    if (_bst.isAzureUser) {
      return true;
    }
    _r.navigate(["login", { url: state.url }]);
    return false;
  };

import {Component, OnInit} from "@angular/core";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {MessageService} from "primeng/api";
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {CommunicationModel} from "../models/mdp/communication.model";
import {CommunicationEmailDetailsModel} from "../models/mdp/communication-email-details.model";
import {CommunicationCallDetailsModel} from "../models/mdp/communication-call-details.model";
import {CommunicationChatDetailsModel} from "../models/mdp/communication-chat-details.model";
import {catchError, take, tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {of} from "rxjs";
import { VrcDataService } from "../services/vrc-data.service";

@Component({
  selector: 'vrc-communication-thread-details',
  templateUrl: './vrc-communication-thread-details.component.html',
  styleUrls: ['./vrc-communication-thread-details.component.scss'],
})
export class VrcCommunicationThreadDetailsComponent implements OnInit {


  public threadData = this.config.data.messageDetails;

  public selectedCommunication!: CommunicationModel;
  public emailDetails: CommunicationEmailDetailsModel | null = null;
  public callDetails: CommunicationCallDetailsModel | null = null;
  public chatDetails: CommunicationChatDetailsModel | null = null;

  private _loadingDetails: string | null = null;

  constructor(
    public ref: DynamicDialogRef,
    public messageService: MessageService,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private _srvc: VrcDataService,
    private _ms: MessageService,
  ) {
  }

  ngOnInit() {
    if(this.config.data.childData){
      this.getDetails(this.threadData, this.config.data.childData)
    } else {
      this.getDetails(this.threadData)
    }
  }

  public getDetails = (c: CommunicationModel, childGuid: string | null = null) => {
    if (this._loadingDetails && this._loadingDetails === c.id) { return; }
    this._loadingDetails = c.id;
    this.selectedCommunication = c;
    this._srvc.getCommunicationDetails(c.contactId ?? 0, childGuid ?? '')
      .pipe(
        take(1),
        tap((result) => {
          switch (result.channel) {
            case "Voice":
              this.callDetails = <CommunicationCallDetailsModel>result;
              break;
            case "Chat":
              this.chatDetails = <CommunicationChatDetailsModel>result;
              break;
            case "Email":
              this.emailDetails = <CommunicationEmailDetailsModel>result;
              break;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this._loadingDetails = null;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }


}

<div class="row pb-5 bg-secondary pt-3">
  <div class="col-12">
    <nav class="nav nav-pills d-inline-flex ms-5">
      <ng-container *ngFor="let m of loyaltiesList; last as isLast">
        <a #active="routerLinkActive" class="nav-link" [routerLink]="m.cardInformation?.cardNumber" routerLinkActive="active">
          {{m.cardInformation?.cardNumber}} <i *ngIf="m.id === st.model?.primaryLoyaltyId" class="pi pi-star-fill" style="font-size: 0.7rem"></i>
          <button class="btn btn-copy ms-4" [vrcClipboard]="m.cardInformation?.cardNumber??''" pTooltip="Copy to clipboard" tooltipPosition="bottom">
            <i class="vi vi-copycontent"></i>
          </button>
        </a>
        <div *ngIf="!isLast && !active.isActive" class="nav-link-splitter"></div>
      </ng-container>
    </nav>
	<button type="button" class="btn btn-sm btn-secondary d-inline-flex text-uppercase"
	style="transform: translateX(50px); height: fit-content - 5px; width: fit-content; " 
	(click)="st.showPoints = !st.showPoints">
		<span [class.fw-bold] = "!st.showPoints" [class.text-black-50] = "st.showPoints">Amount</span>
		<i class="vi vi-swap-horiz px-1 pt-1" style="font-size: 0.5rem;"></i>
		<span [class.fw-bold] = "st.showPoints" [class.text-black-50] = "!st.showPoints">Points</span>
	</button>
    <router-outlet></router-outlet>
  </div>
</div>
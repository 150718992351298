import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { CommunicationCallDetailsModel } from '../models/mdp/communication-call-details.model';
import { CommunicationChatDetailsModel } from '../models/mdp/communication-chat-details.model';
import { CommunicationCloseWorkItemRequestModel } from '../models/mdp/communication-close-work-item-request.model';
import { CommunicationDfoDetailsModel } from '../models/mdp/communication-dfo-details.model';
import { CommunicationEmailDetailsModel } from '../models/mdp/communication-email-details.model';
import { CommunicationModel } from '../models/mdp/communication.model';
import { ValUtilsService } from '../services/val-utils.service';
import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { LoyalityDetailsModel } from './../models/mdp/loyality-details.model';

const DefaultSuccessMessage = "Communication was successfully closed.";

@Component({
  selector: 'vrc-summary',
  templateUrl: './vrc-summary.component.html'
})
export class VrcSummaryComponent implements OnInit, OnDestroy {
  private _sub!: Subscription;
  // Instelisence helpers
  public loyalty!: LoyalityDetailsModel;

  public selectedCommunication!: CommunicationModel;
  public details!: CommunicationEmailDetailsModel | CommunicationChatDetailsModel | CommunicationCallDetailsModel | CommunicationDfoDetailsModel;
  public emalDetails = this.details as CommunicationEmailDetailsModel;
  public chatDetails = this.details as CommunicationChatDetailsModel;
  public callDetails = this.details as CommunicationCallDetailsModel;
  public digitalDetails: CommunicationDfoDetailsModel | null = null;

  public digitalModalTitle = "Chat details";

  public closeModalVisible = false;
  public emailModalVisible = false;
  public chatModalVisible = false;
  public voiceModalVisible = false;
  public digitalModalVisible = false;
  public valamartistList: LoyalityDetailsModel[] = [];
  public loyalityList: LoyalityDetailsModel[] = [];

  constructor(
    public st: VrcStorageService,
    public _bst: VrcBrowserStorageService,
    private _srvc: VrcDataService,
    private _ms: MessageService,
    public u: ValUtilsService,
  ) { }

  public ngOnDestroy(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }
  public ngOnInit(): void {
    if (this.st.model?.loyaltiesList && this.st.model?.loyaltiesList.length > 0) {
      this.valamartistList = this.st.model.loyaltiesList.filter(x => x.program === this.st.ValamartistProgram)
      this.loyalityList = this.st.model.loyaltiesList.filter(x => x.program !== this.st.ValamartistProgram)
    }
    this._sub = this.st.refreshAccount.pipe(tap(() => {
      if (this.st.model?.loyaltiesList && this.st.model.loyaltiesList.length > 0) {
        this.valamartistList = this.st.model.loyaltiesList.filter(x => x.program === this.st.ValamartistProgram)
        this.loyalityList = this.st.model.loyaltiesList.filter(x => x.program !== this.st.ValamartistProgram)
      }
    })).subscribe();
  }
  public showCloseDialog = (c: CommunicationModel) => {
    this.selectedCommunication = c;
    this.closeModalVisible = true;
  }

  public updateCommunication = (communication: CommunicationModel) => {
    if (this.st.model && this.st.model.communication && this.st.model.communication.length > 0) {
      const communicationIndex = this.st.model.communication.findIndex(item => item.contactId === communication.contactId);

      if (communicationIndex > -1) {
        this.st.model.communication[communicationIndex] = communication;
      }
    }
  }

  public closeCommunication = () => {
    if (this.selectedCommunication && this.selectedCommunication.contactId) {
      const requestData = new CommunicationCloseWorkItemRequestModel(
        this.selectedCommunication.contactId, this._bst.agentId, this.st.timeOffset);

      this._ms.add({ severity: 'info', summary: 'Closing', detail: 'Closing communication' });

      this._srvc.closeCommunication(requestData).pipe(
        take(1),
        tap(() => {
          if (this.selectedCommunication) {
            this.selectedCommunication.status = "Closed";
          }

          this._ms.add({ severity: 'success', summary: 'Success', detail: DefaultSuccessMessage });

        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          this.closeModalVisible = false;
          return of(null);
        })
      ).subscribe();
      this.closeModalVisible = false;
    }
  }

  public openDetails = (c: CommunicationModel, childGuid?: string) => {
    this.selectedCommunication = c;
    this._srvc.getCommunicationDetails(c.contactId ?? 0, childGuid)
      .pipe(
        take(1),
        tap((result) => {
          this.details = result;
          switch (result.channel) {
            case "Voice":
              this.callDetails = <CommunicationCallDetailsModel>result;
              this.voiceModalVisible = true;
              break;
            case "Chat":
              this.chatDetails = <CommunicationChatDetailsModel>result;
              this.chatModalVisible = true;
              break;
            case "Email":
              this.emalDetails = <CommunicationEmailDetailsModel>result;
              this.emailModalVisible = true;
              break;
            case "Digital":
              this.digitalDetails = <CommunicationDfoDetailsModel>result;
              this.digitalModalVisible = true;
              this.digitalModalTitle = "Digital " + (<CommunicationDfoDetailsModel>result).subChannel + " details";
              break;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }
}
<div class="scroll-to-top">
  <button
    type="button"
    class="btn btn-sm btn-primary"
    data-toggle="button"
    (click)="onScrollToTop()"
  >
    Back To Top
  </button>
</div>

<ng-container *ngFor="let c of categories">
  <div class="form-label my-5">{{c}}</div>
  <div class="row">
    <ng-container *ngIf="isEditOn">
      <div *ngFor="let m of (model?.interests??[]) | interestsByCategory:c:trigger" class="col-12 col-sm-6 pe-5 pb-3">
        <button (click)="m.isSelected=!m.isSelected" class="btn btn-sm text-white btn-select w-100 text-start" 
        [class.bg-gray-700]="m.isSelected" [class.bg-white]="!m.isSelected" 
        [class.text-gray-700]="!m.isSelected" [class.text-white]="m.isSelected">
          {{m.name}}
          <i [class.vi-times-white]="m.isSelected" [class.vi-plus]="!m.isSelected" class="vi mx-4 float-end"></i>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!isEditOn">
      <div *ngFor="let m of (model?.interests??[]) | selectedInterests:c:trigger" class="col-auto pe-3 pb-3">
        <span class="badge badge-selected bg-gray-700 text-white">
          {{m.name}}
          <i class="vi vi-check-white ms-3"></i>
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>
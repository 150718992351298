import {Component, Input, OnInit} from "@angular/core";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { VrcCommunicationThreadDetailsComponent } from "./vrc-communication-thread-details.component";
import {CommunicationModel} from "../models/mdp/communication.model";
import {catchError, take, tap} from "rxjs/operators";
import {CommunicationCallDetailsModel} from "../models/mdp/communication-call-details.model";
import {CommunicationChatDetailsModel} from "../models/mdp/communication-chat-details.model";
import {CommunicationEmailDetailsModel} from "../models/mdp/communication-email-details.model";
import {HttpErrorResponse} from "@angular/common/http";
import {of} from "rxjs";
import {VrcDataService} from "../services/vrc-data.service";

type CommunicationThreads = {
  currentDate: string | null;
  messages: CommunicationModel[];
}

export enum iconClasses {
  "Email"= "pi pi-envelope",
  "Chat" = "pi pi-comment",
  "Whatsapp" = "pi pi-whatsap",
  "Phone" = "pi pi-phone",
  "Facebook" = "pi pi-facebook"
}

@Component({
  selector: 'vrc-communication-thread-messages-container',
  templateUrl: './vrc-communication-thread-messages-container.component.html',
  styleUrls: ['./vrc-communication-thread-messages-container.component.scss'],
})
export class VrcCommunicationThreadMessagesContainerComponent implements OnInit {

  @Input()
  threadCommunication!: CommunicationThreads;

  public selectedCommunication!: CommunicationModel;
  public emailDetails: CommunicationEmailDetailsModel | null = null;
  public callDetails: CommunicationCallDetailsModel | null = null;
  public chatDetails: CommunicationChatDetailsModel | null = null;

  public closeModalVisible = false;
  public emailModalVisible = false;
  public chatModalVisible = false;
  public voiceModalVisible = false;
  public isModalVisible = false;

  private _loadingDetails: string | null = null;


  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private _srvc: VrcDataService,
    private _ms: MessageService,
  ) {

  }

  ngOnInit(): void {
    console.log();
  }

  openMessageDetails(messageDetails: CommunicationModel, childData?: string) {
    this.ref = this.dialogService.open(VrcCommunicationThreadDetailsComponent, {
      header: `${messageDetails.channel} Details`,
      width: '80%',
      height: '90%',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      maximizable: true,
      data: {
        messageDetails: messageDetails,
        childData: childData
      }
    });
  }

  getIconClass(channel: string | null) {
    switch (channel) {
      case "Email": {
        return iconClasses.Email;
      }
      case "Chat": {
        return iconClasses.Chat
      }
      case "Whatsapp": {
        return iconClasses.Whatsapp
      }
      case "Phone": {
        return iconClasses.Phone
      }
      case "Facebook": {
        return iconClasses.Facebook
      }
      default: {
        return iconClasses.Phone
      }
    }
  }

  public getDetails = (c: CommunicationModel, childGuid: string | null = null) => {
    if (this._loadingDetails && this._loadingDetails === c.id) { return; }
    this._loadingDetails = c.id;
    this.selectedCommunication = c;
    this._srvc.getCommunicationDetails(c.contactId ?? 0, childGuid ?? '')
      .pipe(
        take(1),
        tap((result) => {
          switch (result.channel) {
            case "Voice":
              this.callDetails = <CommunicationCallDetailsModel>result;
              break;
            case "Chat":
              this.chatDetails = <CommunicationChatDetailsModel>result;
              break;
            case "Email":
              this.emailDetails = <CommunicationEmailDetailsModel>result;
              break;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this._loadingDetails = null;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  public openDetails = (c: CommunicationModel, childGuid: string | null = null) => {
    if (this._loadingDetails && this._loadingDetails === c.id) { return; }
    this._loadingDetails = c.id;
    this.selectedCommunication = c;
    this._srvc.getCommunicationDetails(c.contactId ?? 0, childGuid ?? '')
      .pipe(
        take(1),
        tap((result) => {
          this._loadingDetails = null;
          switch (result.channel) {
            case "Voice":
              this.callDetails = <CommunicationCallDetailsModel>result;
              this.voiceModalVisible = true;
              break;
            case "Chat":
              this.chatDetails = <CommunicationChatDetailsModel>result;
              this.chatModalVisible = true;
              break;
            case "Email":
              this.emailDetails = <CommunicationEmailDetailsModel>result;
              this.emailModalVisible = true;
              break;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this._loadingDetails = null;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  setVisibleModal(channel: string | null)  {
    switch (channel) {
      case "Voice":
        this.voiceModalVisible = true;
        return true;
      case "Chat":
        this.chatModalVisible = true;
        return true;
      case "Email":
        this.emailModalVisible = true;
        return true;
      default:
        return false;
    }
  }

  isVisible(channel: string) {
    if(channel === 'Email') {
      this.emailModalVisible = true;
      this.isModalVisible = true;
    }
    if(channel === 'Chat') {
      this.emailModalVisible = true;
      this.isModalVisible = true;
    }
    if(channel === 'Voice') {
      this.emailModalVisible = true;
      this.isModalVisible = true;
    }

  }



}

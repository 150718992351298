import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class VrcBrowserStorageService {
    constructor(private _authService: MsalService) { }
    public get isAzureUser(): boolean {
        return this._authService.instance.getAllAccounts().length > 0;
    }
    public set token(token: string | null) {
        localStorage.setItem('token', <string>token);
    }
    public get token(): string | null {
        return localStorage.getItem('token');
    }
    public set refreshToken(token: string | null) {
        localStorage.setItem('refreshToken', <string>token);
    }
    public get refreshToken(): string | null {
        return localStorage.getItem('refreshToken');
    }
    public set sid(sid: string | null) {
        localStorage.setItem('sid', <string>sid);
    }
    public get sid(): string | null {
        return localStorage.getItem('sid');
    }
    public set subscription(subscription: string | null) {
        localStorage.setItem('subscription', <string>subscription);
    }
    public get subscription(): string | null {
        return localStorage.getItem('subscription');
    }
    public set agentId(agentId: number | null) {
        localStorage.setItem('agentId', agentId ? agentId.toString() : '');
    }
    public get agentId(): number | null {
        const a = localStorage.getItem('agentId');
        return a ? +a : null;
    }
    public deleteAgentId() {
        localStorage.removeItem('agentId');
    }
    public deleteToken = () => {
        localStorage.removeItem('token');
    }
    public deleteRefreshToken = () => {
        localStorage.removeItem('refreshToken');
    }
    public clear = () => {
        localStorage.clear();
    }
    public clearAuthentication = () => {
        const aid = this.agentId;
        const sub = this.subscription;
        localStorage.clear();
        this.agentId = aid;
        this.subscription = sub;
    }
    public get currentUser(): string | null {
        const token = this.token;
        if (!token) { return null; }
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        if (!encodedPayload) { return null; }
        const rawPayload = atob(encodedPayload);
        const user = JSON.parse(rawPayload);
        return user.unique_name;
    }

    public get niceToken(): string | null {
        const token = this.token;
        if (!token) { return null; }
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        if (!encodedPayload) { return null; }
        const rawPayload = atob(encodedPayload);
        const user = JSON.parse(rawPayload);
        return user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"];
    }
    public get sessionId(): string | null {
        const token = this.token;
        if (!token) { return null; }
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        if (!encodedPayload) { return null; }
        const rawPayload = atob(encodedPayload);
        const user = JSON.parse(rawPayload);
        return user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"];
    }

    public get userData(): string | null {
        const token = this.token;
        if (!token) { return null; }
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        if (!encodedPayload) { return null; }
        const rawPayload = atob(encodedPayload);
        const user = JSON.parse(rawPayload);
        return user["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
    }

    public get subscriptionFromToken(): string | null {
        const token = this.token;
        if (!token) { return null; }
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        if (!encodedPayload) { return null; }
        const rawPayload = atob(encodedPayload);
        const user = JSON.parse(rawPayload);
        return user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash"];
    }

    public get isTokenExpired(): boolean | null {
        const token = this.token;
        if (!token) { return null; }
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        if (!encodedPayload) { return null; }
        const rawPayload = atob(encodedPayload);
        const tokenData = JSON.parse(rawPayload);
        const timestamp = +tokenData["exp"];

        // add extra minute to expire
        return (Date.now() - 60000) > (timestamp * 1000);
    }

    public get isRefreshTokenExpired(): boolean | null {
        const token = this.refreshToken;
        if (!token) { return null; }
        const tokenParts = token.split('.');
        const encodedPayload = tokenParts[1];
        if (!encodedPayload) { return null; }
        const rawPayload = atob(encodedPayload);
        const tokenData = JSON.parse(rawPayload);
        const timestamp = +tokenData["exp"];

        // add extra minute to expire
        return (Date.now() - 60000) > timestamp * 1000;
    }
}

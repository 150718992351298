export class AmountsDataModel {
    constructor(
        public earnedAmountAvailable: number | null = null,
        public reservedAmount: number | null = null,
        public promoAmountAvailable: number | null = null,
        public totalAmountAvailable: number | null = null,
        public amountExpiringSoon: number | null = null,
        public amountPending: number | null = null,
    ) { }
}

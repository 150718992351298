import { AdditionalInformationModel } from './additional-information.model';
import { AddressModel } from './address.model';
import { CommunicationModel } from './communication.model';
import { ContactModel } from './contact.model';
import { FamilyStatusModel } from './family-status.model';
import { InterestModel } from './interest.model';
import { LoyalityDetailsModel } from './loyality-details.model';
import { LoyaltyModel } from './loyalty.model';
import { MappingFieldsModel } from './mapping-fields.model';
import { OfferDetailsModel } from './offer-details.model';
import { ReservationDetailsModel } from './reservation-details.model';

export class AccountModel {
    constructor(
        public id: string | null = null,
        public isActive: boolean = true,
        public isDeleted: boolean = false,
        public createdByUser: string | null = null,
        public createdDate: string | null = null,
        public modifiedByUser: string | null = null,
        public modifiedDate: string | null = null,
        public firstName: string | null = null,
        public lastName: string | null = null,
        public salutation: string | null = null,
        public gender: string | null = null,
        public language: string | null = null,
        public birthDate: string | Date | null = null,
        public age: number | null = null,
        public oib: string | null = null,
        public familyStatuses: FamilyStatusModel[] = [],
        public address: AddressModel = new AddressModel(),
        public contact: ContactModel = new ContactModel(),
        public additionalInformation: AdditionalInformationModel = new AdditionalInformationModel(),
        public loyalties: LoyaltyModel[] = [],
        public advertisingPermissions: { [key: string]: 'Yes' | 'No' | 'Not specified' } | null = null,
        public products: { [key: string]: 'Yes' | 'No' | 'Not specified' } | null = null,
        public interests: InterestModel[] = [],
        public reservations: Array<ReservationDetailsModel> = [],
        public offers: Array<OfferDetailsModel> = [],
        public mappingFields: MappingFieldsModel | null = new MappingFieldsModel(),
        public communication: CommunicationModel[] = [],
        public type: string | null = null,
        public score: number = 0,
        public count: number = 0,
        public dataHubVersion: number = 0,
        public version: number = 0,
        public loyaltiesList: LoyalityDetailsModel[] = [],
        public modifiedBySystem: string | null = null,
        public createdBySystem: string | null = null,
		public primaryLoyaltyId: string | null = null,
    ) { }
}
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { catchError, EMPTY, finalize, take, tap } from 'rxjs';
import { AccountNote } from 'src/app/models/mdp/account-note.model';
import { VrcDataService } from 'src/app/services/vrc-data.service';

@Component({
	selector: 'vrc-account-note',
	templateUrl: './vrc-account-note.component.html',
	styleUrls: ['./vrc-account-note.component.scss']
})

export class VrcAccountNoteComponent {
	@ViewChild('contentContainer') contentContainer!: ElementRef;
	@Input() accountNote!: AccountNote;
	@Input() deleteNote!: (accountNoteId: string | null) => void;

	primaryButtonState: "normal" | "editing" | "loading" | "disabled" = "normal";
	secondaryButtonState: "normal" | "editing" | "loading" | "disabled" = "normal";
	validContent = true;

	constructor(
		private vrcDataService: VrcDataService
	) { }

	notePrimaryButtonHandler() {
		switch (this.primaryButtonState) {
			case "normal": {
				this.primaryButtonState = "editing";
				this.secondaryButtonState = "editing";
				break;
			}
			case "editing": {
				this.primaryButtonState = "loading";
				this.secondaryButtonState = "disabled";
				this.saveEditedNote();
				break;
			}
		}
	}

	noteSecondaryButtonHandler() {
		switch (this.secondaryButtonState) {
			case "normal": {
				this.primaryButtonState = "disabled";
				this.secondaryButtonState = "loading";
				this.deleteNote(this.accountNote.id);
				break;
			}
			case 'editing': {
				this.primaryButtonState = "normal";
				this.secondaryButtonState = "normal";
				this.validContent = true;
				this.contentContainer.nativeElement.innerText = this.accountNote.content;
				break;
			}
		}
	}

	saveEditedNote() {
		this.accountNote.content = this.contentContainer.nativeElement.innerText;
		this.vrcDataService.updateAccountNote(this.accountNote)
			.pipe(
				take(1),
				tap(data => {
					this.accountNote = data;
				}),
				catchError(error => {
					console.error('Error:', error);
					return EMPTY;
				}),
				finalize(() => {
					this.primaryButtonState = "normal";
					this.secondaryButtonState = "normal";
				})
			)
			.subscribe();
	}

	validateContent() {
		this.validContent = this.contentContainer.nativeElement.innerText.length > 5;
	}
}
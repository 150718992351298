<p-dialog [header]="vrcStorage.model?.firstName + '\'s Notes'" [visible]="visible" (visibleChange)="visibilityChange($event)" position="right" [style]="{ 'width': '30rem', 'height': '60rem', 'min-height': '250px', 'min-width': '250px'}">
	<div class="modal-body d-flex flex-column">
		<div #scrollContainer class="flex-grow overflow-auto p-4">
			<div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 80%;">
				<p-progressSpinner
					styleClass="w-4rem h-4rem custom-spinner" 
					strokeWidth="5" 
					animationDuration="0.7s" />
			</div>
			<div *ngIf="!loading">
				<div *ngFor="let accountNote of accountNotes" class="media-body">
					<vrc-account-note [accountNote]="accountNote" [deleteNote]="deleteAccountNote.bind(this)"/>
				</div>
			</div>
		</div>
		<div class="fixed-footer">
			<div class="input-group mb-3">
				<textarea [(ngModel)]="newNoteContent" type="text" class="form-control p-3" placeholder="Write a note..." aria-label="Recipient's username"></textarea>
				<button class="btn btn-primary" type="button" id="button-note-submit" (click)="submitButtonHandler()">Add</button>
			</div>
			<div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center" role="alert">
				<i class="pi pi-info-circle me-3"></i>
				<div>
					{{errorMessage}}
				</div>
			  </div>
		</div>
	</div>
</p-dialog>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ClaimPointsModel } from '../models/claim-points.model';
import { CommunicationCallDetailsModel } from '../models/mdp/communication-call-details.model';
import { CommunicationChatDetailsModel } from '../models/mdp/communication-chat-details.model';
import { CommunicationCloseWorkItemRequestModel } from '../models/mdp/communication-close-work-item-request.model';
import { CommunicationEmailAttachmentRequestModel } from '../models/mdp/communication-email-attachment-request.model';
import { CommunicationEmailAttachmentResponseModel } from '../models/mdp/communication-email-attachment-response.model';
import { CommunicationEmailDetailsModel } from '../models/mdp/communication-email-details.model';
import { CommunicationUpdateAccountRequestModel } from '../models/mdp/communication-update-account-request.model';
import { CreateLoyaltyModel } from '../models/mdp/create-loyalty.model';
import { EmailValidationModel } from '../models/mdp/email-validation.model';
import { InterestModel } from '../models/mdp/interest.model';
import { LoyalityDetailsModel } from '../models/mdp/loyality-details.model';
import { OfferEmailModel } from '../models/mdp/offer-email.model';
import { OfferValidationResponseModel } from '../models/mdp/offer-validation-response.model';
import { PhoneValidationModel } from '../models/mdp/phone-validation.model';
import { SendOfferModel } from '../models/mdp/send-offer.model';
import { ValidOffer } from '../models/mdp/valid-offer.model';
import { WebOfferPreviewModel } from '../models/mdp/web-offer-preview.model';
import { PageModel } from '../models/page.model';
import { TokenResponseModel } from '../models/token-response.model';
import { TransferPointsModel } from '../models/transfer-points.model';
import { AccountModel } from '../models/mdp/account.model';
import { FamilyStatusModel } from '../models/mdp/family-status.model';
import { ParamsModel, ParamsModelThread } from '../models/params.model';
import { VrcConfigService } from './vrc-config.service';
import { CommunicationVoiceRecordingsResponseModel } from '../models/mdp/communication-voice-recordings-response.model';
import { ThreadModel, ThreadsPaginator, ThreadsResponse } from "../models/threads.model";
import { map } from "rxjs/operators";
import { CommunicationDfoDetailsModel } from '../models/mdp/communication-dfo-details.model';
import { AccountNote } from '../models/mdp/account-note.model';

@Injectable()
export class VrcDataService {
    constructor(private http: HttpClient, private config: VrcConfigService) { }

    public getToken = (data: { sessionId: string, agentId: number }): Observable<TokenResponseModel> => {
        return this.http.post<TokenResponseModel>(this.config.getTokenUrl, data);
    }
    public getUserToken = (data: { userName: string, password: string }): Observable<TokenResponseModel> => {
        return this.http.post<TokenResponseModel>(this.config.getUserTokenUrl, data);
    }
    public refreshToken = (): Observable<TokenResponseModel> => {
        return this.http.get<TokenResponseModel>(this.config.refreshTokenUrl);
    }
    public getSubscription = (): Observable<string> => {
        return this.http.get<string>(this.config.subscriptionUrl, { responseType: "text" as "json" });
    }
    public getAccountById = (accountId: string, takeComm = 5, takeOffers = 5, takeReservations = 5): Observable<AccountModel | null> => {
        if (!accountId) { return of(null) }
        return this.http.get<AccountModel>(this.config.accountByIdUrl + "?Id=" + accountId + "&TakeComm=" + takeComm + "&TakeOffers=" + takeOffers + "&TakeReservations=" + takeReservations);
    }
    public getAccountByIds = (contactId: string, accountIds: string[]): Observable<AccountModel[] | null> => {
        if (!accountIds) { return of(null) }
        return this.http.get<AccountModel[]>(this.config.accountByIdsUrl + "?contactId=" + contactId + accountIds.map(x => "&accountIds=" + x).reduce((a, b) => a += b, ''));
    }
    public getAccountByEmail = (email: string, takeComm = 5, takeOffers = 5, takeReservations = 5): Observable<AccountModel[]> => {
        return this.http.get<AccountModel[]>(this.config.accountByEmailUrl + "?Email=" + email + "&TakeComm=" + takeComm + "&TakeOffers=" + takeOffers + "&TakeReservations=" + takeReservations);
    }
    public getAccountByVoucherCode = (email: string, takeComm = 5, takeOffers = 5, takeReservations = 5): Observable<AccountModel[]> => {
        return this.http.get<AccountModel[]>(this.config.accountByVoucherCodeUrl + "?VoucherCode=" + email + "&TakeComm=" + takeComm + "&TakeOffers=" + takeOffers + "&TakeReservations=" + takeReservations);
    }
    public getAccountByLoyaltiesEmail = (email: string, takeComm = 5, takeOffers = 5, takeReservations = 5): Observable<AccountModel[]> => {
        return this.http.get<AccountModel[]>(this.config.accountByLoyaltiesEmailUrl + "?Email=" + email + "&TakeComm=" + takeComm + "&TakeOffers=" + takeOffers + "&TakeReservations=" + takeReservations);
    }
    public postAccount = (account: AccountModel): Observable<AccountModel> => {
        return this.http.post<AccountModel>(this.config.accountUrl, account);
    }
    public postAccountAndLoyalty = (account: AccountModel): Observable<AccountModel> => {
        return this.http.post<AccountModel>(this.config.accountAndLoyaltyUrl, account);
    }
    public getAccountSearch = (params: ParamsModel): Observable<PageModel> => {
        return this.http.get<PageModel>(this.config.accountUrl + '/search?data=' + encodeURIComponent(JSON.stringify(params)));
        // return this.http.get<PageModel>(this.config.accountUrl + '/search?data=' + JSON.stringify(params));
    }
    public getOfferPage = (params: ParamsModel): Observable<PageModel> => {
        return this.http.get<PageModel>(this.config.offerUrl + '/page?data=' + JSON.stringify(params));
    }
    public getReservationPage = (params: ParamsModel): Observable<PageModel> => {
        return this.http.get<PageModel>(this.config.reservationUrl + '/page?data=' + JSON.stringify(params));
    }
    public getVoucherPage = (params: ParamsModel): Observable<PageModel> => {
        return this.http.get<PageModel>(this.config.voucherUrl + '/page?data=' + JSON.stringify(params));
    }
    public getLoyalty = (accountId: string): Observable<LoyalityDetailsModel[]> => {
        return this.http.get<LoyalityDetailsModel[]>(this.config.loyaltyUrl + '/getByAccountId/' + accountId);
    }
    public postLoyalty = (loyalty: CreateLoyaltyModel): Observable<AccountModel> => {
        return this.http.post<AccountModel>(this.config.createLoyaltyUrl, loyalty);
    }
    public getInterests = (): Observable<InterestModel[]> => {
        return this.http.get<InterestModel[]>(this.config.interestUrl);
    }
    public getAdvertisingPermissions = (): Observable<{ key: string, label: string }[]> => {
        return this.http.get<{ key: string, label: string }[]>(this.config.advertisingPermissionsUrl);
    }
    public getProducts = (): Observable<{ key: string, label: string }[]> => {
        return this.http.get<{ key: string, label: string }[]>(this.config.productsUrl);
    }
    public getFamilyStatuses = (): Observable<FamilyStatusModel[]> => {
        return this.http.get<FamilyStatusModel[]>(this.config.familyStatusUrl);
    }
    public getOffer = (offerId: string): Observable<SendOfferModel> => {
        return this.http.get<SendOfferModel>(this.config.getOfferUrl + '/' + offerId);
    }
    public cancelOffer = (offerId: string): Observable<SendOfferModel> => {
        return this.http.get<SendOfferModel>(this.config.cancelOfferUrl + '/' + offerId);
    }
    public confirmOffer = (offerId: string): Observable<SendOfferModel> => {
        return this.http.get<SendOfferModel>(this.config.confirmOfferUrl + '/' + offerId);
    }
    public sendOffer = (offerEmail: OfferEmailModel): Observable<boolean> => {
        return this.http.post<boolean>(this.config.offerEmailUrl, offerEmail);
    }
    public getWebOfferLink = (offerId: string): Observable<WebOfferPreviewModel> => {
        return this.http.get<WebOfferPreviewModel>(this.config.getWebOfferLinkUrl + '/' + offerId);
    }
    public transferPoints = (transferPointsPayload: TransferPointsModel): Observable<void> => {
        return this.http.post<void>(this.config.transferPointsUrl, transferPointsPayload);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public claimPoints = (claimPointsPayload: ClaimPointsModel): Observable<any> => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.post<any>(this.config.claimPointsUrl, claimPointsPayload);
    }
    public resendLoyaltyConfirmation = (loyaltyProfileId: string, email: string): Observable<void> => {
        return this.http.post<void>(this.config.resendLoyaltyConfirmationUrl, { loyaltyProfileId: loyaltyProfileId, email: email });
    }
    public loyaltyForgottenPassword = (loyaltyProfileId: string, email: string): Observable<void> => {
        return this.http.post<void>(this.config.loyaltyForgottenPasswordUrl, { loyaltyProfileId: loyaltyProfileId, email: email });
    }
    public loyaltyNewEmail = (loyaltyCardNumber: string, currentEmail: string, newEmail: string): Observable<void> => {
        return this.http.post<void>(this.config.loyaltyNewEmailUrl, { loyaltyCardNumber: loyaltyCardNumber, currentEmail: currentEmail, newEmail: newEmail });
    }
    public getCommunicationPage = (params: ParamsModel | ParamsModelThread): Observable<PageModel> => {
        return this.http.get<PageModel>(this.config.communicationUrl + '/page?data=' + JSON.stringify(params));
    }
    public getCommunicationThreads = (params: ParamsModel | ParamsModelThread): Observable<ThreadModel[]> => {
        return this.http.get<ThreadModel[]>(this.config.communicationUrl + '/threads?data=' + JSON.stringify(params));
    }

    public getCommunicationThreadsAggregation = (params: ParamsModel | ParamsModelThread): Observable<ThreadModel[]> => {
        return this.http.get<ThreadModel[]>(this.config.communicationUrl + '/threads/aggregation?data=' + JSON.stringify(params));
    }

    public getCommunicationThreadsPaginator = (params: ParamsModel | ParamsModelThread): Observable<ThreadsPaginator> => {
        return this.http.get<ThreadsResponse>(this.config.communicationUrl + '/threads/paging?data=' + JSON.stringify(params))
            .pipe(
                map((response) => ({
                    items: response.items,
                    page: params.first,
                    hasMorePages: response.skip + response.limit < response.total
                } as ThreadsPaginator))

            );
    }

    public getCommunicationDetails = (contactId: number, childGuid?: string): Observable<CommunicationChatDetailsModel | CommunicationEmailDetailsModel | CommunicationCallDetailsModel | CommunicationDfoDetailsModel> => {
        return this.http.get<CommunicationChatDetailsModel | CommunicationEmailDetailsModel | CommunicationCallDetailsModel | CommunicationDfoDetailsModel>(this.config.communicationUrl + '/details?contactId=' + contactId + (childGuid ? '&childGuid=' + childGuid : ''));
    }
    public getEmailDetails = (contactId: number): Observable<CommunicationEmailDetailsModel> => {
        return this.http.get<CommunicationEmailDetailsModel>(this.config.communicationUrl + '/emailDetails?contactId=' + contactId);
    }
    public getChatDetails = (contactId: number): Observable<CommunicationChatDetailsModel> => {
        return this.http.get<CommunicationChatDetailsModel>(this.config.communicationUrl + '/chatDetails?contactId=' + contactId);
    }
    public getCallDetails = (contactId: number): Observable<CommunicationCallDetailsModel> => {
        return this.http.get<CommunicationCallDetailsModel>(this.config.communicationUrl + '/callDetails?contactId=' + contactId);
    }
    public closeCommunication = (params: CommunicationCloseWorkItemRequestModel): Observable<void> => {
        return this.http.post<void>(this.config.communicationUrl + '/endContact', params);
    }
    public getEmailAttachment = (params: CommunicationEmailAttachmentRequestModel): Observable<CommunicationEmailAttachmentResponseModel> => {
        return this.http.get<CommunicationEmailAttachmentResponseModel>(this.config.communicationUrl + '/getEmailAttachment?data=' + encodeURIComponent(JSON.stringify(params)));
    }
    public getRecordingFiles = (recordings: Array<{ fileName: string, fullFileName: string }>, id: string): Observable<CommunicationVoiceRecordingsResponseModel> => {
        return this.http.get<CommunicationVoiceRecordingsResponseModel>(
            this.config.communicationUrl + '/getRecordings?recordings=' + encodeURIComponent(JSON.stringify(recordings)) +
            "&id=" + id);
    }
    public updateCommunicationAccount = (params: CommunicationUpdateAccountRequestModel): Observable<boolean> => {
        return this.http.post<boolean>(this.config.communicationUrl + '/updateAccount', params);
    }
    public getCommunicationHistoryPage = (params: ParamsModel): Observable<PageModel> => {
        return this.http.get<PageModel>(this.config.communicationHistoryUrl + '/page?data=' + JSON.stringify(params));
    }
    public getHistoryEmailDetails = (contactId: number): Observable<CommunicationEmailDetailsModel> => {
        return this.http.get<CommunicationEmailDetailsModel>(this.config.communicationHistoryUrl + '/emailDetails?contactId=' + contactId);
    }
    public getHistoryChatDetails = (contactId: number): Observable<CommunicationChatDetailsModel> => {
        return this.http.get<CommunicationChatDetailsModel>(this.config.communicationHistoryUrl + '/chatDetails?contactId=' + contactId);
    }
    public getHistoryCallDetails = (contactId: number): Observable<CommunicationCallDetailsModel> => {
        return this.http.get<CommunicationCallDetailsModel>(this.config.communicationHistoryUrl + '/callDetails?contactId=' + contactId);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public retrieveHierarchyForEmployee = (valamarArtistID: string): Observable<any> => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<any>(this.config.retrieveHierarchyForEmployeeUrl + 'retrieveHierarchyForEmployee?ValamarArtistID=' + valamarArtistID);
    }
    public getAccountByLoyalityparant = (loyalityParantId: string): Observable<AccountModel> => {
        return this.http.get<AccountModel>(this.config.loyaltyUrl + '/getAccount/' + loyalityParantId);
    }
    public getLoyalityByLoyalityId = (loyalityId: string): Observable<LoyalityDetailsModel> => {
        return this.http.get<LoyalityDetailsModel>(this.config.loyaltyUrl + '/getById/' + loyalityId);
    }
    public validOffer = (validOffer: ValidOffer): Observable<OfferValidationResponseModel> => {
        return this.http.post<OfferValidationResponseModel>(this.config.validateOfferUrl, validOffer);
    }
    public validateEmail = (email: string): Observable<EmailValidationModel> => {
        return this.http.get<EmailValidationModel>(this.config.validateEmailUrl + '/' + email);
    }
    public validatePhone = (phone: string): Observable<PhoneValidationModel> => {
        return this.http.get<PhoneValidationModel>(this.config.validatePhoneUrl + '/' + phone);
    }
    public getAccountNotes = (accountId: string): Observable<AccountNote[]> => {
        return this.http.get<AccountNote[]>(this.config.accountNoteUrl + '/' + accountId);
    }
    public GetNoteCountSinceDate = (accountId: string, date: string): Observable<number> => {
        return this.http.get<number>(this.config.accountNoteUrl + '/' + accountId + '/' + date);
    }
    public addAccountNote = (accountNote: AccountNote): Observable<AccountNote> => {
        return this.http.post<AccountNote>(this.config.accountNoteUrl, accountNote);
    }
    public updateAccountNote = (accountNote: AccountNote): Observable<AccountNote> => {
        return this.http.put<AccountNote>(this.config.accountNoteUrl, accountNote);
    }
    public deleteAccountNote = (accountNoteId: string): Observable<AccountNote> => {
        return this.http.delete<AccountNote>(this.config.accountNoteUrl + '/' + accountNoteId);
    }
}

export class OfferEmailModel {
    constructor(
        public accountId: string | null = null,
        public agentId: number | null = null,
        public contactId: number | null = null,
        public subject: string | null = null,
        public body: string | null = null,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public attachments: any[] | null = null,
        public emailaddress: string | null = null,
        public text2agent: string | null = null,
        public status: string | null = null,
        public skillId: number | null = null,
        public phobsOfferId: string | null = null
    ) { }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { of, Subscription } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { AccountModel } from '../models/mdp/account.model';
import { ParamsModel } from '../models/params.model';
import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { ValUtilsService } from '../services/val-utils.service';

@Component({
  selector: 'vrc-search',
  templateUrl: './vrc-search.component.html'
})
export class VrcSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchTable') private _searchTable!: Table;
  private _sub!: Subscription;
  private _searchData!: string | { contactId: string, accountIds: Array<string> };
  private _searchType: 'ALL' | 'EMAIL' | 'VOUCHER' | 'LOYALTIES' = 'ALL';
  public lastParams!: ParamsModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public searchResults = new Array<any>();
  public totalRecords = 0;
  public isSearchLoading = false;

  constructor(
    public st: VrcStorageService,
    public bst: VrcBrowserStorageService,
    private _r: Router,
    private _srvc: VrcDataService,
    private _ars: ActivatedRoute,
    private _ms: MessageService,
    private _u: ValUtilsService
  ) { }

  ngOnInit(): void {
    this._sub = this._ars.params
      .pipe(tap(x => {
        this._searchData = decodeURIComponent(x['phrase']);
        this._searchType = x['type'];
        this._search();
      }))
      .subscribe();
    this._searchData = decodeURIComponent(this._ars.snapshot.params['phrase']);
    this._searchType = this._ars.snapshot.params['type'];
    this._search();
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public closeSearch = () => {
    if (this.st.origModel) {
      this._r.navigate(['main/account/' + this.st.origModel.id]);
    } else {
      this._r.navigate(['main']);
    }
  }

  public selectAccount = (acc: AccountModel) => {
    this.st.accountIsSelected = true;
    this._r.navigate(['main/account/' + acc.id],);
  }

  private _search = () => {
    this.searchResults = [];
    if (!this.lastParams) {
      this.lastParams = new ParamsModel(0, 5, 'score', -1, undefined, {}, this._searchData);
    }
    if (this._searchTable) {
      this._searchTable.first = 0;
    }
    let searchData: { contactId: string, accountIds: Array<string> } | null = null;
    switch (this._searchType.toUpperCase()) {
      case 'ALL':
        this._searchTableLazyLoad(this.lastParams);
        break;
      case 'EMAIL':
        this._searchByEmail(this._searchData as string);
        break;
      case 'VOUCHER':
        this._searchByVoucherCode(this._searchData as string);
        break;
      case 'LOYALTIES':
        this._searchByLoyaltiesEmail(this._searchData as string);
        break;
      case 'AUTO':
        searchData = JSON.parse(this._searchData as string) as { contactId: string, accountIds: Array<string> };
        this._getAccounts(searchData.contactId, searchData.accountIds);
        break;
    }
  }

  private _searchTableLazyLoad = (p: ParamsModel): void => {
    p.globalFilter = this._searchData;
    this.lastParams = p;
    this._searchByAny(p);
  }

  private _searchByAny = (p: ParamsModel): void => {
    this.isSearchLoading = true;
    this._srvc.getAccountSearch(p).pipe(
      take(1),
      tap(x => {
        this.totalRecords = x.totalRecords;
        this.searchResults = x.data;
        this.isSearchLoading = false;
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        this.isSearchLoading = false;
        return of(null);
      })
    ).subscribe();
  }

  private _searchByEmail = (searchString: string) => {
    if (searchString) {
      this.isSearchLoading = true;
      this._srvc.getAccountByEmail(searchString).pipe(
        take(1),
        tap(x => {
          this.searchResults = x;
          this.isSearchLoading = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          this.isSearchLoading = false;
          return of(null);
        })
      ).subscribe();
    }
  }

  private _searchByVoucherCode = (searchString: string) => {
    if (searchString) {
      this.isSearchLoading = true;
      this._srvc.getAccountByVoucherCode(searchString).pipe(
        take(1),
        tap(x => {
          this.searchResults = x;
          this.isSearchLoading = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          this.isSearchLoading = false;
          return of(null);
        })
      ).subscribe();
    }
  }
  private _searchByLoyaltiesEmail = (searchString: string) => {
    if (searchString) {
      this.isSearchLoading = true;
      this._srvc.getAccountByLoyaltiesEmail(searchString).pipe(
        take(1),
        tap(x => {
          x = x.map(y => {
            y.loyaltiesList = y.loyaltiesList?.filter(ll => ll.account?.email == searchString);
            return y;
          });
          this.searchResults = x;
          this.isSearchLoading = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
          this.isSearchLoading = false;
          return of(null);
        })
      ).subscribe();
    }
  }

  private _getAccounts = (contactId: string, accountIds: string[]) => {
    this._srvc.getAccountByIds(contactId, accountIds).pipe(
      take(1),
      tap(acc => {
        if (acc) {
          if (acc.length === 1) {
            this._r.navigate(['main/account/' + acc[0].id]);
          } else {
            this.searchResults = acc;
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }
}



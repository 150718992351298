<div class="row py-5 bg-gray-0">
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Personal Details
        </h6>
        <div class="row">
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Salutation</label>
            <select [disabled]="!st.isEditOn" class="form-select" [(ngModel)]="st.model!.salutation">
              <option [ngValue]="null"></option>
              <option *ngFor="let s of st.salutations" [ngValue]="s">{{s}}</option>
            </select>
          </div>
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Gender</label>
            <val-select-box #v [disabled]="!st.isEditOn" [lookup]="st.genders" [valueField]="null" [displayField]="null" [(value)]="st.model!.gender" [showValidationIfEmpty] = "false">
              <val-validation>
                <ng-template valValidator="custom" [valvFunctionRef]="validateGender" valvMessage="Gender is required"></ng-template>
              </val-validation>
            </val-select-box>
          </div>
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">First name</label>
            <val-text-box #v placeholder="Enter first name" [disabled]="!st.isEditOn" [(value)]="st.model!.firstName" [showValidationIfEmpty] = "false">
              <val-validation>
                <ng-template valValidator="custom" [valvFunctionRef]="validateFirstName" valvMessage="First name is required"></ng-template>
              </val-validation>
            </val-text-box>
          </div>
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Last name</label>
            <val-text-box #v placeholder="Enter last name" [disabled]="!st.isEditOn" [(value)]="st.model!.lastName" [showValidationIfEmpty] = "false">
              <val-validation>
                <ng-template valValidator="custom" [valvFunctionRef]="validateLastName" valvMessage="Last name is required"></ng-template>
              </val-validation>
            </val-text-box>
          </div>
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Language</label>
            <val-select-box #v [disabled]="!st.isEditOn" [lookup]="st.languages" [valueField]="'code'" [displayField]="'name'" [(value)]="st.model!.language" [showValidationIfEmpty] = "false">
              <val-validation>
                <ng-template valValidator="custom" [valvFunctionRef]="validateLanguage" valvMessage="Language is required"></ng-template>
              </val-validation>
            </val-select-box>
          </div>
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Date of Birth</label>
            <val-date-input-mask #v mask="99.99.9999" [max]="todayDate" [customDateFormat]="'dd.MM.yyyy'" [disabled]="!st.isEditOn" [closeOnSelect]="true" [startYear]="1900" [(value)]="st.model!.birthDate!" [format]="'shortDate'" [showValidationIfEmpty] = "false">
            </val-date-input-mask>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Address Details
        </h6>
        <div class="row">
          <div class="col-12 mb-3">
            <label class="form-label">Street</label>
            <input [disabled]="!st.isEditOn" type="text" [(ngModel)]="st.model!.address!.street" class="form-control" placeholder="Enter street address" />
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">City</label>
            <input [disabled]="!st.isEditOn" type="text" [(ngModel)]="st.model!.address!.city!" class="form-control" placeholder="Enter city" />
          </div>
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Zip</label>
            <input [disabled]="!st.isEditOn" type="text" [(ngModel)]="st.model!.address!.zipCode" class="form-control" placeholder="Enter zip" />
          </div>
          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Country {{st.isEditOn?'*':''}}</label>
            <val-select-box #v [disabled]="!st.isEditOn" [lookup]="st.countries" [valueField]="'code'" [(value)]="st.model!.address!.country" [showValidationIfEmpty] = "false">
              <val-validation>
                <ng-template valValidator="custom" [valvFunctionRef]="validateCountry" valvMessage="Country is required"></ng-template>
              </val-validation>
            </val-select-box>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Contact Details
        </h6>
        <div class="row">
          <div class="col-12 mb-3">
            <label class="form-label">Email {{st.isEditOn?'*':''}}</label>
            <div class="d-flex flex-row">
              <val-text-box #v placeholder="Enter email" [disabled]="!st.isEditOn" (change)="emailChange()" [(value)]="st.model!.contact!.email" #email [showValidationIfEmpty] = "false">
                <val-validation [showInParentElement]="false">
                  <ng-template valValidator="email" [valvFunctionRef]="validateEmail" valvMessage="{{errorMessageEmail}}"></ng-template>
                  <!-- <ng-template valValidator="custom" [valvFunctionRef]="validateEmailAndPhone" valvMessage="Email or Phone is required"></ng-template>
                  <ng-template valValidator="custom" [valvFunctionRef]="validMailReq" valvMessage="{{errorMessageEmail}}"></ng-template>
                 -->
                  <!-- <ng-template valValidator="email" valvMessage="Email is invalid"></ng-template> -->
                </val-validation>
              </val-text-box>
              <button *ngIf="!st.isEditOn && st.model!.contact!.email" class="btn btn-sm btn-secondary btn-copy float-end" [vrcClipboard]="email.value!" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Phone {{st.isEditOn?'*':''}}</label>
            <div class="d-flex flex-row">
              <val-text-box #phone #v placeholder="Enter number" (change)="phoneChange()" [disabled]="!st.isEditOn" [(value)]="st.model!.contact!.phone!" [showValidationIfEmpty] = "false">
                <val-validation [showInParentElement]="false">
                  <ng-template valValidator="phone" [valvFunctionRef]="validateTel" valvMessage="{{errorMessagePhone}}"></ng-template>
                  <!-- <ng-template valValidator="custom" [valvFunctionRef]="validateEmailAndPhone" valvMessage="Email or Phone is required"></ng-template>
                  <ng-template valValidator="custom" [valvFunctionRef]="validPhoneReq" valvMessage="Phone number is not valid"></ng-template>
                   -->
                  <!-- <ng-template valValidator="phone" valvMessage="Phone number is invalid, format is like +38598123456"></ng-template> -->
                </val-validation>
              </val-text-box>
              <button *ngIf="!st.isEditOn && st.model!.contact!.phone!" class="btn btn-sm btn-secondary btn-copy float-end" [vrcClipboard]="phone.value!" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Viber</label>
            <div class="d-flex flex-row">
              <input placeholder="Enter viber id" [disabled]="!st.isEditOn" type="text" [(ngModel)]="st.model!.contact!.viber" class="form-control" #viber />
              <button *ngIf="!st.isEditOn && !(!st.model!.contact!.viber)" class="btn btn-sm btn-secondary btn-copy float-end" [vrcClipboard]="viber.value" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Facebook messenger</label>
            <div class="d-flex flex-row">
              <input placeholder="Enter facebook id" [disabled]="!st.isEditOn" type="text" [(ngModel)]="st.model!.contact!.messenger" class="form-control" #messenger />
              <button *ngIf="!st.isEditOn && !(!st.model!.contact!.messenger)" class="btn btn-sm btn-secondary btn-copy float-end" [vrcClipboard]="messenger.value" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          <div class="row">
            <div class="col-12">
              Additional Contact
              <button *ngIf="st.isEditOn" (click)="addPhone()" type="button" class="btn btn-secondary btn-contact-add float-end">
                <i class="vi vi-add-circle"></i>
                Phone
              </button>
              <button *ngIf="st.isEditOn" (click)="addEmail()" type="button" class="btn btn-secondary btn-contact-add float-end me-3">
                <i class="vi vi-add-circle"></i>
                Email
              </button>
            </div>
          </div>
        </h6>
        <div class="row">
          <div *ngIf="st.phones?st.phones.length>0:false" class="col-12 mb-3">
            <label class="form-label">Phone</label>
            <div *ngFor="let phone of st.phones" class="d-flex flex-row">
              <val-text-box placeholder="Enter number" #additionalPhone #v [disabled]="!st.isEditOn" [(value)]="phone!.data" class="mx-2">
                <val-validation [showInParentElement]="false">
                  <ng-template valValidator="phone" valvMessage="Phone is not valid, format is like +38598123456"></ng-template>
                  <ng-template valValidator="required" valvMessage="Phone is required"></ng-template>
                </val-validation>
              </val-text-box>
              <button *ngIf="st.isEditOn" (click)="deleteAdditionalPhone(phone)" type="button" class="btn btn-sm text-danger float-end">
                <i class="vi vi-trash"></i>
              </button>
              <button *ngIf="!st.isEditOn" class="btn btn-sm btn-secondary btn-copy float-end" [vrcClipboard]="additionalPhone.value!" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </div>
          </div>
          <div *ngIf="st.emails?st.emails.length>0:false" class="col-12">
            <label class="form-label">Email</label>
            <div *ngFor="let email of st.emails" class="d-flex flex-row">
              <val-text-box #v placeholder="Enter email" [disabled]="!st.isEditOn" [(value)]="email.data" class="mx-2" #additionalEmail>
                <val-validation [showInParentElement]="false">
                  <ng-template valValidator="email" valvMessage="Email is not valid"></ng-template>
                  <ng-template valValidator="required" valvMessage="Email is required"></ng-template>
                </val-validation>
              </val-text-box>
              <button *ngIf="st.isEditOn" (click)="deleteAdditionalEmail(email)" type="button" class="btn btn-sm text-danger float-end">
                <i class="vi vi-trash"></i>
              </button>
              <button *ngIf="!st.isEditOn" class="btn btn-sm btn-secondary btn-copy float-end" [vrcClipboard]="additionalEmail.value!" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Campaigns
        </h6>
        <vrc-options exclude="ad_email" [lookup]="st.advertisingPermissions" [model]="st.model!.advertisingPermissions" [dictionaryValues]="st.dictionaryValues" [isEditOn]="st.isEditOn"></vrc-options>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Newsletters
        </h6>
        <vrc-options exclude="ad_email" [lookup]="st.products" [model]="st.model!.products" [dictionaryValues]="st.dictionaryValues" [isEditOn]="st.isEditOn"></vrc-options>
      </div>
    </div>
  </div>
  <div *ngIf="st.model!.mappingFields?.crm?.ad_accountid" class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          CRM ID
        </h6>
        <div class="row">
          <div class="col-12 mb-3">
            <span class="badge badge-copy bg-gray-50 text-gray-700">
              {{st.model!.mappingFields?.crm?.ad_accountid}}
              <button class="btn btn-copy ms-4" [vrcClipboard]="st.model!.mappingFields?.crm?.ad_accountid!" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="st.model!.loyalties && st.model!.loyalties.length>0" class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Loyalty Number
        </h6>
        <div class="row">
          <div *ngFor="let l of st.model!.loyalties" class="col-auto mb-3">
            <span class="badge badge-copy bg-gray-50 text-gray-700">
              {{l.cardNumber}}
              <button class="btn btn-copy ms-4" [vrcClipboard]="l.cardNumber?l.cardNumber.toString():''" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Interests
        </h6>
        <div class="row">
          <div class="col-12 mb-3">
            <vrc-interests [model]="st.model!" [isEditOn]="st.isEditOn"></vrc-interests>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngSwitch]="st.model?.additionalInformation?.isBlacklisted" class="col-12 col-sm-6 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Black List Guest
        </h6>
        <div class="row">
          <div class="col-12 mb-5">
            <button [disabled]="!st.isEditOn" *ngSwitchCase="true" type="button" class="btn btn-secondary">
              <i class="vi vi-person-off me-4"></i>
              Not Black List Guest
            </button>
            <button [disabled]="!st.isEditOn" *ngSwitchDefault type="button" class="btn btn-danger">
              <i class="vi vi-person-off-white me-4"></i>
              Black List Guest
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 p-3">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          System
        </h6>
        <div class="row">

          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Created date</label>
            <div class="d-flex flex-row">
              <p>{{st.model!.createdDate | date: 'short'}}</p>
            </div>
          </div>

          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Modified date</label>
            <div class="d-flex flex-row">
              <p>{{st.model!.modifiedDate | date: 'short'}}</p>
            </div>
          </div>

          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Created by user</label>
            <div class="d-flex flex-row">
              <p>{{st.model!.createdByUser}}</p>
            </div>
          </div>

          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Modified by user</label>
            <div class="d-flex flex-row">
              <p>{{st.model!.modifiedByUser}}</p>
            </div>
          </div>

          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Created by system</label>
            <div class="d-flex flex-row">
              <p>{{st.model!.createdBySystem}}</p>
            </div>
          </div>

          <div class="col-12 col-sm-6 mb-3 pe-sm-5">
            <label class="form-label">Modified by system</label>
            <div class="d-flex flex-row">
              <p>{{st.model!.modifiedBySystem}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
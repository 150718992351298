import {
  FormatWidth,
  FormStyle,
  getLocaleDateFormat,
  getLocaleDateTimeFormat,
  getLocaleDayNames,
  getLocaleMonthNames,
  getLocaleTimeFormat,
  TranslationWidth
} from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { DatePickerCellModel } from './date-picker-cell.model';
import { DatePickerWeeksModel } from './date-picker-weeks.model';

@Component({
  selector: 'val-date-picker',
  templateUrl: './val-date-picker.component.html',
  styleUrls: ['./val-date-picker.component.scss']
})
export class ValDatePickerComponent implements OnInit {
  private _fdw = 0;
  private _loaded = false;
  private _startYear = 0;
  private _endYear = 0;
  private _value: Date | null = null;
  private _startOffset = 30;
  private _endOffset = 30;
  private _min: Date | null = null;
  private _max: Date | null = null;
  private _disabledDays: Array<Date> = [];

  private _endYearIsSet = false;
  private _startYearIsSet = false;
  private _year = 0;
  private _month = 0;
  public hours: Array<{ value: number, displayValue: string }> = [];
  public minutes: Array<{ value: number, displayValue: string }> = [];
  public set hour(x: number) {
    if (this._loaded) {
      this._value?.setHours(x);
      if (!this.showControls) {
        if (this.returnType === 'string') {
          this.valueChange.emit(this._getDateAsIsoString(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value));
        } else {
          this.valueChange.emit(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value);
        }
      }
    }
  }
  public get hour() {
    return this._value?.getHours()??0;
  }
  public set minute(x: number) {
    if (this._loaded) {
      this._value?.setMinutes(x);
      if (!this.showControls) {
        if (this.returnType === 'string') {
          this.valueChange.emit(this._getDateAsIsoString(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value));
        } else {
          this.valueChange.emit(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value);
        }
      }
    }
  }
  public get minute() {
    return this._value?.getMinutes()??0;
  }
  public set year(x: number) {
    if (this._loaded) {
      if (!this._startYearIsSet) {
        this._startYear = x - this._startOffset;
      }
      if (!this._endYearIsSet) {
        this._endYear = x + this._endOffset;
      }
      this.years = this._createYears(this._startYear, this._endYear);
      this.model = this._createModel(x, this._month, this._value, this._min, this._max);
    }
    this._year = x;
  }
  public get year() { return this._year; }

  public set month(x: number) {
    this._month = x;
    if (this._loaded) {
      this.model = this._createModel(this._year, this._month, this._value, this._min, this._max);
    }
  }
  public get month() { return this._month; }
  public model: Array<DatePickerWeeksModel> = new Array<DatePickerWeeksModel>();

  public weekdays: Array<{ id: number, name: string, sequence: number | null }> = [];

  public months: Array<{ id: number, name: string }> = [];
  public years: Array<number> = [];

  @Input() set value(x: Date | string) {
    this._value = this._copyDate(this._getDate(x));
    if (x && !this._value) {
      this._value = this._parseDate(x as string);
    }
    this._value = this._value ? (this.returnUtcValue ? zonedTimeToUtc(this._value, 'gmt') : this._value) : this._value;
    if (this._loaded && this._value) {
      const year = this._value.getFullYear();
      const month = this._value.getMonth();
      const day = this._value.getDate();
      this.model.forEach(m => m.days.forEach(d => {
        if (d.date?.getFullYear() === year && d.date.getMonth() === month && d.date.getDate() === day) {
          d.isSelected = true;
        } else {
          d.isSelected = false;
        }
      }));
    }
  }
  @Input() set firstDayOfTheWeek(x: number) {
    this._fdw = x ? x : 0;
  }

  @Input() isHoverEnabled = true;
  @Input() returnUtcValue = false;
  @Input() returnType: 'string' | 'date' = 'date';
  @Input() showYear = true;
  @Input() showMonth = true;
  @Input() showControls = false;
  @Input() showTime = false;
  @Input() set disabledDays(x: Array<Date>) {
    this._disabledDays = x;
    // if (this._loaded) {
    //   this.model = this._createModel(this._year, this._month, this._value, this._min, this._max);
    // }
  }
  @Input() set min(x: Date | string | null) {
    this._min = this._copyDate(this._getDate(x));
    if (x && !this._min) {
      this._min = this._parseDate(x as string);
    }
    if (this._min) { this._min.setHours(0, 0, 0, 0); }

    if (this._loaded) {
      this._minMaxChanged(this.model, this._min, this._max);
    }
  }
  @Input() set max(x: Date | string | null) {
    this._max = this._copyDate(this._getDate(x));
    if (x && !this._max) {
      this._max = this._parseDate(x as string);
    }
    if (this._max) { this._max.setHours(0, 0, 0, 0); }

    if (this._loaded) {
      this._minMaxChanged(this.model, this._min, this._max);
    }
  }

  @Input() set startYear(x: number) {
    this._startYear = x;
    if (!this._startYear) {
      this._startYearIsSet = false;
      const year = new Date().getFullYear();
      this._startYear = year - this._startOffset;
    } else {
      this._startYearIsSet = true;
    }
  }
  @Input() set endYear(x: number) {
    this._endYear = x;
    if (!this._endYear) {
      this._endYearIsSet = false;
      const year = new Date().getFullYear();
      this._endYear = year + this._endOffset;
    } else {
      this._endYearIsSet = true;
    }
  }
  @Output() valueChange = new EventEmitter<Date | string | null>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onMouseover = new EventEmitter<{ date: Date | null, el: HTMLElement }>();

  constructor(@Inject(LOCALE_ID) private _locale: string) { }

  ngOnInit() {
    if (!this._startYear) {
      const year = new Date().getFullYear();
      this._startYear = year - this._startOffset;
    } else {
      this._startOffset = (new Date().getFullYear()) - this._startYear;
    }
    if (!this._endYear) {
      const year = new Date().getFullYear();
      this._endYear = year + this._endOffset;
    } else {
      this._endOffset = this._endYear - (new Date().getFullYear());
    }
    this._value = this._value ? this._value : new Date();
    this.years = this._createYears(this._startYear, this._endYear);
    this.weekdays = this._createWeekdays();
    this.months = this._createMonths();
    this._year = this._value.getFullYear();
    this._month = this._value.getMonth();
    this.model = this._createModel(this._year, this._month, this._value, this._min, this._max);
    for (let i = 0; i < 24; i++) {
      this.hours.push({ value: i, displayValue: this._zeroPad(i, 2) });
    }
    for (let i = 0; i < 60; i++) {
      this.minutes.push({ value: i, displayValue: this._zeroPad(i, 2) });
    }
    this._loaded = true;
  }

  public dayClick = (d: DatePickerCellModel) => {
    if (d.isDisabled) { return; }
    this._value = d.date;
    this.model.forEach(x => x.days.forEach(y => y.isSelected = false));
    d.isSelected = true;
    if (!this.showControls) {
      if (this.returnType === 'string') {
        this.valueChange.emit(this._getDateAsIsoString(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value));
      } else {
        this.valueChange.emit(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value);
      }
    }
  }
  public dayMouseover = (d: Date | null, el: HTMLElement) => {
    this.onMouseover.emit({ date: d, el });
  }

  public confirm = () => {
    if (this.returnType === 'string') {
      this.valueChange.emit(this._getDateAsIsoString(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value));
    } else {
      this.valueChange.emit(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value);
    }
  }

  public cancel = () => {
    if (this.returnType === 'string') {
      this.valueChange.emit(this._getDateAsIsoString(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value));
    } else {
      this.valueChange.emit(this.returnUtcValue ? zonedTimeToUtc(this._value??0, 'gmt') : this._value);
    }
  }
  private _createModel = (year: number, month: number, selectedDate: Date | null, min: Date | null, max: Date | null): Array<DatePickerWeeksModel> => {
    const firstWdInMonth = new Date(year, month, 1).getDay();
    const firstWd = this.weekdays.find(x => x.id === firstWdInMonth)??null;
    const tmpWeeks = this._createWeeks(firstWd, year, month, selectedDate, min, max);
    return tmpWeeks;
  }


  private _createWeeks = (
    firstWdInMonth: { id: number, name: string, sequence: number | null } | null,
    year: number, month: number, selectedDate: Date | null, min: Date | null, max: Date | null
  ): Array<DatePickerWeeksModel> => {

    const ret = new Array<DatePickerWeeksModel>();
    const today = new Date();
    const minYear = min ? min.getFullYear() : 0;
    const minMonth = min ? min.getMonth() : 0;
    const minDay = min ? min.getDate() : 0;
    const minSum = minYear * 10000 + (minMonth + 1) * 100 + minDay;
    const maxYear = max ? max.getFullYear() : 0;
    const maxMonth = max ? max.getMonth() : 0;
    const maxDay = max ? max.getDate() : 0;
    const maxSum = maxYear * 10000 + (maxMonth + 1) * 100 + maxDay;
    let currentDate = new Date(year, month, 0);
    if ((firstWdInMonth?.sequence??0) > 0) {
      currentDate.setDate(currentDate.getDate() - (firstWdInMonth?.sequence??0) + 1);
    } else {
      currentDate = new Date(year, month, 1);
    }
    for (let w = 0; w < 6; w++) {
      const tmp = new DatePickerWeeksModel();
      tmp.days = [];
      for (let wd = 0; wd < 7; wd++) {
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const currentSum = currentYear * 10000 + (currentMonth + 1) * 100 + currentDay;
        const isselected = selectedDate?.getFullYear() === currentYear && selectedDate.getMonth() === currentMonth && selectedDate.getDate() === currentDay;
        const istoday = today.getFullYear() === currentYear && today.getMonth() === currentMonth && today.getDate() === currentDay;
        let isdisabled = min ? currentSum < minSum ? true : false : false;
        isdisabled = isdisabled ? true : max ? currentSum > maxSum ? true : false : false;
        if (!isdisabled) {
          isdisabled = this._disabledDays && this._disabledDays.length > 0 ? this._disabledDays.filter(dd => {
            const sum = dd.getFullYear() * 10000 + (dd.getMonth() + 1) * 100 + dd.getDate();
            return sum === currentSum;
          }).length > 0 : false;
        }
        tmp.days.push(
          new DatePickerCellModel(
            currentDay, new Date(currentDate), isselected, istoday, month === currentMonth, isdisabled
          )
        );
        currentDate.setDate(currentDay + 1);
      }
      ret.push(tmp);
    }

    return ret;
  }

  private _createWeekdays = (): Array<{ id: number, name: string, sequence: number | null }> => {
    const weekdays: Array<{ id: number, name: string, sequence: number | null }> = [];
    const weekDaysNames = getLocaleDayNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated);
    for (let i = 0; i < weekDaysNames.length; i++) {
      weekdays.push({ id: i, name: weekDaysNames[i], sequence: null });
    }
    weekdays.forEach(w => {
      let seq = w.id - this._fdw;
      if (seq < 0) {
        seq = 7 + seq;
      }
      w.sequence = seq;
    });
    return weekdays.sort(this._sortFn);
  }

  private _createMonths = (): Array<{ id: number, name: string }> => {
    const months: Array<{ id: number, name: string, sequence: number | null }> = [];
    const localMonthNames = getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated);
    for (let i = 0; i < localMonthNames.length; i++) {
      months.push({ id: i, name: localMonthNames[i], sequence: i });
    }

    return months.sort(this._sortFn);
  }
  private _createYears = (start: number, end: number): Array<number> => {
    const years: Array<number> = [];
    for (let i = start; i <= end; i++) {
      years.push(i);
    }
    return years;
  }
  private _sortFn = (a: { id: number, name: string, sequence: number | null }, b: { id: number, name: string, sequence: number | null }) => {
    return (a.sequence??0) < (b.sequence??0) ? -1 : a.sequence === b.sequence ? 0 : 1;
  }
  private _getDateExtended = (format: string, value: string): Date | null => {
    let m = this._getDate(parse(value, format, new Date(), {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true
    }));
    if (!m) {
      format = format.replace('y.', 'y');
      m = this._getDate(parse(value, format, new Date(), {
        useAdditionalDayOfYearTokens: true,
        useAdditionalWeekYearTokens: true
      }));
    }
    return m;
  }
  private _parseDate = (x: string): Date | null => {
    // try to parse each of predefined time and date format
    let tmp = x.split('. ').join('.').split('/ ').join('/').split(' /').join('/');
    const timeFormats: Array<string> = [];
    const dateFormats: Array<string> = [];
    const monthNames: Array<{ local: Array<string>, en: Array<string> }> = [];
    const weekNames: Array<{ local: Array<string>, en: Array<string> }> = [];
    const datetimeFormats: Array<string> = [];
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Short));
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Medium));
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Long));
    datetimeFormats.push(getLocaleDateTimeFormat(this._locale, FormatWidth.Full));
    monthNames.push({
      local: getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Wide) as string[],
      en: getLocaleMonthNames('en', FormStyle.Format, TranslationWidth.Wide) as string[]
    });
    monthNames.push({
      local: getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated) as string[],
      en: getLocaleMonthNames('en', FormStyle.Format, TranslationWidth.Abbreviated) as string[]
    });
    monthNames.push({
      local: getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Short) as string[],
      en: getLocaleMonthNames('en', FormStyle.Format, TranslationWidth.Short) as string[]
    });
    weekNames.push({
      local: getLocaleDayNames(this._locale, FormStyle.Format, TranslationWidth.Wide) as string[],
      en: getLocaleDayNames('en', FormStyle.Format, TranslationWidth.Wide) as string[]
    });
    weekNames.push({
      local: getLocaleDayNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated) as string[],
      en: getLocaleDayNames('en', FormStyle.Format, TranslationWidth.Abbreviated) as string[]
    });
    weekNames.push({
      local: getLocaleDayNames(this._locale, FormStyle.Format, TranslationWidth.Short) as string[],
      en: getLocaleDayNames('en', FormStyle.Format, TranslationWidth.Short) as string[]
    });
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Short));
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Medium));
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Long));
    timeFormats.push(getLocaleTimeFormat(this._locale, FormatWidth.Full));
    dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Short).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
    dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Medium).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
    dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Long).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
    dateFormats.push(getLocaleDateFormat(this._locale, FormatWidth.Full).split('. ').join('.').split('/ ').join('/').split(' /').join('/'));
    let replaced = false;
    for (let i = 0; i < monthNames.length; i++) {
      for (let j = 0; j < monthNames[i].local.length; j++) {
        if (tmp.indexOf(monthNames[i].local[j]) > -1) {
          tmp = tmp.replace(monthNames[i].local[j], monthNames[i].en[j]);
          replaced = true;
          break;
        }
      }
      if (replaced) { break; }
    }
    replaced = false;
    for (let i = 0; i < weekNames.length; i++) {
      for (let j = 0; j < weekNames[i].local.length; j++) {
        if (tmp.indexOf(weekNames[i].local[j]) > -1) {
          tmp = tmp.replace(weekNames[i].local[j], weekNames[i].en[j]);
          replaced = true;
          break;
        }
      }
      if (replaced) { break; }
    }
    let tmpFormat = '';
    let m: Date | null = null;
    if (tmp.indexOf(':') > 0) {
      for (let i = 0; i < dateFormats.length; i++) {
        for (let j = 0; j < timeFormats.length; j++) {
          tmpFormat = datetimeFormats[i].replace('{1}', dateFormats[i]).replace('{0}', timeFormats[j].split('(')[0]);
          m = this._getDateExtended(tmpFormat, tmp.split('(')[0]);
          if (!m) {
            tmpFormat = datetimeFormats[i].replace('{1} ', dateFormats[i]).replace('{0}', timeFormats[j]);
            m = this._getDateExtended(tmpFormat, tmp);
          }
          if (m) { break; }
        }
        if (m) { break; }
      }
    } else {
      for (let i = 0; i < dateFormats.length; i++) {
        tmpFormat = dateFormats[i];
        m = this._getDateExtended(tmpFormat, tmp);
        if (m) { break; }
      }
    }
    return m;
  }
  private _minMaxChanged = (model: Array<DatePickerWeeksModel>, min: Date | null, max: Date | null) => {
    const minYear = min ? min.getFullYear() : 0;
    const minMonth = min ? min.getMonth() : 0;
    const minDay = min ? min.getDate() : 0;
    const minSum = minYear * 10000 + (minMonth + 1) * 100 + minDay;
    const maxYear = max ? max.getFullYear() : 0;
    const maxMonth = max ? max.getMonth() : 0;
    const maxDay = max ? max.getDate() : 0;
    const maxSum = maxYear * 10000 + (maxMonth + 1) * 100 + maxDay;
    model.forEach(m => m.days.forEach(d => {
      const currentYear = d.date?.getFullYear();
      const currentMonth = d.date?.getMonth();
      const currentDay = d.date?.getDate();
      const currentSum = (currentYear ?? 0) * 10000 + ((currentMonth ?? 0) + 1) * 100 + (currentDay ?? 0);
      let isdisabled = min ? currentSum < minSum ? true : false : false;
      isdisabled = isdisabled ? true : max ? currentSum > maxSum ? true : false : false;
      d.isDisabled = isdisabled;
    }));
  }
  private _zeroPad(value: number, len: number): string {
    let text = String(value);

    while (text.length < len) {
      text = '0' + text;
    }

    return text;
  }
  private _getDateAsIsoString = (input: Date | null): string => {
    return (input?.getFullYear() ?? 0) + '-' + this._zeroPad((1 + (input?.getMonth() ?? 0)), 2) + '-' + this._zeroPad((input?.getDate() ?? 0), 2) +
      'T' + this._zeroPad((input?.getHours() ?? 0), 2) + ':' + this._zeroPad((input?.getMinutes() ?? 0), 2) + ':00.000Z';
  }
  private _getDate = (input: string | Date | null): Date | null => {
    if (!input) {
      return null;
    }
    if (!(input instanceof Date)) {
      const timestamp = Date.parse(input);

      if (isNaN(timestamp) === false) {
        return new Date(input);
      }
      return null;
    } else {
      // invalid date
      if (isNaN(input.getTime())) {
        return null;
      }
    }
    return input as Date;
  }
  private _copyDate = (input: string | Date | null): Date | null => {
    let tmp: Date = input as Date;
    if (!input) {
      return null;
    }
    if (!(input instanceof Date)) {
      tmp = new Date(input);
    }
    const hours = tmp.getHours();
    const minutes = tmp.getMinutes();
    const miliseconds = tmp.getMilliseconds();
    const seconds = tmp.getSeconds();
    const year = tmp.getFullYear();
    const month = tmp.getMonth();
    const day = tmp.getDate();
    return new Date(year, month, day, hours, minutes, seconds, miliseconds);
  }
}

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable()
export class VrcConfigService {
  private readonly _getVrcBaseUrl = () => {
    return environment.scheme +
      environment.host +
      environment.port +
      '/api/v' +
      environment.version +
      '/';
  };
  private readonly getCrmBaseUrl = () => {
    const url = environment.scheme +
      environment.crm_host +
      environment.port +
      '/api/v' +
      environment.crmVersion +
      '/CrmMdpApi/';
    return url;
  };
  private readonly getValidationBaseUrl = () => {
    const url = environment.scheme +
      environment.validation_host +
      environment.port +
      '/api/v' +
      environment.validationVersion +
      '/Validation/';
    return url;
  };
  private readonly getOfferBaseUrl = () => {
    const url = environment.scheme +
      environment.offer_host +
      environment.port +
      '/api/v' +
      environment.offerVersion +
      '/VrcOffer/';
    return url;
  };

  private readonly _getBaseUrlAuth =()=>{
    const url = environment.scheme +
    environment.auth_host +
    environment.port +
    '/api/v' +
    environment.authVersion +
    '/Auth/';
    return url;
  };

  public readonly getTokenUrl = this._getBaseUrlAuth() + 'getToken';
  public readonly refreshTokenUrl = this._getVrcBaseUrl() + 'Auth/renewToken';
  public readonly getUserTokenUrl = this._getBaseUrlAuth() + 'getUserToken';
  public readonly subscriptionUrl = this._getBaseUrlAuth() + 'subscriptionkey';
  public readonly accountUrl = this._getVrcBaseUrl() + 'account';
  public readonly accountAndLoyaltyUrl = this._getVrcBaseUrl() + 'Account/accountandloyalty';
  public readonly accountByIdsUrl = this._getVrcBaseUrl() + 'Account/allByIds';
  public readonly accountByIdUrl = this._getVrcBaseUrl() + 'Account/byId';
  public readonly accountByEmailUrl = this._getVrcBaseUrl() + 'Account/byEmail';
  public readonly accountByVoucherCodeUrl = this._getVrcBaseUrl() + 'Account/byVoucherCode';
  public readonly accountByLoyaltiesEmailUrl = this._getVrcBaseUrl() + 'Account/byLoyaltiesEmail';
  public readonly accountNoteUrl = this._getVrcBaseUrl() + 'AccountNotes';
  public readonly offerUrl = this._getVrcBaseUrl() + 'Offer';
  public readonly reservationUrl = this._getVrcBaseUrl() + 'Reservation';
  public readonly voucherUrl = this._getVrcBaseUrl() + 'Voucher';
  public readonly loyaltyUrl = this._getVrcBaseUrl() + 'Loyalty';
  public readonly createLoyaltyUrl = this._getVrcBaseUrl() + 'Account/loyalty';
  public readonly interestUrl = this._getVrcBaseUrl() + 'Lookup/interest';
  public readonly advertisingPermissionsUrl = this._getVrcBaseUrl() + 'Lookup/advertisingPermissions';
  public readonly productsUrl = this._getVrcBaseUrl() + 'Lookup/products';
  public readonly familyStatusUrl = this._getVrcBaseUrl() + 'Lookup/familyStatus';
  public readonly getOfferUrl = this.getOfferBaseUrl() + 'getOffer';
  public readonly cancelOfferUrl = this.getOfferBaseUrl() + 'cancelOffer';
  public readonly confirmOfferUrl = this.getOfferBaseUrl() + 'confirmOffer';
  public readonly getWebOfferLinkUrl = this.getOfferBaseUrl() + 'getWebOfferLink';
  public readonly offerEmailUrl = this._getVrcBaseUrl() + 'Communication/offerEmail';
  public readonly transferPointsUrl = this.getCrmBaseUrl() + 'transferPoints';
  public readonly claimPointsUrl = this.getCrmBaseUrl() + 'claimPoints';
  public readonly resendLoyaltyConfirmationUrl = this.getCrmBaseUrl() + 'resendLoyaltyConfirmation';
  public readonly loyaltyForgottenPasswordUrl = this.getCrmBaseUrl() + 'loyaltyForgottenPassword';
  public readonly loyaltyNewEmailUrl = this.getCrmBaseUrl() + 'loyaltyEmailChange';
  public readonly communicationUrl = this._getVrcBaseUrl() + 'Communication';
  public readonly communicationHistoryUrl = this._getVrcBaseUrl() + 'Communication/communicationHistory';
  public readonly retrieveHierarchyForEmployeeUrl = this.getCrmBaseUrl();
  public readonly valamarProfileNewTabUrl = environment.app_url;
  public readonly validateOfferUrl = this._getVrcBaseUrl() + 'Offer/validateOffer';
  public readonly validateEmailUrl = this.getValidationBaseUrl() + 'validateEmail'
  public readonly validatePhoneUrl = this.getValidationBaseUrl() + 'validatePhone'
}

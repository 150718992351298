import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { VrcStorageService } from 'src/app/services/vrc-storage.service';
import { VrcDataService } from 'src/app/services/vrc-data.service';
import { AccountNote } from 'src/app/models/mdp/account-note.model';
import { catchError, EMPTY, finalize, take, tap } from 'rxjs';

@Component({
	selector: 'vrc-account-notes',
	templateUrl: './vrc-account-notes.component.html',
	styleUrls: ['./vrc-account-notes.component.scss']
})
export class VrcAccountNotesComponent implements AfterViewInit {
	@ViewChild('scrollContainer') scrollContainer!: ElementRef;
	@Input() visible!: boolean;
	@Output() visibleChange = new EventEmitter<boolean>();

	accountNotes: AccountNote[] | null = null;
	newNoteContent: string | null = null;
	errorMessage: string | null = null;
	loading: boolean | null = true;
	timer: Date | null = null;

	constructor(
		public vrcStorage: VrcStorageService,
		private vrcDataService: VrcDataService
	) {
		this.getAccountNotes(this.vrcStorage.model?.id ?? null);
	}

	ngAfterViewInit() {
		new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
				}
			}
		}).observe(this.scrollContainer.nativeElement, { childList: true });
	}

	visibilityChange(event: boolean) {
		this.visible = event;
		this.visibleChange.emit(this.visible);
	}

	submitButtonHandler() {
		if (!this.newNoteContent || this.newNoteContent.length < 5) {
			this.errorMessage = "Message must be minimum 5 characters long!";
			return;
		}

		const accountNote = new AccountNote();
		accountNote.accountId = this.vrcStorage.model?.id ?? null;
		accountNote.content = this.newNoteContent;

		this.addAccountNote(accountNote);
		this.errorMessage = null;
	}

	addAccountNote(accountNote: AccountNote) {
		this.vrcDataService.addAccountNote(accountNote)
			.pipe(
				take(1),
				tap(data => {
					this.accountNotes?.push(data);
				}),
				catchError(error => {
					console.error('Error:', error);
					return EMPTY;
				}),
				finalize(() => {
					this.newNoteContent = null;
				})
			)
			.subscribe();
	}

	getAccountNotes(accountId: string | null) {
		if (accountId == null) return;
		this.vrcDataService.getAccountNotes(accountId)
			.pipe(
				take(1),
				tap(data => {
					this.accountNotes = data;
					this.loading = false;
				}),
				catchError(error => {
					console.error('Error:', error);
					return EMPTY;
				})
			)
			.subscribe();
	}

	deleteAccountNote(accountNoteId: string | null) {
		if (accountNoteId == null) return;
		this.vrcDataService.deleteAccountNote(accountNoteId)
			.pipe(
				take(1),
				tap(() => {
					this.accountNotes = this.accountNotes?.filter(note => note.id != accountNoteId) ?? null;
				}),
				catchError(error => {
					console.error('Error:', error);
					return EMPTY;
				})
			)
			.subscribe();
	}
}
<div class="row pb-5 bg-secondary pt-3">
    <div class="col-12">
      <nav class="nav nav-pills d-inline-flex ms-5">
        <ng-container *ngFor="let m of valamartistList; last as isLast">
          <a #active="routerLinkActive" class="nav-link" [routerLink]="m.cardInformation?.cardNumber" routerLinkActive="active">
            {{m.cardInformation?.cardNumber}}
            <button class="btn btn-copy ms-4" [vrcClipboard]="m.cardInformation?.cardNumber??''" pTooltip="Copy to clipboard" tooltipPosition="bottom">
              <i class="vi vi-copycontent"></i>
            </button>
          </a>
          <div *ngIf="!isLast && !active.isActive" class="nav-link-splitter"></div>
        </ng-container>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
import { Component, OnInit } from '@angular/core';
import { LoyalityDetailsModel } from '../models/mdp/loyality-details.model';
import { VrcStorageService } from '../services/vrc-storage.service';

@Component({
  selector: 'vrc-valamartist',
  templateUrl: './vrc-valamartist.component.html',
})
export class VrcValamartistComponent implements OnInit {
  public valamartistList: LoyalityDetailsModel[] = [];
  constructor(
    public st: VrcStorageService
  ) { }

  public ngOnInit(): void {
    this.valamartistList = this.st.model ? this.st.model?.loyaltiesList.filter(x => x.cardInformation?.cardNumber?.startsWith("4")) : [];
  }

  public menuClick = (m: LoyalityDetailsModel, e: MouseEvent) => {
    e.preventDefault();
    this.valamartistList.forEach(x => x.isSelected = false);
    m.isSelected = true;
    this.st.selectedValamartistNumber = m.cardInformation?.cardNumber as string;
  }
}

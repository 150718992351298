<div class="row">
  <div class="col-12 col-sm-6 pe-sm-5 pb-5">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Personal details
        </h6>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Phone number</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerPhoneNumber">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">First name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerFirstName">
          </div>
        </div>
        <div class="row mb-2">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last name</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.customerLastName">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 pe-4 pe-sm-0 pb-4">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Details
        </h6>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Work time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="(model?.workTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Queue time</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext"
              [ngModel]="(model?.queueTime??0) | secondsToTimePeriod">
          </div>
        </div>
        <div class="row">
          <label class="col-auto col-form-label label-communication text-nowrap me-3">Last agent</label>
          <div class="col">
            <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.lastAgent">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-4">
    <div class="card card-shadow border-0">
      <div class="card-body">
        <h6 class="mb-4">
          Contact
        </h6>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Contact Id</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="contactId">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">Channel</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.channel">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Start date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.startDate | date:'short'">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">End date</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.endDate | date:'short'">
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">State</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.state">
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">Direction</label>
                <input type="text" disabled class="form-control-plaintext" [ngModel]="model?.direction">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-4">
	<div class="card card-shadow border-0 mb-4">
		<div class="card-body">
			<h6>
				Dispositions
			</h6>
			<div *ngIf="communication?.contacts?.length === 0; else dispositionTable">
				<span class="sendto-cc-text-style"> <i>There is no disposition notes!</i></span>
			</div>
			<ng-template #dispositionTable>
				<p-table [value]="communication?.contacts!" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll">
					<ng-template pTemplate="header">
					  <tr>
						<th style="width: 2rem" pResizableColumn></th>
						<th style="width: 2rem" pResizableColumn>
						  Name
						</th>
						<th style="width: 2rem" pResizableColumn>
						  Agent
						</th>
						<th pResizableColumn>
						  Note
						</th>
					  </tr>
					</ng-template>
					<ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
					  <tr>
						<td>{{rowIndex + 1}}</td>
						<td>{{contact.disposition.dispositionName}}</td>
						<td>{{contact.agent}}</td>
						<td>{{contact.disposition.notes}}</td>
					  </tr>
					</ng-template>
				  </p-table>
			</ng-template>
		</div>
	</div>
</div>
  <div *ngIf="model?.urlRecording" class="col-12 pt-4">
    <div class="row">
      <div class="col-auto mx-5">
        <h6 class="mb-0 mt-1 text-nowrap">
          Call recording
        </h6>
      </div>
      <div class="col-auto">
        <a class="btn btn-sm btn-secondary" [href]="model?.urlRecording" target="_blank">
          Listen to phone call
          <i class="vi vi-play-circle-filled ms-3"></i>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="model?.recordings?.length || sources.length " class="col-12 pt-4">
    <div *ngIf="recordingLoaded;else loading" class="row">
      <div class="col-auto mx-5">
        <h6 class="mb-0 mt-1 text-nowrap">
          Call recordings
        </h6>
      </div>
      <div *ngFor="let s of sources" class="col-auto">
        <audio controls>
          <source [attr.src]="s" type="audio/wav">
          Your browser does not support the audio tag.
        </audio>
      </div>
    </div>
    <ng-template #loading>
      <div class="row">
        <div class="col-auto mx-5">
          <h6 class="mb-0 mt-1 text-nowrap">
            Loading call recordings ...
          </h6>
        </div>
      </div>
    </ng-template>
  </div>
</div>
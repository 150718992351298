import { Pipe, PipeTransform } from '@angular/core';

import { ReservationModel } from '../models/mdp/reservation.model';
import { VrcStorageService } from '../services/vrc-storage.service';

@Pipe({ name: 'hasActiveReservationPipe' })
export class HasActiveReservationPipe implements PipeTransform {
    constructor(private _s: VrcStorageService) { }
    transform(data: Array<ReservationModel>): string | null {
        return (data && data.length > 0) ?
            data.filter(x => x.status === this._s.reservationStatuses.RESERVED
                || x.status === this._s.reservationStatuses.NEW
                || x.status === this._s.reservationStatuses.MODIFY)
                .map(x => x.phobsId).reduceRight((_a, b) => b, null) : null;
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ValComponent } from '../../interfaces/val-component.interface';
import { ValComponentBaseComponent } from '../val-component-base/val-component-base.component';

@Component({
    selector: 'val-text-box',
    templateUrl: './val-text-box.component.html'
})
export class ValTextBoxComponent extends ValComponentBaseComponent implements OnInit, ValComponent {
    private _loaded = false;
    public set model(x: string) {
        this._v = x;
        if (this.isValidated) {
            this.validate();
        }
        if (this._loaded) {
            if (this.valueChangeEvent === 'change') {
                this.apply();
            }
        }
    }
    public get model() { return this._v; }
    @Input() set value(x: string | null) {
        this._v = x;
        if (this.isValidated) {
            this.validate();
        }
    }
    get value(): string | null { return this._v; }
    @Input() placeholder="";
    @Output() valueChange = new EventEmitter<string | null>();
    ngOnInit(): void {
        this._loaded = true;
    }
    override apply(): void {
        this.valueChange.emit(this.value);
    }

    focusOut = () => {
        if (!this._loaded) { return; }
        if (this.valueChangeEvent === 'blur') {
            this.apply();
        }
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VrcAccountComponent } from './components/vrc-account.component';
import { VrcCampaignsComponent } from './components/vrc-campaings.component';
import { VrcCommunicationHistoryComponent } from './components/vrc-communication-history.component';
import { VrcCommunicationThreadComponent } from "./components/vrc-communication-thread.component";
import { VrcCommunicationComponent } from './components/vrc-communication.component';
import { VrcEmptyComponent } from './components/vrc-empty.component';
import { VrcInformationComponent } from './components/vrc-information.component';
import { VrcLoginComponent } from './components/vrc-login.component';
import { VrcLoyalityDetailsComponent } from './components/vrc-loyality-details.component';
import { VrcLoyalityComponent } from './components/vrc-loyality.component';
import { VrcMainComponent } from './components/vrc-main.component';
import { VrcOfferComponent } from './components/vrc-offer.component';
import { VrcReservationsComponent } from './components/vrc-reservations.component';
import { VrcSearchComponent } from './components/vrc-search.component';
import { VrcSummaryComponent } from './components/vrc-summary.component';
import { vrcAccountResolver } from './services/vrc-account-resolver.service';
import { vrcAuthGuard } from './services/vrc-auth.guard';
import { VrcValamartistComponent } from './components/vrc-valamartist.component';
import { VrcValamartistDetailsComponent } from './components/vrc-valamartist-details.component';
import { VrcNiceLoginComponent } from './components/vrc-nice-login.component';
import { vrcLookupResolver } from './services/vrc-lookup-resolver.service';
import { vrcOfferResolver } from './services/vrc-offer-resolver.service';

const routes: Routes = [
  {
    path: 'login',
    component: VrcLoginComponent
  },
  {
    path: 'nice',
    component: VrcNiceLoginComponent
  },
  {
    path: 'main',
    canActivate: [vrcAuthGuard],
    resolve: {lookup:vrcLookupResolver},
    component: VrcMainComponent,
    children: [
      {
        path: '',
        component: VrcEmptyComponent
      },
      {
        path: 'account/:id',
        resolve: {account:vrcAccountResolver},
        component: VrcAccountComponent,
        children: [
          {
            path: '',
            redirectTo: 'summary',
            pathMatch: 'prefix'
          },
          {
            path: 'summary',
            component: VrcSummaryComponent,
          },
          {
            path: 'details',
            component: VrcInformationComponent,
          },
          {
            path: 'communication',
            component: VrcCommunicationComponent,
          },
          {
            path: 'history',
            component: VrcCommunicationHistoryComponent,
          },
          {
              path: 'thread',
              component: VrcCommunicationThreadComponent,
          },
          {
            path: 'reservations',
            component: VrcReservationsComponent,
          },
          {
            path: 'campaigns',
            component: VrcCampaignsComponent,
          },
          {
            path: 'loyalty',
            component: VrcLoyalityComponent,
            children: [
              {
                path: ':id',
                component: VrcLoyalityDetailsComponent,
              }
            ]
          },
          {
            path: 'valamartist',
            component: VrcValamartistComponent,
            children: [
              {
                path: ':id',
                component: VrcValamartistDetailsComponent,
              }
            ]
          }
        ]
      },
      {
        path: 'search/:type/:phrase',
        component: VrcSearchComponent
      },
      {
        path: 'offer/:id/:accountId',
        resolve: {offer:vrcOfferResolver},
        component: VrcOfferComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'prefix'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const environment = {
  production: false,
  scheme: "https://",
  host: "api-managment-digitalizcija.azure-api.net/vrc-api-test/v2",
  auth_host: "api-managment-digitalizcija.azure-api.net/vrc-api-test-auth/v2",
  crm_host: "api-managment-digitalizcija.azure-api.net/vrc-api-test",
  offer_host: "api-managment-digitalizcija.azure-api.net/vrc-api-test",
  validation_host: "api-managment-digitalizcija.azure-api.net/vrc-api-test",
  port: "",
  version: 2,
  authVersion: 2,
  crmVersion: 1,
  offerVersion: 1,
  validationVersion: 1,
  // app_url:'https://vrc.test.valamar.hr',
  app_url: 'http://localhost:4200',
  web_url:'https://www.valamar-preview.com',
  enableServiceWorker: true,
  theme: "theme-background-test",
  env_text: "Test",
};

<div class="row">
  <div class="col-12 pb-5 px-5 bg-secondary">
    <p-table [value]="loyalityList" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="table-header d-inline-flex">Loyalty</div>
        <button [disabled]="!(loyalityList?loyalityList.length>0:false)" [routerLink]="'../loyalty' + '/' + st.selectedLoyaltyNumber" type="button"
          class="btn btn-sm btn-secondary d-inline-flex ms-5 text-uppercase">
          Show all
          <i class="vi vi-arrow-circle ms-4"></i>
        </button>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 10rem" pResizableColumn>
            Loyalty number
          </th>
          <th style="width: 10rem" pResizableColumn>
            Loyalty status
          </th>
          <th style="width: 10rem" pResizableColumn>
            Loyalty level
          </th>
          <th style="width: 10rem" pResizableColumn>
            E-mail
          </th>
          <th style="width: 10rem" pResizableColumn>
            Available points
          </th>
          <th style="width: 10rem" pResizableColumn>
            Available amount
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-loyalty>
        <tr>
          <td>
            <span class="badge badge-copy bg-gray-50 text-gray-700">
              <span>{{loyalty.cardInformation?.cardNumber}}</span>
              <button class="btn btn-copy ms-3" [vrcClipboard]="loyalty.cardInformation?.cardNumber??''" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </span>
          </td>
          <td>
            <div class="badge badge-status text-gray-700 text-uppercase"
				[class.bg-success-1]="loyalty.status === 'Active'"
				[class.bg-danger-1]="loyalty.status !== 'Active'">
				<i class="vi vi-rectangle me-3"></i>
              	{{loyalty.status}}
			</div>
          </td>
          <td>{{loyalty.level}}</td>
          <td>{{loyalty.account?.email}}</td>
          <td>{{loyalty.pointsData?.pointsAvailable ?? "-"}}</td>
          <td>{{loyalty.amountsData?.totalAmountAvailable ?? "-"}} €</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 pb-5 px-5 bg-secondary">
    <p-table [value]="valamartistList" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="table-header d-inline-flex">Valamartist</div>
        <button [disabled]="!(valamartistList?valamartistList.length>0:false)" [routerLink]="'../valamartist' + '/' + st.selectedValamartistNumber" type="button"
          class="btn btn-sm btn-secondary d-inline-flex ms-5 text-uppercase">
          Show all
          <i class="vi vi-arrow-circle ms-4"></i>
        </button>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 10rem" pResizableColumn>
            Valamartist number
          </th>
          <th style="width: 10rem" pResizableColumn>
            Level
          </th>
          <th style="width: 10rem" pResizableColumn>
            E-mail
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-loyalty>
        <tr>
          <td>
            <span class="badge badge-copy bg-gray-50 text-gray-700">
              <span>{{loyalty.cardInformation?.cardNumber}}</span>
              <button class="btn btn-copy ms-3" [vrcClipboard]="loyalty.cardInformation?.cardNumber??''" pTooltip="Copy to clipboard" tooltipPosition="bottom">
                <i class="vi vi-copycontent"></i>
              </button>
            </span>
          </td>
          <td>{{loyalty.level}}</td>
          <td>{{loyalty.account?.email}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 pb-5 px-5 bg-secondary">
    <p-table [value]="st.model?.communication!" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll" dataKey="contactId">
      <ng-template pTemplate="caption">
        <div class="table-header d-inline-flex">Communication</div>
        <button [routerLink]="'../communication'" type="button" class="btn btn-sm btn-secondary d-inline-flex ms-5 text-uppercase">
          Show all
          <i class="vi vi-arrow-circle ms-4"></i>
        </button>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5rem" pResizableColumn>
            Channel
          </th>
          <th style="width: 4rem" pResizableColumn>
            Date
          </th>
          <th pResizableColumn>
            Subject
          </th>
          <th style="width: 2rem" pResizableColumn>
            Status
          </th>
          <th style="width: 2rem" pResizableColumn>
            Replied
          </th>
          <th style="width: 2rem" pResizableColumn>
            Direction
          </th>
          <th style="width: 2rem" pResizableColumn>
            Agent
          </th>
		  <th pResizableColumn>
            Note
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-comm let-expanded="expanded">
        <tr>
          <td>
            <button [disabled]="!(comm.data.childData && comm.data.childData.length>0)" [pRowToggler]="comm" type="button" class="btn btn-sm btn-primary me-4"
              [class.btn-primary]="expanded" [class.btn-secondary]="!expanded">
              <i class="vi" [class.vi-chevron-down]="!expanded" [class.vi-chevron-up]="expanded"></i>
            </button>
            <button (click)="openDetails(comm)" class="btn btn-sm btn-secondary btn-popup">
              {{comm.channel==='Digital'? comm.channel + ' ' + comm.subChannel:comm.channel}}
              <i class="vi vi-popup ms-3"></i>
            </button>
          </td>
          <td>{{ comm.createDate | date:'short' }}</td>
          <td>{{ comm.subject }}</td>
          <td>
            <span class="badge badge-status text-gray-700 text-uppercase" [class.bg-success-1]="comm.status | isCommInStatus:st.commStatuses.Active:comm.channel"
              [class.bg-info]="comm.status | isCommInStatus:st.commStatuses.Open:comm.channel" [class.bg-gray-50]="comm.status | isCommInStatus:st.commStatuses.Parked"
              [class.bg-danger-1]="comm.status | isCommInStatus:st.commStatuses.Closed" [class.bg-danger]="comm.status | isCommInStatus:st.commStatuses.Escalated"
              [class.bg-success]="comm.status | isCommInStatus:st.commStatuses.Resolved" [class.bg-info-1]="comm.status | isCommInStatus:st.commStatuses.Pending">
              <i class="vi vi-rectangle me-3"></i>
              {{comm.status}}
            </span>
            <button *ngIf="((comm.status | isCommInStatus:st.commStatuses.Open:comm.channel) || (comm.status | isCommInStatus:st.commStatuses.Active:comm.channel))"
              (click)="showCloseDialog(comm)" class="btn btn-close-comm p-0 text-gray-700 bg-danger-1 mx-4">
              <i class="vi vi-times"></i>
            </button>
          </td>
          <td class="text-center">
            <i class="vi float-center me-3" [class.vi-radio-check]="(comm.data.childData && comm.data.childData.length>0)"
              [class.vi-radio-minus]="!(comm.data.childData && comm.data.childData.length>0)">
            </i>
          </td>
          <td>{{ comm.isOutgoing ? 'Outbound' : 'Inbound' }}</td>
          <td>{{ comm.agentName }}</td>
		  <td>{{ comm.lastDisposition?.notes }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-comm>
        <tr *ngIf="comm.data && comm.data.childData && comm.data.childData.length>0">
          <td colspan="7">
            <div class="row expanded-body">
              <div class="col-7 col-sm-8 col-md-9 col-lg-10 col-xl-11 col-xxl-12 p-5">
                <ng-template ngFor let-cd [ngForOf]="comm.data.childData" let-i="index">
                  <div *ngIf="i>0" class="w-100 border-gray-50 border-top"></div>
                  <div class="row">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Replied communication</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Channel</label>
                          <div class="w-100"></div>
                          <button (click)="openDetails(comm,cd.guid)" class="btn btn-sm btn-secondary btn-popup">
                            {{ cd.childChannel }}
                            <i class="vi vi-popup ms-3"></i>
                          </button>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Date</label>
                          <span class="form-control-plaintext disabled text-wrap">{{cd.data.createdDate | date:'short' }}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Subject</label>
                          <span class="form-control-plaintext disabled text-wrap">{{cd.data.subject}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Agent</label>
                          <span class="form-control-plaintext disabled text-wrap">{{cd.data.firstName}} {{cd.data.lastName}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 pb-5 px-5 bg-secondary">
    <p-table [value]="st.model?.offers??[]" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll" sortMode="multiple"
      [multiSortMeta]="[{field: 'checkIn', order: -1}]">
      <ng-template pTemplate="caption">
        <div class="table-header d-inline-flex">Offers</div>
        <button [routerLink]="'../reservations'" type="button" class="btn btn-sm btn-secondary d-inline-flex ms-5 text-uppercase">
          Show all
          <i class="vi vi-arrow-circle ms-3"></i>
        </button>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 12rem" pResizableColumn>
            Offer ID
          </th>
          <th style="width: 12rem" pResizableColumn>
            Item ID
          </th>
          <th style="width: 12rem" pResizableColumn>
            Property
          </th>
          <th style="width: 12rem" pResizableColumn pSortableColumn="checkIn">
            Arrival
          </th>
          <th style="width: 12rem" pResizableColumn>
            Departure
          </th>
          <th style="width: 12rem" pResizableColumn>
            Room type
          </th>
          <th style="width: 12rem" pResizableColumn>
            Total EUR
          </th>
          <th style="width: 12rem" pResizableColumn>
            Status
          </th>
          <th style="width: 12rem" pResizableColumn>
            Offer Holder
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-offer>
        <tr>
          <td>
            <span class="badge badge-open bg-secondary text-gray-700">
              <span>{{offer.mappingFields?.phobs?.offer_id}}</span>
              <button class="btn btn-copy ms-3">
                <i class="vi vi-open-in-new"></i>
              </button>
            </span>
          </td>
          <td>{{offer.mappingFields?.phobs?.item_id}}</td>
          <td>{{offer?.property?.code}}</td>
          <td>{{offer.checkIn | date:'shortDate'}}</td>
          <td>{{offer.checkOut | date:'shortDate'}}</td>
          <td>{{offer.unit?.code }}</td>
          <td>{{offer.total | number:'0.2-2'}}</td>
          <td>
			<div class="badge badge-status text-gray-700 text-uppercase"
				[class.bg-success-1]="offer.additionalInformation?.status === 'Crated'"
				[class.bg-danger-1]="offer.additionalInformation?.status !== 'Crated'">
				<i class="vi vi-rectangle me-3"></i>
				{{offer.additionalInformation?.status}}
			</div>
		  </td>
          <td>{{offer.offerHolderData?.firstName + ' ' + offer.offerHolderData?.lastName}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 pb-5 px-5 bg-secondary">
    <p-table [value]="st.model?.reservations??[]" [scrollable]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="table-header d-inline-flex">Reservations</div>
        <button [routerLink]="'../reservations'" type="button" class="btn btn-sm btn-secondary d-inline-flex ms-5 text-uppercase">
          Show all
          <i class="vi vi-arrow-circle ms-4"></i>
        </button>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 12rem" pResizableColumn>
            Phobs ID
          </th>
          <th style="width: 12rem" pResizableColumn>
            Property
          </th>
          <th style="width: 12rem" pResizableColumn>
            Arrival
          </th>
          <th style="width: 12rem" pResizableColumn>
            Departure
          </th>
          <th style="width: 12rem" pResizableColumn>
            Room type
          </th>
          <th style="width: 12rem" pResizableColumn>
            Guest Balance
          </th>
          <th style="width: 12rem" pResizableColumn>
            Offer Item
          </th>
          <th style="width: 12rem" pResizableColumn>
            Status
          </th>
          <th style="width: 12rem" pResizableColumn>
            Reservation Holder
          </th>
          <th style="width: 12rem" pResizableColumn>
            Opera ID
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-reservation>
        <tr>
          <td>
            <span *ngIf="reservation.mappingFields?.phobs?.reservationCode" class="badge badge-open bg-secondary text-gray-700">
              <span>{{reservation.mappingFields?.phobs?.reservationCode}}</span>
              <button *ngIf="reservation.phobs?.previewUrl" target="_blank" class="btn btn-copy ms-4" (click)="u.openLink(reservation.phobs?.previewUrl)">
                <i class="vi vi-open-in-new"></i>
              </button>
            </span>
          </td>
          <td>{{reservation?.property?.code }}</td>
          <td>
            {{reservation.checkIn | date:'shortDate'}}
          </td>
          <td>
            {{reservation.checkOut | date:'shortDate'}}
          </td>
          <td>{{reservation?.unit?.code}}</td>
          <td>
            {{reservation.costs?.guestBalance | number:'0.2-2'}}
          </td>
          <td>{{reservation.offer?.phobsItemId}}</td>
          	<td>
				<div class="badge badge-status text-gray-700 text-uppercase"
					[class.bg-success-1]="['RESERVED', 'NEW', 'CHECKED IN'].includes(reservation.status)"
					[class.bg-danger-1]="!['RESERVED', 'NEW', 'CHECKED IN'].includes(reservation.status)">
					<i class="vi vi-rectangle me-3"></i>
					{{reservation.status}}
				</div>
			</td>
          <td>{{(reservation.reservationHolderData?.firstName??'') + ' ' +
            (reservation.reservationHolderData?.lastName??'')}}</td>
          <td>{{reservation.mappingFields?.opera?.CONFIRMATION_NO}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 pb-5 px-5 bg-secondary">
    <div class="card card-shadow">
      <div class="card-body">
        <h6 class="mb-5 fw-bold text-gray-700">
          Interests
        </h6>
        <div class="row">
          <div class="col-12 mb-3">
            <vrc-interests [model]="st.model" [isEditOn]="false"></vrc-interests>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template valModal="sm" [(valmVisible)]="closeModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header">
    <h6 class="modal-title">
      Do you really want to close this work item?
    </h6>
    <button type="button" class="btn-close" (click)="closeModalVisible=false"></button>
  </div>
  <div class="modal-footer m-0">
    <button (click)="closeModalVisible=false" type="button" class="btn btn-secondary w-25">No</button>
    <button class="btn btn-primary w-25" (click)="closeCommunication()">Yes</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="emailModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Email details
    </h6>
    <button type="button" class="btn-close" (click)="emailModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-email-details [model]="emalDetails" [communication]="selectedCommunication" [status]="selectedCommunication.status!" [contactId]="selectedCommunication.contactId!"></vrc-communication-email-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="emailModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="chatModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Chat details
    </h6>
    <button type="button" class="btn-close" (click)="chatModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-chat-details [model]="chatDetails" [communication]="selectedCommunication" [contactId]="selectedCommunication.contactId!"></vrc-communication-chat-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="chatModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="voiceModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      Voice details
    </h6>
    <button type="button" class="btn-close" (click)="voiceModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-call-details [model]="callDetails" [communication]="selectedCommunication" [id]="selectedCommunication.id!" [contactId]="selectedCommunication.contactId!"></vrc-communication-call-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="voiceModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>

<ng-template valModal="xl" [(valmVisible)]="digitalModalVisible" [valmCloseOnBackdrop]="false">
  <div class="modal-header p-5">
    <h6 class="modal-title">
      {{digitalModalTitle}}
    </h6>
    <button type="button" class="btn-close" (click)="digitalModalVisible=false"></button>
  </div>
  <div class="modal-body bg-gray-50">
    <vrc-communication-dfo-details [model]="digitalDetails" [communication]="selectedCommunication"  [contactId]="selectedCommunication.contactId??0"></vrc-communication-dfo-details>
  </div>
  <div class="modal-footer m-0">
    <button (click)="digitalModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
  </div>
</ng-template>
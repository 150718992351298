<div class="position-relative ps-0" [style.padding-right.rem]="visible?1:0">
  <ng-content></ng-content>
  <div *ngIf="visible" class="table-scrooler bg-warning border-0 position-absolute d-flex justify-content-center align-items-center">
    <div class="border-0 rounded-0">
      <i class="vi vi-caret-left-fill"></i>
    </div>
    <button (mousedown)="leftDown($event)" (mouseup)="scrollUp($event)" (mouseleave)="scrollUp($event)" type="button" class="btn btn-sm btn-gray-100 btn-left">
      <i class="vi vi-chevron-left"></i>
    </button>
    <button (mousedown)="rightDown($event)" (mouseup)="scrollUp($event)" (mouseleave)="scrollUp($event)" type="button" class="btn btn-sm btn-primary btn-right">
      <i class="vi vi-chevron-right"></i>
    </button>
  </div>
</div>
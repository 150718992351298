import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, delay, take, tap } from 'rxjs/operators';

import { ClaimPointsModel } from '../models/claim-points.model';
import { IdNameSelectedModel } from '../models/id-name-selected.model';
import { HierarchyForEmployeeModel } from '../models/mdp/hierarchy-for-employee.model';
import { LoyalityDetailsModel } from '../models/mdp/loyality-details.model';
import { ReservationDetailsModel } from '../models/mdp/reservation-details.model';
import { ParamsModel } from '../models/params.model';
import { TransferPointsModel } from './../models/transfer-points.model';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { VrcConfigService } from '../services/vrc-config.service';
import { AccountModel } from '../models/mdp/account.model';

@Component({
  selector: 'vrc-valamartist-details',
  templateUrl: './vrc-valamartist-details.component.html',
})
export class VrcValamartistDetailsComponent implements OnInit {
  public menu: Array<IdNameSelectedModel> = [];
  public model: LoyalityDetailsModel | null = new LoyalityDetailsModel;
  public selectedMenuId: number | string = 1;
  public reservationsLoading = false;
  public hierarchyLoading = false;
  public reservationsTotalRecords = 0;
  public reservations!: Array<ReservationDetailsModel>;
  public modalVisible = false;
  public transferPointsModel: TransferPointsModel = new TransferPointsModel();
  public hierarchyTotalRecords = 0;
  public hierarchys!: HierarchyForEmployeeModel[];
  public parentLoyaliteAccount!: AccountModel;
  public reservation!: ReservationDetailsModel;
  public loyalityDetails!: LoyalityDetailsModel;
  constructor(
    private _srvc: VrcDataService,
    private _ms: MessageService,
    private _confirmationService: ConfirmationService,
    private _ar: ActivatedRoute,
    private _st: VrcStorageService,
    private _config: VrcConfigService
  ) { }
  public ngOnInit(): void {
    this._ar.params.pipe(
      delay(0),
      tap(x => {
        this._st.selectedValamartistNumber = x['id'];
        this.model = this._st.model?.loyaltiesList?.filter(x => x.cardInformation?.cardNumber === this._st.selectedValamartistNumber).reduceRight((_a: LoyalityDetailsModel | null, b) => b, null) ?? null;
        this.getvalamArtisHierarchy(this.model?.cardInformation?.cardNumber ?? '');
        if (this.model?.program == "IFF" && this.model.level !== "Employee") {
          if (this.model.parentLoyaltyId != null) {
            this.getAccountByLoyalityparant(this.model.parentLoyaltyId);
          }
        }
      })).subscribe();

    this.menu = [
      new IdNameSelectedModel(1, "Information", true),
      new IdNameSelectedModel(2, "Reservation&Stays"),
      new IdNameSelectedModel(3, "ValamArtist Hierarchy"),
    ];
  }

  public menuClick = (m: IdNameSelectedModel, e: MouseEvent) => {
    e.preventDefault();
    this.menu.forEach(x => x.isSelected = false);
    m.isSelected = true;
    this.selectedMenuId = <string | number>m.id;
  }

  public reservationsTableLazyLoad = (p: ParamsModel): void => {
    p = p ? p : new ParamsModel();
    p.extraData = this._st.model?.id;
    p.filters['loyalty.cardNumber'] =
      [{
        value: this.model?.cardInformation?.cardNumber,
        matchMode: "equals",
        operator: "and"
      }];
    this.getReservations(p);
  };
  public getReservations = (p: ParamsModel): void => {
    this.reservationsLoading = true;
    this._srvc.getReservationPage(p).pipe(
      take(1),
      tap(x => {
        this.reservationsTotalRecords = x.totalRecords;
        this.reservationsLoading = false;
        this.reservations = x.data;
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  };


  public transferPoints = () => {
    if (!this.transferPointsModel || !this.transferPointsModel.toLoyaltyCardNumber || !this.transferPointsModel.pointsTransfer) {
      return;
    }
    this._srvc
      .transferPoints(this.transferPointsModel)
      .pipe(
        take(1),
        tap(() => {
          this.modalVisible = false;
          this._ms.add({ severity: 'success', detail: 'Points transferred' });
        }),
        catchError((err: HttpErrorResponse) => {
          this.modalVisible = false;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      ).subscribe();
  }

  public openTransferModal = () => {
    this.transferPointsModel = new TransferPointsModel(this.model?.cardInformation?.cardNumber, null, null);
    this.modalVisible = true;
  }

  public claimPoints = (reservation: ReservationDetailsModel) => {
    const payload = new ClaimPointsModel(
      this.model?.cardInformation?.cardNumber,
      reservation.property?.code,
      reservation.unit?.code,
      reservation.checkIn,
      reservation.checkOut,
      reservation?.mappingFields?.phobs?.code ? reservation?.mappingFields?.phobs?.code : reservation?.mappingFields?.opera?.CONFIRMATION_NO,
      reservation?.costs?.total?.toString()
    );
    this._confirmationService.confirm({
      message: 'Are you sure that you want to claim points?',
      accept: () => {
        this._srvc.claimPoints(payload)
          .pipe(
            take(1),
            tap(() => {
              this._ms.add({ severity: 'success', detail: 'Points claimed' });
            }),
            catchError((err: HttpErrorResponse) => {
              this._ms.add({
                severity: 'error',
                summary: 'Error',
                detail: err?.error?.message,
              });
              return of(null);
            })
          )
          .subscribe();
      }
    });
  }
  public getAccountByLoyalityparant(parLoyalityId: string) {
    this._srvc.getAccountByLoyalityparant(parLoyalityId).pipe(
      take(1),
      tap(x => {
        this.parentLoyaliteAccount = x;
        if (this.parentLoyaliteAccount.lastName == null && this.parentLoyaliteAccount.firstName == null) {
          this.getLoyalityByLoyalityId(this.model?.parentLoyaltyId??'');
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }
  public getLoyalityByLoyalityId(loyalityId: string) {
    this._srvc.getLoyalityByLoyalityId(loyalityId).pipe(
      take(1),
      tap(x => {
        this.loyalityDetails = x;
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  }
  public openValamartisProfil() {
    window.open(this._config.valamarProfileNewTabUrl + '/main/account/' + this.parentLoyaliteAccount.id);
  }

  public getvalamArtisHierarchy = (cardNumber: string): void => {
    this.hierarchyLoading = true;
    this._srvc.retrieveHierarchyForEmployee(cardNumber).pipe(
      take(1),
      tap(x => {
        this.hierarchys = x.hierarchyForEmployee;
        this.hierarchyLoading = false;
        this.hierarchyTotalRecords = this.hierarchys.length;
      }),
      catchError((err: HttpErrorResponse) => {
        this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message });
        return of(null);
      })
    ).subscribe();
  };

}

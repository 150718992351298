<div class="d-flex">
  <input #input [disabled]="disabled" [placeholder]="placeholder" type="text" class="border-0 m-0 p-0 h-100 text-end" style="position: relative;flex: 1 1 auto;width: 1%;min-width: 0;" [(ngModel)]="model"
  (focus)="focus(true)" (focusout)="focus(false);focusOut();" (click)="inputClick($event)">
  <button *ngIf="showButton && !disabled" class="btn btn-sm btn-outline-secondary ms-3 py-0 px-3 me-0 border-0" type="button">
    <i class="far fa-calendar"></i>
  </button>
</div>
<ng-template [valDropDown]="rootElement" [(valddVisible)]="ddVisible" [valddDisabled]="disabled">
  <val-date-picker [disabledDays]="disabledDays" [returnUtcValue]="returnUtcValue" [min]="min" [max]="max" [firstDayOfTheWeek]="1" (valueChange)="setDate($event)" [startYear]="startYear" [endYear]="endYear" [value]="value"></val-date-picker>
</ng-template>
<ng-content></ng-content>
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FilterMetadata, MessageService } from 'primeng/api';
import { of, timer } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { OfferDetailsModel } from '../models/mdp/offer-details.model';
import { ReservationDetailsModel } from '../models/mdp/reservation-details.model';
import { SendOfferModel } from '../models/mdp/send-offer.model';
import { ParamsModel } from '../models/params.model';
import { ValUtilsService } from '../services/val-utils.service';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';

@Component({
  selector: 'vrc-reservations',
  templateUrl: './vrc-reservations.component.html'
})
export class VrcReservationsComponent implements OnInit {
  private _accountId?: string;
  public emailOfferVisible = false;
  public selectedOfferId!: string;
  public emailOfferContent!: SendOfferModel;
  public reservationsTotalRecords = 0;
  public reservationsLoading!: boolean;
  public offersTotalRecords = 0;
  public offersLoading!: boolean;
  public isOfferLoading = false;
  public sendOffer!: SendOfferModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public webPreview: any;
  public webPreviewIsLoading = false;
  public reservations: Array<ReservationDetailsModel> = [];
  public offers: Array<OfferDetailsModel> = [];

  // intelisence helpers
  public offer!: OfferDetailsModel;
  public reservation!: ReservationDetailsModel;

  constructor(
    private _srvc: VrcDataService,
    public st: VrcStorageService,
    private _ms: MessageService,
    public u: ValUtilsService,
  ) { }
  public ngOnInit(): void {
    this._accountId = this.st.model?.id ?? '';
    this.offersLoading = true;
    this.reservationsLoading = true;
  }

  public reservationsTableLazyLoad = (p: ParamsModel): void => {
    p.extraData = this._accountId;
    if (p.multiSortMeta == null) {
      p.multiSortMeta = [{ field: 'checkIn', order: -1 }];
    }
    const pCopy = this.u.deepCopyObject(p);
    if (pCopy.filters['checkIn']) {
      for (const x of (<FilterMetadata[]>pCopy.filters['checkIn'])) {
        x.value = this.u.getDateAsIsoString(x.value);
      }
    }
    if (pCopy.filters['checkOut']) {
      (<FilterMetadata[]>pCopy.filters['checkOut']).forEach(x => {
        x.value = this.u.getDateAsIsoString(x.value);
      });
    }
    const lastName: FilterMetadata[] = [];
    if (pCopy.filters['reservationHolderData.firstName']) {
      (<FilterMetadata[]>pCopy.filters['reservationHolderData.firstName']).forEach(x => {
        if (x.value) {
          const parts = x.value.split(' ');
          if (parts.length > 1) {
            lastName.push({ value: parts[1], matchMode: x.matchMode, operator: x.operator });
            x.value = parts[0];
          }
        }
      });
    }
    if (lastName.length > 0) {
      (<FilterMetadata[]>pCopy.filters['reservationHolderData.lastName']) = [];
      lastName.forEach(x => {
        (<FilterMetadata[]>pCopy.filters['reservationHolderData.lastName']).push(x);
      });
    }
    if (!this._accountId) {
      // ExpressionChangedAfterItHasBeenCheckedError fix
      timer(0)
        .pipe(
          take(1),
          tap(() => (this.reservationsLoading = false))
        )
        .subscribe();
      return;
    }
    this.getReservations(pCopy);
  };
  private getReservations = (p: ParamsModel): void => {
    this.reservationsLoading = true;
    this._srvc
      .getReservationPage(p)
      .pipe(
        take(1),
        tap((x) => {
          this.reservationsTotalRecords = x.totalRecords;
          this.reservations = x.data;
          this.reservationsLoading = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message, });
          this.reservationsLoading = false;
          return of(null);
        })
      )
      .subscribe();
  };
  public offersTableLazyLoad = (p: ParamsModel): void => {
    p.filters['accountId'] = [
      {
        value: this._accountId,
        matchMode: 'equals',
        operator: 'and',
      },
    ];
    if (p.multiSortMeta == null) {
      p.multiSortMeta = [{ field: 'checkIn', order: -1 }];
    }
    const pCopy = this.u.deepCopyObject(p);
    if (pCopy.filters['checkIn']) {
      (<FilterMetadata[]>pCopy.filters['checkIn']).forEach(x => {
        x.value = this.u.getDateAsIsoString(x.value);
      });
    }
    if (pCopy.filters['checkOut']) {
      (<FilterMetadata[]>pCopy.filters['checkOut']).forEach(x => {
        x.value = this.u.getDateAsIsoString(x.value);
      });
    }
    const lastName: FilterMetadata[] = [];
    if (pCopy.filters['offerHolderData.firstName']) {
      (<FilterMetadata[]>pCopy.filters['offerHolderData.firstName']).forEach(x => {
        if (x.value) {
          const parts = x.value.split(' ');
          if (parts.length > 1) {
            lastName.push({ value: parts[1], matchMode: x.matchMode, operator: x.operator });
            x.value = parts[0];
          }
        }
      });
    }
    if (lastName.length > 0) {
      (<FilterMetadata[]>pCopy.filters['offerHolderData.lastName']) = [];
      lastName.forEach(x => {
        (<FilterMetadata[]>pCopy.filters['offerHolderData.lastName']).push(x);
      });
    }
    if (!this._accountId) {
      // ExpressionChangedAfterItHasBeenCheckedError fix
      timer(0)
        .pipe(
          take(1),
          tap(() => (this.offersLoading = false))
        )
        .subscribe();
      return;
    }
    this.getOffers(pCopy);
  };
  public getOffers = (p: ParamsModel): void => {
    this.offersLoading = true;
    this._srvc
      .getOfferPage(p)
      .pipe(
        take(1),
        tap((x) => {
          this.offersTotalRecords = x.totalRecords;
          this.offers = x.data;
          this.offersLoading = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message, });
          this.offersLoading = false;
          return of(null);
        })
      )
      .subscribe();
  };

  public getEmailOffer = (offerId: string) => {
    this.isOfferLoading = true;
    this._ms.add({ severity: 'info', summary: 'Loading', detail: 'Offer email is loading' });
    this._srvc.getOffer(offerId)
      .pipe(
        take(1),
        tap((content) => {
          this.isOfferLoading = false;
          this.selectedOfferId = offerId;
          this.emailOfferContent = content;
          this.emailOfferVisible = true;
          this._ms.add({ severity: 'success', summary: 'Success', detail: 'Offer email is loaded' });
        }),
        catchError((err: HttpErrorResponse) => {
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message, });
          this.isOfferLoading = false;
          return of(null);
        })
      )
      .subscribe();
  };

  public getWebPreview(offerId: string) {
    this.webPreviewIsLoading = true;
    this._srvc.getWebOfferLink(offerId)
      .pipe(
        take(1),
        tap((content) => {
          this.webPreviewIsLoading = false;
          window.open(content.link ?? '', '_blank');
        }),
        catchError((err: HttpErrorResponse) => {
          this.webPreviewIsLoading = false;
          this._ms.add({ severity: 'error', summary: 'Error', detail: err?.error?.message, });
          return of(null);
        })
      )
      .subscribe();
  }
}

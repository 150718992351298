import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';

import { ValDatePickerModule } from '../val-date-picker/val-date-picker.module';
import { ValDropDownModule } from '../val-drop-down/val-drop-down.module';
import { ValDateInputMaskComponent } from './val-date-input-mask.component';

@NgModule({
    imports: [
        CommonModule, 
        FormsModule,
        InputMaskModule,
        ValDatePickerModule,
        ValDropDownModule
    ],
    declarations: [
        ValDateInputMaskComponent
    ],
    exports: [ValDateInputMaskComponent],
    providers: []
})
export class ValDateInputMaskModule { }

import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ValComponent } from '../../interfaces/val-component.interface';
import { ValComponentBaseComponent } from '../val-component-base/val-component-base.component';

@Component({
	selector: 'val-select-box',
	templateUrl: './val-select-box.component.html'
})
export class ValSelectBoxComponent extends ValComponentBaseComponent implements OnInit, OnDestroy, ValComponent {
	private _sub!: Subscription;
	private _loaded = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private _lookup: Array<any> = [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public set model(x: any) {
		if (this._v === x) { return; }
		this._v = x;
		if (this.isValidated && this._loaded) {
			this.validate();
		}
		if (this._loaded) {
			if (this.valueChangeEvent === 'change') {
				this.valueChange.emit(this._v);
			}
		}
	}
	public get model() { return this._v; }

	@HostBinding('class') get _class() {
		return this._css;
	}
	@Input() isNullable = false;
	@Input() placeholder = "";
	@Input() valueField: string | null = 'id';
	@Input() displayField: string | null = 'name';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() set lookup(x: Array<any>) {
		this._lookup = x;
	}
	get lookup() { return this._lookup; }
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() set value(x: any) {
		if (this._v === x) { return; }
		this._v = x;
		if (this.isValidated && this._loaded) {
			this.validate();
		}
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get value(): any { return this._v; }
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Output() valueChange = new EventEmitter<any>();
	ngOnInit(): void {
		this._loaded = true;
		this._sub = this.styleChange.pipe(tap(() => {
			this._getCssClass();
		})).subscribe();
	}
	ngOnDestroy(): void {
		this._sub.unsubscribe();
	}
	override apply(): void {
		this.valueChange.emit(this.value);
	}

	focusOut = () => {
		if (!this._loaded) { return; }
		if (this.valueChangeEvent === 'blur') {
			this.apply();
		}
	}
	private _getCssClass = () => {
		setTimeout(() => {
			this._css = (this._isFocused ?
				this.isValidated ?
					this.isValid ?
						!this._v && !this.showValidationIfEmpty ?
							'form-control vrc-control component-focus' :
							'form-control vrc-control vrc-select px-0 component-focus is-valid' :
						'form-control vrc-control vrc-select ps-0 position-relative component-focus is-invalid' :
					'form-control vrc-control vrc-select px-0 component-focus' + (this.required ? ' is-required' : '') :
				this.isValidated ?
					this.isValid ?
						!this._v && !this.showValidationIfEmpty ?
							'form-control vrc-control component-focus' :
							'form-control px-0 is-valid' :
						'form-control vrc-control vrc-select ps-0 position-relative is-invalid' :
					'form-control vrc-control vrc-select px-0' + (this.required ? ' is-required' : '')) + (this.disabled ? (this._isValid ? ' disabled disabled-border' : ' disabled') : '');
		});
	}
}

<div class="row py-5 bg-gray-0">
  <div class="col-12 col-sm-6 col-xl-7 p-3 space-left">
    <vrc-scroll-to-top *ngIf="showScroll$ | async" (scrollToTop)="onScrollToTop()"></vrc-scroll-to-top>
    <div class="col col-12"
         style="
          height: 100px;
          position: sticky;
          padding: 0.5rem;
          top: 100px;
          z-index: 1000;">


    <form action="" #filterForm="ngForm" (ngSubmit)="filterThreads(filterForm.value)">
      <div class="row">
        <div class="col-3">
          <select
            ngModel
            name="communicationType"
            id="communicationType"
            class="form-select"
            aria-label=".form-select-sm example"
            style="min-width:11.25rem;"
          >
            <option value="" selected disabled hidden>Communication type</option>
            <option *ngFor="let comType of commTypes" [ngValue]="comType.key">
              {{ comType.label }}
            </option>
          </select>
        </div>
        <div class="col-3">
          <val-date-input-mask
            mask="99.99.9999"
            [customDateFormat]="'dd.MM.yyyy'"
            [disabled]="false"
            [closeOnSelect]="true"
            [startYear]="1900"
            [(value)]="todayStart"
            [format]="'shortDate'"
          >
          </val-date-input-mask>
        </div>
        <div class="col-3">
          <val-date-input-mask
            mask="99.99.9999"
            [customDateFormat]="'dd.MM.yyyy'"
            [disabled]="false"
            [closeOnSelect]="true"
            [startYear]="1900"
            [(value)]="todayEnd"
            [format]="'shortDate'"

            >
            </val-date-input-mask>
          </div>
          <div class="col-3" style="display: flex;">
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              style="width: 70%; margin-left: 30%;"
            >
              Filter Threads
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="card card-shadow">
      <div class="card-body">
      <div class="paginator"
           *ngIf="paginator$ | async as paginator"
           infiniteScroll
           [infiniteScrollDistance]="2"
           [infiniteScrollThrottle]="50"
           (scrolled)="loadMoreProducts(paginator)"
      >
        <div class="thread-container" *ngFor="let comm of paginator.items">
          <vrc-communication-thread-messages-container [threadCommunication]=comm></vrc-communication-thread-messages-container>
        </div>
        <p class="text-center my-3" *ngIf="!paginator.hasMorePages">You have reached the end of the threads.</p>
      </div>
      </div>
    </div>
  </div>
</div>


import { Component, Input } from '@angular/core';

@Component({
  selector: 'vrc-options',
  templateUrl: './vrc-options.component.html'
})
export class VrcOptionsComponent {
  public object = Object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() dictionaryValues: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() model: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() lookup: any;
  @Input() isEditOn = false;
  @Input() exclude!: string;
}

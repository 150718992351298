import { FilterMetadata, LazyLoadEvent, SortMeta } from "primeng/api";

interface LazyLoadEventFixed extends Omit<LazyLoadEvent, 'filters'> {
    filters: {
        [s: string]: [FilterMetadata] | FilterMetadata
    }
}

export class ParamsModel implements LazyLoadEventFixed {
    constructor(
        public first: number = 0,
        public rows: number = 5,
        public sortField?: string,
        public sortOrder?: number,
        public multiSortMeta: SortMeta[] = [],
        public filters: {
            [s: string]: [FilterMetadata] | FilterMetadata
        } = {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public globalFilter: any = null,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public extraData: any = null,
        public last?: number | undefined,
        public forceUpdate?: (() => void) | undefined,
    ) { }
}

export class ParamsModelThread implements LazyLoadEventFixed {
  constructor(
    public first: number = 0,
    public rows: number | undefined,
    public sortField?: string,
    public sortOrder?: number,
    public multiSortMeta: SortMeta[] = [],
    public filters: {
      [s: string]: [FilterMetadata] | FilterMetadata
    } = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public globalFilter: any = null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public extraData: any = null,
    public last?: number | undefined,
    public forceUpdate?: (() => void) | undefined,
  ) { }
}

<div class="row py-5 bg-gray-0">
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card border-secondary shadow-sm">
      <div class="card-body">
        <h6 class="mb-4 text-gray-700">
          Campaigns
        </h6>
        <vrc-options exclude="ad_email" [lookup]="st.advertisingPermissions" [model]="st.model?.advertisingPermissions" [dictionaryValues]="st.dictionaryValues"
          [isEditOn]="false"></vrc-options>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-xl-4 p-3">
    <div class="card border-secondary shadow-sm">
      <div class="card-body">
        <h6 class="mb-4 text-gray-700">
          Newsletters
        </h6>
        <vrc-options exclude="ad_email" [lookup]="st.products" [model]="st.model?.products" [dictionaryValues]="st.dictionaryValues" [isEditOn]="false"></vrc-options>
      </div>
    </div>
  </div>
  <div class="col-12 pb-5 mt-3 px-5 bg-secondary">
    <val-table-scroller>
      <p-table [value]="vouchers" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="vouchersLoading" [paginator]="true" currentPageReportTemplate="{first}-{last} OF {totalRecords}" [filterDelay]="0" [lazy]="true"
        (onLazyLoad)="vouchersTableLazyLoad($event)" [totalRecords]="vouchersTotalRecords" sortMode="multiple" [reorderableColumns]="true" [scrollable]="true"
        columnResizeMode="expand" [resizableColumns]="true" [multiSortMeta]="[{field: 'status', order: 1}]">

        <ng-template pTemplate="caption">
          <div class="table-header">
            Vouchers
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="promotion.name">
              Promotion
              <p-sortIcon field="promotion.name"></p-sortIcon>
              <p-columnFilter type="text" field="promotion.name" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="code">
              Code
              <p-sortIcon field="code"></p-sortIcon>
              <p-columnFilter type="text" field="code" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="status">
              Status
              <p-sortIcon field="status"></p-sortIcon>
              <p-columnFilter type="text" field="status" display="menu" class="float-end"></p-columnFilter>
            </th>
            <th style="width: 12rem" pReorderableColumn pResizableColumn pSortableColumn="expiryDate">
              Expiry date
              <p-sortIcon field="expiryDate"></p-sortIcon>
              <p-columnFilter type="date" field="expiryDate" display="menu" class="float-end">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy" [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th style="width: 11rem" pReorderableColumn pResizableColumn pSortableColumn="promotion.usageRules.bookingDateFrom">
              Booking From
              <p-sortIcon field="promotion.usageRules.bookingDateFrom"></p-sortIcon>
              <p-columnFilter type="date" field="promotion.usageRules.bookingDateFrom" display="menu" class="float-end">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy" [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
            <th style="width: 11rem" pReorderableColumn pResizableColumn pSortableColumn="promotion.usageRules.bookingDateTo">
              Booking To
              <p-sortIcon field="promotion.usageRules.bookingDateTo"></p-sortIcon>
              <p-columnFilter type="date" field="promotion.usageRules.bookingDateTo" display="menu" class="float-end">
                <ng-template pTemplate="filter" let-value let-filterCallback="filterCallback">
                  <p-calendar [showOnFocus]="false" yearRange="2020:2050" [yearNavigator]="true" [ngModel]="value" (ngModelChange)="filterCallback($event)"
                    dateFormat="dd.mm.yy" [showTime]="false" [showIcon]="true">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-voucher let-expanded="expanded">
          <tr>
            <td>
              <button [pRowToggler]="voucher" type="button" class="btn btn-sm btn-primary me-5" [class.btn-primary]="expanded" [class.btn-secondary]="!expanded">
                <i class="vi" [class.vi-chevron-down]="!expanded" [class.vi-chevron-up]="expanded"></i>
              </button>
              {{ voucher.promotion.name }}
            </td>
            <td>{{ voucher.code }}</td>
            <td>
              <span class="badge badge-status text-gray-700 text-uppercase" [class.bg-success-1]="voucher.status==='Available'"
                [class.bg-danger-1]="voucher.status==='Expired' || voucher.status==='Used'">
                <i class="vi vi-rectangle me-3"></i>
                {{voucher.status}}
              </span>
            </td>
            <td>{{ voucher.expiryDate | date: "shortDate" }} </td>
            <td>{{ voucher.promotion.usageRules.bookingDateFrom | date: "shortDate" }} </td>
            <td>{{ voucher.promotion.usageRules.bookingDateTo | date: "shortDate" }} </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-voucher>
          <tr>
            <td colspan="6">
              <div class="row expanded-body">
                <div class="col-5 col-sm-6 col-md-7 col-lg-9 col-xl-10 col-xxl-10 p-5">
                  <div class="row">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Info</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Voucher Promotion Type</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.type}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5"></div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Issued</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.issued}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Rules</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Can use</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.usageRules.canUse}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Promotion valid from</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.promotionPeriod.validFrom |
                            date: 'shortDate'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Promotion valid to</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.promotionPeriod.validTo |
                            date: 'shortDate'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Currency</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.usageRules.currency}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Minimum value</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.usageRules.minimumValue |
                            number:'0.2-2'}}</span>
                        </div>
                        <div class="col-6 col-sm-3 mb-5">
                          <label class="form-label">Value</label>
                          <span class="form-control-plaintext disabled">{{voucher.promotion.usageRules.value |
                            number:'0.2-2'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Travel information's</h6>
                      </div>
                      <div class="row">
                        <div class="col-4 col-sm-3 mb-5">
                          <label class="form-label">Stay from</label>
                          <ng-template ngFor let-t [ngForOf]="voucher.promotion.usageRules.travelInfo">
                            <span class="form-control-plaintext disabled">{{t.dateFrom | date:'shortDate'}}</span>
                            <div class="w-100"></div>
                          </ng-template>
                        </div>
                        <div class="col-4 col-sm-3 mb-5">
                          <label class="form-label">Stay to</label>
                          <ng-template ngFor let-t [ngForOf]="voucher.promotion.usageRules.travelInfo">
                            <span class="form-control-plaintext disabled">{{t.dateTo | date:'shortDate'}}</span>
                            <div class="w-100"></div>
                          </ng-template>
                        </div>
                        <div class="col-4 col-sm-3 mb-5">
                          <label class="form-label">Minimum nights</label>
                          <ng-template ngFor let-t [ngForOf]="voucher.promotion.usageRules.travelInfo" >
                            <span class="form-control-plaintext disabled">{{voucher.promotion.usageRules.minimumStay}}</span>
                            <div class="w-100"></div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Restrictions</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 mb-5">
                          <label class="form-label">Property</label>
                          <p-listbox *ngIf="voucher?.promotion?.properties?.length>0" [options]="voucher.promotion.properties" [(ngModel)]="selectedProperty"
                            optionLabel="name" [listStyle]="{'height':'150px'}"></p-listbox>
                        </div>
                        <div class="col-6 mb-5">
                          <label class="form-label">Room types</label>
                          <p-listbox *ngIf="voucher?.promotion?.properties?.length>0" [options]="selectedProperty ? selectedProperty.roomTypes : []" optionLabel="name"
                            [listStyle]="{'height':'150px'}"></p-listbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 border-gray-50 border-top"></div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <i class="vi vi-dot-circle mx-3"></i>
                    </div>
                    <div class="col">
                      <div class="row">
                        <h6 class="table-body-header">Ambassador</h6>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-3 mb-3 mb-sm-5">
                          <label class="form-label">Contact</label>
                          <span class="form-control-plaintext disabled">{{voucher.ambassador}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">No vouchers found.</td>
          </tr>
        </ng-template>
      </p-table>
    </val-table-scroller>
  </div>
</div>
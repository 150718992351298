import { Pipe, PipeTransform } from '@angular/core';
import { LoyalityDetailsModel } from '../models/mdp/loyality-details.model';

@Pipe({ name: 'isSelectedLoyaltyProgram' })
export class IsSelectedLoyaltyProgramPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(loyalties: Array<LoyalityDetailsModel> | undefined, program: string, not = false, trigger = 0): boolean {
        if (loyalties && loyalties.length > 0 && loyalties.filter(x => not ? x.program !== program : x.program === program).length > 0) {
            return true;
        }
        return false;
    }
}

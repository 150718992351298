import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';

import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { VrcBrowserStorageService } from '../services/vrc-browser-storage.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vrc-main',
  templateUrl: './vrc-main.component.html'
})
export class VrcMainComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  public theme = environment.theme;
  public envText = environment.env_text;
  public searchString = '';
  public searchType: 'ALL' | 'EMAIL' | 'VOUCHER' | 'LOYALTIES' = 'ALL';
  public searchOptions: Array<{ key: string, label: string }> = [
    { key: 'ALL', label: 'All' },
    { key: 'EMAIL', label: 'Email' },
    { key: 'VOUCHER', label: 'Voucher' },
    { key: 'LOYALTIES', label: 'Loyalty email' }
  ];

  public get isLocalOrAzureUser() {
    return this._bst.isAzureUser || this._bst.userData === 'API';
  }

  constructor(
    private _bst: VrcBrowserStorageService,
    private _authService: MsalService,
    public st: VrcStorageService,
    private _r: Router,
    private _ars: ActivatedRoute,
    private _broadcastService: MsalBroadcastService,
  ) { }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public ngOnInit(): void {
    this.st.updateClosedCommunicationEvent.pipe(
      takeUntil(this._destroying$),
      tap(communication => {
        if (this.st.model && this.st.model.communication && this.st.model.communication.length > 0) {
          const communicationIndex = this.st.model.communication.findIndex(item => item.contactId === communication.contactId);

          if (communicationIndex > -1) {
            this.st.model.communication[communicationIndex] = communication;
          }
        }
      })).subscribe();
    this._r.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1),
      tap(() => {
        if (this._ars.snapshot.children.length === 1 && this._ars.snapshot.children[0].component?.name === "VrcSearchComponent") {
          if (this._ars.snapshot.children[0].params['type'] == "auto") {
            this.searchString = this.st.email ?? '';
          } else {
            this.searchString = decodeURIComponent(this._ars.snapshot.children[0].params['phrase']);
          }
        }
      })
    ).subscribe();
    if (this._ars.snapshot.children.length === 1 && this._ars.snapshot.children[0].component?.name === "VrcSearchComponent") {
      if (this._ars.snapshot.children[0].params['type'] == "auto") {
        this.searchString = this.st.email ?? '';
      } else {
        this.searchString = decodeURIComponent(this._ars.snapshot.children[0].params['phrase']);
      }
    }
    this._broadcastService.msalSubject$.pipe(
      filter(x => x.eventType == EventType.ACQUIRE_TOKEN_FAILURE || x.eventType === EventType.ACCOUNT_ADDED || x.eventType === EventType.ACCOUNT_REMOVED),
      takeUntil(this._destroying$),
      tap((x: EventMessage) => {
        if (!this._bst.token) {
          this._logout(x.eventType === EventType.ACQUIRE_TOKEN_FAILURE);
        }
      }),
      catchError(err => {
        this._logout(true);
        return err;
      })
    ).subscribe();

    this._broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
        tap(() => {
          this._checkAndSetActiveAccount();

          if (!this._r.url.startsWith("/nice")) {
            // if local login do nothing token has value
            if (!this._bst.token) {
              this._logout(false);
            }
          }
        }),
        catchError(err => {
          this._logout(true);
          return err;
        }),
      ).subscribe();
  }

  public logOff = () => {
    if (this._bst.isAzureUser) {
      this._authService.logoutPopup({
        postLogoutRedirectUri: '/login',
        mainWindowRedirectUri: '/login'
      });
      if (this.st.contactId) {
        this._bst.clearAuthentication();
      } else {
        this._bst.clear();
      }
      this.searchString = '';
      this.st.model = null;
      return;
    }
    if (this.st.contactId) {
      this._bst.clearAuthentication();
    } else {
      this._bst.clear();
    }
    this.searchString = '';
    this.st.model = null;
    this._r.navigate(['login']);
  }

  public isProduction = () => {
    return environment.production
  }

  public new = () => {
	this.st.createNewClicked.emit();
    this._r.navigate(['main/account/new/details']);
  }

  public search = (e?: KeyboardEvent) => {
    if ((!e || e.key === 'Enter') && this.searchString) {
      this._r.navigate(['main/search/' + this.searchType.toLowerCase() + '/' + encodeURIComponent(this.searchString)]);
    }
  }

  private _logout = (forceLogout: boolean): void => {
    if (forceLogout || !(this._authService.instance.getAllAccounts().length > 0)) {
      this._bst.clearAuthentication();
      this._r.navigate(['login']);
    }
  }

  private _checkAndSetActiveAccount = (): void => {
    const activeAccount = this._authService.instance.getActiveAccount();

    if (!activeAccount && this._authService.instance.getAllAccounts().length > 0) {
      const accounts = this._authService.instance.getAllAccounts();
      this._authService.instance.setActiveAccount(accounts[0]);
    }
  }
}


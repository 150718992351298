<ng-container *ngIf="threadData.channel=== 'Email'">
  <div class="modal-body bg-gray-50" style="padding: 1rem;">
    <vrc-communication-email-details
      [model]="emailDetails"
      [status]="threadData.status??''"
      [contactId]="threadData.contactId??0">
    </vrc-communication-email-details>
  </div>
</ng-container>

<ng-container *ngIf="threadData.channel=== 'Chat'">
  <div class="modal-body bg-gray-50" style="padding: 1rem;">
  <vrc-communication-chat-details
    [model]="chatDetails"
    [contactId]="threadData.contactId??0">
  </vrc-communication-chat-details>
</div>
</ng-container>

<ng-container *ngIf="threadData.channel=== 'Voice'">
  <div class="modal-body bg-gray-50" style="padding: 1rem;">
    <vrc-communication-call-details
      [model]="callDetails"
      [id]="threadData.id??''"
      [contactId]="threadData.contactId??0">
    </vrc-communication-call-details>
  </div>
</ng-container>



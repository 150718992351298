import { Component, Input, OnInit } from '@angular/core';

import { VrcStorageService } from '../services/vrc-storage.service';
import { AccountModel } from './../models/mdp/account.model';

@Component({
  selector: 'vrc-interests',
  templateUrl: './vrc-interests.component.html'
})
export class VrcInterestsComponent implements OnInit {
  private _loaded = false;
  private _isEditOn = false;
  private _model: AccountModel | null = null;
  public categories: string[] = [];
  public trigger = 0;
  @Input() set model(x: AccountModel | null) {
    this._model = x;
    if (this._loaded) {
      this.categories = this._getCategories();
      this.trigger = Math.random();
    }
  }
  get model() { return this._model; }
  @Input() set isEditOn(x: boolean) {
    this._isEditOn = x;
  }
  get isEditOn() { return this._isEditOn; }
  constructor(
    public st: VrcStorageService
  ) { }
  public ngOnInit(): void {
    this.categories = this._getCategories();
    this._loaded = true;
  }
  private _getCategories = (): string[] => {
    const categ: string[] = [];
    this.model?.interests?.forEach(x => {
      if (!categ.includes(x.category ?? '')) {
        categ.push(x.category ?? '');
      }
    });
    return categ;
  }
  public selectInterest = (m: { id: string, name: string, category: string, isSelected: boolean }) => {
    m.isSelected = !m.isSelected;
  }
}



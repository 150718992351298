import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { CommunicationCallDetailsModel } from '../models/mdp/communication-call-details.model';
import { CommunicationChatDetailsModel } from '../models/mdp/communication-chat-details.model';
import { CommunicationEmailDetailsModel } from '../models/mdp/communication-email-details.model';
import { CommunicationRequestModel } from '../models/mdp/communication-request.model';
import { CommunicationModel } from '../models/mdp/communication.model';
import { ParamsModel } from '../models/params.model';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import { ValUtilsService } from '../services/val-utils.service';

@Component({
  selector: 'vrc-communication-history',
  templateUrl: './vrc-communication-history.component.html',
  styleUrls: ['./vrc-communication-history.component.scss']
})
export class VrcCommunicationHistoryComponent implements OnInit {
  private _communicationLastParams!: ParamsModel;
  private _email: string | null = null;
  private _phone: string | null = null;
  private _timezoneOffset!: string;
  private _loadingDetails: string | null = null;

  public selectedCommunication!: CommunicationModel;
  public emailDetails: CommunicationEmailDetailsModel | null = null;
  public callDetails: CommunicationCallDetailsModel | null = null;
  public chatDetails: CommunicationChatDetailsModel | null = null;

  public closeModalVisible = false;
  public emailModalVisible = false;
  public chatModalVisible = false;
  public voiceModalVisible = false;

  public totalRecords = 0;
  public loading = false;
  public communication: Array<CommunicationModel> = [];

  constructor(
    private _srvc: VrcDataService,
    private _ms: MessageService,
    private _u: ValUtilsService,
    public st: VrcStorageService,
    private _rend: Renderer2,
  ) { }
  public ngOnInit(): void {
    this._email = this.st.model?.contact && this.st.model.contact.email ? this.st.model.contact.email : null;
    this._phone = this.st.model?.contact && this.st.model.contact.phone ? this.st.model.contact.phone : null;
    this._timezoneOffset = new Date(Date.now()).getTimezoneOffset().toString();
    this._communicationLastParams = new ParamsModel(
      0, 10, undefined, 1, [{ field: "status", order: 1 }, { field: "date", order: -1 }], undefined, null, null);
    this.tableLazyLoad(this._communicationLastParams);
  }

  public tableLazyLoad = (params: ParamsModel) => {
    this._communicationLastParams = params;
    this._getCommunication(params);
  }
  public openEmailDetails = (c: CommunicationModel) => {
    if (this._loadingDetails && this._loadingDetails === c.id) { return; }
    this._loadingDetails = c.id;
    this.selectedCommunication = c;
    this._srvc.getHistoryEmailDetails(c.contactId ?? 0)
      .pipe(
        take(1),
        tap((result) => {
          this._loadingDetails = null;
          this.emailDetails = result;
          this.emailModalVisible = true;
        }),
        catchError((err: HttpErrorResponse) => {
          this._loadingDetails = null;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  public openChatDetails = (c: CommunicationModel) => {
    if (this._loadingDetails && this._loadingDetails === c.id) { return; }
    this._loadingDetails = c.id;
    this.selectedCommunication = c;
    this._srvc.getHistoryChatDetails(c.contactId ?? 0).pipe(
      take(1),
      tap((result) => {
        this._loadingDetails = null;
        this.chatDetails = result;
        this.chatModalVisible = true;
      }),
      catchError((err: HttpErrorResponse) => {
        this._loadingDetails = null;
        this._ms.add({
          severity: 'error',
          summary: 'Error',
          detail: err?.error?.message,
        });
        return of(null);
      })
    )
      .subscribe();
  }

  public openVoiceDetails = (c: CommunicationModel) => {
    if (this._loadingDetails && this._loadingDetails === c.id) { return; }
    this._loadingDetails = c.id;
    this.selectedCommunication = c;
    this._srvc.getHistoryCallDetails(c.contactId ?? 0)
      .pipe(
        take(1),
        tap((result) => {
          this._loadingDetails = null;
          this.callDetails = result;
          this.voiceModalVisible = true;
        }),
        catchError((err: HttpErrorResponse) => {
          this._loadingDetails = null;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  private _getCommunication = (params: ParamsModel) => {
    if (!this.st.model?.id) { return; }
    Promise.resolve().then(() => this.loading = true);

    params.extraData =
      new CommunicationRequestModel(this.st.model?.id, this._email, this._phone, this._timezoneOffset);

    this._srvc
      .getCommunicationHistoryPage(params)
      .pipe(
        take(1),
        tap((result) => {
          if (result && result.data && result.totalRecords !== null && !isNaN(result.totalRecords)) {
            this.totalRecords = result.totalRecords;
            this.communication = result.data;
          }

          this.loading = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this.loading = false;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }
}
<div class="thread-date">
  <h6>
    {{ threadCommunication.currentDate }}
  </h6>
</div>
<div class="thread-messages">
  <ng-container *ngFor="let message of threadCommunication?.messages">
    <div class="thread-item">
      <div class="thread-icon"><i [ngClass]="getIconClass(message.channel)"></i></div>
      <div class="thread-date-time">{{ message?.startDate | date:'short' }}</div>
      <div class="thread-text">{{message?.data?.subject }}</div>
      <div class="thread-status">
        <span>
            <i class="vi float-center me-3"
               [class.vi-radio-check]="(message?.data?.childData?.length>0)"
               [class.vi-radio-minus]="!(message?.data?.childData?.length>0)"
            >
            </i>
        </span>
        <span class="outbound-inbound">{{ message?.isOutgoing ? 'Outbound' : 'Inbound' }} &nbsp;</span>
        <span class="badge badge-status text-gray-700 text-uppercase"
              [class.bg-success-1]="message.status==='Active'"
              [class.bg-info]="message.status==='Open'"
              [class.bg-gray-50]="message.status==='Parked'"
              [class.bg-danger-1]="message.status==='Closed'">
              <i class="vi vi-rectangle me-3"></i>
              {{message.status}}
        </span>
      </div>

      <div class="thread-link" (click)="openDetails(message)">
        <a>details >>></a>
      </div>

    </div>
    <ng-container *ngIf="message?.data?.childData?.length > 0 && message.data.childData !== null">
      <ng-container *ngFor="let childData of message?.data?.childData">
        <div class="thread-item">
          <div class="thread-icon"><i [ngClass]="getIconClass(childData?.childChannel)"></i></div>
          <div class="replied-item">replied</div>
          <div class="thread-date-time">{{ childData?.data?.createdDate | date:'short' }}</div>
          <div class="thread-text">{{childData?.data?.subject }}</div>
          <div class="thread-status">
            <span class="outbound-inbound">{{ 'Outbound' }} </span>
            <span class="badge badge-status text-gray-700 text-uppercase"
                  [class.bg-success-1]="childData.stateName==='Active'"
                  [class.bg-info]="childData.stateName==='Open'"
                  [class.bg-gray-50]="childData.stateName==='Parked'"
                  [class.bg-danger-1]="childData.stateName==='Closed'">
              <i class="vi vi-rectangle me-3"></i>
              {{message?.status}}
            </span>
          </div>
          <ng-container *ngIf="message?.data?.childData?.state !== null">
            <div class="thread-link" (click)="openDetails(message, childData.guid)">
              <a>details >>></a>
            </div>
          </ng-container>

        </div>
      </ng-container>
    </ng-container>




    <ng-template valModal="xl" [(valmVisible)]="emailModalVisible" [valmCloseOnBackdrop]="false">
      <div class="modal-header p-5">
        <h6 class="modal-title">
          Email details
        </h6>
        <button type="button" class="btn-close" (click)="emailModalVisible=false"></button>
      </div>
      <div class="modal-body bg-gray-50">
        <vrc-communication-email-details [model]="emailDetails" [status]="message.status??''" [contactId]="message.contactId??0"></vrc-communication-email-details>
      </div>
      <div class="modal-footer m-0">
        <button (click)="emailModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
      </div>
    </ng-template>

    <ng-template valModal="xl" [(valmVisible)]="chatModalVisible" [valmCloseOnBackdrop]="false">
      <div class="modal-header p-5">
        <h6 class="modal-title">
          Chat details
        </h6>
        <button type="button" class="btn-close" (click)="chatModalVisible=false"></button>
      </div>
      <div class="modal-body bg-gray-50">
        <vrc-communication-chat-details [model]="chatDetails" [contactId]="message.contactId??0"></vrc-communication-chat-details>
      </div>
      <div class="modal-footer m-0">
        <button (click)="chatModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
      </div>
    </ng-template>



    <ng-template valModal="xl" [(valmVisible)]="voiceModalVisible" [valmCloseOnBackdrop]="false">
      <div class="modal-header p-5">
        <h6 class="modal-title">
          Call details
        </h6>
        <button type="button" class="btn-close" (click)="voiceModalVisible=false"></button>
      </div>
      <div class="modal-body bg-gray-50">
        <vrc-communication-call-details [model]="callDetails" [id]="message.id??''" [contactId]="message.contactId??0"></vrc-communication-call-details>
      </div>
      <div class="modal-footer m-0">
        <button (click)="voiceModalVisible=false" type="button" class="btn btn-secondary shadow-sm">Close</button>
      </div>
    </ng-template>


  </ng-container>
</div>


import {Component, inject } from "@angular/core";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommunicationCallDetailsModel } from '../models/mdp/communication-call-details.model';
import { CommunicationChatDetailsModel } from '../models/mdp/communication-chat-details.model';
import { CommunicationEmailDetailsModel } from '../models/mdp/communication-email-details.model';
import { CommunicationRequestModel } from '../models/mdp/communication-request.model';
import { CommunicationModel } from '../models/mdp/communication.model';
import { ParamsModel, ParamsModelThread} from '../models/params.model';
import { FilterThreads } from '../models/mdp/communication-request.model';
import { VrcDataService } from '../services/vrc-data.service';
import { VrcStorageService } from '../services/vrc-storage.service';
import {ValUtilsService} from "../services/val-utils.service";
import {catchError, map, take, tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {of, BehaviorSubject, Observable, scan, switchMap, fromEvent} from "rxjs";
import { DatePipe } from '@angular/common';
import { ThreadModel, ThreadsPaginator } from "../models/threads.model";

import { DOCUMENT, ViewportScroller } from '@angular/common';


@Component({
  selector: 'vrc-communication-thread',
  templateUrl: './vrc-communication-thread.component.html',
  styleUrls: ['./vrc-communication-thread.component.scss'],
})
export class VrcCommunicationThreadComponent  {

  private readonly document = inject(DOCUMENT);
  private readonly viewport = inject(ViewportScroller);

  public communication: Array<CommunicationModel> = [];
  public selectedCommunication!: CommunicationModel;
  public emailDetails: CommunicationEmailDetailsModel | null = null;
  public callDetails: CommunicationCallDetailsModel | null = null;
  public chatDetails: CommunicationChatDetailsModel | null = null;

  private _communicationLastParams!: ParamsModelThread | ParamsModel;
  private _email: string | null = null;
  private _phone: string | null = null;
  private _timezoneOffset!: string;
  private _loadingDetails: string | null = null;

  public loading = false;
  public totalRecords = 0;
  public commTypes: Array<{ key: string, label: string }> = [
    { key: 'None', label: 'All' },
    { key: 'Email', label: 'Email' },
    { key: 'Voice', label: 'Voice' },
    { key: 'Chat', label: 'Chat' }
  ];

  public paginator$: Observable<ThreadsPaginator>;

  public loading$ = new BehaviorSubject(true);
  private page$ = new BehaviorSubject(1);

  public dataComm: ThreadModel[] = [];
  public defaultComm = 'None';

  public todayStart: string | Date = "";
  public todayEnd: string | Date = "";
  public filterEvent = false;


  readonly showScroll$: Observable<boolean> = fromEvent(
    this.document,
    'scroll'
  ).pipe(
    map(() => this.viewport.getScrollPosition()?.[1] > 0)
  );

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private _srvc: VrcDataService,
    private _ms: MessageService,
    private _u: ValUtilsService,
    public st: VrcStorageService,
    private _datePipe: DatePipe
  ) {

    this._email = this.st.model?.contact && this.st.model.contact.email ? this.st.model.contact.email : null;
    this._phone = this.st.model?.contact && this.st.model.contact.phone ? this.st.model.contact.phone : null;
    this._timezoneOffset = new Date(Date.now()).getTimezoneOffset().toString();
    this._communicationLastParams = new ParamsModelThread(
      0, 15, undefined, 1, [{ field: "stateId", order: 1 }, { field: "createDate", order: -1 }], undefined, null, null);
    this.paginator$ = this.loadThreads$(this._communicationLastParams);
  }

  public getAllCommunications = (params: ParamsModelThread, filterThreads?: FilterThreads) => {
    this._communicationLastParams = params;
    filterThreads ? this._getCommunicationThreads(params, filterThreads): this._getCommunicationThreads(params);
  }

  private _getCommunication = (params: ParamsModelThread) => {
    if (!this.st.model?.id) { return; }
    Promise.resolve().then(() => this.loading = true);

    params.extraData =
      new CommunicationRequestModel(this.st.model.id, this._email, this._phone, this._timezoneOffset);

    this._srvc
      .getCommunicationPage(params)
      .pipe(
        take(1),
        tap((result) => {
          if (result && result.data && result.totalRecords !== null && !isNaN(result.totalRecords)) {
            this.totalRecords = result.totalRecords;
            this.communication = result.data;
          }
          this.loading = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this.loading = false;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  private _getCommunicationThreads = (params: ParamsModelThread, filterThreads?: FilterThreads) => {
    if (!this.st.model?.id) { return; }
    Promise.resolve().then(() => this.loading = true);
    params.extraData =
      new CommunicationRequestModel(this.st.model.id, this._email, this._phone, this._timezoneOffset);
    if(filterThreads) {
      params.extraData.filterThreads = filterThreads;
    }
    this._srvc
      .getCommunicationThreadsAggregation(params)
      .pipe(
        take(1),
        tap((result) => {
          this.dataComm = result;
        }),
        catchError((err: HttpErrorResponse) => {
          this.loading = false;
          this._ms.add({
            severity: 'error',
            summary: 'Error',
            detail: err?.error?.message,
          });
          return of(null);
        })
      )
      .subscribe();
  }

  getUniqueDates (communication: Array<CommunicationModel>): {currentDate: string | null, messages: CommunicationModel[]}[] {
    const initDates: CommunicationModel | (string | null)[] = [];
    const currentDates = communication.reduce((acc, curr) => {
      const val = curr.createDate?.split('T');
      if (val !== undefined) {
        acc.push(val[0]);
      }
      return acc;
    }, initDates);

    const uniqueDates = currentDates.reduce((accumulator: (string | null)[], currentValue) => {
      if(!accumulator.includes(currentValue)){
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

    const threadData: {currentDate: string | null, messages: CommunicationModel[]}[] = [];
    uniqueDates.forEach((msgDate) => {
      threadData.push({
        currentDate: msgDate,
        messages: []
      })
    });

    threadData.forEach((item => {
      communication.forEach(comm => {
        if (comm.createDate !== null) {
          if (this._datePipe.transform(Date.parse(comm?.createDate),'yyyy-MM-dd') === item.currentDate) {
            item.messages.push(comm);
          }
        }
      })
    }));
    return threadData;
  }

  filterThreads (formValues: FilterThreads) {

    const communicationType = (
      formValues['communicationType'] !== null &&
      formValues['communicationType'] !== undefined &&
      formValues['communicationType'] !== "" &&
      formValues['communicationType'] !== "None"
    ) ? formValues['communicationType'] : null;

    const todayStart = this._datePipe.transform(this.todayStart,'yyyy-MM-dd');
    const todayEnd = this._datePipe.transform(this.todayEnd,'yyyy-MM-dd');

    const body: FilterThreads = {
      startDate: todayStart,
      endDate: todayEnd,
      communicationType,
    }
    this._communicationLastParams.first = 0;
    this._communicationLastParams.rows = 15;

    this.paginator$ = this.loadThreads$(this._communicationLastParams, body);
    this.filterEvent = true;
  }

  private loadThreads$(params: ParamsModelThread, filter?: FilterThreads ): Observable<ThreadsPaginator> {

    if(params !== undefined) {
      params.extraData = new CommunicationRequestModel(this.st?.model?.id, this._email, this._phone, this._timezoneOffset);
    }
    if(filter !== undefined) {
      params.extraData.filterThreads = filter;
    }
    return this.page$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap(page => {
          if(this.filterEvent) {
            page = 1;
            params.first = 0;
            params.rows = 2;
            this.filterEvent = false;
          }
          this._communicationLastParams.first = page;
          return this._srvc.getCommunicationThreadsPaginator(params);
        }
      ),
      scan(this.updatePaginator, {items: [], page: 0, hasMorePages: true} as ThreadsPaginator),
      tap(
        () => {
          this.loading$.next(false)
        }
      ),
    );
  }

  private updatePaginator(accumulator: ThreadsPaginator, value: ThreadsPaginator): ThreadsPaginator {
    if (value.page === 1) {
      return value;
    }
    accumulator.items.push(...value.items);
    accumulator.page = value.page;
    accumulator.hasMorePages = value.hasMorePages;
    return accumulator;
  }

  public loadMoreProducts(paginator: ThreadsPaginator) {
    if (!paginator.hasMorePages) {
      return;
    }
    this.page$.next(paginator.page + 1);
  }

}

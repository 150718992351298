import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'vrc-communication-unknown-details',
  templateUrl: './vrc-communication-unknown-details.component.html'
})
export class VrcCommunicationUnknownDetailsComponent {
  constructor(
    public config: DynamicDialogConfig
  ) { }
}
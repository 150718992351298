import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'vrc-scroll-to-top',
  templateUrl: './vrc-scroll-to-top.component.html',
  styleUrls: ['./vrc-scroll-to-top.component.scss']
})
export class VrcScrollToTopComponent {

  @Output() scrollToTop = new EventEmitter<void>();

  onScrollToTop(): void {
    this.scrollToTop.emit();
  }
}
